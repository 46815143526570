// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'fuse... Remove this comment to see the full error message
import Fuse from 'fuse.js/dist/fuse.basic.esm.min.js';
import { clone, get, setWith } from 'lodash';

// Sets data at provided path while shallowly cloning objects in the path.
// Returns a new object, does not mutate the original.
export const update = (obj: any, path: any, data: any) => setWith(clone(obj), path, data, clone);

export const replaceAtIndex = (items: any, item: any, index: any) => {
  const itemsCopy = [...items];
  itemsCopy[index] = item;
  return itemsCopy;
};

export const fuzzyListSearch = (list: any, query: any, options = {}) => {
  const fuse = new Fuse(list, { includeScore: true, ...options });
  return fuse.search(query);
};

export const sortCollection = (collection: any, key: any, direction: any) =>
  [...collection].sort((a, b) => {
    const isASC = direction === 'ASC';
    const valueA = get(a, key);
    const valueB = get(b, key);

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return isASC ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }

    if (valueA < valueB) return isASC ? -1 : 1;
    if (valueA > valueB) return isASC ? 1 : -1;
    return 0;
  });

export type LocalSearchProps<T extends any> = {
  items: T[];
  indexFields: string[];
  searchTerm: string;
};

export const localSearch = <T extends any>(props?: LocalSearchProps<T>): T[] => {
  const { items = [], indexFields = [], searchTerm = '' } = props || {};

  // Only recompute the index when things change
  const searchIndex = items.map<string>((item) => {
    return indexFields
      .map((field) => {
        const value = get(item, field);
        return typeof value === 'string' ? value.toLowerCase() : '';
      })
      .join('');
  });

  const normalizedTerm = searchTerm.trim().toLowerCase();

  const found = searchTerm
    ? searchIndex.reduce<T[]>((acc, key, index) => {
        key.includes(normalizedTerm) && acc.push(items[index]);
        return acc;
      }, [])
    : items;

  return found;
};
