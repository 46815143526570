import { pick } from 'lodash';

import { HeaderCanonicalPayloadT, HeaderMapCurateUiStateT } from '.';

export const localStateToCanonicalPayload = (
  vendorStepState: HeaderMapCurateUiStateT,
): HeaderCanonicalPayloadT => {
  if (vendorStepState.canonicalVendor?.canonical_vendor_id) {
    return {
      canonical_vendor: undefined,
      canonical_vendor_id: vendorStepState.canonicalVendor?.canonical_vendor_id,
    };
  } else {
    return {
      canonical_vendor: pick(vendorStepState.canonicalVendor!, [
        'city',
        'country',
        'description',
        'display_name',
        'domain',
        'email_address',
        'founded_year',
        'industry',
        'industry_group',
        'legal_name',
        'location_clearbit',
        'logo',
        'phone_number',
        'sector',
        'state',
        'street_address_1',
        'street_address_2',
        'sub_industry',
        'zip_code',
      ]),

      canonical_vendor_id: vendorStepState.canonicalVendor?.canonical_vendor_id,
    };
  }
};
