/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanonicalLineItemDTO
 */
export interface CanonicalLineItemDTO {
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanonicalLineItemDTO
     */
    files: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanonicalLineItemDTO
     */
    names: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDTO
     */
    canonical_line_item_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDTO
     */
    canonical_line_item_type?: CanonicalLineItemDTOCanonicalLineItemTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDTO
     */
    is_license?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDTO
     */
    is_overage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDTO
     */
    is_fail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDTO
     */
    has_constrained_capacity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDTO
     */
    canonical_vendor_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDTO
     */
    is_staging?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CanonicalLineItemDTO
     */
    average_unit_price?: number;
    /**
     * 
     * @type {number}
     * @memberof CanonicalLineItemDTO
     */
    average_total_amount?: number;
}

export function CanonicalLineItemDTOFromJSON(json: any): CanonicalLineItemDTO {
    return CanonicalLineItemDTOFromJSONTyped(json, false);
}

export function CanonicalLineItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalLineItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'files': json['files'],
        'names': json['names'],
        'canonical_line_item_id': !exists(json, 'canonical_line_item_id') ? undefined : json['canonical_line_item_id'],
        'canonical_line_item_type': !exists(json, 'canonical_line_item_type') ? undefined : json['canonical_line_item_type'],
        'is_license': !exists(json, 'is_license') ? undefined : json['is_license'],
        'is_overage': !exists(json, 'is_overage') ? undefined : json['is_overage'],
        'is_fail': !exists(json, 'is_fail') ? undefined : json['is_fail'],
        'has_constrained_capacity': !exists(json, 'has_constrained_capacity') ? undefined : json['has_constrained_capacity'],
        'canonical_vendor_id': !exists(json, 'canonical_vendor_id') ? undefined : json['canonical_vendor_id'],
        'is_staging': !exists(json, 'is_staging') ? undefined : json['is_staging'],
        'average_unit_price': !exists(json, 'average_unit_price') ? undefined : json['average_unit_price'],
        'average_total_amount': !exists(json, 'average_total_amount') ? undefined : json['average_total_amount'],
    };
}

export function CanonicalLineItemDTOToJSON(value?: CanonicalLineItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'files': value.files,
        'names': value.names,
        'canonical_line_item_id': value.canonical_line_item_id,
        'canonical_line_item_type': value.canonical_line_item_type,
        'is_license': value.is_license,
        'is_overage': value.is_overage,
        'is_fail': value.is_fail,
        'has_constrained_capacity': value.has_constrained_capacity,
        'canonical_vendor_id': value.canonical_vendor_id,
        'is_staging': value.is_staging,
        'average_unit_price': value.average_unit_price,
        'average_total_amount': value.average_total_amount,
    };
}

/**
* @export
* @enum {string}
*/
export enum CanonicalLineItemDTOCanonicalLineItemTypeEnum {
    BYDAY = 'BY_DAY',
    BYVOLUME = 'BY_VOLUME',
    CONSOLIDATED = 'CONSOLIDATED'
}


