import React, { useState } from 'react';

import { deleteManageExecutionByArn } from 'shared/api';
import { Modal } from 'shared/components/modal';
import { ModalControls } from 'shared/components/modal-controls';
import { toast } from 'shared/components/toast';

import { ExecutionModalContainer, ExecutionModalTitle } from './styles';

export const DeleteModal = ({
  executionsToDelete,
  onClose = () => {},
  onComplete = () => {},
  ...rest
}: any) => {
  const [requesting, setRequesting] = useState(false);
  return (
    <Modal
      onClose={() => {
        !requesting && onClose();
      }}
      {...rest}
      renderContent={(modal) => (
        <ExecutionModalContainer>
          <ExecutionModalTitle>
            Are you sure you want to delete{' '}
            {executionsToDelete.length > 1
              ? `these ${executionsToDelete.length} executions `
              : 'this execution '}
            from the audit log?
          </ExecutionModalTitle>
          <ModalControls.Container>
            <ModalControls.CancelButton onClick={modal.close} variant="outlined">
              Cancel
            </ModalControls.CancelButton>
            <ModalControls.ConfirmButton
              isWorking={requesting}
              onClick={() => {
                setRequesting(true);
                Promise.allSettled(
                  executionsToDelete.map((arn: string) => deleteManageExecutionByArn(arn)),
                )
                  .then((res) => {
                    const successful = res.filter((r: any) => r.status === 'fulfilled');
                    const unsuccessful = res.filter((r: any) => r.status !== 'fulfilled');

                    if (res.length === successful.length) {
                      toast.success(
                        `Successfully deleted ${res.length} documents from the audit log.`,
                      );
                    } else {
                      toast.danger(
                        `Deleted ${successful.length} documents from the audit log but ${unsuccessful.length} were unable to be deleted.`,
                      );
                    }
                    onComplete();
                  })
                  .finally(() => {
                    setRequesting(false);
                    modal.close();
                  });
              }}
            >
              Delete
            </ModalControls.ConfirmButton>
          </ModalControls.Container>
        </ExecutionModalContainer>
      )}
    ></Modal>
  );
};
