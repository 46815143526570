import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { border, color, font, padding } from 'shared/styles';

export const Container = styled.div<{ maxWidth?: number }>`
  margin: 0 auto;
  padding: 70px 30px;
  ${({ maxWidth }) => {
    if (maxWidth)
      return css`
        max-width: ${maxWidth}px;
      `;
    return css`
      max-width: 850px;
    `;
  }}
`;

export const Heading = styled.h1`
  padding-bottom: 60px;
  text-align: center;
  ${font.size(32)}
`;

export const Grid = styled.div<{ justify?: 'center' | 'flex-start' }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2%;
  ${({ justify }) => {
    if (justify === 'flex-start')
      return css`
        justify-content: flex-start;
      `;
    return css`
      justify-content: center;
    `;
  }}
`;

export const JobLink = styled(Link)`
  display: block;
  margin: 0 1.25% 4%;
  width: 17.5%;
  padding: 15px 15px;
  text-align: center;
  border-radius: ${border.borderRadius};
  background: ${color.background3};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${padding.med};
`;

export const JobLinkIconContainer = styled.div`
  padding: 15px;
  border-radius: 50%;
  background: linear-gradient(
    31deg,
    rgba(255, 255, 255, 0.15) 8.42%,
    rgba(255, 255, 255, 0.0319) 77.63%
  );
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JobSectionTitle = styled.h2`
  text-transform: uppercase;
  ${font.bold};
  ${font.size(16)}
  margin-bottom: ${padding.med}
`;

export const JobTitle = styled.h3`
  width: 100%;
  text-align: center;
  ${font.size(18)}
`;

export const LogoutContainer = styled.div`
  text-align: center;
  padding-top: 40px;
`;

export const JobCount = styled.p`
  width: 100%;
  text-align: center;
  ${font.size(14)}
  color: ${color.text2};
`;
