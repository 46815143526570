import React from 'react';

import { uniq } from 'lodash';

import { Container, StyledCheckbox } from './styles';

type Props = {
  className?: string;
  value: any[];
  onChange: (...args: any[]) => any;
  options: {
    label?: string;
    value?: any;
  }[];
};

export const CheckboxGroup = ({ className, value: propsValue, onChange, options }: Props) => {
  const items = propsValue.map((val) => val.toString());

  const onCheck = (checked: any, value: any) => {
    onChange(
      checked
        ? uniq([...items, value.toString()])
        : items.filter((val) => val !== value.toString()),
    );
  };

  return (
    <Container className={className}>
      {options.map(({ label, value }) => (
        <StyledCheckbox
          key={value}
          checkboxLabel={label}
          value={items.includes(value.toString())}
          onChange={(_, event) => onCheck(event.target.checked, value)}
        />
      ))}
    </Container>
  );
};
