import React from 'react';

import { Divider, Field, Grid, Label } from '../shared';
import { Container } from './styles';

export const PaymentCheck = () => {
  return (
    <Container>
      <Label>CHECK PAYMENT DETAILS</Label>
      <Grid>
        <Field name="vendor.pay_by_check.pay_by_check_make_check_payable_to" />
        <Field name="vendor.pay_by_check.pay_by_check_address" textarea />
      </Grid>
      <Divider />
      <Label>CREDIT CARD DETAILS</Label>
      <Grid>
        <Field name={'vendor.credit_card.link'} />
      </Grid>
      <Divider />
      <Label>PROCESS DATE</Label>
      <Grid>
        <Field name="vendor.payment_bank.process_date" perRow={1} />
      </Grid>
    </Container>
  );
};

export const PaymentACH = () => {
  return (
    <Container>
      <Label>BANK DETAILS</Label>
      <Grid>
        <Field name="vendor.payment_bank.account_name" perRow={1} />
        <Field name="vendor.payment_bank.bank_name" perRow={2} />
        <Field name="vendor.payment_bank.bank_address" perRow={2} />
      </Grid>
      <Divider />
      <Label>ACH DETAILS</Label>
      <Grid>
        <Field name="vendor.payment_bank.ach_account_number" perRow={2} />
        <Field name="vendor.payment_bank.ach_routing_number" perRow={2} />
      </Grid>
      <Divider />
      <Label>WIRE DETAILS</Label>
      <Grid>
        <Field name={'vendor.payment_bank.wire_account_number'} perRow={2} />
        <Field name={'vendor.payment_bank.swift_code'} perRow={2} />
      </Grid>
    </Container>
  );
};
