import React, { useEffect, useRef } from 'react';

import { useDeepCompareMemoize } from './deep-compare-memoize';

type Ref = React.MutableRefObject<HTMLElement | null>;

export const useOnOutsideClick = (
  ignoredElementRefs: Ref | Ref[],
  isListening: boolean,
  onOutsideClick: () => void,
  listeningElementRef?: Ref,
) => {
  const mouseDownTargetRef = useRef<EventTarget | null>(null);
  const ignoredElementRefsMemoized = useDeepCompareMemoize([ignoredElementRefs].flat());

  useEffect(() => {
    const handleMouseDown = (event: Event) => {
      mouseDownTargetRef.current = event.target;
    };

    const handleMouseUp = (event: Event) => {
      const isAnyIgnoredElementAncestorOfTarget = ignoredElementRefsMemoized
        ?.filter((elementRef) => !!elementRef.current)
        .some(
          (elementRef) =>
            elementRef.current!.contains(mouseDownTargetRef.current as Node) ||
            elementRef.current!.contains(event.target as Node),
        );
      if ((event as MouseEvent).button === 0 && !isAnyIgnoredElementAncestorOfTarget) {
        onOutsideClick();
      }
    };

    const listeningElement = (listeningElementRef || {}).current || document;

    if (isListening) {
      listeningElement.addEventListener('mousedown', handleMouseDown);
      listeningElement.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      listeningElement.removeEventListener('mousedown', handleMouseDown);
      listeningElement.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ignoredElementRefsMemoized, listeningElementRef, isListening, onOutsideClick]);
};
