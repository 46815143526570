import React from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { color } from 'shared/styles';

type Props = {
  date?: string | any | number;
};

export const ExecutionDate = ({ date }: Props) => {
  // we're assuming that the local time these
  // events occurred was utc. if no timezone is pressend
  let d = moment(date || undefined).utc();
  if (!date || !d.isValid())
    return (
      <IndicatorGrid>
        <IndicatorTitle> -- </IndicatorTitle>
        <IndicatorSub> </IndicatorSub>
      </IndicatorGrid>
    );
  const dateString = d.format('MM/DD/YYYY');
  const timeString = d.format('LT');
  return (
    <IndicatorGrid>
      <IndicatorTitle>{dateString}</IndicatorTitle>
      <IndicatorSub>{timeString}</IndicatorSub>
    </IndicatorGrid>
  );
};

const IndicatorGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 2px;
`;
const IndicatorTitle = styled.p`
  color: $fff;
  font-size: 13px;
  line-height: 16px;
`;

const IndicatorSub = styled.p`
  color: ${color.text4}
  font-size: 13px;
  line-height: 16px;
`;
