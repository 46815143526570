import React, { useEffect, useReducer, useRef, useState } from 'react';

import { get } from 'lodash';

import gleanSchema, { GleanSchema } from '@gleanhq/schema';

import * as api from 'shared/api';
import { CommentsStream } from 'shared/components/comments-stream';
import { NavBar } from 'shared/components/nav-bar';
import { StatusBadge } from 'shared/components/status-badge/styles';
import { toast } from 'shared/components/toast';
import { invoicePDFCDNHost } from 'shared/constants';
import { useCommentsData } from 'shared/hooks/comments-meta-data';
import { JobT } from 'shared/types/job';
import { cleanFormDataFields } from 'shared/utils/schema';

import { ActiveFlagsModal } from './active-flags-modal';
import { ErrorsModal } from './errors-modal';
import { getJobSteps } from './get-job-steps';
import {
  CurrentStepT,
  ExtractValidateContext,
  ExtractValidateStateT,
  ValidationFlagT,
  extractValidateReducer,
} from './state';
import { StepForm } from './step-form';
import { Container, InvoicePDF, Right } from './styles';
import {
  getActiveValidationFlags,
  getInitialFormValues,
  getLineItemJobErrors,
  removeEmptyValuesFromFormDataValues,
} from './utils';

const getJobAction = (job: JobT): 'extract' | 'validate' => {
  if (job.jobType.toLowerCase().includes('extract')) return 'extract';
  if (job.jobType.toLowerCase().includes('validate')) return 'validate';
  throw new Error(`${job.jobType} is not an extract or validate job`);
};

export const Job = ({
  data,
  fetchJob,
  deescalating,
}: {
  data: { job: JobT; invoiceData: GleanSchema; validationFlags: ValidationFlagT[] };
  deescalating: boolean;
} & Omit<any, 'data' | 'deescalating'>) => {
  const { job, invoiceData } = data;
  const { executionArn, jobType } = job;

  const jobSteps = getJobSteps(job, data.validationFlags);

  const [defaultStep] = jobSteps;
  const [
    { currentStep, formValues, errors, validationFlags, focusedFlag, jobAction },
    dispatch,
  ] = useReducer(extractValidateReducer, {
    currentStep: defaultStep as ExtractValidateStateT['currentStep'],
    formValues: getInitialFormValues(invoiceData),
    errors: {},
    validationFlags: data.validationFlags,
    focusedFlag: null,
    jobAction: getJobAction(job),
    jobType: jobType as 'detail-extract' | 'detail-validate' | 'header-extract' | 'header-validate',
  });

  const isFirstStep = jobSteps.indexOf(currentStep) === 0;
  const isLastStep = jobSteps.indexOf(currentStep) === jobSteps.length - 1;

  const mergeState = (state: Partial<ExtractValidateStateT>) =>
    dispatch({ type: 'MERGE', payload: state });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [isIncompleteInformationModalOpen, setIsIncompleteInformationModalOpen] = useState(false);
  const [isActiveFlagsModalOpen, setIsActiveFlagsModalOpen] = useState(false);
  const currentFormValuesRef = useRef(formValues);
  const handleStepSubmit = ({
    currentStep,
    formValues,
    focusedFlag,
  }: Partial<ExtractValidateStateT>) => {
    const errors =
      job.jobType.indexOf('detail') !== -1
        ? getLineItemJobErrors(formValues)
        : { errors: undefined, hasErrors: false };
    const newState: Partial<ExtractValidateStateT> = { currentStep, formValues, errors };
    if (focusedFlag) newState.focusedFlag = focusedFlag;
    mergeState(newState);
  };

  const updateValidationFlag = (flagId: string, updateFn: (flag: any) => Partial<any>) => {
    dispatch({
      type: 'UPDATE_VALIDATION_FLAG',
      payload: {
        flagId,
        updateFn,
      },
    });
  };

  useEffect(() => {
    for (let validationFlag of validationFlags) {
      if (validationFlag.invoice_part === 'LINE_ITEMS') {
        const flagId = validationFlag.flag_id;
        const lineItemId = validationFlag.line_item_id;
        const lineItems = invoiceData.line_items.line_items;
        const lineItem = lineItems?.find((lineItem) => lineItem.line_item_id === lineItemId);
        if (!lineItem && !validationFlag.is_resolved) {
          updateValidationFlag(flagId, () => ({ is_resolved: true }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationFlags, invoiceData]);

  const getInvoiceForApi = ({ formData }: any) => {
    const cleanedFormData = cleanFormDataFields({
      schema: gleanSchema,
      formData: formData,
    });

    // The form data payload is confusingly being used to store
    // ui state. It's worth removing these before submitting the
    // payload so the data team don't get mad at us
    delete cleanedFormData._action;
    delete cleanedFormData._step;
    delete cleanedFormData._flag;
    return {
      ...invoiceData,
      ...cleanedFormData,
      ...(job.jobType.toLowerCase().includes('header')
        ? {
            // for header jobs, dont override the original payload line items with the cleaned data.
            // it needs to be preserved until the detail step
            line_items: invoiceData.line_items,
          }
        : {}),
      validation_flags: validationFlags,
    };
  };

  const handleFinalSubmit = async ({ formData }: any) => {
    setHasAttemptedSubmit(true);
    const cleanedFormData = removeEmptyValuesFromFormDataValues(formData);
    const { errors, hasErrors } =
      job.jobType.indexOf('detail') !== -1
        ? getLineItemJobErrors(cleanedFormData)
        : { errors: undefined, hasErrors: false };
    if (hasErrors) {
      setIsIncompleteInformationModalOpen(true);
      mergeState({ errors: { errors } });
      return;
    }

    if (getActiveValidationFlags(validationFlags).length > 0) {
      setIsActiveFlagsModalOpen(true);
      return;
    }

    try {
      setIsSubmitting(true);
      const invoiceForApi = getInvoiceForApi({ formData });
      await api.createJobCompletion({
        job,
        invoice: invoiceForApi,
      });
      toast.success('The job was completed successfully.');
      await fetchJob();
    } catch (e) {
      toast.genericError();
      setIsSubmitting(false);
    }
  };

  const saveInvoiceSnapshot = async () => {
    const invoiceForApi = getInvoiceForApi({ formData: currentFormValuesRef?.current || {} });
    await api.createInvoiceSnapshot({
      job,
      invoice: invoiceForApi,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await saveInvoiceSnapshot();
      toast.success('Saved.');
    } catch (e) {
      toast.danger(e.message);
      toast.danger('Unable to save.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleEscalate = async () => {
    setIsSaving(true);
    await saveInvoiceSnapshot();
    await fetchJob();
    toast.success('This job has been escalated.');
  };

  const { data: executionEvents, isFetching, error, refetch: refetchComments } = api.useQuery({
    queryKey: `execution-comments-${executionArn}`,
    queryFn: () => api.getComments({ executionArn }),
    transformData: (data: { data?: any }) => {
      const { comments, escalations, restartCategory, restartReason, startingStep } = data?.data;
      return {
        comments,
        escalations,
        restartCategory,
        restartReason,
        startingStep,
      };
    },
  });

  const { commentsData, setCommentsData } = useCommentsData({
    comments: executionEvents?.comments,
    restartCategory: executionEvents?.restartCategory,
  });
  const [commentAdded, setCommentAdded] = useState(false);
  const handleSubmitComment = async (value: string) => {
    try {
      await api.addComment({ executionArn, jobType, commentText: value, deescalating });
      setCommentAdded(true);
      refetchComments();
    } catch (e) {
      toast.danger(e.message);
    }
  };

  const getJobHeaderLabel = (jobType: string) => {
    switch (jobType) {
      case 'header-extract':
      default:
        return 'Header Extract';
      case 'header-validate':
        return 'Header Validate';
      case 'detail-extract':
        return 'Detail Extract';
      case 'detail-validate':
        return 'Detail Validate';
    }
  };

  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.GridLeft>
              <NavBar.ExitJob job={job} />
              {deescalating && <StatusBadge backgroundColor="danger">Escalated</StatusBadge>}
            </NavBar.GridLeft>
            <NavBar.Heading>{getJobHeaderLabel(jobType)}</NavBar.Heading>
            <NavBar.GridRight>
              <NavBar.IconBtnGroup>
                <NavBar.Comments
                  className={commentsData.commentsOpen ? 'selected' : ''}
                  onClick={() =>
                    setCommentsData({ ...commentsData, commentsOpen: !commentsData.commentsOpen })
                  }
                />
                <NavBar.Help />
              </NavBar.IconBtnGroup>
              <NavBar.TextBtnGroup>
                <NavBar.SaveJob onSave={handleSave} isWorking={isSaving} />
                {!deescalating && <NavBar.EscalateJob onEscalate={handleEscalate} job={job} />}
                <NavBar.ViewExecution arn={executionArn} />
                {deescalating && (
                  <NavBar.DeescalateJob
                    onDeescalated={fetchJob}
                    job={job}
                    commentAdded={commentAdded}
                  />
                )}
              </NavBar.TextBtnGroup>
            </NavBar.GridRight>
          </>
        }
      />

      <Container>
        <InvoicePDF>
          <iframe
            src={job.fileBucket ? `${invoicePDFCDNHost}/${job.fileKey}` : undefined}
            title="Invoice PDF"
          />
        </InvoicePDF>
        <Right>
          <CommentsStream
            error={error}
            comments={executionEvents?.comments || []}
            executionEvents={executionEvents}
            handleSubmitComment={handleSubmitComment}
            isFetching={isFetching}
            open={commentsData.commentsOpen}
          />
          <ExtractValidateContext.Provider
            value={[
              {
                currentStep,
                formValues,
                errors,
                validationFlags,
                focusedFlag,
                jobAction,
                jobType: jobType as
                  | 'detail-extract'
                  | 'detail-validate'
                  | 'header-extract'
                  | 'header-validate',
              },
              dispatch,
            ]}
          >
            <StepForm
              job={job}
              currentStep={currentStep}
              hideValidationFlags={!commentsData.commentsOpen}
              formValues={formValues}
              initialFormValues={getInitialFormValues(invoiceData)}
              confidenceScores={get(invoiceData, 'confidence_scores')}
              errors={hasAttemptedSubmit ? errors : {}}
              validationFlags={validationFlags}
              updateValidationFlag={updateValidationFlag}
              focusedFlag={focusedFlag}
              currentFormValuesRef={currentFormValuesRef}
              onBack={
                !isFirstStep
                  ? (formData: Partial<GleanSchema>) => {
                      const prevStepIndex = jobSteps.indexOf(currentStep) - 1;
                      handleStepSubmit({
                        currentStep: jobSteps[prevStepIndex],
                        formValues: formData,
                      });
                    }
                  : undefined
              }
              onNext={
                !isLastStep
                  ? (formData: Partial<GleanSchema>) => {
                      const nextStepIndex = jobSteps.indexOf(currentStep) + 1;
                      handleStepSubmit({
                        currentStep: jobSteps[nextStepIndex],
                        formValues: formData,
                      });
                    }
                  : undefined
              }
              onStepChange={(formData: Partial<GleanSchema>, step: CurrentStepT) => {
                handleStepSubmit({
                  currentStep: step,
                  formValues: formData,
                });
              }}
              onSubmit={
                isLastStep
                  ? (formData: Partial<GleanSchema>) => {
                      handleFinalSubmit({ formData });
                    }
                  : undefined
              }
              isSubmitting={isSubmitting}
              readOnly={deescalating}
            />
            {isIncompleteInformationModalOpen && (
              <ErrorsModal
                errors={errors}
                currentStep={currentStep}
                onClose={() => setIsIncompleteInformationModalOpen(false)}
              />
            )}
            {isActiveFlagsModalOpen && (
              <ActiveFlagsModal onClose={() => setIsActiveFlagsModalOpen(false)} />
            )}
          </ExtractValidateContext.Provider>
        </Right>
      </Container>
    </>
  );
};
