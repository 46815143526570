import React, { useState } from 'react';

import { Spinner } from '@glean/glean-ui.atoms.spinner';
import { Button } from '@glean/glean-ui.molecules.button';

import { CommentDTO } from 'shared/api/generated/models/CommentDTO';
import { Comments } from 'shared/components/comments';
import { ExecutionMetadataT } from 'shared/types';

import { ExecutionEvents } from './execution-events/execution-events';
import { CommentSubmit, Header, Inner, Slide, SpinnerContainer } from './styles';

export const CommentsStream = ({
  executionEvents = {},
  comments,
  handleSubmitComment,
  open,
  isFetching,
  error,
}: {
  executionEvents?: ExecutionMetadataT;
  handleSubmitComment: (comment: string) => void;
  open?: boolean | null;
  isFetching?: boolean;
  error?: {
    error?: Error;
    errorMessage?: string;
  };
  comments: CommentDTO[];
}) => {
  const [value, setValue] = useState('');
  const handleChange = (e: any) => {
    const target = e.target;
    setValue(target.value);
  };
  const onClickSubmitComment = async (e: any) => {
    e.preventDefault();
    if (value !== '') {
      handleSubmitComment(value);
      setValue('');
    }
  };
  return (
    <Slide open={open}>
      <Inner>
        <ExecutionEvents
          escalations={executionEvents?.escalations || []}
          restartCategory={executionEvents.restartCategory}
          restartReason={executionEvents.restartReason}
          startingStep={executionEvents.startingStep}
        />

        <Header>Comments</Header>
        {isFetching ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <Comments comments={comments} error={error} />
        )}
        <CommentSubmit>
          <textarea
            placeholder="Comment here..."
            id="textarea-comment"
            onChange={(e) => {
              handleChange(e);
            }}
            value={value}
          />
          <Button
            isWorking={isFetching}
            onClick={(e: any) => {
              onClickSubmitComment(e);
            }}
            type="submit"
          >
            Submit
          </Button>
        </CommentSubmit>
      </Inner>
    </Slide>
  );
};
