import React, { useState } from 'react';

import { intersectionWith, isEqual } from 'lodash';

import { Spinner } from '@glean/glean-ui.atoms.spinner';

import { restartManageExecutions, usePipelineExecutionRestartSteps } from 'shared/api';
import { Checkbox } from 'shared/components/checkbox';
import { Form } from 'shared/components/form';
import { Modal, ModalPropT } from 'shared/components/modal';
import { ModalControls } from 'shared/components/modal-controls';
import { toast } from 'shared/components/toast';

import { executionReprocessCategory } from './constants';
import {
  ExecutionModalTitle,
  RestartModalFormFields,
  RestartModalLoadingContainer,
  RestartModalStepSelectContainer,
} from './styles';

export const RestartModal = ({
  executionsToRestart,
  onComplete = () => {},
  ...rest
}: {
  executionsToRestart: {
    executionArn: string;
    lastUpdatedDate?: Date | undefined;
  }[];
  onComplete?: () => void;
} & Omit<ModalPropT, 'renderContent'>) => {
  const [includeInputStep, setIncludeInputStep] = useState(false);

  const { data: executionsRestartSteps, isLoading } = usePipelineExecutionRestartSteps({
    executions: executionsToRestart,
  });

  let rawAvailableRestartSteps: string[] = [];

  for (let executionRestartSteps of executionsRestartSteps || []) {
    if (rawAvailableRestartSteps.length === 0) {
      rawAvailableRestartSteps = executionRestartSteps.availableRestartSteps;
    } else {
      const intersectingRestartSteps = intersectionWith(
        executionRestartSteps.availableRestartSteps,
        rawAvailableRestartSteps,
        isEqual,
      );
      rawAvailableRestartSteps = intersectingRestartSteps;
    }
  }

  const availableRestartSteps = rawAvailableRestartSteps.map((step) => ({
    label: step,
    value: step,
  }));

  const [requesting, setRequesting] = useState(false);
  const handleClickRestart = async ({
    inputStep,
    restartCategory,
    restartReason,
    startingStep,
    modal,
  }: any) => {
    try {
      setRequesting(true);
      const executionList = executionsToRestart.map((execution) => ({
        executionArn: execution.executionArn,
        startingStep,
        restartCategory,
        restartReason,
        ...(includeInputStep ? { inputStep } : {}),
      }));
      const res = await restartManageExecutions({ executionList });
      if ((res as any).data?.successful?.length > 0) {
        for (const { original_execution_arn, restart_execution_arn } of res.data.successful) {
          toast.success(
            `${original_execution_arn} restarted successfully. New ARN: ${restart_execution_arn}`,
          );
        }
      }
      // When restarting multiple executions and some of them fail a 207 will return,
      // after toasting the successful ones throw an error for the failed executions
      if (res.data?.failed?.length > 0) {
        throw Object.assign(new Error((res as any).error), { response: res });
      }
      onComplete();
    } catch (e) {
      setRequesting(true);
      if (e.response.data?.failed?.length > 0) {
        for (const { original_execution_arn, error } of e.response.data.failed) {
          toast.danger(`Unable to restart. ${original_execution_arn}. ${error}`);
        }
      }
    }
    setRequesting(false);
    modal.close();
  };
  return (
    <Modal
      {...rest}
      renderContent={(modal) => {
        return isLoading ? (
          <RestartModalLoadingContainer>
            <Spinner />
          </RestartModalLoadingContainer>
        ) : (
          <>
            <Form
              initialValues={{
                inputStep: '',
                restartCategory: '',
                restartReason: '',
                startingStep: '',
              }}
              validations={{
                restartCategory: Form.is.required('Required'),
                restartReason: Form.is.required('Required'),
                startingStep: Form.is.required('Required'),
              }}
              onSubmit={async ({
                inputStep,
                restartCategory,
                restartReason,
                startingStep,
              }: any) => {
                handleClickRestart({
                  inputStep,
                  restartCategory,
                  restartReason,
                  startingStep,
                  modal,
                });
              }}
            >
              {({ submitForm }: any) => {
                return (
                  <>
                    <ExecutionModalTitle>
                      Are you sure you want to restart{' '}
                      {executionsToRestart.length > 1
                        ? `these ${executionsToRestart.length} executions?`
                        : 'this execution?'}
                    </ExecutionModalTitle>

                    <RestartModalFormFields>
                      <RestartModalStepSelectContainer>
                        <label htmlFor="restart-category-select">Restart Reason:</label>
                        <Form.Field.Select
                          className="noMargin"
                          name="restartCategory"
                          id="restart-category-select"
                          isGroup={true}
                          options={[{ label: 'Select', value: '' }, ...executionReprocessCategory]}
                        />
                      </RestartModalStepSelectContainer>

                      <RestartModalStepSelectContainer>
                        <div />
                        <Form.Field.Textarea
                          className="noMargin"
                          name="restartReason"
                          placeholder="Explain reason here..."
                          resizeDirection="vertical"
                        ></Form.Field.Textarea>
                      </RestartModalStepSelectContainer>

                      <RestartModalStepSelectContainer>
                        <label htmlFor="starting-step-select">Starting Step:</label>
                        <Form.Field.Select
                          className="noMargin"
                          id="starting-step-select"
                          options={[{ label: 'Select', value: '' }, ...availableRestartSteps]}
                          name="startingStep"
                        />
                      </RestartModalStepSelectContainer>

                      {includeInputStep && (
                        <RestartModalStepSelectContainer>
                          <label htmlFor="input-step-select">Input Step:</label>
                          <Form.Field.Select
                            className="noMargin"
                            id="input-step-select"
                            options={[{ label: 'Select', value: '' }, ...availableRestartSteps]}
                            name="inputStep"
                          />
                        </RestartModalStepSelectContainer>
                      )}
                    </RestartModalFormFields>

                    <ModalControls.Container>
                      <Checkbox
                        value={includeInputStep}
                        checkboxLabel={'Include Input Step'}
                        onChange={() => {
                          setIncludeInputStep(!includeInputStep);
                        }}
                      />
                      <div style={{ display: 'flex', gap: '16px' }}>
                        <ModalControls.CancelButton
                          onClick={() => modal.close()}
                          variant="unstyled"
                        >
                          Cancel
                        </ModalControls.CancelButton>
                        <ModalControls.ConfirmButton isWorking={requesting} onClick={submitForm}>
                          Restart
                        </ModalControls.ConfirmButton>
                      </div>
                    </ModalControls.Container>
                  </>
                );
              }}
            </Form>
          </>
        );
      }}
    />
  );
};
