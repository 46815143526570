import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { Divider } from 'shared/components/divider';

import { Buttons } from '../styles';
import { Container, Header, NewCliName, StyledRow } from './styles';

export const CreateConfirmationModal = ({
  newCliName,
  suggestedClis,
  onSubmit,
  onCanonicalLineItemSelect,
  onCancel,
}: {
  newCliName: string;
  suggestedClis: any[];
  onSubmit: () => void;
  onCanonicalLineItemSelect: (cli: any) => void;
  onCancel: () => void;
}) => {
  return (
    <Container>
      <Header>Are you sure you want to create this new CLI?</Header>
      <NewCliName>{`"${newCliName}"`}</NewCliName>

      <Buttons>
        <Button variant="outlined" icon="arrowLeft" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" onClick={onSubmit}>
          Create a new CLI
        </Button>
      </Buttons>
      <Divider />

      <Header>Or click to select an existing CLI:</Header>
      <ul>
        {suggestedClis.map((cli) => {
          return (
            <StyledRow
              key={cli.canonical_line_item_id}
              onClick={() => onCanonicalLineItemSelect(cli)}
            >
              {cli.name}
            </StyledRow>
          );
        })}
      </ul>
    </Container>
  );
};
