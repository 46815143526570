import React, { useState } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';
import gleanSchema, { GleanSchema } from '@gleanhq/schema';

import * as api from 'shared/api';
import { CanonicalVendorT } from 'shared/api/generated';
import { Avatar } from 'shared/components/avatar';
import { Form } from 'shared/components/form';
import { PageLoader } from 'shared/components/page-loader';
import { cleanFormDataFields } from 'shared/utils/schema';

import {
  Actions,
  Container,
  Fields,
  Header,
  Heading,
  Section,
  SectionTitle,
  Vendor,
  VendorName,
} from './styles';

export const VendorCreateForm = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (formData: CanonicalVendorT) => Promise<void> | void;
  onCancel: () => void;
}) => {
  const [formState, setFormState] = useState(getInitialValuesFromVendorData({}));
  const [isEnrichFormOpen, setIsEnrichFormOpen] = useState(false);
  return !isEnrichFormOpen ? (
    <BaseForm
      formState={formState}
      onSubmit={(formData: any) => {
        setFormState(formData);
        setIsEnrichFormOpen(true);
      }}
      onCancel={onCancel}
    />
  ) : (
    <EnrichForm
      formState={formState}
      onSubmit={(formData: any) => onSubmit(cleanVendorFormData(formData))}
      onCancel={() => setIsEnrichFormOpen(false)}
    />
  );
};
export const VendorEditForm = ({ vendorData, onSubmit, onCancel }: any) => (
  <RichForm
    isEdit
    initialFormData={getInitialValuesFromVendorData(vendorData)}
    onSubmit={(formData: any) => onSubmit(cleanVendorFormData(formData))}
    onCancel={onCancel}
  />
);

const BaseForm = ({ formState, onSubmit, onCancel }: any) => (
  <Container maxWidth="320px">
    <Form
      initialValues={formState}
      validations={{
        display_name: Form.is.required(),
        legal_name: Form.is.required(),
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: any) => (
        <Form.Element>
          <Heading>Create Canonical Vendor</Heading>

          <Form.Field.Input name="display_name" label="Display Name *" />
          <Form.Field.Input name="legal_name" label="Legal Name *" />
          <Form.Field.Input name="domain" label="Domain (Website URL)" />

          <Actions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" isWorking={isSubmitting}>
              Next
            </Button>
          </Actions>
        </Form.Element>
      )}
    </Form>
  </Container>
);
const EnrichForm = ({ formState, onSubmit, onCancel }: any) => {
  const { data: clearbit, isLoading } = api.useQuery({
    queryKey: formState.domain && ['clearbitCompany', formState.domain],
    queryFn: () => api.getClearbitCompany(formState.domain),
    validateData: (res: any) => res && res.data && !res.data.error,
    transformData: (res: any) => res.data,
  });
  if (isLoading)
    return (
      <Container maxWidth="560px">
        <PageLoader padding={80} message="Fetching vendor data..." />
      </Container>
    );
  return (
    <RichForm
      isEdit={false}
      initialFormData={getInitialValuesFromClearbit(clearbit || {}, formState)}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};
const RichForm = ({ isEdit, initialFormData, onSubmit, onCancel }: any) => {
  const [isLogoRemoved, setIsLogoRemoved] = useState(false);
  return (
    <Container maxWidth="100%">
      <Header>
        <Heading>{isEdit ? 'Edit Vendor' : 'Verify Canonical Vendor Details'}</Heading>

        <Vendor>
          <Avatar
            src={isLogoRemoved ? undefined : initialFormData.logo}
            alt={initialFormData.display_name}
          />
          <VendorName>{initialFormData.display_name}</VendorName>
          <Button variant="outlined" size="small" onClick={() => setIsLogoRemoved(!isLogoRemoved)}>
            {isLogoRemoved ? 'Use' : 'Remove'} Logo
          </Button>
        </Vendor>
      </Header>

      <Form
        initialValues={initialFormData}
        validations={{
          display_name: Form.is.required(),
          legal_name: Form.is.required(),
        }}
        onSubmit={(formData: any) => {
          onSubmit({
            ...formData,
            logo: isLogoRemoved ? '' : formData.logo,
          });
        }}
      >
        {({ isSubmitting }: any) => (
          <Form.Element>
            <Section>
              <SectionTitle>About</SectionTitle>
              <Fields>
                <Form.Field.Input name="display_name" label="Display Name *" />
                <Form.Field.Input name="legal_name" label="Legal Name *" />
                <Form.Field.Input name="sector" label="Sector" />
                <Form.Field.Input name="industry" label="Industry" />
                <Form.Field.Input name="industry_group" label="Industry Group" />
                <Form.Field.Input name="sub_industry" label="Sub Industry" />
                <Form.Field.Input name="description" label="Description" />
                <Form.Field.Input name="founded_year" label="Founding Year" />
              </Fields>
            </Section>
            <Section>
              <SectionTitle>Contact Information</SectionTitle>
              <Fields>
                <Form.Field.Input name="email_address" label="Email Address" />
                <Form.Field.Input name="phone_number" label="Phone Number" />
                <Form.Field.Input name="location_clearbit" label="Location" />
                <Form.Field.Input name="country" label="Country" />
                <Form.Field.Input name="zip_code" label="ZIP Code" />
                <Form.Field.Input name="state" label="State" />
                <Form.Field.Input name="city" label="City" />
                <Form.Field.Input name="street_address_1" label="Street Address 1" />
                <Form.Field.Input name="street_address_2" label="Street Address 2" />
              </Fields>
            </Section>

            <Actions>
              <Button variant="outlined" onClick={onCancel}>
                {isEdit ? 'Cancel' : 'Back'}
              </Button>
              <Button type="submit" isWorking={isSubmitting}>
                Submit
              </Button>
            </Actions>
          </Form.Element>
        )}
      </Form>
    </Container>
  );
};
const getInitialValuesFromVendorData = (vendorData: Partial<CanonicalVendorT>) =>
  Form.initialValues(vendorData, (get: any) => ({
    display_name: get('display_name'),
    legal_name: get('legal_name'),
    domain: get('domain'),
    phone_number: get('phone_number'),
    email_address: get('email_address'),
    sector: get('sector'),
    industry: get('industry'),
    industry_group: get('industry_group'),
    sub_industry: get('sub_industry'),
    description: get('description'),
    founded_year: get('founded_year'),
    location_clearbit: get('location_clearbit'),
    logo: get('logo'),
    country: get('country'),
    zip_code: get('zip_code'),
    state: get('state'),
    city: get('city'),
    street_address_1: get('street_address_1'),
    street_address_2: get('street_address_2'),
  }));
const getInitialValuesFromClearbit = (clearbitData: any, formState: CanonicalVendorT) =>
  Form.initialValues(clearbitData, (get: any) => ({
    display_name: get('name') || formState.display_name,
    legal_name: formState.legal_name,
    domain: formState.domain,
    phone_number: get('phone'),
    email_address: get('site.email_addresses.0'),
    sector: get('category.sector'),
    industry: get('category.industry'),
    industry_group: get('category.industryGroup'),
    sub_industry: get('category.subIndustry'),
    description: get('description'),
    founded_year: get('founded_year'),
    location_clearbit: get('location'),
    logo: get('logo'),
    country: get('geo.country'),
    zip_code: get('geo.postalCode'),
    state: get('geo.state'),
    city: get('geo.city'),
    street_address_1: get('geo.streetName'),
    street_address_2: get('geo.streetNumber'),
  }));
const cleanVendorFormData = (formData: GleanSchema) =>
  cleanFormDataFields({
    schema: gleanSchema.properties.canonical_vendor,
    formData,
  });
