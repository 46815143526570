export type Value = any;
export type FieldValues = { [key: string]: Value };
export type Validator = (value: Value, fieldValues: FieldValues) => false | string;
export type FieldValidators = {
  [key: string]: Validator | Validator[] | FieldValidators;
};

const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const is = {
  match: (testFn: any, message = '') => (value: any, fieldValues: any) =>
    !testFn(value, fieldValues) && message,
  required: (errorText = 'This field is required') => (value: any) =>
    isNilOrEmptyString(value) && errorText,
  minLength: (min: any) => (value: any) =>
    !!value && value.length < min && `Must be at least ${min} characters`,
  maxLength: (max: any) => (value: any) =>
    !!value && value.length > max && `Must be at most ${max} characters`,
  notEmptyArray: () => (value: any) =>
    Array.isArray(value) && value.length === 0 && 'Please add at least one item',
  email: () => (value: any) => !!value && !/.+@.+\..+/.test(value) && 'Must be a valid email',
  uuid: (errorText = 'This field must be a valid UUID') => (value: any) =>
    typeof value === 'string' && !uuidRegex.test(value) && errorText,
};
const isNilOrEmptyString = (value: any) =>
  value === undefined || value === null || (typeof value === 'string' && value.trim() === '');
export const generateErrors = (fieldValues: any, fieldValidators: any) => {
  const errors = {};
  Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
    [validators].flat().forEach((validator) => {
      const errorMessage = (validator as any)(fieldValues[fieldName], fieldValues);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (errorMessage && !errors[fieldName]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        errors[fieldName] = errorMessage;
      }
    });
  });
  return errors;
};
