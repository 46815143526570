import styled, { css } from 'styled-components';

import { border, color, font, mixin, padding } from 'shared/styles';

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
`;

export const GridCell = styled.div<{
  perRow: number;
  isValidate?: boolean;
  hasActiveFlags?: boolean;
  hasFocusedFlag?: boolean;
}>`
  width: 100%;
  padding: 0 6px;
  ${(props) => props.perRow && `width: ${100 / props.perRow}%;`}

  ${(props) =>
    props.isValidate &&
    css`
      input,
      select {
        background: none;
        border: 1px solid transparent;
        &:hover,
        &:focus {
          ${mixin.placeholderColor('transparent')}
        }
      }
    `}

  ${(props) =>
    props.hasActiveFlags &&
    css`
      input,
      select {
        background: ${color.background4};
        &,
        &:hover:not(:focus) {
          border: 1px solid ${color.warning};
        }
      }
    `}

  ${(props) =>
    props.hasFocusedFlag &&
    css`
      input,
      select {
        border: 1.5px solid ${color.warning};
        box-shadow: 0 0 12px 0 ${mixin.rgba(color.warning, 0.8)};
      }
    `}
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: ${border.section};
  margin: 24px 0 0;
`;

export const SectionHeading = styled.h5`
  padding-top: 24px;
  ${font.size(16)}
  ${font.medium}
`;

export const Label = styled.p`
  ${font.bold}
  ${font.size(13)}
  text-transform: uppercase;
  margin-top: ${padding.med};
  margin-bottom: calc(-1 * ${padding.sm});
`;
