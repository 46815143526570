import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { popoverAnchorDomId } from 'app';

import { useOnEscapeKeyDown } from 'shared/hooks/on-escape-keydown';
import { useOnOutsideClick } from 'shared/hooks/on-outside-click';

import { ClickableOverlay, CloseIcon, ScrollOverlay, StyledModal } from './styles';

export type ModalPropT = {
  className?: string;
  variant?: 'center' | 'aside';
  maxWidth?: number;
  fullHeight?: boolean;
  withCloseIcon?: boolean;
  isOpen?: boolean;
  onClose?: (...args: any[]) => any;
  renderHandle?: (...args: any[]) => any;
  renderContent: (...args: any[]) => any;
};

export const Modal = ({
  className,
  variant = 'center',
  maxWidth = 600,
  fullHeight = false,
  withCloseIcon = true,
  isOpen: propsIsOpen,
  onClose: tellParentToClose = () => {},
  renderHandle = () => null,
  renderContent,
}: ModalPropT) => {
  const [stateIsOpen, setStateOpen] = useState(false);
  const isControlled = typeof propsIsOpen === 'boolean';
  const isOpen = isControlled ? propsIsOpen : stateIsOpen;

  const modalRef = useRef<HTMLDivElement>(null);
  const clickableOverlayRef = useRef<HTMLDivElement>(null);

  const closeModal = useCallback(() => {
    if (!isControlled) {
      setStateOpen(false);
    } else {
      tellParentToClose();
    }
  }, [isControlled, tellParentToClose]);

  useOnOutsideClick(modalRef, isOpen, closeModal, clickableOverlayRef);
  useOnEscapeKeyDown(isOpen, closeModal);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);
  const $root = document.getElementById(popoverAnchorDomId);
  if (!$root) return null;

  return (
    <>
      {!isControlled &&
        React.cloneElement(renderHandle(), {
          onClick: () => setStateOpen(true),
        })}

      {isOpen &&
        ReactDOM.createPortal(
          <ScrollOverlay>
            <ClickableOverlay variant={variant} ref={clickableOverlayRef}>
              <StyledModal
                className={className}
                maxWidth={maxWidth}
                fullHeight={fullHeight}
                ref={modalRef}
              >
                {withCloseIcon && <CloseIcon type="close" size={32} onClick={closeModal} />}
                {renderContent({ close: closeModal })}
              </StyledModal>
            </ClickableOverlay>
          </ScrollOverlay>,
          $root,
        )}
    </>
  );
};
