/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineItemT
 */
export interface LineItemT {
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    lineItemId: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    lineItemType?: LineItemTLineItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    commitmentTerm?: LineItemTCommitmentTermEnum;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    periodStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    periodEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    billingFrequency?: LineItemTBillingFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    quantityUnits: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    dailyUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    normalizedUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    creditDiscountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    pretaxSubtotalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    feesAndSurcharges?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    totalTaxAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    canonicalLineItemId: string;
    /**
     * 
     * @type {Date}
     * @memberof LineItemT
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof LineItemT
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LineItemT
     */
    deletedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemT
     */
    hasCapacityLimit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    capacityLimitValue?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItemT
     */
    capacityUnitType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemT
     */
    isInstallment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    installmentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    totalInstallments?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemT
     */
    isProrated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalTotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalCreditDiscountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalPretaxSubtotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalTotalTaxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalDailyUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemT
     */
    originalNormalizedUnitPrice?: number;
}

export function LineItemTFromJSON(json: any): LineItemT {
    return LineItemTFromJSONTyped(json, false);
}

export function LineItemTFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItemT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemId': json['lineItemId'],
        'lineItemType': !exists(json, 'lineItemType') ? undefined : json['lineItemType'],
        'commitmentTerm': !exists(json, 'commitmentTerm') ? undefined : json['commitmentTerm'],
        'invoiceId': json['invoiceId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'itemNumber': !exists(json, 'itemNumber') ? undefined : json['itemNumber'],
        'periodStartDate': !exists(json, 'periodStartDate') ? undefined : json['periodStartDate'],
        'periodEndDate': !exists(json, 'periodEndDate') ? undefined : json['periodEndDate'],
        'billingFrequency': !exists(json, 'billingFrequency') ? undefined : json['billingFrequency'],
        'quantityUnits': json['quantityUnits'],
        'unitPrice': !exists(json, 'unitPrice') ? undefined : json['unitPrice'],
        'dailyUnitPrice': !exists(json, 'dailyUnitPrice') ? undefined : json['dailyUnitPrice'],
        'normalizedUnitPrice': !exists(json, 'normalizedUnitPrice') ? undefined : json['normalizedUnitPrice'],
        'totalAmount': !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
        'creditDiscountAmount': !exists(json, 'creditDiscountAmount') ? undefined : json['creditDiscountAmount'],
        'pretaxSubtotalAmount': json['pretaxSubtotalAmount'],
        'feesAndSurcharges': !exists(json, 'feesAndSurcharges') ? undefined : json['feesAndSurcharges'],
        'totalTaxAmount': !exists(json, 'totalTaxAmount') ? undefined : json['totalTaxAmount'],
        'canonicalLineItemId': json['canonicalLineItemId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'hasCapacityLimit': !exists(json, 'hasCapacityLimit') ? undefined : json['hasCapacityLimit'],
        'capacityLimitValue': !exists(json, 'capacityLimitValue') ? undefined : json['capacityLimitValue'],
        'capacityUnitType': !exists(json, 'capacityUnitType') ? undefined : json['capacityUnitType'],
        'isInstallment': !exists(json, 'isInstallment') ? undefined : json['isInstallment'],
        'installmentNumber': !exists(json, 'installmentNumber') ? undefined : json['installmentNumber'],
        'totalInstallments': !exists(json, 'totalInstallments') ? undefined : json['totalInstallments'],
        'isProrated': !exists(json, 'isProrated') ? undefined : json['isProrated'],
        'originalTotalAmount': !exists(json, 'originalTotalAmount') ? undefined : json['originalTotalAmount'],
        'originalCreditDiscountAmount': !exists(json, 'originalCreditDiscountAmount') ? undefined : json['originalCreditDiscountAmount'],
        'originalPretaxSubtotalAmount': !exists(json, 'originalPretaxSubtotalAmount') ? undefined : json['originalPretaxSubtotalAmount'],
        'originalTotalTaxAmount': !exists(json, 'originalTotalTaxAmount') ? undefined : json['originalTotalTaxAmount'],
        'originalUnitPrice': !exists(json, 'originalUnitPrice') ? undefined : json['originalUnitPrice'],
        'originalDailyUnitPrice': !exists(json, 'originalDailyUnitPrice') ? undefined : json['originalDailyUnitPrice'],
        'originalNormalizedUnitPrice': !exists(json, 'originalNormalizedUnitPrice') ? undefined : json['originalNormalizedUnitPrice'],
    };
}

export function LineItemTToJSON(value?: LineItemT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemId': value.lineItemId,
        'lineItemType': value.lineItemType,
        'commitmentTerm': value.commitmentTerm,
        'invoiceId': value.invoiceId,
        'name': value.name,
        'description': value.description,
        'itemNumber': value.itemNumber,
        'periodStartDate': value.periodStartDate,
        'periodEndDate': value.periodEndDate,
        'billingFrequency': value.billingFrequency,
        'quantityUnits': value.quantityUnits,
        'unitPrice': value.unitPrice,
        'dailyUnitPrice': value.dailyUnitPrice,
        'normalizedUnitPrice': value.normalizedUnitPrice,
        'totalAmount': value.totalAmount,
        'creditDiscountAmount': value.creditDiscountAmount,
        'pretaxSubtotalAmount': value.pretaxSubtotalAmount,
        'feesAndSurcharges': value.feesAndSurcharges,
        'totalTaxAmount': value.totalTaxAmount,
        'canonicalLineItemId': value.canonicalLineItemId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'hasCapacityLimit': value.hasCapacityLimit,
        'capacityLimitValue': value.capacityLimitValue,
        'capacityUnitType': value.capacityUnitType,
        'isInstallment': value.isInstallment,
        'installmentNumber': value.installmentNumber,
        'totalInstallments': value.totalInstallments,
        'isProrated': value.isProrated,
        'originalTotalAmount': value.originalTotalAmount,
        'originalCreditDiscountAmount': value.originalCreditDiscountAmount,
        'originalPretaxSubtotalAmount': value.originalPretaxSubtotalAmount,
        'originalTotalTaxAmount': value.originalTotalTaxAmount,
        'originalUnitPrice': value.originalUnitPrice,
        'originalDailyUnitPrice': value.originalDailyUnitPrice,
        'originalNormalizedUnitPrice': value.originalNormalizedUnitPrice,
    };
}

/**
* @export
* @enum {string}
*/
export enum LineItemTLineItemTypeEnum {
    CHARGE = 'CHARGE',
    CREDITORDISCOUNT = 'CREDIT_OR_DISCOUNT',
    TAX = 'TAX',
    FEEANDSURCHARGE = 'FEE_AND_SURCHARGE',
    OVERAGE = 'OVERAGE'
}
/**
* @export
* @enum {string}
*/
export enum LineItemTCommitmentTermEnum {
    OTHER = 'OTHER',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    ANNUAL = 'ANNUAL',
    MULTIYEAR = 'MULTI_YEAR'
}
/**
* @export
* @enum {string}
*/
export enum LineItemTBillingFrequencyEnum {
    OTHER = 'OTHER',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    ANNUAL = 'ANNUAL',
    ANNUALLY = 'ANNUALLY',
    SEMIANNUALLY = 'SEMI_ANNUALLY'
}


