import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { Popover } from 'shared/components/popover';

export const LineItemsTooltip = ({ lineItems }: { lineItems: string[] }) => (
  <Popover placement="bottom" content={lineItems.length > 0 ? lineItems.join('\n') : 'n/a'}>
    <Button icon="list" size="small" color="background4">
      Line Items
    </Button>
  </Popover>
);
