import styled from 'styled-components';

import { Checkbox } from 'shared/components/checkbox';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 50%;
  padding-top: 12px;
`;
