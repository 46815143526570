import React, { Fragment, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Button } from '@glean/glean-ui.molecules.button';

import * as api from 'shared/api';
import { useGetCompanyById, useGetPipelineExecution } from 'shared/api';
import { CompanyDTO, EventT, SingleExecutionDTO } from 'shared/api/generated';
import { CommentDTO } from 'shared/api/generated/models/CommentDTO';
import { AbortModal } from 'shared/components/manage-execution-modals/abort-modal';
import { DeleteModal } from 'shared/components/manage-execution-modals/delete-modal';
import { RestartModal } from 'shared/components/manage-execution-modals/restart-modal';
import { NavBar } from 'shared/components/nav-bar';
import { PageLoader } from 'shared/components/page-loader';
import { PageMessage } from 'shared/components/page-message';
import { Tabs } from 'shared/components/tabs';
import { invoicePDFCDNHost } from 'shared/constants';
import { ExecutionMetadataT } from 'shared/types';
import { objectToQueryString, queryStringToObject } from 'shared/utils/url';

import { Content } from './content';
import {
  ArrowDown,
  Container,
  ContentCont,
  Header,
  Heading,
  Main,
  Sidebar,
  Step,
  Steps,
} from './styles';

const ManageExecutionViewContainer = () => {
  const location = useLocation();
  const history = useHistory();

  const { selectedEventTimestamp } = queryStringToObject(location.search);
  const { executionArn } = useParams<{ executionArn: string }>();

  const {
    data: execution,
    isLoading: isLoadingExecution,
    refetch: refetchExecution,
  } = useGetPipelineExecution({
    executionArn,
    onSuccess: (data) => {
      if (!selectedEventTimestamp) {
        handleEventSelect(data.events[0]);
      }
    },
  });

  const { data: executionEvents } = api.useQuery({
    queryKey: ['execution-comments', executionArn],
    queryFn: () => api.getComments({ executionArn }),
    transformData: (data: any) => {
      const { comments, escalations, restartCategory, restartReason, startingStep } = data?.data;
      return {
        comments,
        escalations,
        restartCategory,
        restartReason,
        startingStep,
      };
    },
  });

  const selectedEvent = execution?.events.find(
    (event) => event.timestamp === selectedEventTimestamp,
  );

  const { data: company } = useGetCompanyById({ companyId: execution?.companyId });

  const handleEventSelect = (event: any) => {
    history.replace({
      pathname: location.pathname,
      search: objectToQueryString({ selectedEventTimestamp: event.timestamp }),
    });
  };

  return (
    <ManageExecutionViewMain
      company={company}
      execution={execution}
      comments={executionEvents?.comments || []}
      executionEvents={executionEvents}
      handleEventSelect={handleEventSelect}
      isLoadingExecution={isLoadingExecution}
      refetchExecution={refetchExecution}
      selectedEvent={selectedEvent}
      selectedEventTimestamp={selectedEventTimestamp as string}
    />
  );
};

const ManageExecutionViewMain = ({
  company,
  execution,
  comments,
  executionEvents,
  handleEventSelect,
  isLoadingExecution,
  refetchExecution,
  selectedEvent,
  selectedEventTimestamp,
}: {
  company?: CompanyDTO;
  execution?: SingleExecutionDTO;
  comments?: CommentDTO[];
  executionEvents?: ExecutionMetadataT;
  handleEventSelect: (event: EventT) => void;
  isLoadingExecution: boolean;
  refetchExecution?: () => void;
  selectedEvent?: EventT;
  selectedEventTimestamp?: string;
}) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<'pdf' | 'steps'>('steps');

  if (isLoadingExecution) return <PageLoader message="Fetching execution data..." />;
  if (!execution) return <PageMessage message="There was a problem fetching execution data." />;

  const executionArn = execution.arn || execution.executionArn;

  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.GridLeft>
              <NavBar.BackLink to="/manage/execution-list" />
            </NavBar.GridLeft>
            <NavBar.Heading>Manage</NavBar.Heading>
            <NavBar.GridRight>
              <NavBar.Help />
            </NavBar.GridRight>
          </>
        }
      />

      <Container>
        <Header>
          <Heading>Execution Details</Heading>
          <div style={{ display: 'flex', gap: 'var(--spacing-sm)' }}>
            <RestartModal
              executionsToRestart={[
                { executionArn, lastUpdatedDate: new Date(execution.updatedAt) },
              ]}
              onComplete={refetchExecution}
              renderHandle={() => (
                <Button variant="outlined" icon="restart">
                  Restart
                </Button>
              )}
            />

            {!execution.stopDate && (
              <AbortModal
                executionsToAbort={[executionArn]}
                onComplete={refetchExecution}
                renderHandle={() => (
                  <Button variant="outlined" icon="stop">
                    Abort
                  </Button>
                )}
              />
            )}

            <DeleteModal
              executionsToDelete={[executionArn]}
              onComplete={() => history.push('/manage/execution-list')}
              renderHandle={() => (
                <Button variant="outlined" icon="delete">
                  Delete
                </Button>
              )}
            />
          </div>
        </Header>

        <Main>
          <Sidebar width={activeTab === 'steps' ? '340px' : '640px'}>
            <Tabs.Links large fullWidth>
              <Tabs.Link
                as="div"
                active={activeTab === 'steps'}
                onClick={() => setActiveTab('steps')}
              >
                Steps
              </Tabs.Link>
              <Tabs.Link as="div" active={activeTab === 'pdf'} onClick={() => setActiveTab('pdf')}>
                PDF
              </Tabs.Link>
            </Tabs.Links>

            {activeTab === 'steps' && (
              <Steps>
                {execution.events.map((event, index) => {
                  const eventName = 'eventName' in event.data ? event.data.eventName : event.status;
                  return (
                    <Fragment key={index}>
                      {index !== 0 && <ArrowDown icon="arrowDown" />}

                      <Step
                        isSelected={event.timestamp === selectedEventTimestamp}
                        onClick={() => handleEventSelect(event)}
                      >
                        {eventName}
                      </Step>
                    </Fragment>
                  );
                })}
              </Steps>
            )}

            {activeTab === 'pdf' && (
              <iframe src={`${invoicePDFCDNHost}/${execution.file}`} title="Invoice PDF" />
            )}
          </Sidebar>

          <ContentCont>
            <Content
              company={company}
              execution={execution}
              selectedEvent={selectedEvent}
              comments={comments}
              executionEvents={executionEvents}
            />
          </ContentCont>
        </Main>
      </Container>
    </>
  );
};

export const ManageExecutionView = ManageExecutionViewContainer;
