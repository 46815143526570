import { omit, pick } from 'lodash';

import { CanonicalVendor, GleanSchema } from '@gleanhq/schema';

import { HeaderCanonicalPayloadT, HeaderMapCurateUiStateT } from '.';

export const localStateToCanonicalPayload = (
  invoiceData: GleanSchema,
  vendorStepState: HeaderMapCurateUiStateT,
  step: 'MAP' | 'CURATE',
): HeaderCanonicalPayloadT => {
  const isStagingMapping = vendorStepState?.canonicalVendor?.is_staging;

  // If the vendor being mapped is a staging vendor
  // we must include both `canonical_vendor_map` and `canonical_vendor`
  if (isStagingMapping && step === 'CURATE') {
    return {
      canonical_vendor: pick(vendorStepState.canonicalVendor!, [
        'city',
        'country',
        'description',
        'display_name',
        'domain',
        'email_address',
        'founded_year',
        'industry',
        'industry_group',
        'legal_name',
        'location_clearbit',
        'logo',
        'phone_number',
        'sector',
        'state',
        'street_address_1',
        'street_address_2',
        'sub_industry',
        'zip_code',
      ]) as CanonicalVendor,
      canonical_vendor_map: {
        canonical_vendor_id: vendorStepState.canonicalVendor?.canonical_vendor_id,
      },
    };
  }

  if (isStagingMapping && step === 'MAP') {
    return {
      canonical_vendor: undefined,
      canonical_vendor_map: {
        canonical_vendor_id: vendorStepState.canonicalVendor?.canonical_vendor_id,
      },
    };
  }

  // if the vendor being created is new, only include the `canonical_vendor`
  // section of the payload
  if (vendorStepState.canonicalVendor?.isNew) {
    return {
      canonical_vendor: omit(vendorStepState.canonicalVendor, 'isNew') as CanonicalVendor,
      canonical_vendor_map: undefined,
    };
  }

  // if the vendor already exists only include the map
  return {
    canonical_vendor: undefined,
    canonical_vendor_map: {
      canonical_vendor_id: vendorStepState.canonicalVendor?.canonical_vendor_id,
    },
  };
};
