import styled from 'styled-components';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { border, color, font, mixin, sizes } from 'shared/styles';

const headerHeight = 76;
const containerPaddingBottom = 25;
export const Container = styled.div`
  margin: ${sizes.navbarHeight}px auto;
  max-width: 1440px;
  padding: 0 30px ${containerPaddingBottom}px;
`;
Container.displayName = 'Container';
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight}px;
`;
Header.displayName = 'Header';
export const Heading = styled.h1`
  ${font.size(26)}
  ${font.regular}
`;

Heading.displayName = 'Heading';
export const Main = styled.main`
  display: flex;
  align-items: flex-start;
`;
Main.displayName = 'Main';
export const Sidebar = styled.aside<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  height: ${window.innerHeight - sizes.navbarHeight - headerHeight - containerPaddingBottom}px;
  padding: 25px 0 0;
  border-radius: 5px;
  background: ${color.background3};
  ${mixin.scrollableY}
  ${mixin.customScrollbar()}

  iframe {
    flex: 1;
    margin-top: 20px;
    border: none;
    width: 100%;
  }
`;
Sidebar.displayName = 'Sidebar';
export const ContentCont = styled.div`
  margin-left: 30px;
  flex: 1;
  max-height: ${window.innerHeight - sizes.navbarHeight - headerHeight - containerPaddingBottom}px;
  ${mixin.scrollableY}
  ${mixin.customScrollbar()}
`;
ContentCont.displayName = 'ContentCont';
export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;
Steps.displayName = 'Steps';
export const Step = styled.div<{ isSelected: boolean }>`
  display: flex;
  margin: 10px 0;
  padding: 6px 12px;
  border-radius: ${border.borderRadius};
  background: ${color.background4};
  transition: all 0.1s;
  ${mixin.clickable}
  ${font.size(13)}
  ${font.medium}

  &:hover {
    transform: scale(1.06);
  }
  &:last-of-type {
    margin-bottom: 35px;
  }

  ${(props) => props.isSelected && `background: ${color.primary};`}
`;
Step.displayName = 'Step';
export const ArrowDown = styled(Icon)`
  color: ${color.text3};
`;
ArrowDown.displayName = 'ArrowDown';
