/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobMetricsReturnTHeader
 */
export interface JobMetricsReturnTHeader {
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnTHeader
     */
    identify?: number;
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnTHeader
     */
    map?: number;
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnTHeader
     */
    curate?: number;
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnTHeader
     */
    extract?: number;
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnTHeader
     */
    validate?: number;
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnTHeader
     */
    multiInvoice?: number;
}

export function JobMetricsReturnTHeaderFromJSON(json: any): JobMetricsReturnTHeader {
    return JobMetricsReturnTHeaderFromJSONTyped(json, false);
}

export function JobMetricsReturnTHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobMetricsReturnTHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identify': !exists(json, 'identify') ? undefined : json['identify'],
        'map': !exists(json, 'map') ? undefined : json['map'],
        'curate': !exists(json, 'curate') ? undefined : json['curate'],
        'extract': !exists(json, 'extract') ? undefined : json['extract'],
        'validate': !exists(json, 'validate') ? undefined : json['validate'],
        'multiInvoice': !exists(json, 'multiInvoice') ? undefined : json['multiInvoice'],
    };
}

export function JobMetricsReturnTHeaderToJSON(value?: JobMetricsReturnTHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identify': value.identify,
        'map': value.map,
        'curate': value.curate,
        'extract': value.extract,
        'validate': value.validate,
        'multiInvoice': value.multiInvoice,
    };
}


