import styled from 'styled-components';

import { Modal } from 'shared/components/modal';
import { color, font } from 'shared/styles';

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  background: none;
`;

export const Box = styled.div`
  margin-bottom: 25px;
  padding: 25px 30px;
  border-radius: 5px;
  background: ${color.background3};
`;

export const Heading = styled.div`
  margin-bottom: 12px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${color.border2};
  ${font.size(20)}
  ${font.medium}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2%;
`;

export const Item = styled.div`
  margin: 20px 2% 10px;
  width: 29.333%;
`;

export const Caption = styled.div`
  padding-bottom: 18px;
  color: ${color.text2};
  ${font.size(14)}
`;

export const Value = styled.div`
  line-height: 1.4;
  ${font.size(16)}
`;
