export const getEditDistance = (word1: string, word2: string): number => {
  let matrix = Array(word1.length + 1)
    .fill(null)
    .map(() => Array(word2.length + 1));

  for (let row = 0; row <= word1.length; row++) {
    for (let column = 0; column <= word2.length; column++) {
      if (row === 0) matrix[0][column] = column;
      else if (column === 0) matrix[row][0] = row;
      else if (word1[row - 1] === word2[column - 1])
        matrix[row][column] = matrix[row - 1][column - 1];
      else
        matrix[row][column] =
          Math.min(matrix[row][column - 1], matrix[row - 1][column - 1], matrix[row - 1][column]) +
          1;
    }
  }
  return matrix[word1.length][word2.length];
};

// Get the edit distance of name, description, and name + description concatenated
// Use the min edit distance of the three to get the final edit distance
export const calculateOverallEditDistance = ({
  cliName,
  originLineItem,
}: {
  cliName: string;
  originLineItem: { name?: string; description?: string };
}) => {
  const { name: originName, description: originDescription } = originLineItem;
  const nameEditDistance = originName ? getEditDistance(cliName, originName) : Infinity;
  const descriptionEditDistance = originDescription
    ? getEditDistance(cliName, originDescription)
    : Infinity;
  const concatenatedEditDistance =
    originName || originDescription
      ? getEditDistance(cliName, `${originName} ${originDescription}`)
      : Infinity;

  return Math.min(nameEditDistance, descriptionEditDistance, concatenatedEditDistance);
};

export const isNewCliNameSimilarToExistingClis = ({
  newCliName,
  existingClis,
}: {
  newCliName: string;
  existingClis: any[];
}) => {
  for (let cli of existingClis) {
    const editDistance = getEditDistance(cli.name.toLowerCase(), newCliName.toLowerCase());
    if (editDistance <= 2) return true;
  }

  return false;
};
