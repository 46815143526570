import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/icon';
import { border, color, font } from 'shared/styles';

export const Label = styled.label<{ invalid: boolean }>`
  display: inline-block;
  position: relative;
  height: 18px;
  line-height: 1.5;
  padding-left: 28px;
  cursor: pointer;
  color: ${color.text2};
  ${font.size(14)}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: ${color.background4};
    border: 1px solid ${color.background4};
    border-radius: ${border.borderRadius};
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.invalid &&
    css`
      &:before {
        border: 1px solid ${color.danger} !important;
      }
    `}
`;

export const CheckIcon = styled(Icon)`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  color: ${color.text1};
`;

export const HiddenInput = styled.input`
  display: none;
  &:not([disabled]) + ${Label}:focus:before {
    border: 1px solid ${color.borderFocus};
  }
  &:checked + ${Label} {
    &:before {
      border: 1px solid ${color.primary} !important;
      background: ${color.primary};
    }
    ${CheckIcon} {
      display: inline-block;
    }
  }
`;
