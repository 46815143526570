import React, { useEffect, useState } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { Input } from 'shared/components/input';
import { Table } from 'shared/components/table';
import { useSearch } from 'shared/hooks/search';

import { calculateOverallEditDistance } from '../utils';
import {
  Results,
  SearchAndCreateContainer,
  StyledCell,
  StyledRow,
  TableScrollContainer,
} from './styles';

export const SearchForm = ({
  originLineItem,
  canonicalLineItems = [],
  focusedCanonicalLineItem,
  onCanonicalLineItemFocus,
  onCanonicalLineItemSelect,
  onClickCreate,
}: {
  originLineItem: { name?: string; description?: string };
  canonicalLineItems: any[];
  focusedCanonicalLineItem: any;
  onCanonicalLineItemFocus: (cliToFocus: any) => void;
  onCanonicalLineItemSelect: () => void;
  onClickCreate: (newCanonicalLineItem: any) => void;
}) => {
  const { searchedItems, search } = useSearch({ items: canonicalLineItems, indexFields: ['name'] });
  const [sortedClis, setSortedClis] = useState<any[]>([]);

  useEffect(() => {
    const editDistancesOfCliMap = new Map();

    for (let cli of canonicalLineItems) {
      const editDistance = calculateOverallEditDistance({
        cliName: cli?.canonical_line_item_name?.toLowerCase() ?? '',
        originLineItem: {
          name: originLineItem?.name?.toLowerCase() ?? '',
          description: originLineItem?.description?.toLowerCase() ?? '',
        },
      });

      editDistancesOfCliMap.set(cli.canonical_line_item_id, editDistance);
    }

    const sortedClisByEditDistance = [...canonicalLineItems].sort((a, b) => {
      const editDistanceOfCliA = editDistancesOfCliMap.get(a.canonical_line_item_id);
      const editDistanceOfCliB = editDistancesOfCliMap.get(b.canonical_line_item_id);

      if (editDistanceOfCliA < editDistanceOfCliB) return -1;
      if (editDistanceOfCliA > editDistanceOfCliB) return 1;
      return 0;
    });

    setSortedClis(sortedClisByEditDistance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itemsToDisplay = search.searchTerm ? searchedItems : sortedClis;

  return (
    <>
      <SearchAndCreateContainer>
        <Input
          placeholder="Search"
          iconStart="search"
          value={search.searchTerm}
          onChange={(val) => {
            search.onChange(val);
          }}
        />
        <Button onClick={onClickCreate}>Create New Line Item</Button>
      </SearchAndCreateContainer>
      <TableScrollContainer>
        <Table
          hideControls
          columns={[
            {
              name: 'Display Name',
              sortKey: 'name',
              width: '70%',
            },
            {
              name: 'Unit Price',
              sortKey: 'average_unit_price',
              width: '15%',
            },
            {
              name: 'Total Amount',
              sortKey: 'name',
              width: '15%',
            },
          ]}
          isEmpty={canonicalLineItems.length === 0}
          body={itemsToDisplay.map((cli) => {
            return (
              <StyledRow
                key={(cli as any).canonical_line_item_id}
                isActive={
                  (cli as any).canonical_line_item_id ===
                  focusedCanonicalLineItem?.canonical_line_item_id
                }
                onClick={() => {
                  if (
                    (cli as any).canonical_line_item_id ===
                    focusedCanonicalLineItem?.canonical_line_item_id
                  ) {
                    onCanonicalLineItemSelect();
                  } else {
                    onCanonicalLineItemFocus(
                      canonicalLineItems.find(
                        ({ canonical_line_item_id }: any) =>
                          canonical_line_item_id === (cli as any).canonical_line_item_id,
                      ),
                    );
                  }
                }}
              >
                <StyledCell>{(cli as any).name}</StyledCell>
                <StyledCell>{(cli as any).average_unit_price}</StyledCell>
                <StyledCell>{(cli as any).average_total_amount}</StyledCell>
              </StyledRow>
            );
          })}
        />
      </TableScrollContainer>
      <Results></Results>
    </>
  );
};
