/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanonicalVendorMergedDataDTO
 */
export interface CanonicalVendorMergedDataDTO {
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorMergedDataDTO
     */
    originalCanonicalVendorId: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorMergedDataDTO
     */
    newCanonicalVendorId: string;
}

export function CanonicalVendorMergedDataDTOFromJSON(json: any): CanonicalVendorMergedDataDTO {
    return CanonicalVendorMergedDataDTOFromJSONTyped(json, false);
}

export function CanonicalVendorMergedDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalVendorMergedDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'originalCanonicalVendorId': json['originalCanonicalVendorId'],
        'newCanonicalVendorId': json['newCanonicalVendorId'],
    };
}

export function CanonicalVendorMergedDataDTOToJSON(value?: CanonicalVendorMergedDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'originalCanonicalVendorId': value.originalCanonicalVendorId,
        'newCanonicalVendorId': value.newCanonicalVendorId,
    };
}


