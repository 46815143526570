import React from 'react';

import { Popover } from 'shared/components/popover';

import { ValidationFlagT } from 'views/job/extract-validate/state';

import { FlagDescription, FlagToggleButton, FlagsPopover } from './styles';

export const ValidationFlagsPopover: React.FC<{
  fieldFlags: ValidationFlagT[];
  updateValidationFlag: (
    flagId: string,
    updateFn: (flag?: ValidationFlagT) => Partial<ValidationFlagT>,
  ) => void;
  onRevertToInitialValueClick: () => void;
}> = ({ fieldFlags, updateValidationFlag, onRevertToInitialValueClick, children }) => (
  <Popover
    maxWidth={320}
    variant="dropdown"
    interaction="hover"
    renderContent={(popover) => (
      <FlagsPopover>
        <ol>
          {fieldFlags.map((flag: any, idx) => (
            <li key={`flag-${flag.id}-${idx}`}>
              <FlagDescription isOverridenOrResolved={flag.is_overridden || flag.is_resolved}>
                {flag.flag_description}
              </FlagDescription>

              {flag.is_resolved && !flag.is_overridden ? (
                <FlagToggleButton
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    onRevertToInitialValueClick();

                    if (fieldFlags.length === 1) {
                      popover.close();
                    }
                  }}
                >
                  Undo Resolved
                </FlagToggleButton>
              ) : (
                <FlagToggleButton
                  size="small"
                  variant={flag.is_overridden ? 'outlined' : 'primary'}
                  color={flag.is_overridden ? undefined : 'warning'}
                  onClick={() => {
                    updateValidationFlag(flag.flag_id, () => ({
                      is_overridden: !flag.is_overridden,
                    }));

                    if (fieldFlags.length === 1 && !flag.is_resolved) {
                      popover.close();
                    }
                  }}
                >
                  {flag.is_overridden ? 'Undo Override' : 'Override Flag'}
                </FlagToggleButton>
              )}
            </li>
          ))}
        </ol>
      </FlagsPopover>
    )}
  >
    {children}
  </Popover>
);
