import React, { useContext } from 'react';

import { usePipelineMetrics } from 'shared/api';
import { ExecutionTableContext } from 'shared/components/execution-table';

import { PipelineMetricsMain } from './pipeline-metrics-main';

export const PipelineMetricsContainer = ({ openModal }: { openModal?: () => void }) => {
  const { data, isLoading } = usePipelineMetrics();
  const context = useContext(ExecutionTableContext);
  if (!context) return null;
  const { filters } = context;
  return (
    <PipelineMetricsMain
      isLoading={isLoading}
      metrics={data}
      openModal={openModal}
      filters={filters}
    />
  );
};
