import React, { useState } from 'react';

import { v4 } from 'uuid';

import { Button } from '@glean/glean-ui.molecules.button';

import {
  useCanonicalVendorLineItems,
  useCreateCanonicalLineItem,
  useMergeCanonicalLineItems,
  useUpdateCanonicalLineItem,
} from 'shared/api';
import { CanonicalLineItemDetailT, CanonicalVendorResponseT } from 'shared/api/generated';
import { Form } from 'shared/components/form';
import { Modal } from 'shared/components/modal';
import { PageLoader } from 'shared/components/page-loader';
import { PageMessage } from 'shared/components/page-message';
import { Select } from 'shared/components/select';
import { Cell, SelectAction, Table } from 'shared/components/table';
import { toast } from 'shared/components/toast';
import { useTable } from 'shared/hooks/table';

import {
  Actions,
  Flex,
  FormHeading,
  Label,
  MergeHeading,
  MergeTip,
  ModalContent,
  StyledRow,
  TopActions,
} from './styles';

export const LineItems = ({ vendor }: { vendor: CanonicalVendorResponseT }) => {
  const [lineItemIdsToMerge, setLineItemIdsToMerge] = useState<string[]>([]);
  const { data: lineItems, isLoading, refetch: refetchLineItems } = useCanonicalVendorLineItems({
    canonicalVendorId: String(vendor.canonical_vendor_id),
  });
  const { mutateAsync: createLineItem } = useCreateCanonicalLineItem();
  const { mutateAsync: updateLineItem } = useUpdateCanonicalLineItem();
  const { tableItems, search, sort, pagination } = useTable({
    items: lineItems || [],
    search: { indexFields: ['name'] },
    sort: {},
    pagination: { itemsPerPage: 50 },
  });
  if (isLoading) return <PageLoader message="Fetching Line Items..." />;
  if (!lineItems) return <PageMessage />;
  return (
    <>
      <TopActions>
        <Modal
          maxWidth={400}
          withCloseIcon={false}
          renderHandle={() => <Button>Create Line Item</Button>}
          renderContent={(modal) => (
            <LineItemForm
              isCreate
              lineItemData={{}}
              onSubmit={async (formData: any) => {
                try {
                  await createLineItem({
                    canonicalVendorId: String(vendor.canonical_vendor_id),
                    canonicalLineItem: {
                      canonical_line_item_id: v4(),
                      ...formData,
                    },
                  });

                  await refetchLineItems();
                  toast.success('Line item created successfully.');
                } catch {
                  toast.danger('Line item could not be created.');
                }
                modal.close();
              }}
              onCancel={modal.close}
            />
          )}
        />
      </TopActions>

      {lineItemIdsToMerge.length > 0 && (
        <MergeModal
          lineItemsToMerge={lineItemIdsToMerge
            .map((lineItemid) =>
              lineItems.find(({ canonical_line_item_id }) => canonical_line_item_id === lineItemid),
            )
            .filter((lineItem) => !!lineItem)}
          refetchLineItems={refetchLineItems}
          clearLineItemIdsToMerge={() => setLineItemIdsToMerge([])}
        />
      )}

      {lineItems && (
        <Table
          filters={null}
          search={search}
          sort={sort}
          pagination={pagination}
          select={{
            keys: tableItems.map((lineItem) => (lineItem as any).canonical_line_item_id),
            // eslint-disable-next-line react/display-name
            renderActions: (selectedKeys) => (
              <SelectAction
                onClick={() => {
                  if (selectedKeys.length > 1) {
                    setLineItemIdsToMerge(selectedKeys);
                  } else {
                    toast.primary('Please select multiple line items.');
                  }
                }}
              >
                Merge
              </SelectAction>
            ),
          }}
          isEmpty={tableItems.length === 0}
          columns={[
            { name: 'Name', sortKey: 'name', width: '50%' },
            { name: 'Overage', sortKey: 'is_overage', width: '120px', align: 'right' },
            {
              name: 'Capacity Constrained',
              sortKey: 'has_constrained_capacity',
              width: '200px',
              align: 'right',
            },
            { name: 'Type', sortKey: 'canonical_line_item_type', width: '150px', align: 'right' },
            { name: 'Invoices', sortKey: 'invoice_count', width: '120px', align: 'right' },
            { name: 'Line Items', sortKey: 'line_item_count', width: '120px', align: 'right' },
            { name: 'Companies', sortKey: 'company_count', width: '120px', align: 'right' },
            { width: '86px' },
          ]}
          body={tableItems.map((lineItem) => (
            <StyledRow
              key={lineItem.canonical_line_item_id}
              selectKey={lineItem.canonical_line_item_id}
            >
              <Cell>{lineItem.name}</Cell>
              <Cell align="right">{lineItem.is_overage ? 'Yes' : 'No'}</Cell>
              <Cell align="right">{lineItem.has_constrained_capacity ? 'Yes' : 'No'}</Cell>
              <Cell align="right">{lineItem.canonical_line_item_type}</Cell>
              <Cell align="right">{lineItem.invoice_count}</Cell>
              <Cell align="right">{lineItem.line_item_count}</Cell>
              <Cell align="right">{lineItem.company_count}</Cell>
              <Cell>
                <Modal
                  maxWidth={400}
                  withCloseIcon={false}
                  renderHandle={() => <Button variant="outlined" icon="edit" />}
                  renderContent={(modal) => (
                    <LineItemForm
                      lineItemData={lineItem}
                      onSubmit={async (formData: any) => {
                        try {
                          await updateLineItem({
                            canonicalLineItemId: String(lineItem.canonical_line_item_id),
                            canonicalVendorId: String(vendor.canonical_vendor_id),
                            canonicalLineItem: {
                              ...formData,
                            },
                          });

                          await refetchLineItems();
                          toast.success('Line item updated successfully.');
                        } catch {
                          toast.danger('Line item could not be updated.');
                        }
                        modal.close();
                      }}
                      onCancel={modal.close}
                    />
                  )}
                />
              </Cell>
            </StyledRow>
          ))}
        />
      )}
    </>
  );
};
export const LineItemForm = ({
  lineItemData,
  isCreate,
  onCancel,
  onSubmit,
}: {
  lineItemData: Partial<CanonicalLineItemDetailT>;
  isCreate?: boolean;
  onCancel: () => void;
  onSubmit: (formData: any) => void;
}) => (
  <Form
    initialValues={{ ...lineItemData }}
    validations={{
      name: Form.is.required(),
      canonical_line_item_type: Form.is.required(),
    }}
    onSubmit={(formData: any) => {
      onSubmit({ ...formData, name: formData.name.trim() });
    }}
  >
    {({ isSubmitting }: any) => (
      <Form.Element>
        <ModalContent>
          <FormHeading>{isCreate ? 'Create' : 'Edit'} Line Item</FormHeading>

          <Form.Field.Input name="name" label="Name *" />
          <Form.Field.Select
            name="canonical_line_item_type"
            label="Line Item Type"
            options={[
              {
                label: 'Select',
                value: '',
              },
              {
                label: 'By Day',
                value: 'BY_DAY',
              },
              {
                label: 'By Volume',
                value: 'BY_VOLUME',
              },
              {
                label: 'Consolidated',
                value: 'CONSOLIDATED',
              },
            ]}
          />
          <Form.Field.Checkbox
            name="has_constrained_capacity"
            checkboxLabel="Capacity Constrained"
          />
          <Form.Field.Checkbox name="is_overage" checkboxLabel="Overage" />
        </ModalContent>

        <Actions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" isWorking={isSubmitting}>
            Submit
          </Button>
        </Actions>
      </Form.Element>
    )}
  </Form>
);
const MergeModal = ({ lineItemsToMerge, refetchLineItems, clearLineItemIdsToMerge }: any) => {
  const [primaryLineItemId, setPrimaryLineItemId] = useState('');
  const { mutateAsync: mergeLineItems } = useMergeCanonicalLineItems();
  const [isMerging, setIsMerging] = useState(false);
  const handleMerge = async () => {
    setIsMerging(true);
    try {
      const otherLineItemIds = lineItemsToMerge
        .map(({ canonical_line_item_id }: any) => canonical_line_item_id)
        .filter((canonical_line_item_id: any) => canonical_line_item_id !== primaryLineItemId);
      await mergeLineItems({
        canonicalLineItemId: primaryLineItemId,
        toMergeIds: otherLineItemIds,
      });
      await refetchLineItems();
      clearLineItemIdsToMerge();
      toast.success('Line items merged successfully.');
    } catch {
      toast.danger('Could not merge line items.');
    }
    setIsMerging(false);
  };
  return (
    <Modal
      isOpen
      onClose={clearLineItemIdsToMerge}
      maxWidth={600}
      withCloseIcon={false}
      renderContent={(modal) => (
        <>
          <ModalContent>
            <MergeHeading>Merge Canonical Line Items</MergeHeading>
            <MergeTip>All line items other than the primary line item will be deleted.</MergeTip>
            <Flex>
              <Label>Choose Primary Line Item*</Label>
              <div style={{ width: '100%' }}>
                <Select
                  options={[
                    { value: '', label: 'Select' },
                    ...lineItemsToMerge.map((lineItem: any) => ({
                      label: lineItem.name,
                      value: lineItem.canonical_line_item_id,
                    })),
                  ]}
                  value={primaryLineItemId}
                  onChange={setPrimaryLineItemId}
                />
              </div>
            </Flex>
          </ModalContent>

          <Actions>
            <Button variant="outlined" onClick={modal.close}>
              Cancel
            </Button>
            <Button disabled={!primaryLineItemId} isWorking={isMerging} onClick={handleMerge}>
              Merge
            </Button>
          </Actions>
        </>
      )}
    />
  );
};
