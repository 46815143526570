import { useRef } from 'react';

import { isEqual } from 'lodash';

export const useDeepCompareMemoize = <T>(value: T): T | undefined => {
  const valueRef = useRef<T>();

  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value;
  }
  return valueRef.current;
};
