import React, { useState } from 'react';

import { Icon } from '@glean/glean-ui.atoms.icon';
import { Button } from '@glean/glean-ui.molecules.button';

import { invoicePDFCDNHost } from 'shared/constants';

import {
  Invoice,
  InvoiceLabel,
  Invoices,
  NoInvoices,
  TopRow,
  TopRowContent,
  TopRowItemContainer,
  TopRowLabel,
} from './styles';

export const InvoiceCompare = ({ data, canonicalLineItem, originLineItemName }: any) => {
  const originInvoiceNumber = data.invoiceData.invoice?.summary?.number;
  const originVendorName = data.invoiceData.vendor?.id?.legal_name;
  const originPDFURL = `${invoicePDFCDNHost}/${data.job.fileKey}`;
  const hasNoFiles = !canonicalLineItem?.files || canonicalLineItem.files.length === 0;

  const [currentPDFIndex, setCurrentPDFIndex] = useState(0);

  return (
    <Invoices>
      <Invoice>
        <TopRow>
          <TopRowItem label="Origin Vendor" content={originVendorName} />
          <TopRowItem label="Origin Line Item" content={originLineItemName} />
        </TopRow>

        <InvoiceLabel>
          Invoice <strong>{originInvoiceNumber}</strong>
        </InvoiceLabel>

        <iframe title="Origin invoice" src={originPDFURL} />
      </Invoice>

      <Invoice>
        <TopRow>
          <TopRowItem label="Canonical Vendor" content={''} />
          <TopRowItem label="Canonical Line Item" content={canonicalLineItem?.name} />
        </TopRow>

        {hasNoFiles ? (
          <NoInvoices>
            <NoInvoices>
              <Icon icon="eye" size={3} />
            </NoInvoices>
            <p>There are no sample invoices to show.</p>
          </NoInvoices>
        ) : (
          <>
            <InvoiceLabel>
              Example Invoice
              {canonicalLineItem.files.length > 1 && (
                <Button
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: 12 }}
                  onClick={() => {
                    setCurrentPDFIndex((currentPDFIndex + 1) % canonicalLineItem.files.length);
                  }}
                >
                  Show Next
                </Button>
              )}
            </InvoiceLabel>

            <iframe
              title="Example Canonical invoice"
              src={canonicalLineItem.files[currentPDFIndex]}
            />
          </>
        )}
      </Invoice>
    </Invoices>
  );
};

const TopRowItem = ({ label, content }: any) => (
  <TopRowItemContainer>
    <TopRowLabel>{label}</TopRowLabel>
    <TopRowContent>{content}</TopRowContent>
  </TopRowItemContainer>
);
