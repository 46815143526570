import styled from 'styled-components';

import { color, font } from 'shared/styles';

export const Invoices = styled.div`
  display: flex;
  height: 100%;
  padding-top: 20px;
`;

export const Invoice = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  &:first-of-type {
    padding-right: 20px;
  }
  &:last-of-type {
    padding-left: 20px;
  }

  iframe {
    flex: 1;
    margin-top: 20px;
    border: none;
    width: 100%;
    height: 100%;
  }
`;

export const TopRow = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  padding: 0 30px;
`;

export const TopRowItemContainer = styled.div`
  width: 50%;
`;

export const TopRowLabel = styled.div`
  padding-bottom: 5px;
  color: ${color.text2};
  ${font.size(14)}
`;

export const TopRowContent = styled.div`
  color: ${color.text1};
  ${font.size(14)}
  ${font.bold}
`;

export const InvoiceLabel = styled.p`
  padding-left: 30px;
  color: ${color.text2};
  ${font.size(18)}

  strong {
    padding-left: 12px;
    color: ${color.text1};
    ${font.bold}
  }
`;

export const NoInvoices = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 3%;
`;
