import React, { useState } from 'react';

import PropTypes from 'prop-types';

import * as api from 'shared/api';
import { CommentsStream } from 'shared/components/comments-stream';
import { Divider } from 'shared/components/divider';
import { NavBar } from 'shared/components/nav-bar';
import { StatusBadge } from 'shared/components/status-badge/styles';
import { toast } from 'shared/components/toast';
import { invoicePDFCDNHost } from 'shared/constants';
import { useCommentsData } from 'shared/hooks/comments-meta-data';

import { Buttons, Container, Instructions, InvoicePDF, JobId, Right, StyledButton } from './styles';

export const Job = ({
  data,
  fetchJob,
  deescalating,
}: {
  data: {
    job: {
      executionArn: string;
      jobType: string;
      notInvoice: boolean;
      fileBucket: string;
      fileKey: string;
      id: string;
    };
    ocrData: {
      invoice: { summary: { record_type: string; number: string } };
      vendor: { id: { legal_name: string; dba_name: string } };
      document: { type: { document_type: string } };
      [key: string]: unknown;
    };
  };
  fetchJob: () => Promise<void>;
  deescalating: boolean;
}) => {
  const { job } = data;
  const { executionArn, jobType } = job;

  const { data: executionEvents, error, isFetching, refetch: refetchComments } = api.useQuery({
    config: {},
    notifyOnNetworkStatusChange: true,
    queryKey: `execution-comments-${executionArn}`,
    queryFn: () => api.getComments({ executionArn }),
    transformData: (data: any) => {
      const { comments, escalations, restartCategory, restartReason, startingStep } = data?.data;
      return {
        comments,
        escalations,
        restartCategory,
        restartReason,
        startingStep,
      };
    },
  });

  const { commentsData, setCommentsData } = useCommentsData({
    comments: executionEvents?.comments,
    restartCategory: executionEvents?.restartCategory,
  });
  const [commentAdded, setCommentAdded] = useState(false);
  const handleSubmitComment = async (commentText: string) => {
    try {
      await api.addComment({ executionArn, jobType, commentText, deescalating });
      setCommentAdded(true);
      refetchComments();
    } catch (e) {
      toast.danger(e.message);
    }
  };

  const handleSubmit = async () => {
    await api.createJobCompletion({ invoice: {}, job });
    toast.success('The job was completed successfully.');
    await fetchJob();
  };

  const handleEscalate = async () => {
    toast.success('This job has been escalated.');
    await fetchJob();
  };

  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.GridLeft>
              <NavBar.ExitJob job={job} />
              {deescalating && <StatusBadge backgroundColor="danger">Escalated</StatusBadge>}
            </NavBar.GridLeft>
            <NavBar.Heading>Multi Invoice Check</NavBar.Heading>
            <NavBar.GridRight>
              <NavBar.IconBtnGroup>
                <NavBar.Comments
                  className={commentsData.commentsOpen ? 'selected' : ''}
                  onClick={() =>
                    setCommentsData({ ...commentsData, commentsOpen: !commentsData.commentsOpen })
                  }
                />
              </NavBar.IconBtnGroup>
              <NavBar.TextBtnGroup>
                {!deescalating && <NavBar.EscalateJob onEscalate={handleEscalate} job={job} />}
                <NavBar.ViewExecution arn={executionArn} navbar={true} />
                {deescalating && (
                  <NavBar.DeescalateJob
                    onDeescalated={fetchJob}
                    job={job}
                    commentAdded={commentAdded}
                  />
                )}
              </NavBar.TextBtnGroup>
            </NavBar.GridRight>
          </>
        }
      />

      <Container>
        <InvoicePDF>
          <iframe
            src={job.fileBucket ? `${invoicePDFCDNHost}/${job.fileKey}` : undefined}
            title="Invoice PDF"
          />
        </InvoicePDF>
        <Right>
          <CommentsStream
            error={error}
            comments={executionEvents?.comments || []}
            executionEvents={executionEvents}
            handleSubmitComment={handleSubmitComment}
            isFetching={isFetching}
            open={commentsData.commentsOpen}
          />

          <JobId>Job # {job.id}</JobId>

          <>
            <Instructions>
              <h4>Multi Invoice Check:</h4>
              <p>There is a high chance that this document contains multiple invoices.</p>
              <Divider />
              <h5>Abort Execution if:</h5>
              <ul>
                <li>
                  If this document does indeed contain multiple invoices, this execution must be
                  aborted, and a new execution for each invoice must be started.
                </li>
              </ul>
              <h5>Click Process if:</h5>
              <ul>
                <li>
                  If there is only one invoice in this document, it is safe to process as normal.
                </li>
              </ul>
            </Instructions>
            <Buttons>
              <StyledButton onClick={() => handleSubmit()}>Process</StyledButton>
            </Buttons>
          </>
        </Right>
      </Container>
    </>
  );
};

Job.propTypes = {
  data: PropTypes.shape({
    job: PropTypes.shape({
      executionArn: PropTypes.string,
      fileBucket: PropTypes.string,
      fileKey: PropTypes.string,
      hasComments: PropTypes.bool,
      id: PropTypes.string,
      jobType: PropTypes.string,
      notInvoice: PropTypes.bool,
    }),
    ocrData: PropTypes.shape({}),
  }),
  deescalating: PropTypes.bool,
  fetchJob: PropTypes.func,
};
