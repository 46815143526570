import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from '@glean/glean-ui.molecules.button';

import {
  useCanonicalVendors,
  useCreateCanonicalVendor,
  useMergeCanonicalVendors,
  useUpdateCanonicalVendor,
} from 'shared/api';
import { CanonicalVendorDetailT } from 'shared/api/generated';
import { Modal } from 'shared/components/modal';
import { PageLoader } from 'shared/components/page-loader';
import { PageMessage } from 'shared/components/page-message';
import { Select } from 'shared/components/select';
import { Cell, SelectAction, Table } from 'shared/components/table';
import { toast } from 'shared/components/toast';
import { VendorCreateForm, VendorEditForm } from 'shared/components/vendor-form';
import { useTable } from 'shared/hooks/table';
import { parseDomainFromUrl } from 'shared/utils/url';

import {
  Actions,
  Flex,
  Label,
  MergeHeading,
  MergeTip,
  ModalContent,
  StyledRow,
  TopActions,
  VendorFormModal,
} from './styles';

export const CanonicalVendors = () => {
  const history = useHistory();
  const [vendorIdsToMerge, setVendorIdsToMerge] = useState<string[]>([]);
  const { data: vendors, isLoading, refetch: refetchVendors } = useCanonicalVendors();
  const { mutateAsync: createCanonicalVendor } = useCreateCanonicalVendor();
  const { mutateAsync: updateCanonicalVendor } = useUpdateCanonicalVendor();
  const { tableItems, search, sort, pagination } = useTable({
    items: vendors || [],
    search: { indexFields: ['display_name'] },
    sort: {},
    pagination: { itemsPerPage: 50 },
  });
  const goTo = (vendor: CanonicalVendorDetailT) => {
    history.push(`/manage/canonical-vendors/${vendor.canonical_vendor_id}`);
  };
  if (isLoading) return <PageLoader message="Fetching vendors..." />;
  if (!vendors) return <PageMessage />;

  return (
    <>
      <TopActions>
        <VendorFormModal
          maxWidth={800}
          withCloseIcon={false}
          renderHandle={() => <Button>Create Vendor</Button>}
          renderContent={(modal) => (
            <VendorCreateForm
              onSubmit={async (formData: any) => {
                try {
                  await createCanonicalVendor(formData);
                  await refetchVendors();
                  toast.success('Vendor created successfully.');
                } catch {
                  toast.danger('Vendor could not be created.');
                }
                modal.close();
              }}
              onCancel={modal.close}
            />
          )}
        />
      </TopActions>

      {vendorIdsToMerge.length > 0 && (
        <MergeModal
          vendorsToMerge={vendorIdsToMerge
            .map(
              (vendorId) =>
                vendors.find(
                  ({ canonical_vendor_id }) => canonical_vendor_id === vendorId,
                ) as CanonicalVendorDetailT,
            )
            .filter((vendor) => !!vendor)}
          refetchVendors={refetchVendors}
          clearVendorIdsToMerge={() => setVendorIdsToMerge([])}
        />
      )}

      <Table
        filters={null}
        search={search}
        sort={sort}
        pagination={pagination}
        select={{
          keys: tableItems.map((vendor) => vendor.canonical_vendor_id),
          // eslint-disable-next-line react/display-name
          renderActions: (selectedKeys) => (
            <SelectAction
              onClick={() => {
                if (selectedKeys.length > 1) {
                  setVendorIdsToMerge(selectedKeys);
                } else {
                  toast.primary('Please select multiple vendors.');
                }
              }}
            >
              Merge
            </SelectAction>
          ),
        }}
        isEmpty={tableItems.length === 0}
        columns={[
          { name: 'Display Name', sortKey: 'display_name', width: '30%' },
          { name: 'Legal Name', sortKey: 'legal_name', width: '42%' },
          { name: 'Domain', width: '28%' },
          { name: 'Invoices', sortKey: 'invoice_count', width: '120px', align: 'right' },
          {
            name: 'Line Items',
            sortKey: 'canonicalLineItemCount',
            width: '120px',
            align: 'right',
          },
          { name: 'Companies', sortKey: 'company_count', width: '120px', align: 'right' },
          { width: '86px' },
        ]}
        body={tableItems.map((vendor) => (
          <StyledRow key={vendor.canonical_vendor_id} selectKey={vendor.canonical_vendor_id}>
            <Cell onClick={() => goTo(vendor)}>{vendor.display_name}</Cell>
            <Cell onClick={() => goTo(vendor)}>{vendor.legal_name}</Cell>
            <Cell onClick={() => goTo(vendor)}>{parseDomainFromUrl(vendor.domain)}</Cell>
            <Cell align="right" onClick={() => goTo(vendor)}>
              {vendor.invoice_count}
            </Cell>
            <Cell align="right" onClick={() => goTo(vendor)}>
              {vendor.canonical_line_item_count}
            </Cell>
            <Cell align="right" onClick={() => goTo(vendor)}>
              {vendor.company_count}
            </Cell>
            <Cell>
              <VendorFormModal
                maxWidth={800}
                withCloseIcon={false}
                renderHandle={() => <Button variant="outlined" icon="edit" />}
                renderContent={(modal) => (
                  <VendorEditForm
                    vendorData={vendor}
                    onSubmit={async (formData: any) => {
                      try {
                        await updateCanonicalVendor({
                          input: formData,
                          canonicalVendorId: String(vendor.canonical_vendor_id),
                        });
                        await refetchVendors();
                        toast.success('Vendor updated successfully.');
                      } catch {
                        toast.danger('Vendor could not be updated.');
                      }
                      modal.close();
                    }}
                    onCancel={modal.close}
                  />
                )}
              />
            </Cell>
          </StyledRow>
        ))}
      />
    </>
  );
};

const MergeModal = ({
  vendorsToMerge,
  refetchVendors,
  clearVendorIdsToMerge,
}: {
  vendorsToMerge: CanonicalVendorDetailT[];
  refetchVendors: () => void;
  clearVendorIdsToMerge: () => void;
}) => {
  const { mutateAsync: mergeVendors } = useMergeCanonicalVendors();
  const [primaryVendorId, setPrimaryVendorId] = useState('');
  const [isMerging, setIsMerging] = useState(false);

  const handleMerge = async () => {
    setIsMerging(true);
    try {
      const otherVendorIds = vendorsToMerge
        .map(({ canonical_vendor_id }) => String(canonical_vendor_id))
        .filter((canonicalVendorId) => canonicalVendorId !== primaryVendorId);

      await mergeVendors({ canonicalVendorId: primaryVendorId, toMergeIds: otherVendorIds });
      await refetchVendors();
      clearVendorIdsToMerge();
      toast.success('Vendors merged successfully.');
    } catch {
      toast.danger('Could not merge vendors.');
    }

    setIsMerging(false);
  };

  return (
    <Modal
      isOpen
      onClose={clearVendorIdsToMerge}
      maxWidth={600}
      withCloseIcon={false}
      renderContent={(modal) => (
        <>
          <ModalContent>
            <MergeHeading>Merge Canonical Vendors</MergeHeading>
            <MergeTip>All vendors other than the primary vendor will be deleted.</MergeTip>
            <Flex>
              <Label>Choose Primary Vendor*</Label>
              <div style={{ width: '100%' }}>
                <Select
                  options={[
                    { value: '', label: 'Select' },
                    ...vendorsToMerge.map((vendor: any) => ({
                      label: vendor.display_name,
                      value: vendor.canonical_vendor_id,
                    })),
                  ]}
                  value={primaryVendorId}
                  onChange={setPrimaryVendorId}
                />
              </div>
            </Flex>
          </ModalContent>

          <Actions>
            <Button variant="outlined" onClick={modal.close}>
              Cancel
            </Button>
            <Button disabled={!primaryVendorId} isWorking={isMerging} onClick={handleMerge}>
              Merge
            </Button>
          </Actions>
        </>
      )}
    />
  );
};
