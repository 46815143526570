import React from 'react';

import { useFeatureFlag } from 'flagg';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useAuth } from 'shared/auth';
import { ExecutionTableContextProvider } from 'shared/components/execution-table';
import { NavBar } from 'shared/components/nav-bar';
import { Tabs } from 'shared/components/tabs';

import { CanonicalVendors } from './canonical-vendors';
import { CompanyPriority } from './company-priority';
import { EditInvoices } from './edit-invoices';
import { EditInvoiceForm } from './edit-invoices/edit-invoice-form';
import { ExecutionList } from './execution-list/execution-list-main';
import { PipelineStatus } from './pipeline-status';
import { Container } from './shared/styles';

export const Manage = () => {
  const [ffCompanyPriorityEnabled] = useFeatureFlag('app_companyPriority');
  const { permissions } = useAuth();
  const canEdit = permissions.includes('proof:edit');

  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.GridLeft>
              <NavBar.BackLink to="/" />
            </NavBar.GridLeft>
            <NavBar.Heading>Manage</NavBar.Heading>
            <NavBar.GridRight>
              <NavBar.Help />
            </NavBar.GridRight>
          </>
        }
      />

      <Container>
        <Tabs.Links large style={{ padding: '30px 0 25px' }}>
          <Tabs.Link to="/manage/pipeline-status" className="leftAlign">
            Pipeline Status
          </Tabs.Link>
          <Tabs.Link to="/manage/execution-list">Execution List</Tabs.Link>
          <Tabs.Link to="/manage/canonical-vendors">Canonical Vendors</Tabs.Link>
          {ffCompanyPriorityEnabled && (
            <Tabs.Link to="/manage/company-priority">Company Priority</Tabs.Link>
          )}
          {canEdit && <Tabs.Link to="/manage/edit">Edit Invoices</Tabs.Link>}
        </Tabs.Links>

        <ExecutionTableContextProvider>
          <Switch>
            <Redirect exact from="/manage" to="/manage/pipeline-status" />

            <Route path="/manage/pipeline-status" component={PipelineStatus} />
            <Route path="/manage/execution-list" component={ExecutionList} />
            <Route path="/manage/canonical-vendors" component={CanonicalVendors} />

            {ffCompanyPriorityEnabled && (
              <Route path="/manage/company-priority" component={CompanyPriority} />
            )}
            {canEdit && (
              <>
                <Route path="/manage/edit/" exact component={EditInvoices} />
                <Route path="/manage/edit/:invoiceId" component={EditInvoiceForm} />
              </>
            )}
          </Switch>
        </ExecutionTableContextProvider>
      </Container>
    </>
  );
};
