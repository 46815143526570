import styled, { css } from 'styled-components';

import { border, color, font, mixin, padding } from 'shared/styles';

export const Wrapper = styled.div`
  position: relative;
`;
export const StyledInput = styled.input<{
  withIconStart?: boolean;
  invalid?: boolean;
  withIconEnd?: boolean;
}>`
  height: 32px;
  width: 100%;
  padding: 0 ${padding.sm};
  border-radius: ${border.borderRadius};
  color: ${color.text1};
  border: 1px solid ${color.background4};
  background: ${color.background4};
  transition: all 0.1s;
  ${font.regular}
  ${font.size(14)}
  &:hover:not(:focus) {
    border: 1px solid ${color.background5};
    background: ${color.background5};
  }
  &:focus {
    border: 1px solid ${color.borderFocus};
    background: ${color.background4};
    box-shadow: 0 0 0 1px ${color.borderFocus};
  }
  ${(props) =>
    props.invalid &&
    css`
      &,
      &:hover,
      &:focus {
        border: 1px solid ${color.warning};
        box-shadow: none;
      }
    `}

  ${(props) => props.withIconStart && `padding: 0 7px 0 36px;`}
  ${(props) => props.withIconEnd && `padding: 0 36px 0 7px;`}
`;
export const IconContainer = styled.div<{ isStart?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.text2};
  ${mixin.cover}
  width: 36px;
  ${(props) => (props.isStart ? 'left: 0;' : 'right: 0; left: auto;')}
`;
