import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ArrayHelpers, FieldArray } from 'formik';
import { findIndex, get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Icon } from '@glean/glean-ui.atoms.icon';
import { Button } from '@glean/glean-ui.molecules.button';
import { LineItemElement as LineItemT } from '@gleanhq/schema';

import { update } from 'shared/utils/javascript';
import { sumTotal } from 'shared/utils/money';

import { ExtractValidateContext } from 'views/job/extract-validate/state';

import { getInitialFormSubsectionValues, useIfNotEmptyOrHasValidationFlags } from '../../../utils';
import { Divider, Field, Grid, Label } from '../shared';
import {
  InstallmentFieldsWrapper,
  ItemTitle,
  ItemTop,
  LineItemDropdownBar,
  LineItemDropdownItem,
  LineItemDropdownLabel,
  LineItemDropdownList,
  LineItemTopNav,
  LineItemTopNavCell,
  ReadOnlyFieldWrapper,
  StyledLineItem,
} from './styles';

const getLineItemName = (lineItem: LineItemT) => {
  return (lineItem.name?.length || 0) > 0 ? lineItem.name : 'Unnamed Line Item';
};
export const LineItems = ({
  values,
  readOnly,
  validationFlags,
  updateValidationFlag,
  ...formProps
}: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentLineItemIndex, setCurrentLineItemIndex] = useState(0);
  const lineItems = values.line_items.line_items;
  const countLineItems = lineItems?.length || 0;
  const isEmpty = countLineItems === 0;
  const currentLineItem = isEmpty ? null : values.line_items.line_items[currentLineItemIndex];
  const handleAddNewLineItem = (push: any) => () => {
    push(
      getInitialFormSubsectionValues('line_items.line_items', {
        line_item_id: uuidv4(),
      }),
    );
    setCurrentLineItemIndex(lineItems.length);
  };
  const handleDuplicateCurrentLineItem = (push: any) => () => {
    push(
      getInitialFormSubsectionValues('line_items.line_items', {
        ...(currentLineItem ? currentLineItem : {}),
        name: `Copy of ${getLineItemName(currentLineItem)}`,
        line_item_id: uuidv4(),
      }),
    );
    setCurrentLineItemIndex(lineItems.length);
  };

  const [{ focusedFlag }] = useContext(ExtractValidateContext);
  const currentActiveFlagPart = focusedFlag?.invoice_part;
  const currentActiveFlagLineItemId = focusedFlag?.line_item_id;
  const currentFlagId = focusedFlag?.flag_id;
  useEffect(() => {
    if (!!currentActiveFlagPart && !!currentActiveFlagLineItemId) {
      if (currentActiveFlagPart === 'LINE_ITEMS') {
        const newLineItemIndex = findIndex(
          lineItems,
          (lineItem) => (lineItem as any).line_item_id === currentActiveFlagLineItemId,
        );

        if (newLineItemIndex === -1) {
          // Flag no longer exists and should be overridden
          // this is the last chance to recover from this.
          updateValidationFlag(currentFlagId, () => ({ is_resolved: true }));
        } else if (newLineItemIndex !== currentLineItemIndex) {
          setCurrentLineItemIndex(newLineItemIndex);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentActiveFlagLineItemId,
    currentActiveFlagPart,
    currentFlagId,
    currentLineItemIndex,
    lineItems,
  ]);
  const onRemoveLineItem = () => {
    setCurrentLineItemIndex((idx) => Math.max(0, idx - 1));
  };
  return (
    <>
      <FieldArray
        name="line_items.line_items"
        render={({ remove, push }: ArrayHelpers) => {
          return (
            <>
              <LineItemTopNav>
                <LineItemTopNavCell onClick={() => setDropdownOpen((open) => !open)}>
                  <LineItemDropdownBar>
                    <LineItemDropdownLabel>
                      {currentLineItemIndex + 1}/{countLineItems} {getLineItemName(currentLineItem)}
                    </LineItemDropdownLabel>
                    <Icon icon="chevronDown" size="xxsmall" />
                    {dropdownOpen && (
                      <LineItemDropdownList>
                        {lineItems.map((lineItem: any, index: any) => (
                          <LineItemDropdownItem
                            key={lineItem.line_item_id}
                            onClick={() => setCurrentLineItemIndex(index)}
                          >
                            {index + 1}. {getLineItemName(lineItem)}
                          </LineItemDropdownItem>
                        ))}
                      </LineItemDropdownList>
                    )}
                  </LineItemDropdownBar>
                </LineItemTopNavCell>
                <LineItemTopNavCell onClick={handleDuplicateCurrentLineItem(push)}>
                  Duplicate
                </LineItemTopNavCell>
                <LineItemTopNavCell onClick={handleAddNewLineItem(push)}>Add</LineItemTopNavCell>
              </LineItemTopNav>
              <LineItem
                index={currentLineItemIndex}
                remove={remove}
                onRemoveLineItem={onRemoveLineItem}
                lineItemId={currentLineItem.line_item_id}
                values={values}
                {...formProps}
              />
            </>
          );
        }}
      />
    </>
  );
};
const LineItem = ({
  index,
  lineItemId,
  values,
  setFieldValue,
  validationFlags,
  updateValidationFlag,
  uiState,
  setUiState,
  remove,
  onRemoveLineItem,
}: any) => {
  const setLineItemUiState = (key: any, value: any) =>
    setUiState(update(uiState, `lineItems.${lineItemId}.${key}`, value));
  const name = useCallback((name: string) => `line_items.line_items.${index}.${name}`, [index]);
  const hiddenFields = [
    'credit_amount',
    'discount_amount',
    'fee_penalty_amount',
    'state_tax_amount',
    'local_tax_amount',
    'special_tax_amount',
  ].map((fieldName) => name(fieldName));
  useIfNotEmptyOrHasValidationFlags({ fieldNames: hiddenFields, values, validationFlags }, () => {
    setLineItemUiState('isOtherOpen', true);
  });
  const subtotal = Number(get(values, name('subtotal_amount')));
  const credit = Number(get(values, name('credit_discount_amount')));
  const fees = Number(get(values, name('fees_and_surcharges')));
  const tax = Number(get(values, name('total_tax_amount')));
  const currency = get(values, 'invoice.summary.payment_currency', 'USD');
  useEffect(() => {
    const totalAmount = sumTotal({ subtotal, credit, fees, tax, currency });

    if (totalAmount !== null) {
      setFieldValue(name('total_amount'), totalAmount);
    }
  }, [subtotal, credit, fees, tax, currency, setFieldValue, name]);
  const capacityLimitValue = get(values, name('capacity_limit_value'));
  const capacityUnitType = get(values, name('capacity_unit_type'));

  useEffect(() => {
    if (
      capacityLimitValue &&
      capacityLimitValue.length > 0 &&
      capacityUnitType &&
      capacityUnitType.length > 0
    ) {
      setFieldValue(name('has_capacity_limit'), true);
    } else {
      setFieldValue(name('has_capacity_limit'), false);
    }
  }, [capacityLimitValue, capacityUnitType, name, setFieldValue]);

  const totalInstallments = get(values, name('installment_number'));
  const installmentNumber = get(values, name('total_installments'));
  useEffect(() => {
    if (
      totalInstallments &&
      totalInstallments.length > 0 &&
      installmentNumber &&
      installmentNumber.length > 0
    ) {
      setFieldValue(name('is_installment'), true);
    } else {
      setFieldValue(name('is_installment'), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalInstallments, installmentNumber]);
  const handleRemoveLineItem = () => {
    remove(index);
    const lineItemFlags =
      validationFlags?.filter((flag: any) => flag.line_item_id === lineItemId) || [];
    lineItemFlags.forEach(({ flag_id }: any) => {
      updateValidationFlag(flag_id, () => ({ is_resolved: true }));
    });
    onRemoveLineItem();
  };
  return (
    <StyledLineItem>
      <ItemTop>
        <ItemTitle>
          # {index + 1}: {get(values, name('name'))}
        </ItemTitle>
        {values.line_items.line_items.length > 1 && (
          <Button variant="outlined" size="small" icon="close" onClick={handleRemoveLineItem}>
            Remove
          </Button>
        )}
      </ItemTop>

      <Grid>
        <Divider />
        <Field name={name('period_start_date')} perRow={2} />
        <Field name={name('period_end_date')} perRow={2} />
        <Field name={name('name')} />
        <Field name={name('description')} />
        <Field name={name('line_item_type')} perRow={2} />
        <Field name={name('billing_frequency')} perRow={2} />

        <Field name={name('quantity')} perRow={3} />
        <Field name={name('unit_price')} perRow={3} />
        <Field name={name('subtotal_amount')} perRow={3} />

        <Field name={name('credit_discount_amount')} perRow={3} />
        <Field name={name('fees_and_surcharges')} perRow={3} />
        <Field name={name('total_tax_amount')} perRow={3} />
        <ReadOnlyFieldWrapper>
          <Field name={name('total_amount')} disabled={true} />
        </ReadOnlyFieldWrapper>
      </Grid>
      <Grid>
        <Label>Extra Information</Label>
        <Divider />
        <Field name={name('commitment_term')} perRow={2} />
        <InstallmentFieldsWrapper>
          <Field name={name('installment_number')} />
          <p>of</p>
          <Field name={name('total_installments')} />
        </InstallmentFieldsWrapper>
        <Field name={name('capacity_limit_value')} perRow={2} />
        <Field name={name('capacity_unit_type')} perRow={2} />
      </Grid>
    </StyledLineItem>
  );
};
