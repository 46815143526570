import styled from 'styled-components';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { color, font, mixin, padding } from 'shared/styles';

export const Container = styled.div`
  align-items: center;
  background: ${color.warning};
  display: flex;
  height: ${padding.xlrg};
  justify-content: space-between;
  padding: 0 ${padding.med};
  z-index: 5;
`;
Container.displayName = 'Container';
export const PrevNextIcon = styled(Icon)<{ disabled?: boolean }>`
  height: 100%;
  padding: 0 ${padding.xs};
  ${mixin.clickable}
  ${(props) => props.disabled && `opacity: 0.5;`}
`;
PrevNextIcon.displayName = 'PrevNextIcon';
export const Label = styled.div`
  display: flex;
  align-items: center;
  ${font.size(14)}
  ${font.medium}

  span {
    margin-left: ${padding.sm};
  }
`;
Label.displayName = 'Label';
