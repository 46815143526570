import Color from 'color';
import { css, keyframes } from 'styled-components';

const darken = (colorValue: string, amount: number) => Color(colorValue).darken(amount).string();
const lighten = (colorValue: string, amount: number) => Color(colorValue).lighten(amount).string();
const rgba = (colorValue: string, opacity: number) => Color(colorValue).alpha(opacity).string();

export const color = {
  primary: '#4d52c7', // purple
  success: '#009F90', // green
  danger: '#C90D31', // red
  warning: '#FF6407', // orange
  yellow: '#E2BA55',

  text1: '#f2f2f2',
  text2: '#959AAF',
  text3: lighten('#636681', 0.08),
  text4: '#9194AB',
  text5: '#C4C6D5',

  background1: '#000',
  background2: '#0C0C14',
  background3: '#121218',
  background4: '#242430',
  background5: '#292934',
  background6: '#45475B',
  background7: '#333439',
  backgroundContrast: '#fff',

  border1: rgba('#fff', 0.24),
  border2: '#292934',
  borderFocus: '#4d52c7',

  hoverBackground: rgba('#fff', 0.15),
  selectedBackground: '#4D52C7',
};

export const zIndexValues = {
  navbar: 10,
  modal: 1000,
  toast: 1001,
  popover: 1002,
};

export const sizes = {
  navbarHeight: 60,
  sidebarWidth: '468px',
};

export const font = {
  /**
   * weight: 400
   * 'font-family: "Montserrat", sans-serif; font-weight: 400;',
   */
  regular: 'font-family: "Montserrat", sans-serif; font-weight: 400;',
  /**
   * weight: 500
   * font-family: "Montserrat", sans-serif; font-weight: 500;',
   */
  medium: 'font-family: "Montserrat", sans-serif; font-weight: 500;',
  /**
   * weight: 600
   * 'font-family: "Montserrat", sans-serif; font-weight: 600;',
   */
  bold: 'font-family: "Montserrat", sans-serif; font-weight: 600;',
  size: (size: number) => `font-size: ${size}px;`,
};

export const mixin = {
  darken,
  lighten,
  rgba,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  boxShadowLarge: css`
    box-shadow: 0 5px 8px -4px ${rgba(color.background1, 0.08)},
      0 0 20px 0 ${rgba(color.background1, 0.05)}, 0 40px 34px -16px ${rgba(color.background1, 0.1)};
  `,
  boxShadowFaint: css`
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.0479403), 0px 0px 0px #ebecf4;
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `,
  backgroundImage: (imageURL: string) => css`
    background-image: url("${imageURL}");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  `,
  cover: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 99%;
    width: 100%;
  `,
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  customScrollbar: ({
    width = 10,
    backgroundTrack = color.background3,
    backgroundThumb = color.background5,
  } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: ${backgroundTrack};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: ${backgroundThumb};
    }
  `,
  placeholderColor: (colorValue: string) => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
};

export const animation = {
  timingFunc: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  scaleIn: keyframes`
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `,
};

export const padding = {
  sidebarReg: 'padding: 0 24px 24px;',
  sidebarSm: 'padding: 0 8px 24px',
  sidebarSection: 'padding: 16px 24px;',
  executionComment: 'padding: 0 0 24px 0',
  xs: '4px',
  sm: '8px',
  med: '16px',
  lrg: '24px',
  xlrg: '32px',
};

export const border = {
  section: `1px solid ${color.border2}`,
  borderRadius: '4px',
};

export const shadow = {
  sidebar: `-4px 0px 8px 0px rgba(0, 0, 0, 0.5)`,
};
