import React, { useRef } from 'react';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsRoundedCorners from 'highcharts-rounded-corners';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import { omit } from 'lodash';

import { Spinner } from '@glean/glean-ui.atoms.spinner';

import { color } from 'shared/styles';

HighchartsMore(Highcharts);
HighchartsDrilldown(Highcharts);
HighchartsRoundedCorners(Highcharts);

// Apply the theme
Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  chart: {
    backgroundColor: 'transparent',
  },
  legend: {
    symbolRadius: 4,
  },
  tooltip: {
    borderWidth: 0,
    borderRadius: 16,
    useHTML: true,
  },
  plotOptions: {
    series: {
      animation: false,
    },
  },
});

type Props = {
  options?: Highcharts.Options;
  seriesData: Partial<Highcharts.SeriesColumnrangeOptions>[];
  labelFormatter?: (arg: string) => string;
  xProperty: string;
  yProperty: string;
  loading?: boolean;
  hideDataLabels?: boolean;
  hideToolTip?: boolean;
  yAxisMax: number;
};
export const BarChart = ({
  options,
  seriesData = [],
  labelFormatter,
  xProperty,
  yProperty,
  loading,
  hideDataLabels,
  hideToolTip,
  yAxisMax,
}: Props) => {
  const internalChartRef = useRef<Highcharts.Chart>();
  const defaultOptions: Highcharts.Options = {
    colors: seriesData.map((series) => String(series.color)),
    chart: {
      height: 320,
      width: 500,
      marginBottom: 40,
      marginTop: 12,
      panning: {
        enabled: true,
        type: 'x',
      },
      animation: false,
    },
    xAxis: {
      type: 'category',
      lineWidth: 1,
      lineColor: 'transparent',
      tickWidth: 0,
      labels: {
        y: 24,
        rotation: 0,
        useHTML: true,
        style: {
          transition: 'none',
          opacity: 1,
          animation: 'none',
        },
        formatter: function (currentColumn) {
          const label = labelFormatter
            ? labelFormatter(String(currentColumn.value))
            : String(currentColumn.value);
          return `
            <div style="display:flex;align-items:flex-start;justify-content:center;min-height:32px;min-width: 52px;">
              <span style="color: ${color.text1};white-space: normal;text-align: center;display: inline-block;">
                ${label}
              </span>
            </div>`;
        },
      },
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      visible: false,
      min: 0,
      max: yAxisMax * 1.1,
    },
    legend: {
      enabled: false,
      y: 3,
      useHTML: true,
    },
    tooltip: {
      enabled: !hideToolTip,
    },
    plotOptions: {
      series: {
        pointWidth: 52,
        stacking: 'normal',
        minPointLength: 10,
        borderWidth: 0,
        cursor: 'pointer',
        states: {
          hover: {
            brightness: 0,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    } as Highcharts.PlotOptions,
    series: seriesData.map((series) => {
      return {
        name: series.name,
        type: 'column',
        minPointLength: 0,
        dataLabels: {
          overflow: 'allow',
          crop: false,
          inside: false,
          enabled: !hideDataLabels,
          verticalAlign: 'bottom',
          style: {
            textOutline: '0',
            fontWeight: '500',
            fontSize: '11',
            color: '#fff',
            marginBottom: 2,
          },
          useHTML: true,
        },
        borderRadiusTopLeft: '3',
        borderRadiusTopRight: '3',
        borderRadiusBottomLeft: '3',
        borderRadiusBottomRight: '3',
        ...omit(series, 'type'),
        data:
          series.data?.map((point: any) => {
            return {
              name: point[xProperty],
              y: point[yProperty] || 0,
            };
          }) || [],
      };
    }),
  };
  if (loading)
    return (
      <div
        style={{
          height: '320px',
          width: '500px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <HighchartsReact
      options={{ ...defaultOptions, ...options }}
      highcharts={Highcharts}
      callback={(chart: Highcharts.Chart) => {
        internalChartRef.current = chart;
      }}
    />
  );
};
