import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { escalateJob } from 'shared/api';
import { Form } from 'shared/components/form';
import { Modal } from 'shared/components/modal';
import { ModalControls } from 'shared/components/modal-controls';

import { EscalationModalContent, EscalationModalTitle } from './styles';

export const EscalateJobModal = ({ onEscalate, job }: { onEscalate: () => void; job: any }) => {
  return (
    <Modal
      renderContent={(modal) => {
        return (
          <>
            <Form
              initialValues={{
                reason: '',
                commentText: '',
              }}
              validations={{
                reason: Form.is.required('Required'),
                commentText: Form.is.required('Required'),
              }}
              onSubmit={async ({ commentText, reason }: any) => {
                await escalateJob({ jobId: job.id, commentText, reason });
                await onEscalate();
              }}
            >
              {({ isSubmitting, submitForm }: any) => {
                return (
                  <>
                    <EscalationModalContent>
                      <EscalationModalTitle>Escalate Invoice</EscalationModalTitle>
                      <Form.Field.Select
                        name="reason"
                        options={[
                          { label: 'Select a reason...', value: '' },
                          { label: 'Not an invoice', value: 'NOT_AN_INVOICE' },
                          {
                            label: 'Question about invoice summary',
                            value: 'INVOICE_SUMMARY_QUESTION',
                          },
                          {
                            label: 'Question about invoice costs',
                            value: 'INVOICE_COSTS_QUESTION',
                          },
                          {
                            label: 'Question about line item period start and end dates',
                            value: 'LINE_ITEM_PERIOD_DATES_QUESTION',
                          },
                          {
                            label: 'Question about line item quantity and unit price',
                            value: 'LINE_ITEM_UNITS_QUESTION',
                          },
                          {
                            label: 'Question about line item costs',
                            value: 'LINE_ITEM_COSTS_QUESTION',
                          },
                          { label: 'Other', value: 'OTHER' },
                        ]}
                      />
                      <Form.Field.Input name="commentText" placeholder="Comment here..." />
                    </EscalationModalContent>
                    <ModalControls.Container>
                      <div></div>
                      <div style={{ display: 'flex', gap: '16px' }}>
                        <ModalControls.CancelButton onClick={modal.close}>
                          Cancel
                        </ModalControls.CancelButton>
                        <ModalControls.ConfirmButton onClick={submitForm} isWorking={isSubmitting}>
                          Escalate
                        </ModalControls.ConfirmButton>
                      </div>
                    </ModalControls.Container>
                  </>
                );
              }}
            </Form>
          </>
        );
      }}
      renderHandle={() => (
        <Button icon="alertTriangle" variant="unstyled">
          Escalate
        </Button>
      )}
    />
  );
};
