import styled from 'styled-components';

import { color, font } from 'shared/styles';

export const Container = styled.div<{ maxWidth: string }>`
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  padding: 25px;
  border-radius: 5px;
  background: ${color.background3};
`;
export const Header = styled.div`
  margin-bottom: 30px;
`;
export const Heading = styled.h3`
  ${font.size(18)}
`;
export const Vendor = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid ${color.border2};
  border-bottom: 1px solid ${color.border2};
`;
export const VendorName = styled.div`
  padding: 0 14px 0 16px;
  ${font.size(16)}
  ${font.bold}
`;
export const Section = styled.section`
  padding-bottom: 30px;
  border-bottom: 1px solid ${color.border2};

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;
export const SectionTitle = styled.div`
  ${font.size(16)}
  ${font.medium}
`;
export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2%;

  & > * {
    margin: 24px 2% 0;
    width: 29.333%;
  }
`;
export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;

  & > button {
    margin-left: 12px;
  }
`;
