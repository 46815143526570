import React, { useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@glean/glean-ui.molecules.button';

import { useAuth } from 'shared/auth';

const Container = styled.section`
  display: grid;
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  > * {
    padding: 25px;
  }
`;

export const LogIn = () => {
  let { user, lock, redirect } = useAuth();

  useEffect(() => {
    // debounce the lock.show call slightly
    // to prevent awkward flashes of login
    let to = setTimeout(lock.show, 500);
    return () => {
      clearTimeout(to);
      lock.hide();
    };
  }, [lock]);

  if (user) {
    return <Redirect to={redirect === '/log-in' ? '/home' : redirect} />;
  } else {
    return (
      <Container>
        <h1>You must log in to continue...</h1>
        <Button
          size="large"
          variant="outlined"
          trailingIcon="arrowRight"
          onClick={() => {
            lock.show();
          }}
        >
          Log in
        </Button>
      </Container>
    );
  }
};
