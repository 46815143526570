/* tslint:disable */

/* eslint-disable */

/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  CanonicalLineItemDTO,
  CanonicalLineItemDTOFromJSON,
  CanonicalLineItemDTOToJSON,
  CanonicalLineItemDetailT,
  CanonicalLineItemDetailTFromJSON,
  CanonicalLineItemDetailTToJSON,
  CanonicalLineItemPostT,
  CanonicalLineItemPostTFromJSON,
  CanonicalLineItemPostTToJSON,
  CanonicalVendorDTO,
  CanonicalVendorDTOFromJSON,
  CanonicalVendorDTOToJSON,
  CanonicalVendorDetailT,
  CanonicalVendorDetailTFromJSON,
  CanonicalVendorDetailTToJSON,
  CanonicalVendorMergedDataDTO,
  CanonicalVendorMergedDataDTOFromJSON,
  CanonicalVendorMergedDataDTOToJSON,
  CanonicalVendorResponseT,
  CanonicalVendorResponseTFromJSON,
  CanonicalVendorResponseTToJSON,
  CanonicalVendorT,
  CanonicalVendorTFromJSON,
  CanonicalVendorTToJSON,
  CommentDTO,
  CommentDTOFromJSON,
  CommentDTOToJSON,
  Company,
  CompanyDTO,
  CompanyDTOFromJSON,
  CompanyDTOToJSON,
  CompanyFromJSON,
  CompanyToJSON,
  EditCompanyPriorityReturnT,
  EditCompanyPriorityReturnTFromJSON,
  EditCompanyPriorityReturnTToJSON,
  EditGetInvoiceDTO,
  EditGetInvoiceDTOFromJSON,
  EditGetInvoiceDTOToJSON,
  EditUpdateInvoiceDTO,
  EditUpdateInvoiceDTOFromJSON,
  EditUpdateInvoiceDTOToJSON,
  ExecutionsByStepDTO,
  ExecutionsByStepDTOFromJSON,
  ExecutionsByStepDTOToJSON,
  ExecutionsDTO,
  ExecutionsDTOFromJSON,
  ExecutionsDTOToJSON,
  GetCompanyPrioritiesReturnT,
  GetCompanyPrioritiesReturnTFromJSON,
  GetCompanyPrioritiesReturnTToJSON,
  GetManagePipelineAggregationsT,
  GetManagePipelineAggregationsTFromJSON,
  GetManagePipelineAggregationsTToJSON,
  GetPipelineMetricsT,
  GetPipelineMetricsTFromJSON,
  GetPipelineMetricsTToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  InvoiceJsonsDTO,
  InvoiceJsonsDTOFromJSON,
  InvoiceJsonsDTOToJSON,
  JobMetricsReturnT,
  JobMetricsReturnTFromJSON,
  JobMetricsReturnTToJSON,
  LineItemStpResponsePagination,
  LineItemStpResponsePaginationFromJSON,
  LineItemStpResponsePaginationToJSON,
  MergeCanonicalLineItemT,
  MergeCanonicalLineItemTFromJSON,
  MergeCanonicalLineItemTToJSON,
  MergeCanonicalVendorT,
  MergeCanonicalVendorTFromJSON,
  MergeCanonicalVendorTToJSON,
  RestartExecutionInputT,
  RestartExecutionInputTFromJSON,
  RestartExecutionInputTToJSON,
  RestartStepsDTO,
  RestartStepsDTOFromJSON,
  RestartStepsDTOToJSON,
  SingleExecutionDTO,
  SingleExecutionDTOFromJSON,
  SingleExecutionDTOToJSON,
  SuccessT,
  SuccessTFromJSON,
  SuccessTToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface CanonicalVendorsServiceCreateCanonicalLineItemRequest {
  canonicalVendorId: string;
  canonicalLineItem: CanonicalLineItemPostT;
}

export interface CanonicalVendorsServiceCreateCanonicalVendorRequest {
  input: CanonicalVendorT;
}

export interface CanonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorIdRequest {
  canonicalVendorId: string;
}

export interface CanonicalVendorsServiceGetCanonicalVendorByIdRequest {
  canonicalVendorId: string;
}

export interface CanonicalVendorsServiceMergeLineItemsRequest {
  input: MergeCanonicalLineItemT;
}

export interface CanonicalVendorsServiceMergeVendorsRequest {
  input: MergeCanonicalVendorT;
}

export interface CanonicalVendorsServiceUpdateCanonicalLineItemRequest {
  canonicalLineItemId: string;
  canonicalVendorId: string;
  input: CanonicalLineItemPostT;
}

export interface CanonicalVendorsServiceUpdateCanonicalVendorRequest {
  canonicalVendorId: string;
  canonicalVendor: CanonicalVendorT;
}

export interface CommentsServiceGetCommentsForExecutionArnRequest {
  executionArn: string;
}

export interface CompanyPriorityServiceEditCompanyPriorityRequest {
  input: Company;
}

export interface CompanyServiceGetCompanyByIdRequest {
  companyId: string;
}

export interface EditServiceEditInvoiceRequest {
  id: string;
  fields: Array<EditUpdateInvoiceDTO>;
}

export interface EditServiceGetInvoiceRequest {
  id: string;
}

export interface ExecutionsServiceGetExecutionRequest {
  executionArn: string;
}

export interface ExecutionsServiceGetExecutionInvoiceJsonsRequest {
  executionArn: string;
}

export interface ExecutionsServiceGetExecutionInvoiceJsonsForStepRequest {
  executionArn: string;
  step: ExecutionsServiceGetExecutionInvoiceJsonsForStepStepEnum;
}

export interface ExecutionsServiceGetExecutionRestartStepsRequest {
  executionArn: string;
}

export interface ExecutionsServiceRestartExecutionRequest {
  executionArn: string;
  input: RestartExecutionInputT;
}

export interface MapServiceGetCanonicalVendorRequest {
  canonicalVendorId: string;
}

export interface MapServiceGetCanonicalVendorLineItemsRequest {
  canonicalVendorId: string;
  offset?: number;
  limit?: number;
}

export interface MapServiceGetCanonicalVendorLineItemsByIdRequest {
  canonicalVendorId: string;
  lineItemIds: Array<string>;
}

export interface MapServiceGetCanonicalVendorMergedDataRequest {
  canonicalVendorId: string;
}

export interface MapServiceLineItemStpRequest {
  executionArn: string;
  fileKey: string;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Returns the collection of roll-ups and aggregations necessary to render the manage dashboard
   */
  async aggregationsServiceGetManagePipelineAggregationsRaw(): Promise<
    runtime.ApiResponse<GetManagePipelineAggregationsT>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-aggregations`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetManagePipelineAggregationsTFromJSON(jsonValue),
    );
  }

  /**
   * Returns the collection of roll-ups and aggregations necessary to render the manage dashboard
   */
  async aggregationsServiceGetManagePipelineAggregations(): Promise<
    GetManagePipelineAggregationsT
  > {
    const response = await this.aggregationsServiceGetManagePipelineAggregationsRaw();
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceCreateCanonicalLineItemRaw(
    requestParameters: CanonicalVendorsServiceCreateCanonicalLineItemRequest,
  ): Promise<runtime.ApiResponse<CanonicalLineItemDetailT>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling canonicalVendorsServiceCreateCanonicalLineItem.',
      );
    }

    if (
      requestParameters.canonicalLineItem === null ||
      requestParameters.canonicalLineItem === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalLineItem',
        'Required parameter requestParameters.canonicalLineItem was null or undefined when calling canonicalVendorsServiceCreateCanonicalLineItem.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/canonical-vendors/{canonicalVendorId}/line-items`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CanonicalLineItemPostTToJSON(requestParameters.canonicalLineItem),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CanonicalLineItemDetailTFromJSON(jsonValue),
    );
  }

  /**
   */
  async canonicalVendorsServiceCreateCanonicalLineItem(
    requestParameters: CanonicalVendorsServiceCreateCanonicalLineItemRequest,
  ): Promise<CanonicalLineItemDetailT> {
    const response = await this.canonicalVendorsServiceCreateCanonicalLineItemRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceCreateCanonicalVendorRaw(
    requestParameters: CanonicalVendorsServiceCreateCanonicalVendorRequest,
  ): Promise<runtime.ApiResponse<CanonicalVendorResponseT>> {
    if (requestParameters.input === null || requestParameters.input === undefined) {
      throw new runtime.RequiredError(
        'input',
        'Required parameter requestParameters.input was null or undefined when calling canonicalVendorsServiceCreateCanonicalVendor.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/canonical-vendors`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CanonicalVendorTToJSON(requestParameters.input),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CanonicalVendorResponseTFromJSON(jsonValue),
    );
  }

  /**
   */
  async canonicalVendorsServiceCreateCanonicalVendor(
    requestParameters: CanonicalVendorsServiceCreateCanonicalVendorRequest,
  ): Promise<CanonicalVendorResponseT> {
    const response = await this.canonicalVendorsServiceCreateCanonicalVendorRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorIdRaw(
    requestParameters: CanonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorIdRequest,
  ): Promise<runtime.ApiResponse<Array<CanonicalLineItemDetailT>>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling canonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorId.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/canonical-vendors/{canonicalVendorId}/line-items`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CanonicalLineItemDetailTFromJSON),
    );
  }

  /**
   */
  async canonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorId(
    requestParameters: CanonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorIdRequest,
  ): Promise<Array<CanonicalLineItemDetailT>> {
    const response = await this.canonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorIdRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceGetCanonicalVendorByIdRaw(
    requestParameters: CanonicalVendorsServiceGetCanonicalVendorByIdRequest,
  ): Promise<runtime.ApiResponse<CanonicalVendorResponseT>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling canonicalVendorsServiceGetCanonicalVendorById.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/canonical-vendors/{canonicalVendorId}`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CanonicalVendorResponseTFromJSON(jsonValue),
    );
  }

  /**
   */
  async canonicalVendorsServiceGetCanonicalVendorById(
    requestParameters: CanonicalVendorsServiceGetCanonicalVendorByIdRequest,
  ): Promise<CanonicalVendorResponseT> {
    const response = await this.canonicalVendorsServiceGetCanonicalVendorByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceGetCanonicalVendorsRaw(): Promise<
    runtime.ApiResponse<Array<CanonicalVendorDetailT>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/canonical-vendors`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CanonicalVendorDetailTFromJSON),
    );
  }

  /**
   */
  async canonicalVendorsServiceGetCanonicalVendors(): Promise<Array<CanonicalVendorDetailT>> {
    const response = await this.canonicalVendorsServiceGetCanonicalVendorsRaw();
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceMergeLineItemsRaw(
    requestParameters: CanonicalVendorsServiceMergeLineItemsRequest,
  ): Promise<runtime.ApiResponse<SuccessT>> {
    if (requestParameters.input === null || requestParameters.input === undefined) {
      throw new runtime.RequiredError(
        'input',
        'Required parameter requestParameters.input was null or undefined when calling canonicalVendorsServiceMergeLineItems.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/canonical-vendors/merge-canonical-line-items`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: MergeCanonicalLineItemTToJSON(requestParameters.input),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SuccessTFromJSON(jsonValue));
  }

  /**
   */
  async canonicalVendorsServiceMergeLineItems(
    requestParameters: CanonicalVendorsServiceMergeLineItemsRequest,
  ): Promise<SuccessT> {
    const response = await this.canonicalVendorsServiceMergeLineItemsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceMergeVendorsRaw(
    requestParameters: CanonicalVendorsServiceMergeVendorsRequest,
  ): Promise<runtime.ApiResponse<SuccessT>> {
    if (requestParameters.input === null || requestParameters.input === undefined) {
      throw new runtime.RequiredError(
        'input',
        'Required parameter requestParameters.input was null or undefined when calling canonicalVendorsServiceMergeVendors.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/canonical-vendors/merge-canonical-vendors`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: MergeCanonicalVendorTToJSON(requestParameters.input),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SuccessTFromJSON(jsonValue));
  }

  /**
   */
  async canonicalVendorsServiceMergeVendors(
    requestParameters: CanonicalVendorsServiceMergeVendorsRequest,
  ): Promise<SuccessT> {
    const response = await this.canonicalVendorsServiceMergeVendorsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceUpdateCanonicalLineItemRaw(
    requestParameters: CanonicalVendorsServiceUpdateCanonicalLineItemRequest,
  ): Promise<runtime.ApiResponse<SuccessT>> {
    if (
      requestParameters.canonicalLineItemId === null ||
      requestParameters.canonicalLineItemId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalLineItemId',
        'Required parameter requestParameters.canonicalLineItemId was null or undefined when calling canonicalVendorsServiceUpdateCanonicalLineItem.',
      );
    }

    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling canonicalVendorsServiceUpdateCanonicalLineItem.',
      );
    }

    if (requestParameters.input === null || requestParameters.input === undefined) {
      throw new runtime.RequiredError(
        'input',
        'Required parameter requestParameters.input was null or undefined when calling canonicalVendorsServiceUpdateCanonicalLineItem.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/canonical-vendors/{canonicalVendorId}/line-items/{canonicalLineItemId}`
        .replace(
          `{${'canonicalLineItemId'}}`,
          encodeURIComponent(String(requestParameters.canonicalLineItemId)),
        )
        .replace(
          `{${'canonicalVendorId'}}`,
          encodeURIComponent(String(requestParameters.canonicalVendorId)),
        ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: CanonicalLineItemPostTToJSON(requestParameters.input),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => SuccessTFromJSON(jsonValue));
  }

  /**
   */
  async canonicalVendorsServiceUpdateCanonicalLineItem(
    requestParameters: CanonicalVendorsServiceUpdateCanonicalLineItemRequest,
  ): Promise<SuccessT> {
    const response = await this.canonicalVendorsServiceUpdateCanonicalLineItemRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async canonicalVendorsServiceUpdateCanonicalVendorRaw(
    requestParameters: CanonicalVendorsServiceUpdateCanonicalVendorRequest,
  ): Promise<runtime.ApiResponse<CanonicalVendorResponseT>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling canonicalVendorsServiceUpdateCanonicalVendor.',
      );
    }

    if (
      requestParameters.canonicalVendor === null ||
      requestParameters.canonicalVendor === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendor',
        'Required parameter requestParameters.canonicalVendor was null or undefined when calling canonicalVendorsServiceUpdateCanonicalVendor.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/canonical-vendors/{canonicalVendorId}`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: CanonicalVendorTToJSON(requestParameters.canonicalVendor),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CanonicalVendorResponseTFromJSON(jsonValue),
    );
  }

  /**
   */
  async canonicalVendorsServiceUpdateCanonicalVendor(
    requestParameters: CanonicalVendorsServiceUpdateCanonicalVendorRequest,
  ): Promise<CanonicalVendorResponseT> {
    const response = await this.canonicalVendorsServiceUpdateCanonicalVendorRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async commentsServiceGetCommentsForExecutionArnRaw(
    requestParameters: CommentsServiceGetCommentsForExecutionArnRequest,
  ): Promise<runtime.ApiResponse<Array<CommentDTO>>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling commentsServiceGetCommentsForExecutionArn.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/comments/{executionArn}`.replace(
        `{${'executionArn'}}`,
        encodeURIComponent(String(requestParameters.executionArn)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentDTOFromJSON));
  }

  /**
   */
  async commentsServiceGetCommentsForExecutionArn(
    requestParameters: CommentsServiceGetCommentsForExecutionArnRequest,
  ): Promise<Array<CommentDTO>> {
    const response = await this.commentsServiceGetCommentsForExecutionArnRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async companyPriorityServiceEditCompanyPriorityRaw(
    requestParameters: CompanyPriorityServiceEditCompanyPriorityRequest,
  ): Promise<runtime.ApiResponse<EditCompanyPriorityReturnT>> {
    if (requestParameters.input === null || requestParameters.input === undefined) {
      throw new runtime.RequiredError(
        'input',
        'Required parameter requestParameters.input was null or undefined when calling companyPriorityServiceEditCompanyPriority.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/company-priorities`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyToJSON(requestParameters.input),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EditCompanyPriorityReturnTFromJSON(jsonValue),
    );
  }

  /**
   */
  async companyPriorityServiceEditCompanyPriority(
    requestParameters: CompanyPriorityServiceEditCompanyPriorityRequest,
  ): Promise<EditCompanyPriorityReturnT> {
    const response = await this.companyPriorityServiceEditCompanyPriorityRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async companyPriorityServiceGetCompanyPrioritiesRaw(): Promise<
    runtime.ApiResponse<GetCompanyPrioritiesReturnT>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/company-priorities`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetCompanyPrioritiesReturnTFromJSON(jsonValue),
    );
  }

  /**
   */
  async companyPriorityServiceGetCompanyPriorities(): Promise<GetCompanyPrioritiesReturnT> {
    const response = await this.companyPriorityServiceGetCompanyPrioritiesRaw();
    return await response.value();
  }

  /**
   */
  async companyServiceGetCompaniesRaw(): Promise<runtime.ApiResponse<Array<CompanyDTO>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/companies`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyDTOFromJSON));
  }

  /**
   */
  async companyServiceGetCompanies(): Promise<Array<CompanyDTO>> {
    const response = await this.companyServiceGetCompaniesRaw();
    return await response.value();
  }

  /**
   */
  async companyServiceGetCompanyByIdRaw(
    requestParameters: CompanyServiceGetCompanyByIdRequest,
  ): Promise<runtime.ApiResponse<CompanyDTO>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling companyServiceGetCompanyById.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(requestParameters.companyId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
  }

  /**
   */
  async companyServiceGetCompanyById(
    requestParameters: CompanyServiceGetCompanyByIdRequest,
  ): Promise<CompanyDTO> {
    const response = await this.companyServiceGetCompanyByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an invoice and reprocesses it as necessary
   */
  async editServiceEditInvoiceRaw(
    requestParameters: EditServiceEditInvoiceRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling editServiceEditInvoice.',
      );
    }

    if (requestParameters.fields === null || requestParameters.fields === undefined) {
      throw new runtime.RequiredError(
        'fields',
        'Required parameter requestParameters.fields was null or undefined when calling editServiceEditInvoice.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/edit/invoice/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.fields.map(EditUpdateInvoiceDTOToJSON),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates an invoice and reprocesses it as necessary
   */
  async editServiceEditInvoice(requestParameters: EditServiceEditInvoiceRequest): Promise<void> {
    await this.editServiceEditInvoiceRaw(requestParameters);
  }

  /**
   * Returns an invoice by id and all its line items if they have been processed
   */
  async editServiceGetInvoiceRaw(
    requestParameters: EditServiceGetInvoiceRequest,
  ): Promise<runtime.ApiResponse<EditGetInvoiceDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling editServiceGetInvoice.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/edit/invoice/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EditGetInvoiceDTOFromJSON(jsonValue),
    );
  }

  /**
   * Returns an invoice by id and all its line items if they have been processed
   */
  async editServiceGetInvoice(
    requestParameters: EditServiceGetInvoiceRequest,
  ): Promise<EditGetInvoiceDTO> {
    const response = await this.editServiceGetInvoiceRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async executionsServiceGetExecutionRaw(
    requestParameters: ExecutionsServiceGetExecutionRequest,
  ): Promise<runtime.ApiResponse<SingleExecutionDTO>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling executionsServiceGetExecution.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-executions/{executionArn}`.replace(
        `{${'executionArn'}}`,
        encodeURIComponent(String(requestParameters.executionArn)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SingleExecutionDTOFromJSON(jsonValue),
    );
  }

  /**
   */
  async executionsServiceGetExecution(
    requestParameters: ExecutionsServiceGetExecutionRequest,
  ): Promise<SingleExecutionDTO> {
    const response = await this.executionsServiceGetExecutionRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async executionsServiceGetExecutionInvoiceJsonsRaw(
    requestParameters: ExecutionsServiceGetExecutionInvoiceJsonsRequest,
  ): Promise<runtime.ApiResponse<InvoiceJsonsDTO>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling executionsServiceGetExecutionInvoiceJsons.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-executions/{executionArn}/invoice-jsons`.replace(
        `{${'executionArn'}}`,
        encodeURIComponent(String(requestParameters.executionArn)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceJsonsDTOFromJSON(jsonValue));
  }

  /**
   */
  async executionsServiceGetExecutionInvoiceJsons(
    requestParameters: ExecutionsServiceGetExecutionInvoiceJsonsRequest,
  ): Promise<InvoiceJsonsDTO> {
    const response = await this.executionsServiceGetExecutionInvoiceJsonsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async executionsServiceGetExecutionInvoiceJsonsForStepRaw(
    requestParameters: ExecutionsServiceGetExecutionInvoiceJsonsForStepRequest,
  ): Promise<runtime.ApiResponse<Array<ExecutionsByStepDTO>>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling executionsServiceGetExecutionInvoiceJsonsForStep.',
      );
    }

    if (requestParameters.step === null || requestParameters.step === undefined) {
      throw new runtime.RequiredError(
        'step',
        'Required parameter requestParameters.step was null or undefined when calling executionsServiceGetExecutionInvoiceJsonsForStep.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-executions/{executionArn}/invoice-jsons/{step}`
        .replace(`{${'executionArn'}}`, encodeURIComponent(String(requestParameters.executionArn)))
        .replace(`{${'step'}}`, encodeURIComponent(String(requestParameters.step))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ExecutionsByStepDTOFromJSON),
    );
  }

  /**
   */
  async executionsServiceGetExecutionInvoiceJsonsForStep(
    requestParameters: ExecutionsServiceGetExecutionInvoiceJsonsForStepRequest,
  ): Promise<Array<ExecutionsByStepDTO>> {
    const response = await this.executionsServiceGetExecutionInvoiceJsonsForStepRaw(
      requestParameters,
    );
    return await response.value();
  }

  /**
   */
  async executionsServiceGetExecutionRestartStepsRaw(
    requestParameters: ExecutionsServiceGetExecutionRestartStepsRequest,
  ): Promise<runtime.ApiResponse<RestartStepsDTO>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling executionsServiceGetExecutionRestartSteps.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-executions/{executionArn}/restart-steps`.replace(
        `{${'executionArn'}}`,
        encodeURIComponent(String(requestParameters.executionArn)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => RestartStepsDTOFromJSON(jsonValue));
  }

  /**
   */
  async executionsServiceGetExecutionRestartSteps(
    requestParameters: ExecutionsServiceGetExecutionRestartStepsRequest,
  ): Promise<RestartStepsDTO> {
    const response = await this.executionsServiceGetExecutionRestartStepsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async executionsServiceGetExecutionsRaw(): Promise<runtime.ApiResponse<ExecutionsDTO>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-executions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ExecutionsDTOFromJSON(jsonValue));
  }

  /**
   */
  async executionsServiceGetExecutions(): Promise<ExecutionsDTO> {
    const response = await this.executionsServiceGetExecutionsRaw();
    return await response.value();
  }

  /**
   */
  async executionsServiceRestartExecutionRaw(
    requestParameters: ExecutionsServiceRestartExecutionRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling executionsServiceRestartExecution.',
      );
    }

    if (requestParameters.input === null || requestParameters.input === undefined) {
      throw new runtime.RequiredError(
        'input',
        'Required parameter requestParameters.input was null or undefined when calling executionsServiceRestartExecution.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/manage/pipeline-executions/{executionArn}/restart`.replace(
        `{${'executionArn'}}`,
        encodeURIComponent(String(requestParameters.executionArn)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RestartExecutionInputTToJSON(requestParameters.input),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async executionsServiceRestartExecution(
    requestParameters: ExecutionsServiceRestartExecutionRequest,
  ): Promise<void> {
    await this.executionsServiceRestartExecutionRaw(requestParameters);
  }

  /**
   * returns the count of all currently running jobs in both pipelines
   */
  async jobMetricsServiceGetJobMetricsRaw(): Promise<runtime.ApiResponse<JobMetricsReturnT>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/job-metrics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      JobMetricsReturnTFromJSON(jsonValue),
    );
  }

  /**
   * returns the count of all currently running jobs in both pipelines
   */
  async jobMetricsServiceGetJobMetrics(): Promise<JobMetricsReturnT> {
    const response = await this.jobMetricsServiceGetJobMetricsRaw();
    return await response.value();
  }

  /**
   */
  async mapServiceGetCanonicalVendorRaw(
    requestParameters: MapServiceGetCanonicalVendorRequest,
  ): Promise<runtime.ApiResponse<CanonicalVendorDTO>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling mapServiceGetCanonicalVendor.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/map/canonical-vendor/{canonicalVendorId}`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CanonicalVendorDTOFromJSON(jsonValue),
    );
  }

  /**
   */
  async mapServiceGetCanonicalVendor(
    requestParameters: MapServiceGetCanonicalVendorRequest,
  ): Promise<CanonicalVendorDTO> {
    const response = await this.mapServiceGetCanonicalVendorRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async mapServiceGetCanonicalVendorLineItemsRaw(
    requestParameters: MapServiceGetCanonicalVendorLineItemsRequest,
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling mapServiceGetCanonicalVendorLineItems.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/map/canonical-vendor/{canonicalVendorId}/canonical-line-items`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse200FromJSON(jsonValue),
    );
  }

  /**
   */
  async mapServiceGetCanonicalVendorLineItems(
    requestParameters: MapServiceGetCanonicalVendorLineItemsRequest,
  ): Promise<InlineResponse200> {
    const response = await this.mapServiceGetCanonicalVendorLineItemsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async mapServiceGetCanonicalVendorLineItemsByIdRaw(
    requestParameters: MapServiceGetCanonicalVendorLineItemsByIdRequest,
  ): Promise<runtime.ApiResponse<Array<CanonicalLineItemDTO>>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling mapServiceGetCanonicalVendorLineItemsById.',
      );
    }

    if (requestParameters.lineItemIds === null || requestParameters.lineItemIds === undefined) {
      throw new runtime.RequiredError(
        'lineItemIds',
        'Required parameter requestParameters.lineItemIds was null or undefined when calling mapServiceGetCanonicalVendorLineItemsById.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.lineItemIds) {
      queryParameters['lineItemIds'] = requestParameters.lineItemIds.join(
        runtime.COLLECTION_FORMATS['csv'],
      );
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/map/canonical-vendor/{canonicalVendorId}/canonical-line-item-by-id`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CanonicalLineItemDTOFromJSON),
    );
  }

  /**
   */
  async mapServiceGetCanonicalVendorLineItemsById(
    requestParameters: MapServiceGetCanonicalVendorLineItemsByIdRequest,
  ): Promise<Array<CanonicalLineItemDTO>> {
    const response = await this.mapServiceGetCanonicalVendorLineItemsByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async mapServiceGetCanonicalVendorMergedDataRaw(
    requestParameters: MapServiceGetCanonicalVendorMergedDataRequest,
  ): Promise<runtime.ApiResponse<CanonicalVendorMergedDataDTO>> {
    if (
      requestParameters.canonicalVendorId === null ||
      requestParameters.canonicalVendorId === undefined
    ) {
      throw new runtime.RequiredError(
        'canonicalVendorId',
        'Required parameter requestParameters.canonicalVendorId was null or undefined when calling mapServiceGetCanonicalVendorMergedData.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/map/canonical-vendor-merge-data/{canonicalVendorId}`.replace(
        `{${'canonicalVendorId'}}`,
        encodeURIComponent(String(requestParameters.canonicalVendorId)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CanonicalVendorMergedDataDTOFromJSON(jsonValue),
    );
  }

  /**
   */
  async mapServiceGetCanonicalVendorMergedData(
    requestParameters: MapServiceGetCanonicalVendorMergedDataRequest,
  ): Promise<CanonicalVendorMergedDataDTO> {
    const response = await this.mapServiceGetCanonicalVendorMergedDataRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async mapServiceLineItemStpRaw(
    requestParameters: MapServiceLineItemStpRequest,
  ): Promise<runtime.ApiResponse<LineItemStpResponsePagination>> {
    if (requestParameters.executionArn === null || requestParameters.executionArn === undefined) {
      throw new runtime.RequiredError(
        'executionArn',
        'Required parameter requestParameters.executionArn was null or undefined when calling mapServiceLineItemStp.',
      );
    }

    if (requestParameters.fileKey === null || requestParameters.fileKey === undefined) {
      throw new runtime.RequiredError(
        'fileKey',
        'Required parameter requestParameters.fileKey was null or undefined when calling mapServiceLineItemStp.',
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.executionArn !== undefined) {
      queryParameters['executionArn'] = requestParameters.executionArn;
    }

    if (requestParameters.fileKey !== undefined) {
      queryParameters['fileKey'] = requestParameters.fileKey;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/map/line-item-stp`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LineItemStpResponsePaginationFromJSON(jsonValue),
    );
  }

  /**
   */
  async mapServiceLineItemStp(
    requestParameters: MapServiceLineItemStpRequest,
  ): Promise<LineItemStpResponsePagination> {
    const response = await this.mapServiceLineItemStpRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async pipelineMetricsServiceGetPipelineMetricsRaw(): Promise<
    runtime.ApiResponse<GetPipelineMetricsT>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/manage/pipeline-metrics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetPipelineMetricsTFromJSON(jsonValue),
    );
  }

  /**
   */
  async pipelineMetricsServiceGetPipelineMetrics(): Promise<GetPipelineMetricsT> {
    const response = await this.pipelineMetricsServiceGetPipelineMetricsRaw();
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum ExecutionsServiceGetExecutionInvoiceJsonsForStepStepEnum {
  OCRGLEANPDFREADERRAW = 'OCR_GLEAN_PDF_READER_RAW',
  OCRGLEANPDFREADER = 'OCR_GLEAN_PDF_READER',
  OCRGLEANLAYOUTLMRAWEXTRACT = 'OCR_GLEAN_LAYOUTLM_RAW_EXTRACT',
  OCRGLEANLAYOUTLMV2RAWEXTRACT = 'OCR_GLEAN_LAYOUTLM_V2_RAW_EXTRACT',
  OCRGLEANLAYOUTLMV3RAWEXTRACT = 'OCR_GLEAN_LAYOUTLM_V3_RAW_EXTRACT',
  OCRGLEANLAYOUTLMEXTRACT = 'OCR_GLEAN_LAYOUTLM_EXTRACT',
  OCRGLEANLAYOUTLMV2EXTRACT = 'OCR_GLEAN_LAYOUTLM_V2_EXTRACT',
  OCRGLEANLAYOUTLMV3EXTRACT = 'OCR_GLEAN_LAYOUTLM_V3_EXTRACT',
  OCRGLEANTEXTBASEDDOCCLF = 'OCR_GLEAN_TEXT_BASED_DOC_CLF',
  OCRGLEANRAWTEXTBASEDDOCCLF = 'OCR_GLEAN_RAW_TEXT_BASED_DOC_CLF',
  OCRGLEANTEXTBASEDCURRENCYCLF = 'OCR_GLEAN_TEXT_BASED_CURRENCY_CLF',
  OCRGLEANRAWTEXTBASEDCURRENCYCLF = 'OCR_GLEAN_RAW_TEXT_BASED_CURRENCY_CLF',
  OCRGLEANLAYOUTLMANNOTATION = 'OCR_GLEAN_LAYOUTLM_ANNOTATION',
  OCRGLEANLAYOUTLMV2ANNOTATION = 'OCR_GLEAN_LAYOUTLM_V2_ANNOTATION',
  OCRGLEANSKIMFORMEREXTRACT = 'OCR_GLEAN_SKIMFORMER_EXTRACT',
  OCRGLEANSKIMFORMERRAWEXTRACT = 'OCR_GLEAN_SKIMFORMER_RAW_EXTRACT',
  OCRGLEANLGBMPDFFEATURES = 'OCR_GLEAN_LGBM_PDF_FEATURES',
  OCRGLEANLGBMRAWEXTRACT = 'OCR_GLEAN_LGBM_RAW_EXTRACT',
  OCRGLEANLGBMEXTRACT = 'OCR_GLEAN_LGBM_EXTRACT',
  OCRSCRYRAWEXTRACT = 'OCR_SCRY_RAW_EXTRACT',
  OCRSCRYEXTRACT = 'OCR_SCRY_EXTRACT',
  OCRAZURERAWEXTRACT = 'OCR_AZURE_RAW_EXTRACT',
  OCRAZUREEXTRACT = 'OCR_AZURE_EXTRACT',
  OCRVERYFIRAWEXTRACT = 'OCR_VERYFI_RAW_EXTRACT',
  OCRVERYFIEXTRACT = 'OCR_VERYFI_EXTRACT',
  OCRROSSUMRAWEXTRACT = 'OCR_ROSSUM_RAW_EXTRACT',
  OCRROSSUMEXTRACT = 'OCR_ROSSUM_EXTRACT',
  OCRFMEXTRACT = 'OCR_FM_EXTRACT',
  OCRDATAENSEMBLE = 'OCR_DATA_ENSEMBLE',
  DATASTP = 'DATA_STP',
  PROOFIDENTIFY = 'PROOF_IDENTIFY',
  DATAAUTOMAP = 'DATA_AUTOMAP',
  PROOFEXTRACT = 'PROOF_EXTRACT',
  WIPPROOFEXTRACT = 'WIP_PROOF_EXTRACT',
  DATAIMPUTE = 'DATA_IMPUTE',
  DATAVALIDATE = 'DATA_VALIDATE',
  PROOFVALIDATE = 'PROOF_VALIDATE',
  WIPPROOFVALIDATE = 'WIP_PROOF_VALIDATE',
  DATANORMALIZE = 'DATA_NORMALIZE',
  PROOFMAP = 'PROOF_MAP',
  WIPPROOFMAP = 'WIP_PROOF_MAP',
  INSERTCANONSTAGING = 'INSERT_CANON_STAGING',
  PROOFCURATE = 'PROOF_CURATE',
  WIPPROOFCURATE = 'WIP_PROOF_CURATE',
  DATAQUALITY = 'DATA_QUALITY',
  OCRDATAQUALITY = 'OCR_DATA_QUALITY',
  INSERTCANON = 'INSERT_CANON',
  DATAENRICH = 'DATA_ENRICH',
  INSERTDATA = 'INSERT_DATA',
  UPDATEDATA = 'UPDATE_DATA',
}
