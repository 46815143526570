/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventT,
    EventTFromJSON,
    EventTFromJSONTyped,
    EventTToJSON,
} from './';

/**
 * 
 * @export
 * @interface SingleExecutionDTO
 */
export interface SingleExecutionDTO {
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    companyId: string;
    /**
     * 
     * @type {Array<EventT>}
     * @memberof SingleExecutionDTO
     */
    events: Array<EventT>;
    /**
     * patched in for compat with the old pipeline
     * should be removed when old pipeline is deprecated
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    arn: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    executionArn: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    executionStatus: SingleExecutionDTOExecutionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    executionStep: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    restartedAt: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    file: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    stopDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    escalatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExecutionDTO
     */
    deescalatedAt?: string;
}

export function SingleExecutionDTOFromJSON(json: any): SingleExecutionDTO {
    return SingleExecutionDTOFromJSONTyped(json, false);
}

export function SingleExecutionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleExecutionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'events': ((json['events'] as Array<any>).map(EventTFromJSON)),
        'arn': json['arn'],
        'executionArn': json['executionArn'],
        'executionStatus': json['executionStatus'],
        'executionStep': json['executionStep'],
        'startDate': json['startDate'],
        'updatedAt': json['updatedAt'],
        'restartedAt': json['restartedAt'],
        'file': json['file'],
        'stopDate': !exists(json, 'stopDate') ? undefined : json['stopDate'],
        'escalatedAt': !exists(json, 'escalatedAt') ? undefined : json['escalatedAt'],
        'deescalatedAt': !exists(json, 'deescalatedAt') ? undefined : json['deescalatedAt'],
    };
}

export function SingleExecutionDTOToJSON(value?: SingleExecutionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'events': ((value.events as Array<any>).map(EventTToJSON)),
        'arn': value.arn,
        'executionArn': value.executionArn,
        'executionStatus': value.executionStatus,
        'executionStep': value.executionStep,
        'startDate': value.startDate,
        'updatedAt': value.updatedAt,
        'restartedAt': value.restartedAt,
        'file': value.file,
        'stopDate': value.stopDate,
        'escalatedAt': value.escalatedAt,
        'deescalatedAt': value.deescalatedAt,
    };
}

/**
* @export
* @enum {string}
*/
export enum SingleExecutionDTOExecutionStatusEnum {
    SUCCEEDED = 'SUCCEEDED',
    ABORTED = 'ABORTED',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING'
}


