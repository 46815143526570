import React from 'react';

import { Spinner } from '../';
import { MetricContainer, MetricSubtitle, MetricTitle, MetricValue } from './styles';

type Props = {
  label?: React.ReactNode;
  value?: string | number;
  loading?: boolean;
  cursor?: 'pointer' | 'default';
  subtitle?: string;
  onClick?: () => void;
};

export const Metric = ({ label, subtitle, value, loading, cursor, onClick }: Props) => {
  return (
    <MetricContainer cursor={cursor} onClick={onClick}>
      <MetricTitle>{label}</MetricTitle>
      <MetricSubtitle>{subtitle}&nbsp;</MetricSubtitle>
      <br />
      <MetricValue>{loading ? <Spinner /> : value}</MetricValue>
    </MetricContainer>
  );
};
