import { get } from 'lodash';

import {
  EditGetInvoiceDTO,
  InvoiceT,
  InvoiceTProcessingStatusEnum,
  LineItemT,
} from 'shared/api/generated';
import { ArrayElement, assertNever } from 'shared/utils/types';

export type FormValues = {
  data: Array<{
    invoiceField: ArrayElement<ReturnType<typeof getTopLevelFieldOptionsFromInvoice>>['value'];
    value?: string;
    lineItemId?: string;
    lineItemField?: ArrayElement<ReturnType<typeof getLineItemFieldOptions>>['value'];
  }>;
};

/**
 * This function transforms properties on the invoice response model
 * to the pipeline schema property ie the types defined in `GleanSchema`
 */
export const transformInvoiceFieldToSchemaField = (field: keyof InvoiceT): string => {
  switch (field) {
    /** invoice.summary */
    case 'invoiceDate':
      return 'invoice.summary.invoice_date';
    case 'invoiceNumber':
      return 'invoice.summary.invoice_number';
    case 'dueDate':
      return 'invoice.summary.due_date';
    case 'periodStartDate':
      return 'invoice.summary.period_start_date';
    case 'periodEndDate':
      return 'invoice.summary.period_end_date';
    case 'paymentCurrency':
      return 'invoice.summary.payment_currency';
    case 'paymentTerms':
      return 'invoice.summary.payment_terms';
    case 'originalPretaxSubtotalAmount':
      return 'invoice.cost.subtotal_amount';
    case 'projectNumber':
      return 'invoice.summary.project_number';
    case 'taxIdNumber':
      return 'invoice.summary.tax_id_number';
    case 'workOrderNumber':
      return 'invoice.summary.work_order_number';
    case 'purchaseOrderEmail':
      return 'invoice.summary.purchase_order_email';

    /** invoice.cost */
    case 'originalAmountDue':
      return 'invoice.cost.amount_due';
    case 'originalAmountPaid':
      return 'invoice.cost.amount_paid';
    case 'originalBeginningBalance':
      return 'invoice.cost.beginning_balance';
    case 'originalEndingBalance':
      return 'invoice.cost.ending_balance';
    case 'originalTotalAmount':
      return 'invoice.cost.total_billed_amount';
    /** invoice.shipping */
    case 'shipFromName':
      return 'invoice.shipping.ship_from_name';
    case 'shipToAddress':
      return 'invoice.shipping.ship_to_address';
    case 'shipper':
      return 'invoice.shipping.shipper';
    case 'trackingNumber':
      return 'invoice.shipping.tracking_number';
    /** document.type */
    case 'recordType':
      return 'document.type.document_type';

    /** invoice fields that do not map to the schema */
    case 'totalAmount':
    case 'amountPaid':
    case 'amountDue':
    case 'beginningBalance':
    case 'endingBalance':
    case 'currencyConvertedAt':
    case 'invoiceId':
    case 'companyVendorId':
    case 'fileKey':
    case 'createdAt':
    case 'updatedAt':
    case 'deletedAt':
    case 'billingFrequency':
    case 'pretaxSubtotalAmount':
    case 'approvalStatus':
    case 'paymentStatus':
    case 'processingStatus':
    case 'isBacklog':
    case 'isDuplicate':
    case 'primaryDuplicateId':
    case 'requiresLedgerSync':
    case 'markedAsPaidVia':
    case 'markedAsPaidPaymentDate':
    case 'markedAsPaidAmount':
    case 'markedAsPaidNotes':
    case 'isAmortizationActionBarDismissed':
      throw new Error(`cannot map ${field} to schema.`);
  }
};

/**
 * This function transforms properties on the line item response model
 * to the pipeline schema property ie the types defined in `GleanSchema`
 */
export const transformLineItemFieldToSchemaField = (field: keyof LineItemT): string => {
  switch (field) {
    case 'isProrated':
      return 'line_items.line_items.is_prorated';
    case 'capacityUnitType':
      return 'line_items.line_items.capacity_unit_type';
    case 'capacityLimitValue':
      return 'line_items.line_items.capacity_limit_value';
    case 'hasCapacityLimit':
      return 'line_items.line_items.has_capacity_limit';
    case 'totalInstallments':
      return 'line_items.line_items.total_installments';
    case 'installmentNumber':
      return 'line_items.line_items.installment_number';
    case 'isInstallment':
      return 'line_items.line_items.is_installment';
    case 'commitmentTerm':
      return 'line_items.line_items.commitment_term';
    case 'billingFrequency':
      return 'line_items.line_items.billing_frequency';
    case 'originalTotalTaxAmount':
      return 'line_items.line_items.total_tax_amount';
    case 'feesAndSurcharges':
      return 'line_items.line_items.fees_and_surcharges';
    case 'originalCreditDiscountAmount':
      return 'line_items.line_items.credit_discount_amount';
    case 'originalPretaxSubtotalAmount':
      return 'line_items.line_items.subtotal_amount';
    case 'originalTotalAmount':
      return 'line_items.line_items.total_amount';
    case 'originalNormalizedUnitPrice':
      return 'line_items.line_items.normalized_unit_price';
    case 'originalDailyUnitPrice':
      return 'line_items.line_items.daily_unit_price';
    case 'lineItemType':
      return 'line_items.line_items.line_item_type';
    case 'name':
      return 'line_items.line_items.name';
    case 'description':
      return 'line_items.line_items.description';
    case 'periodStartDate':
      return 'line_items.line_items.period_start_date';
    case 'periodEndDate':
      return 'line_items.line_items.period_end_date';
    case 'originalUnitPrice':
      return 'line_items.line_items.unit_price';
    case 'quantityUnits':
      return 'line_items.line_items.quantity';
    case 'itemNumber':
    case 'lineItemId':
    case 'invoiceId':
    case 'canonicalLineItemId':
    case 'createdAt':
    case 'updatedAt':
    case 'deletedAt':
    case 'totalAmount':
    case 'creditDiscountAmount':
    case 'pretaxSubtotalAmount':
    case 'totalTaxAmount':
    case 'dailyUnitPrice':
    case 'normalizedUnitPrice':
    case 'unitPrice':
      throw new Error(`cannot map ${field} to schema.`);
  }
};

export const getTopLevelFieldOptionsFromInvoice = (invoice: InvoiceT) => {
  const base = [
    {
      value: 'invoiceNumber',
      label: 'Invoice Number',
    },
    {
      value: 'recordType',
      label: 'Record Type',
    },
    {
      value: 'invoiceDate',
      label: 'Invoice Date',
    },
    { value: 'dueDate', label: 'Due Date' },
    {
      value: 'periodStartDate',
      label: 'Period Start Date',
    },
    {
      value: 'periodEndDate',
      label: 'Period End Date',
    },
    {
      value: 'paymentCurrency',
      label: 'Payment Currency',
    },
    {
      value: 'paymentTerms',
      label: 'Payment Terms',
    },
    { value: 'originalAmountDue', label: 'Amount Due' },
    { value: 'originalAmountPaid', label: 'Amount Paid' },
    { value: 'originalPretaxSubtotalAmount', label: 'Subtotal Amount' },
    {
      value: 'originalTotalAmount',
      label: 'Total Amount',
    },
    {
      value: 'originalBeginningBalance',
      label: 'Beginning Balance',
    },
    {
      value: 'originalEndingBalance',
      label: 'Ending Balance',
    },
    {
      value: 'projectNumber',
      label: 'Project Number',
    },
    {
      value: 'purchaseOrderEmail',
      label: 'Purchase Order Email',
    },
    {
      value: 'shipFromName',
      label: 'Ship From Name',
    },
    {
      value: 'shipToAddress',
      label: 'Ship to Address',
    },
    {
      value: 'shipper',
      label: 'Shipper',
    },
    {
      value: 'taxIdNumber',
      label: 'Tax ID Number',
    },
    {
      value: 'trackingNumber',
      label: 'Tracking Number',
    },
    {
      value: 'workOrderNumber',
      label: 'Work Order Number',
    },
  ] as const;

  if (invoice.processingStatus === InvoiceTProcessingStatusEnum.LINEITEMSCOMPLETED) {
    return [...base, { value: 'lineItem', label: 'Line Item' }] as const;
  }

  return base;
};

export const getLineItemOptions = (lineItems: LineItemT[]) =>
  lineItems.map((li) => ({ value: li.lineItemId, label: li.name }));

export const getLineItemFieldOptions = () => {
  return [
    { value: 'periodStartDate', label: 'Period Start Date' },
    { value: 'periodEndDate', label: 'Period End Date' },
    { value: 'name', label: 'Name' },
    { value: 'description', label: 'Description' },
    { value: 'lineItemType', label: 'Line Item Type' },
    { value: 'billingFrequency', label: 'Billing Frequency' },

    { value: 'quantityUnits', label: 'Quantity' },
    { value: 'originalUnitPrice', label: 'Unit Price' },
    { value: 'originalPretaxSubtotalAmount', label: 'Subtotal' },

    { value: 'originalCreditDiscountAmount', label: 'Credit Discount Amount' },
    { value: 'feesAndSurcharges', label: 'Fees & Surcharges' },
    { value: 'originalTotalTaxAmount', label: 'Total Tax Amount' },

    { value: 'originalTotalAmount', label: 'Total Amount' },

    { value: 'commitmentTerm', label: 'Commitment Term' },
    { value: 'installmentNumber', label: 'Installment Number' },
    { value: 'totalInstallments', label: 'Total Installments' },
    { value: 'capacityUnitType', label: 'Capacity Unit Type' },
    { value: 'capacityLimitValue', label: 'Capacity Limit Value' },

    { value: 'isProrated', label: 'Is Prorated' },
  ] as const;
};

export const getFieldType = (
  key: Exclude<
    | ArrayElement<ReturnType<typeof getTopLevelFieldOptionsFromInvoice>>['value']
    | ArrayElement<ReturnType<typeof getLineItemFieldOptions>>['value'],
    'lineItem'
  >,
) => {
  switch (key) {
    case 'dueDate':
    case 'invoiceDate':
    case 'periodEndDate':
    case 'periodStartDate':
      return 'date' as const;
    case 'invoiceNumber':
    case 'name':
    case 'description':
    case 'capacityUnitType':
    case 'projectNumber':
    case 'purchaseOrderEmail':
    case 'shipFromName':
    case 'shipper':
    case 'shipToAddress':
    case 'taxIdNumber':
    case 'trackingNumber':
    case 'workOrderNumber':
      return 'string' as const;
    case 'originalAmountPaid':
    case 'originalBeginningBalance':
    case 'originalEndingBalance':
    case 'originalTotalAmount':
    case 'originalAmountDue':
    case 'originalPretaxSubtotalAmount':
    case 'originalUnitPrice':
    case 'originalCreditDiscountAmount':
    case 'feesAndSurcharges':
    case 'originalTotalTaxAmount':
    case 'capacityLimitValue':
      return 'amount' as const;
    case 'quantityUnits':
    case 'installmentNumber':
    case 'totalInstallments':
      return 'number' as const;
    case 'paymentTerms':
    case 'paymentCurrency':
    case 'recordType':
    case 'lineItemType':
    case 'billingFrequency':
    case 'commitmentTerm':
    case 'isProrated':
      return key;
    default:
      try {
        assertNever(key);
      } catch (e) {
        /**
         * If this line is erroring it means a new return value
         * was added to either `getTopLevelFieldOptionsFromInvoice` or `getLineItemFieldOptions`
         * which was not accounted for here
         **/
      }
      return null;
  }
};

/**
 * for a given row of form state, retrieves the original value of the input from the invoice
 */
export const getOriginalDataForField = (
  value: { invoiceField: string; lineItemId?: string; lineItemField?: string },
  invoiceData: EditGetInvoiceDTO,
): string | undefined => {
  const { invoiceField, lineItemId, lineItemField } = value;

  if (invoiceField === 'lineItem') {
    if (!lineItemField) return;
    const lineItem = invoiceData.lineItems.find((lineItem) => lineItem.lineItemId === lineItemId);
    if (!lineItem) return;
    if (!lineItemKeyTypeGuard(lineItemField, lineItem)) return;
    const data = get(lineItem, lineItemField);
    if (data === undefined || data === null) return;
    return String(data);
  } else {
    if (!invoiceKeyTypeGuard(invoiceField, invoiceData.invoice)) return;
    const data = get(invoiceData.invoice, invoiceField);
    if (data === undefined || data === null) return;
    return String(data);
  }
};

export const invoiceKeyTypeGuard = (key: string, invoice: InvoiceT): key is keyof InvoiceT => {
  return Object.keys(invoice).includes(key);
};

export const lineItemKeyTypeGuard = (key: string, lineItem: LineItemT): key is keyof LineItemT => {
  return Object.keys(lineItem).includes(key);
};
