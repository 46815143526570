import scrollIntoView from 'scroll-into-view';

import { sizes } from 'shared/styles';

export const scrollToElement = (
  id: string,
  { top = 0, topOffset = sizes.navbarHeight + 20, time = 500, ...options } = {},
) => {
  const el: HTMLElement | null = document.querySelector(`#${id}`);
  el &&
    scrollIntoView(el, {
      align: { top, topOffset },
      time,
      ...options,
    });
};
