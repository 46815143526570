import React, { useState } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { invoicePDFCDNHost } from 'shared/constants';

import { JobProps } from 'views/job/detail-map-curate';
import { OriginLineItemT } from 'views/job/detail-map-curate/utils';

import { CreateForm } from './create-form';
import { SearchForm } from './search-form';
import {
  Container,
  Heading,
  InvoiceLabel,
  Left,
  OriginLineItemDescription,
  OriginLineItemGrid,
  OriginLineItemName,
  OriginLineItemPrice,
  OriginLineItemPriceLabel,
  Right,
} from './styles';

export const CanonicalLineItemSelect = ({
  data,
  vendorClis,
  addToVendorClis,
  setMappedCliAtCurrentIndex,
  closeModal,
  originLineItem,
}: {
  data: JobProps['data'];
  vendorClis: any[];
  addToVendorClis: (newVendorClis: any) => void;
  setMappedCliAtCurrentIndex: (newCli: any) => void;
  closeModal: () => void;
  originLineItem: OriginLineItemT;
}) => {
  const [focusedCanonicalLineItem, setFocusedCanonicalLineItem] = useState(vendorClis[0] || null);
  const [currentPDFIndex, setCurrentPDFIndex] = useState(0);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(vendorClis.length === 0);

  const originPDFURL = `${invoicePDFCDNHost}/${data.job.fileKey}`;
  const canonicalPDFURL = focusedCanonicalLineItem?.files[currentPDFIndex];
  const isDisplayingOriginPDF = isCreateFormOpen || !canonicalPDFURL;
  const selectExistingCliAndCloseModal = (cli: any) => {
    setMappedCliAtCurrentIndex(cli);
    closeModal();
  };
  return (
    <Container>
      <Left>
        <Heading>{isCreateFormOpen ? 'Create' : 'Switch'} Canonical Line Item</Heading>
        <OriginLineItem originLineItem={originLineItem} />
        {isCreateFormOpen ? (
          <CreateForm
            job={data.job}
            onCancel={() => setIsCreateFormOpen(false)}
            onCreate={(newCanonicalLineItem: any) => {
              addToVendorClis([newCanonicalLineItem]);
              selectExistingCliAndCloseModal(newCanonicalLineItem);
            }}
            vendorClis={vendorClis}
            onCanonicalLineItemSelect={selectExistingCliAndCloseModal}
          />
        ) : (
          <>
            <SearchForm
              originLineItem={{
                name: originLineItem.name,
                description: originLineItem.description,
              }}
              canonicalLineItems={vendorClis}
              focusedCanonicalLineItem={focusedCanonicalLineItem}
              onCanonicalLineItemFocus={(cliToFocus: any) => {
                setFocusedCanonicalLineItem(cliToFocus);
                setCurrentPDFIndex(0);
              }}
              onCanonicalLineItemSelect={() =>
                selectExistingCliAndCloseModal(focusedCanonicalLineItem)
              }
              onClickCreate={() => setIsCreateFormOpen(true)}
            />
          </>
        )}
      </Left>
      <Right>
        <InvoiceLabel>
          {`${isDisplayingOriginPDF ? 'Origin' : 'Canonical'} Invoice`}

          {!isDisplayingOriginPDF && focusedCanonicalLineItem?.files?.length > 1 && (
            <Button
              variant="outlined"
              size="small"
              style={{ marginLeft: 12 }}
              onClick={() => {
                setCurrentPDFIndex((currentPDFIndex + 1) % focusedCanonicalLineItem.files.length);
              }}
            >
              Show Next
            </Button>
          )}
        </InvoiceLabel>

        <iframe src={isDisplayingOriginPDF ? originPDFURL : canonicalPDFURL} title="Invoice PDF" />
      </Right>
    </Container>
  );
};

const OriginLineItem = ({ originLineItem }: any) => {
  return (
    <OriginLineItemGrid>
      <OriginLineItemName>{originLineItem.name}</OriginLineItemName>
      <OriginLineItemDescription>{originLineItem.description}</OriginLineItemDescription>
      <OriginLineItemPriceLabel>Unit Price</OriginLineItemPriceLabel>
      <OriginLineItemPrice>{originLineItem.unit_price}</OriginLineItemPrice>
      <OriginLineItemPriceLabel>Total Amount</OriginLineItemPriceLabel>
      <OriginLineItemPrice>{originLineItem.total_amount}</OriginLineItemPrice>
    </OriginLineItemGrid>
  );
};
