import React from 'react';

import styled from 'styled-components';

import { GetPipelineMetricsT } from 'shared/api/generated';
import { ExecutionsTablePropT } from 'shared/components/execution-table';
import { Metric } from 'shared/components/metric';
import { Filter } from 'shared/hooks/filters';

import { Box, Grid, PipelineTopLevelTitle } from '../styles';

export const PipelineMetricsMain = ({
  isLoading,
  metrics,
  openModal,
  filters,
}: {
  isLoading: boolean;
  metrics?: GetPipelineMetricsT | undefined;
  openModal?: () => void;
  filters: ExecutionsTablePropT['filters'];
}) => {
  const click = ({
    pipeline,
    status,
    escalated,
    last7Days,
  }: {
    pipeline: 'Header' | 'Detail';
    status?: string;
    escalated?: boolean;
    last7Days?: boolean;
  }) => {
    const filtersToSet: Filter[] = [{ filterKey: 'pipeline', filterValue: pipeline }];
    if (status) filtersToSet.push({ filterKey: 'executionStatus', filterValue: status });
    if (escalated) filtersToSet.push({ filterKey: 'tag', filterValue: 'Escalated' });
    if (last7Days) filtersToSet.push({ filterKey: 'dateRange', filterValue: 'Last 7 Days' });
    filters.onChange(filtersToSet);
    if (typeof openModal !== 'undefined') {
      openModal();
    }
  };
  return (
    <Grid numCols={2}>
      <PipelineTopLevel
        onActiveClick={() => click({ pipeline: 'Header', status: 'Running' })}
        onEscalatedClick={() => click({ pipeline: 'Header', escalated: true })}
        onCompletedClick={() => click({ pipeline: 'Header', status: 'Completed', last7Days: true })}
        title={'Header Summary'}
        {...metrics?.header}
        loading={isLoading}
      />
      <PipelineTopLevel
        onActiveClick={() => click({ pipeline: 'Detail', status: 'Running' })}
        onEscalatedClick={() => click({ pipeline: 'Detail', escalated: true })}
        onCompletedClick={() => click({ pipeline: 'Detail', status: 'Completed', last7Days: true })}
        title={'Detail Summary'}
        {...metrics?.detail}
        loading={isLoading}
      />
    </Grid>
  );
};

const PipelineTopLevelContainer = styled.div`
  margin-bottom: 24px;
`;

const PipelineTopLevel = ({
  title,
  activeExecutions,
  completedExecutions,
  escalatedExecutions,
  loading,
  onActiveClick,
  onEscalatedClick,
  onCompletedClick,
}: {
  title: string;
  activeExecutions?: number;
  completedExecutions?: number;
  escalatedExecutions?: number;
  loading: boolean;

  onActiveClick: () => void;
  onEscalatedClick: () => void;
  onCompletedClick: () => void;
}) => (
  <PipelineTopLevelContainer>
    <Box>
      <PipelineTopLevelTitle>{title}</PipelineTopLevelTitle>
      <Grid numCols={3}>
        <Metric
          cursor="pointer"
          label="In Progress"
          loading={loading}
          value={activeExecutions}
          onClick={onActiveClick}
        ></Metric>
        <Metric
          cursor="pointer"
          label="Escalated"
          loading={loading}
          value={escalatedExecutions}
          onClick={onEscalatedClick}
        ></Metric>
        <Metric
          cursor="pointer"
          label="Completed"
          subtitle="Last 7 Days"
          loading={loading}
          value={completedExecutions}
          onClick={onCompletedClick}
        ></Metric>
      </Grid>
    </Box>
  </PipelineTopLevelContainer>
);
