/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExecutionSummaryDTO,
    ExecutionSummaryDTOFromJSON,
    ExecutionSummaryDTOFromJSONTyped,
    ExecutionSummaryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExecutionsDTO
 */
export interface ExecutionsDTO {
    /**
     * 
     * @type {Array<ExecutionSummaryDTO>}
     * @memberof ExecutionsDTO
     */
    executions: Array<ExecutionSummaryDTO>;
}

export function ExecutionsDTOFromJSON(json: any): ExecutionsDTO {
    return ExecutionsDTOFromJSONTyped(json, false);
}

export function ExecutionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'executions': ((json['executions'] as Array<any>).map(ExecutionSummaryDTOFromJSON)),
    };
}

export function ExecutionsDTOToJSON(value?: ExecutionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'executions': ((value.executions as Array<any>).map(ExecutionSummaryDTOToJSON)),
    };
}


