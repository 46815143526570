import styled from 'styled-components';

import { padding } from 'shared/styles';

export const RenderHandleContainer = styled.div`
  position: fixed;
  bottom: ${padding.med};
  left: ${padding.med};
  cursor: pointer;
  z-index: 1000;
`;
