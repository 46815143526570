import React, { useEffect, useState } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import * as api from 'shared/api';
import { Avatar } from 'shared/components/avatar';
import { PageLoader } from 'shared/components/page-loader';
import { PageMessage } from 'shared/components/page-message';
import { Popover } from 'shared/components/popover';
import { toast } from 'shared/components/toast';
import { VendorCreateForm } from 'shared/components/vendor-form';
import { color } from 'shared/styles';

import { HeaderMapCurateUiStateT } from '../..';
import { Invoice, InvoiceNumber, InvoicePDF, SmallCaption, Top, VendorName } from '../sharedStyles';
import { LineItemsTooltip } from './line-items-tooltip';
import { SearchForm } from './search-form';
import { Center, SwitchContainer, SwitchVendorHeading, Vendor, VendorInfo } from './styles';

export const CanonicalSection = ({
  requestedVendorName,
  vendorStepState,
  onVendorStepStateChange,
}: {
  requestedVendorName: string;
  vendorStepState: HeaderMapCurateUiStateT;
  onVendorStepStateChange: any;
}) => {
  const {
    canonicalVendor,
    isCanonicalVendorLoaded,
    sampleInvoices,
    sampleLineItems,
    sampleInvoiceIndex,
  } = vendorStepState;

  const defaultSearchQuery = canonicalVendor ? '' : requestedVendorName;
  const sampleInvoice = sampleInvoices[sampleInvoiceIndex];

  const handleCanonicalVendorChange = ({
    invoices,
    line_items,
    ...vendorData
  }: {
    invoices?: HeaderMapCurateUiStateT['sampleInvoices'];
    line_items?: HeaderMapCurateUiStateT['sampleLineItems'];
  } & HeaderMapCurateUiStateT['canonicalVendor']) => {
    onVendorStepStateChange({
      canonicalVendor: vendorData,
      sampleInvoices: invoices || [],
      sampleLineItems: line_items || [],
      sampleInvoiceIndex: 0,
      isVendorStepValid: true,
    });
  };

  useFetchCanonicalVendor({
    isCanonicalVendorLoaded,
    handleCanonicalVendorChange,
    requestedVendorName,
    onVendorStepStateChange,
  });

  const handleNextSampleInvoice = () => {
    const isLastIndex = sampleInvoiceIndex === sampleInvoices.length - 1;
    onVendorStepStateChange({ sampleInvoiceIndex: isLastIndex ? 0 : sampleInvoiceIndex + 1 });
  };

  const [isVendorFormOpen, setIsVendorFormOpen] = useState(false);

  if (!isCanonicalVendorLoaded) {
    return <PageLoader message="Fetching vendor candidate..." />;
  }

  if (isVendorFormOpen) {
    return (
      <Center>
        <VendorCreateForm
          onSubmit={(formData) => {
            handleCanonicalVendorChange({ ...formData, isNew: true });
            setIsVendorFormOpen(false);
          }}
          onCancel={() => {
            onVendorStepStateChange({ isVendorStepValid: !!canonicalVendor });
            setIsVendorFormOpen(false);
          }}
        />
      </Center>
    );
  }

  if (!canonicalVendor) {
    return (
      <Center>
        <SwitchVendor
          defaultSearchQuery={defaultSearchQuery}
          onVendorChange={handleCanonicalVendorChange}
          onCreateVendorClick={() => {
            onVendorStepStateChange({ isVendorStepValid: false });
            setIsVendorFormOpen(true);
          }}
        />
      </Center>
    );
  }

  return (
    <>
      <Top style={{ paddingLeft: 0 }}>
        <Vendor>
          <Avatar src={canonicalVendor.logo} alt={canonicalVendor.display_name} />
          <VendorInfo>
            <SmallCaption>Canonical Vendor</SmallCaption>
            <VendorName>
              <span>
                <span>{canonicalVendor.display_name} </span>
                <br />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    marginRight: 34,
                    color: color.text2,
                  }}
                >
                  {' '}
                  {canonicalVendor.domain}
                </span>
              </span>
              <Popover
                variant="dropdown"
                placement="bottom"
                maxWidth={400}
                renderContent={(popover) => (
                  <SwitchVendor
                    defaultSearchQuery={defaultSearchQuery}
                    onVendorChange={handleCanonicalVendorChange}
                    onCreateVendorClick={() => {
                      onVendorStepStateChange({ isVendorStepValid: false });
                      setIsVendorFormOpen(true);
                    }}
                    popover={popover}
                  />
                )}
              >
                <Button variant="outlined" size="small" style={{ marginLeft: 10 }}>
                  Switch
                </Button>
              </Popover>
            </VendorName>
          </VendorInfo>
        </Vendor>

        <Invoice>
          <InvoiceNumber>
            Invoice
            <span>{sampleInvoice ? sampleInvoice.invoice_number : 'n/a'}</span>
            {sampleInvoices.length > 1 && (
              <Button variant="outlined" size="small" onClick={handleNextSampleInvoice}>
                Show Next
              </Button>
            )}
          </InvoiceNumber>

          <LineItemsTooltip lineItems={sampleLineItems} />
        </Invoice>
      </Top>

      {sampleInvoice ? (
        <InvoicePDF>
          <iframe src={sampleInvoice.file_url} title="Invoice PDF" />
        </InvoicePDF>
      ) : (
        <PageMessage message="There are no sample invoices to show." icon="eye" />
      )}
    </>
  );
};

const SwitchVendor = ({
  defaultSearchQuery,
  onVendorChange,
  onCreateVendorClick,
  popover,
}: any) => (
  <SwitchContainer>
    <SwitchVendorHeading>
      Find Canonical Vendor
      {popover && (
        <Button variant="outlined" size="small" trailingIcon="close" onClick={popover.close}>
          Close
        </Button>
      )}
    </SwitchVendorHeading>

    <SearchForm
      defaultQuery={defaultSearchQuery}
      onVendorSelect={onVendorChange}
      onRender={(popover || {}).setPosition}
    />

    <Button variant="outlined" icon="plus" style={{ width: '100%' }} onClick={onCreateVendorClick}>
      Create canonical vendor
    </Button>
  </SwitchContainer>
);

const useFetchCanonicalVendor = ({
  isCanonicalVendorLoaded,
  handleCanonicalVendorChange,
  requestedVendorName,
  onVendorStepStateChange,
}: {
  isCanonicalVendorLoaded: boolean;
  handleCanonicalVendorChange: (
    args: {
      invoices?: HeaderMapCurateUiStateT['sampleInvoices'];
      line_items?: HeaderMapCurateUiStateT['sampleLineItems'];
    } & HeaderMapCurateUiStateT['canonicalVendor'],
  ) => void;
  requestedVendorName: string;
  onVendorStepStateChange: (newState: Partial<HeaderMapCurateUiStateT>) => void;
}) => {
  useEffect(() => {
    if (isCanonicalVendorLoaded) return;

    const getDefaultCanonicalVendor = async () => {
      try {
        // No canonical mapping exists yet. Fetch a recommendation
        const { data } = await api.recommendCanonicalVendors({
          originVendorDisplayName: requestedVendorName,
          originVendorLegalName: requestedVendorName,
        });
        if (data.canonical_vendors[0]) {
          //@ts-expect-error these are actually the same type, but appear incorrectly due to code gen issues
          handleCanonicalVendorChange(data.canonical_vendors[0]);
        }
      } catch {
        toast.danger('There was an issue fetching data.');
      }
      onVendorStepStateChange({ isCanonicalVendorLoaded: true });
    };

    getDefaultCanonicalVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
