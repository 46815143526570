import React, { useEffect, useState } from 'react';

import { history } from 'browserHistory';
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';

import { Button } from '@glean/glean-ui.molecules.button';

import { useGetInvoice, useUpdateInvoice } from 'shared/api/api-hooks/edit-invoices';
import { Form, PageLoader, toast } from 'shared/components';
import { ArrayElement } from 'shared/utils/types';

import {
  FormValues,
  getFieldType,
  getLineItemFieldOptions,
  getLineItemOptions,
  getOriginalDataForField,
  getTopLevelFieldOptionsFromInvoice,
  transformInvoiceFieldToSchemaField,
  transformLineItemFieldToSchemaField,
} from './edit-invoice-form-utils';
import { InputValueField } from './input-value-field';
import { ButtonRow, Container, FieldRow } from './styles';

const initialValues: FormValues = {
  data: [
    {
      invoiceField: 'invoiceNumber',
    },
  ],
};

export const EditInvoiceForm = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { data: invoiceData, isError, isLoading, error } = useGetInvoice({ invoiceId });
  const { mutateAsync: updateInvoice } = useUpdateInvoice();

  if (isLoading) return <PageLoader message="Fetching Invoice Details..." />;
  if (isError || !invoiceData) {
    return <InvoiceError error={error} />;
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (formValues: FormValues) => {
          try {
            const submitValues = formValues.data
              .filter(
                (row): row is Omit<ArrayElement<FormValues['data']>, 'value'> & { value: string } =>
                  typeof row.value === 'string',
              )
              .map(({ invoiceField, value, lineItemField, lineItemId }) => {
                if (invoiceField !== 'lineItem') {
                  return {
                    field: transformInvoiceFieldToSchemaField(invoiceField),
                    value,
                  };
                } else {
                  if (!lineItemField || !lineItemId)
                    throw new Error(
                      'Cannot serialize lineitem field without line item values or line item id',
                    );
                  return {
                    field: transformLineItemFieldToSchemaField(lineItemField),
                    value,
                    lineItemId,
                  };
                }
              });

            await updateInvoice({
              id: invoiceId,
              fields: submitValues,
            });
            history.push('/manage/edit');
            toast.success('Submitted');
          } catch (e) {
            toast.danger('Failed to submit');
            if (e instanceof Response) {
              const message = e.statusText || 'An unknown error occurred.';
              toast.danger(message);
            }
          }
        }}
      >
        <>
          <FieldArray
            name="data"
            render={({ form, ...arrayHelpers }: FieldArrayRenderProps) => {
              const { data: formData } = (form as FormikProps<FormValues>).values;
              return (
                <Container>
                  <h1>Edit [{invoiceData.invoice.invoiceId}]</h1>
                  <p>{formData.length} total</p>
                  <FieldRow>
                    <p>Field</p>
                    <p>Current Value</p>
                    <p>New Value</p>
                  </FieldRow>
                  <div>
                    {formData.map((value, idx) => {
                      const originalData = getOriginalDataForField(value, invoiceData);
                      const lineItemOptions = getLineItemOptions(invoiceData.lineItems);
                      const lineItemFieldOptions = getLineItemFieldOptions();

                      if (originalData && typeof value.value === 'undefined') {
                        form.setFieldValue(`data.${idx}.value`, String(originalData));
                      }

                      if (value.invoiceField === 'lineItem') {
                        typeof value.lineItemId === 'undefined' &&
                          form.setFieldValue(`data.${idx}.lineItemId`, lineItemOptions[0]?.value);
                        typeof value.lineItemField === 'undefined' &&
                          form.setFieldValue(
                            `data.${idx}.lineItemField`,
                            lineItemFieldOptions[0]?.value,
                          );
                      }

                      const valueFieldType =
                        value.invoiceField === 'lineItem'
                          ? getFieldType(value.lineItemField!)
                          : getFieldType(value.invoiceField);
                      const valueFieldName = `data.${idx}.value`;

                      return (
                        <FieldRow key={`key-${idx}`}>
                          <div>
                            <Form.Field.Select
                              className="noMargin"
                              name={`data.${idx}.invoiceField`}
                              onChange={(e: string) => {
                                form.setFieldValue(`data.${idx}.invoiceField`, e);

                                const originalData = getOriginalDataForField(
                                  { ...value, invoiceField: e },
                                  invoiceData,
                                );

                                originalData &&
                                  form.setFieldValue(`data.${idx}.value`, String(originalData));
                              }}
                              options={getTopLevelFieldOptionsFromInvoice(invoiceData.invoice)}
                            />

                            {value.invoiceField === 'lineItem' && (
                              <>
                                <Form.Field.Select
                                  name={`data.${idx}.lineItemId`}
                                  options={lineItemOptions}
                                  onChange={(e: string) => {
                                    form.setFieldValue(`data.${idx}.lineItemId`, e);
                                    const originalData = getOriginalDataForField(
                                      { ...value, lineItemId: e },
                                      invoiceData,
                                    );
                                    originalData &&
                                      form.setFieldValue(`data.${idx}.value`, String(originalData));
                                  }}
                                />
                                <Form.Field.Select
                                  name={`data.${idx}.lineItemField`}
                                  options={lineItemFieldOptions}
                                  onChange={(e: string) => {
                                    form.setFieldValue(`data.${idx}.lineItemField`, e);

                                    const originalData = getOriginalDataForField(
                                      { ...value, lineItemField: e },
                                      invoiceData,
                                    );
                                    originalData &&
                                      form.setFieldValue(`data.${idx}.value`, String(originalData));
                                  }}
                                />
                              </>
                            )}
                          </div>
                          <div>{originalData ?? '--'}</div>
                          <div>
                            <InputValueField
                              valueFieldType={valueFieldType}
                              name={valueFieldName}
                            />
                          </div>
                          <Button
                            icon="delete"
                            variant="danger"
                            onClick={() => {
                              arrayHelpers.remove(idx);
                            }}
                          />
                        </FieldRow>
                      );
                    })}

                    <ButtonRow>
                      <Button
                        icon={'plus'}
                        variant="outlined"
                        onClick={() => {
                          arrayHelpers.push({ invoiceField: 'invoiceNumber' });
                        }}
                      />
                      <Button onClick={() => form.submitForm()} isWorking={form.isSubmitting}>
                        Submit
                      </Button>
                    </ButtonRow>
                  </div>
                </Container>
              );
            }}
          />
        </>
      </Formik>
    </div>
  );
};

const InvoiceError = ({ error }: { error?: Response | null }) => {
  const [errorText, setErrorText] = useState<'GoneError' | 'UnknownError'>('UnknownError');
  useEffect(() => {
    const clone = error?.clone();
    if (clone) {
      clone.text().then((text) => {
        text.indexOf('GoneError') ? setErrorText('GoneError') : setErrorText('UnknownError');
      });
    }
  });
  if (error) {
    return (
      <div>
        <h2>There was an error retrieving this invoice:</h2>
        <p>{error.statusText}</p>
        {error.status === 404 && <p>There is no invoice with that ID</p>}
        {error.status === 422 && <p>That is not a valid invoice ID</p>}
        {error.status === 410 && <p>This invoice is too old to edit.</p>}
        {error.status >= 500 &&
          (errorText === 'GoneError' ? (
            <p>This invoice is too old to edit.</p>
          ) : (
            <p>An unknown error occurred</p>
          ))}
      </div>
    );
  }
  return (
    <div>
      <p>There was an error retrieving this invoice</p>
    </div>
  );
};
