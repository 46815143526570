import { Filter, FilterProps, useFilters } from './filters';
import { usePagination } from './pagination';
import { UseSearchProps, useSearch } from './search';
import { SortByDirection, SortDir, useSort } from './sort';

export const useTable = <T extends { [key: string]: any }>({
  items,
  search: searchOptions,
  sort: sortOptions,
  pagination: paginationOptions,
  filters: filterOptions,
}: {
  items: T[];
  search?: UseSearchProps<T>;
  sort?: {
    defaultSort?: SortByDirection;
    disabled?: boolean;
    fieldSorters?: {
      [key: string]: (items: T[], sortDirection: SortDir) => T[];
    };
  };
  pagination?: {
    itemsPerPage?: number;
    defaultPage?: number;
  };
  filters?: FilterProps<T>;
}) => {
  const { search, searchedItems } = useSearch({
    items,
    ...searchOptions,
  });

  const { sort, sortedItems } = useSort({
    items: searchedItems,
    ...sortOptions,
  });

  const { filters, filteredItems } = useFilters({
    items: sortedItems,
    ...filterOptions,
  });

  const { pagination, paginatedItems } = usePagination({
    items: filteredItems,
    ...paginationOptions,
  });

  return {
    tableItems: paginatedItems,
    search: {
      ...search,
      onChange: (searchTerm: string) => {
        search.onChange(searchTerm);
        pagination.onChange(1);
      },
    },
    sort: {
      ...sort,
      onChange: (sortProps: SortByDirection) => {
        sort.onChange(sortProps);
        pagination.onChange(1);
      },
    },
    filters: {
      ...filters,
      onChange: (incomingFilters: Filter[]) => {
        filters.onChange(incomingFilters);
        pagination.onChange(1);
      },
    },
    pagination,
  };
};

// Getting the return value of a generic function is actually pretty tough
// without actually calling the function
// I've found this to be the least bad way
class Helper<T extends { [key: string]: any }> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  Return = useTable<T>({ items: [] });
}

export type UseTableT<T extends { [key: string]: any }> = Helper<T>['Return'];
