import React from 'react';

import { get } from 'lodash';

import { GleanSchema } from '@gleanhq/schema';

import { HeaderMapCurateUiStateT } from '..';
import { CanonicalSection } from './canonical-section';
import { OriginSection } from './origin-section';
import { Canonical, Container, Origin } from './styles';

export const MapVendor = ({
  data,
  vendorStepState,
  onVendorStepStateChange,
}: {
  data: {
    invoiceData: GleanSchema;
    job: {
      executionArn: string;
      jobType: string;
    };
  };
  vendorStepState: HeaderMapCurateUiStateT;
  onVendorStepStateChange: any;
}) => {
  const { job, invoiceData } = data;

  const originData: {
    vendorDisplayName: string;
    vendorLegalName: string;
    invoiceNumber: string;
  } = {
    vendorDisplayName: get(invoiceData, 'vendor.id.dba_name', ''),
    vendorLegalName: get(invoiceData, 'vendor.id.legal_name', ''),
    invoiceNumber: get(invoiceData, 'invoice.summary.number', ''),
  };

  return (
    <Container>
      <Origin>
        <OriginSection originData={originData} job={job} />
      </Origin>

      <Canonical>
        <CanonicalSection
          data={data}
          originVendorName={originData.vendorDisplayName}
          originVendorLegalName={originData.vendorLegalName}
          vendorStepState={vendorStepState}
          onVendorStepStateChange={onVendorStepStateChange}
        />
      </Canonical>
    </Container>
  );
};
