import styled from 'styled-components';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { animation, border, color, font, mixin, zIndexValues } from 'shared/styles';

export const Container = styled.div`
  z-index: ${zIndexValues.toast};
  position: fixed;
  right: 30px;
  top: 60px;
`;
export const StyledToast = styled.div<{ type?: keyof typeof color }>`
  position: relative;
  margin-bottom: 5px;
  max-width: 400px;
  padding: 15px 20px;
  border-radius: ${border.borderRadius};
  background: ${(props) => color[props.type || 'primary']};
  cursor: pointer;
  transition: all 0.15s;
  ${mixin.hardwareAccelerate}
  animation: ${animation.scaleIn} 0.2s ${animation.timingFunc};
`;
export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 11px;
  right: 14px;
  cursor: pointer;
  color: ${color.text1};
`;
export const Title = styled.div`
  padding-right: 22px;
  ${font.size(14)}
  ${font.medium}
  a {
    text-decoration: underline;
  }
`;
export const Message = styled.div`
  padding: 8px 10px 0 0;
  white-space: pre-wrap;
  ${font.size(13)}
  ${font.medium}
  a {
    text-decoration: underline;
  }
`;
