import styled from 'styled-components';

import { Tabs } from 'shared/components/tabs';
import { border, color, font, mixin, padding } from 'shared/styles';

export const Box = styled.div`
  background: ${color.background3};
  border-radius: ${border.borderRadius};
  display: grid;
  margin-bottom: ${padding.lrg};
`;
Box.displayName = 'Box';

export const Heading = styled.div`
  border-bottom: ${border.section};
  grid-column: 1 / span 2;
  padding: ${padding.med} ${padding.xlrg};
  ${font.medium};
  ${font.size(18)};
`;
Heading.displayName = 'Heading';

export const ARN = styled.div`
  grid-column: 1 / span 2;
  padding: ${padding.med} ${padding.xlrg} ${padding.sm};

  a {
    word-break: break-all;
    ${font.size(13)};
    &:hover {
      text-decoration: underline;
    }
  }
`;
ARN.displayName = 'ARN';

export const Left = styled.div`
  grid-column: 1;
  padding: ${padding.lrg} ${padding.sm} ${padding.lrg} ${padding.xlrg};
`;
Left.displayName = 'Left';

export const Right = styled.div`
  grid-column: 2;
  padding: ${padding.lrg} ${padding.xlrg} ${padding.lrg} ${padding.sm};
  @media (max-width: 1280px) {
    grid-column: 1;
    padding: 0px ${padding.sm} ${padding.lrg} ${padding.xlrg};
  }
`;
Right.displayName = 'Right';

export const Item = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 0 0 ${padding.lrg} 0;
  min-width: 275px;

  &:last-child {
    padding-bottom: 0px;
  }
`;
Item.displayName = 'Item';

export const Caption = styled.div`
  min-width: 140px;
  ${font.size(13)};
  ${font.medium};

  &.arnCaption {
    padding-bottom: ${padding.sm};
  }
`;
Caption.displayName = 'Caption';

export const Value = styled.div`
  overflow-wrap: break-word;
  ${font.size(13)};

  .subValue {
    padding: ${padding.xs} 0px ${padding.sm} 0px;
    color: ${color.text2};
  }
`;
Value.displayName = 'Value';

export const TabsLinks = styled(Tabs.Links)`
  grid-column: 1 / span 2;
  padding: ${padding.med} ${padding.xlrg} 0;
  border-bottom: ${border.section};

  &.secondTabs {
    border-top: ${border.section};
  }
`;
TabsLinks.displayName = 'TabsLinks';

export const TabLink = styled(Tabs.Link)`
  text-transform: unset;
  ${font.size(18)};
  ${font.medium};
`;
TabLink.displayName = 'TabLink';

export const JSONRow = styled.div`
  ${font.size(13)};
  padding: ${padding.xlrg};
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const JSONFileName = styled.div`
  ${font.bold};
`;
JSONFileName.displayName = 'JSONFileName';

export const FormattedJSON = styled.pre`
  padding: ${padding.med} ${padding.xlrg};
  white-space: pre-wrap;
  word-break: break-word;
  ${font.size(13)};
  border-radius: ${border.borderRadius};
  background: ${color.background4};
`;
FormattedJSON.displayName = 'FormattedJSON';

export const CommentsContainer = styled.div`
  padding: ${padding.med} ${padding.xlrg};
  grid-column: 1 / span 2;
`;
CommentsContainer.displayName = 'CommentsContainer';

export const DropdownHandle = styled.h3`
  display: flex;
  ${font.size(13)};
  ${mixin.clickable};

  svg {
    margin-left: ${padding.xs};
  }
`;
DropdownHandle.displayName = 'DropdownHandle';

export const List = styled.ul`
  list-style: decimal;
  padding-left: 12px;
`;

export const JSONExplorerHeaderLayout = styled.div`
  padding: ${padding.lrg};
  padding-bottom: 0;
  display: grid;
  font-size: ${font.size(12)};
  gap: ${padding.lrg};
`;

export const LinkIconLayout = styled.p`
  display: flex;
  align-items: center;
  gap: ${padding.sm};
`;
