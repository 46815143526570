import { useState } from 'react';

import Fuse from 'fuse.js';

import { localSearch } from 'shared/utils/javascript';

export type UseSearchProps<T> = {
  items?: T[];
  indexFields?: Array<keyof T | string | any>;
  entityLabel?: string;
  scopeLabel?: string;
  fuzzySearch?: boolean;
};

export type SearchProps = {
  entityLabel?: string;
  scopeLabel?: string;
  searchTerm: string;
  matchingItemsCount?: number;
  onChange: (newSearchTerm: string) => void;
};

export const useSearch = <T extends { [key: string]: any }>({
  items = [],
  indexFields = [],
  entityLabel,
  scopeLabel,
  fuzzySearch = true,
}: UseSearchProps<T>) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems: T[] = fuzzySearch
    ? fuzzyListSearch(items, searchTerm, {
        keys: indexFields as string[],
      })
    : localSearch({
        items,
        indexFields: indexFields as string[],
        searchTerm,
      });

  const itemsToDisplay = searchTerm.length > 0 ? filteredItems : items;

  const search: SearchProps = {
    entityLabel,
    scopeLabel,
    searchTerm,
    matchingItemsCount: itemsToDisplay.length,
    onChange: (newSearchTerm) => {
      setSearchTerm(newSearchTerm);
    },
  };

  return { search, searchedItems: itemsToDisplay };
};

export const fuzzyListSearch = <T>(
  list: T[],
  query: string,
  options: Fuse.IFuseOptions<any> = {},
) => {
  const fuse = new Fuse(list, {
    includeScore: true,
    threshold: 0.3,
    ...options,
  });
  return fuse.search<T>(query).map((result) => result.item);
};
