import styled from 'styled-components';

import { font, padding } from 'shared/styles';

export const EscalationModalContent = styled.div`
  padding: ${padding.lrg} ${padding.xlrg} ${padding.xlrg} ${padding.xlrg};
`;

export const EscalationModalTitle = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  ${font.medium}
  ${font.size(20)}
`;
