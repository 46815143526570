import { createGlobalStyle } from 'styled-components';

import { color, font, mixin } from 'shared/styles';

export const BaseStyles = createGlobalStyle`
  body {
    margin: 0;
    min-height: 100vh;
    color: ${color.text1};
    background: ${color.background1};
    ${font.size(16)}
    ${font.regular}
  }

  #react-root {
    min-height: 100vh;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    ${font.regular}
  }

  *, *:after, *:before, input[type="search"] {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  p {
    line-height: 1.6;
  }

  ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, strong {
    ${font.bold}
  }

  [role="button"], button, input, select, textarea {
    outline: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 1;
    }
  }

  select:-moz-focusring {
    color: ${color.text1};
    text-shadow: 0 0 0 #000;
  }

  select::-ms-expand {
    display: none;
  }

  body, select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    touch-action: manipulation;
  }

  ${mixin.placeholderColor(color.text2)}
`;
