import moment from 'moment';

export function formatDateSeries<T>(
  s: T & { key_as_string: moment.MomentInput },
): T & { prettyDate: string } {
  return {
    ...s,
    prettyDate: moment(s.key_as_string).format('MMM DD'),
  };
}
export const sortDateSeries = (s1: any, s2: any) => s1.key - s2.key;
