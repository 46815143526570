import styled from 'styled-components';

import { border, color, font, mixin, padding } from 'shared/styles';

export const Container = styled.div`
  border-bottom: ${border.section};
  padding: ${padding.lrg};
`;
Container.displayName = 'Container';

export const Section = styled.div`
  padding: ${padding.sm} 0;

  div {
    padding-bottom: ${padding.xs};
  }

  ul {
    list-style: decimal;
    padding-left: 16px;

    li {
      padding: ${padding.xs} 0;
    }
  }

  .subText {
    color: ${color.text2};
  }

  :last-child {
    padding-bottom: 0px;
  }
`;
Section.displayName = 'Section';

export const Heading = styled.h4`
  color: ${color.text2};
  padding-bottom: ${padding.sm};
  ${font.bold}
`;
Heading.displayName = 'Heading';

export const DropdownHandle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${padding.sm};
  ${font.size(16)};
  ${mixin.clickable};
`;
DropdownHandle.displayName = 'DropdownHandle';
