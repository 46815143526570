import styled from 'styled-components';

import { mixin, sizes } from 'shared/styles';

export const Container = styled.div`
  display: flex;
  ${mixin.cover}
`;

export const Origin = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  padding-right: 20px;
  padding-top: ${sizes.navbarHeight + 25}px;
`;

export const Canonical = styled(Origin)`
  padding-right: 0;
  padding-left: 20px;
`;
