/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RollupT
 */
export interface RollupT {
    /**
     * 
     * @type {string}
     * @memberof RollupT
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof RollupT
     */
    doc_count: number;
}

export function RollupTFromJSON(json: any): RollupT {
    return RollupTFromJSONTyped(json, false);
}

export function RollupTFromJSONTyped(json: any, ignoreDiscriminator: boolean): RollupT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'doc_count': json['doc_count'],
    };
}

export function RollupTToJSON(value?: RollupT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'doc_count': value.doc_count,
    };
}


