import React, { createContext } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';
import { GleanSchema } from '@gleanhq/schema';

import { Form as SharedFormComponent } from 'shared/components';
import { useMergeState } from 'shared/hooks/merge-state';
import { Job } from 'shared/types';

import { CurrentStepT, ValidationFlagT } from '../state';
import { PrevNextValidationFlags } from './prev-next-validation-flags';
import { DetailCosts } from './steps/detail-costs';
import { LineItems } from './steps/line-items';
import { PaymentACH, PaymentCheck } from './steps/payment';
import { Shipping } from './steps/shipping';
import { Summary } from './steps/summary';
import { FormActions, FormFields, JobId, StyledFormElement } from './styles';

export const StepForm = ({
  job,
  hideValidationFlags,
  currentStep,
  formValues,
  initialFormValues,
  validationFlags,
  updateValidationFlag,
  focusedFlag,
  onBack,
  onNext,
  onStepChange,
  onSubmit,
  isSubmitting,
  currentFormValuesRef,
  readOnly,
  confidenceScores,
}: {
  job: Job;
  confidenceScores: GleanSchema['confidence_scores'];
  onBack?: (formData: Partial<GleanSchema>) => void;
  onNext?: (formData: Partial<GleanSchema>) => void;
  onSubmit?: (formData: Partial<GleanSchema>) => void;
  currentStep: CurrentStepT;
  isSubmitting: boolean;
  readOnly: boolean;
  hideValidationFlags: boolean;
  formValues: Partial<GleanSchema>;
  initialFormValues: Partial<GleanSchema>;
  errors: any;
  validationFlags: ValidationFlagT[];
  updateValidationFlag: (flagId: string, updateFn: (flag: any) => Partial<any>) => void;
  focusedFlag: ValidationFlagT | null;
  currentFormValuesRef: React.MutableRefObject<Partial<GleanSchema>>;
  onStepChange: (formData: Partial<GleanSchema>, step: CurrentStepT) => void;
}) => {
  const [uiState, setUiState] = useMergeState({});
  const stepProps = {
    key: currentStep,
    job,
    hideValidationFlags,
    formValues,
    initialFormValues,
    validationFlags,
    updateValidationFlag,
    focusedFlag,
    onStepChange,
    uiState,
    setUiState,
    currentFormValuesRef,
    readOnly,
    confidenceScores,
    onNext,
    onBack,
    onSubmit,
    isSubmitting,
  };

  if (currentStep === 'SUMMARY') {
    return <Form {...stepProps} component={Summary} />;
  } else if (currentStep === 'SHIPPING') {
    return <Form {...stepProps} component={Shipping} />;
  } else if (currentStep === 'PAYMENT_ACH') {
    return <Form {...stepProps} component={PaymentACH} />;
  } else if (currentStep === 'PAYMENT_CHECK') {
    return <Form {...stepProps} component={PaymentCheck} />;
  } else if (currentStep === 'DETAIL_COSTS') {
    return <Form {...stepProps} component={DetailCosts} />;
  } else if (currentStep === 'LINE_ITEMS') {
    return <Form {...stepProps} component={LineItems} />;
  } else {
    return <pre>{JSON.stringify({ currentStep })}</pre>;
  }
};

export const Form = ({
  job,
  hideValidationFlags,
  formValues,
  initialFormValues,
  validationFlags,
  updateValidationFlag,
  focusedFlag,
  uiState,
  setUiState,
  onBack,
  onNext,
  onStepChange,
  onSubmit,
  isSubmitting,
  currentFormValuesRef,
  component: StepComponent,
  readOnly,
  validations,
  confidenceScores,
}: Omit<any, 'confidenceScores'> & { confidenceScores: GleanSchema['confidence_scores'] }) => (
  <SharedFormComponent
    validations={validations}
    validateOnChange
    validateOnBlur
    initialValues={formValues}
    onSubmit={({ _action, _step, _flag, ...formData }: any) => {
      if (_action === 'back') onBack(formData);
      if (_action === 'next') onNext(formData);
      if (_action === 'stepChange') onStepChange(formData, _step);
      if (_action === 'submit') onSubmit(formData);
    }}
  >
    {({ values, setValues, submitForm, ...formikProps }: any) => {
      const handleSubmit = (_action: any, data?: any) => {
        setValues({ ...values, _action, ...data });
        submitForm();
      };

      currentFormValuesRef.current = values;
      return (
        <>
          {job.jobType.endsWith('validate') && hideValidationFlags && <PrevNextValidationFlags />}

          <JobId>Job # {job.id}</JobId>

          <StyledFormElement autoComplete="off">
            <FormContext.Provider
              value={{
                jobType: job.jobType,
                initialFormValues,
                confidenceScores,
                validationFlags,
                updateValidationFlag,
                focusedFlag,
                values,
                readOnly,
                ...formikProps,
              }}
            >
              <FormFields>
                <StepComponent
                  values={values}
                  validationFlags={validationFlags}
                  updateValidationFlag={updateValidationFlag}
                  uiState={uiState}
                  setUiState={setUiState}
                  readOnly={readOnly}
                  {...formikProps}
                />
              </FormFields>
            </FormContext.Provider>

            <FormActions>
              {onBack && (
                <Button variant="outlined" icon="arrowLeft" onClick={() => handleSubmit('back')}>
                  Back
                </Button>
              )}

              {onNext && (
                <Button trailingIcon="arrowRight" onClick={() => handleSubmit('next')}>
                  Next
                </Button>
              )}

              {onSubmit && !readOnly && (
                <Button isWorking={isSubmitting} onClick={() => handleSubmit('submit')}>
                  Submit
                </Button>
              )}
            </FormActions>
          </StyledFormElement>
        </>
      );
    }}
  </SharedFormComponent>
);

/**
 * @deprecated use the strongly typed ExtractValidateContext instead
 */
export const FormContext = createContext<{ validationFlags?: any[] }>({});
