/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CanonicalLineItemDTO,
    CanonicalLineItemDTOFromJSON,
    CanonicalLineItemDTOFromJSONTyped,
    CanonicalLineItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<CanonicalLineItemDTO>}
     * @memberof InlineResponse200
     */
    canonicalLineItems?: Array<CanonicalLineItemDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    hasMore?: boolean;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonicalLineItems': !exists(json, 'canonicalLineItems') ? undefined : ((json['canonicalLineItems'] as Array<any>).map(CanonicalLineItemDTOFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonicalLineItems': value.canonicalLineItems === undefined ? undefined : ((value.canonicalLineItems as Array<any>).map(CanonicalLineItemDTOToJSON)),
        'hasMore': value.hasMore,
    };
}


