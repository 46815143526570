import styled from 'styled-components';

import { color, font, mixin, padding, sizes, zIndexValues } from 'shared/styles';

export const StyledNavBar = styled.nav`
  align-items: center;
  background: ${color.background1};
  display: grid;
  grid-template-columns: 30% auto 30%;
  height: ${sizes.navbarHeight}px;
  justify-content: space-between;
  justify-items: left;
  left: 0;
  padding: 0 ${padding.lrg};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndexValues.navbar};
  ${mixin.hardwareAccelerate}

  @media (max-width: 1440px) {
    grid-template-columns: 10% auto auto;
  }
`;
StyledNavBar.displayName = 'StyledNavBar';

export const ExitLink = styled.div`
  display: flex;
  align-items: center;
  ${mixin.clickable}
  ${font.size(14)}
  ${font.medium}
`;
ExitLink.displayName = 'ExitLink';

export const Heading = styled.h1`
  ${font.size(18)};
  ${font.medium};
`;
Heading.displayName = 'Heading';

export const GridRight = styled.div`
  display: flex;
  justify-self: right;
`;
GridRight.displayName = 'GridRight';

export const GridLeft = styled.div`
  display: flex;
  justify-self: left;
  align-items: center;
  gap: 12px;
`;
GridLeft.displayName = 'GridLeft';

export const IconBtnGroup = styled.div`
  display: flex;
  button {
    margin-left: ${padding.med};
  }
`;

export const TextBtnGroup = styled.div`
  display: flex;
  button {
    margin-left: ${padding.sm};
  }
`;
