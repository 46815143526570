import React, { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { history } from 'browserHistory';
import { FlaggProvider } from 'flagg';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { ProofTheme } from '@glean/glean-ui.design.proof-theme';

import { baseApiUrl, queryClient } from 'shared/api';
import { AuthProvider } from 'shared/auth';
import { Toast } from 'shared/components/toast';
import { ff } from 'shared/feature-flags';

import { Home } from 'views/home';
import { JobLoader } from 'views/job';
import { LogIn } from 'views/login';
import { Manage } from 'views/manage';
import { ManageVendorView } from 'views/manage/canonical-vendors/vendor-view';
import { ManageExecutionView } from 'views/manage/view-execution';

import { FlaggAdminModal } from './flagg-admin-modal/flagg-admin-modal';
import { BaseStyles } from './global-styles/base';
import { NormalizeStyles } from './global-styles/normalize';

export const App = () => {
  useEffect(() => {
    if (!window.location.host.includes('localhost')) {
      datadogRum.init({
        applicationId: 'b146dc04-7c65-4f9e-83df-f0ce49ec2271',
        clientToken: 'pub7c321fcf763221fc99564c4453d2e0a9',
        site: 'datadoghq.com',
        service: 'proof',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'allow',
        env: import.meta.env.VITE_STAGE === 'production' ? 'prod' : 'dev',
        allowedTracingOrigins: [window.location.origin, new URL(baseApiUrl).origin],
      });
      datadogRum.startSessionReplayRecording();
    }
  }, []);
  return (
    <>
      <ProofTheme>
        <span id={popoverAnchorDomId} />
        <NormalizeStyles />
        <BaseStyles />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <FlaggProvider featureFlags={ff}>
              <Router history={history}>
                <Toast />
                <FlaggAdminModal />
                <Switch>
                  <Redirect exact from="/" to="/home" />
                  <Route path="/log-in" component={LogIn} />
                  <Route path="/home" component={Home} />
                  <Route
                    path={['/job/:jobType/:jobId?', '/deescalate/:jobId?']}
                    component={JobLoader}
                  />
                  <Route path="/manage/view/:executionArn" component={ManageExecutionView} />
                  <Route path="/manage/canonical-vendors/:vendorId" component={ManageVendorView} />
                  <Route path="/manage" component={Manage} />
                </Switch>
              </Router>
            </FlaggProvider>
          </AuthProvider>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </ProofTheme>
    </>
  );
};

export const popoverAnchorDomId = 'glean-popover-anchor';
