import React from 'react';

import { ExecutionTable } from 'shared/components/execution-table';

import { PipelineMetrics } from '../shared/pipeline-metrics';

export const ExecutionList = () => {
  return (
    <>
      <PipelineMetrics />
      <ExecutionTable />
    </>
  );
};
