import styled, { css } from 'styled-components';

import { border, color, font, padding } from 'shared/styles';

export const StyledLineItem = styled.div`
  margin-bottom: ${padding.lrg};
  border-radius: 6px;
  background: ${color.background3};
`;
StyledLineItem.displayName = 'StyledLineItem';

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${padding.med} ${padding.lrg};
  border-bottom: ${border.section};
`;
Top.displayName = 'Top';

export const Heading = styled.div<{ isShaded?: boolean }>`
  ${font.size(18)};
  ${font.bold};

  ${({ isShaded }) =>
    isShaded
      ? css`
          color: ${color.text2};
          cursor: pointer;
        `
      : css`
          color: ${color.text1};
        `};
`;
Heading.displayName = 'Heading';

export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: ${padding.med} ${padding.lrg};
  border-bottom: ${border.section};
  word-wrap: break-word;

  &:last-of-type {
    border-bottom: none;
  }
  &:nth-of-type(2) {
    align-items: center;
  }

  & > *:nth-child(1) {
    width: 15%;
  }
  & > *:nth-child(2) {
    width: 35%;
    padding-right: 20px;
  }
  & > *:nth-child(3) {
    width: 35%;
    padding-right: 20px;
  }
  & > *:nth-child(4) {
    width: 15%;
  }
`;
Row.displayName = 'Row';

export const Label = styled.div`
  ${font.size(13)}
  ${font.medium}
`;
Label.displayName = 'Label';

export const Value = styled.div`
  ${font.size(13)}
`;
Value.displayName = 'Value';

export const Edit = styled.div`
  display: flex;
  align-items: center;
`;
Edit.displayName = 'Edit';

export const SmallCaption = styled.div`
  padding-top: 2px;
  color: ${color.text2};
  ${font.size(11)}
`;
SmallCaption.displayName = 'SmallCaption';

export const SmallHeading = styled.div`
  margin-top: ${padding.lrg};
  margin-bottom: ${padding.sm};
  color: ${color.text4};
  ${font.size(12)}
  ${font.bold}
`;
SmallHeading.displayName = 'SmallHeading';

export const Description = styled.p`
  ${font.size(13)}
`;
Description.displayName = 'Description';

export const ShadingMessage = styled.div`
  padding: ${padding.lrg};
  color: ${color.text2};
  ${font.size(14)};
  font-style: italic;
  border-bottom: ${border.section};
`;
