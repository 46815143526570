/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LineItemStpWithParsedModelOutputAttributeTypeMap,
    LineItemStpWithParsedModelOutputAttributeTypeMapFromJSON,
    LineItemStpWithParsedModelOutputAttributeTypeMapFromJSONTyped,
    LineItemStpWithParsedModelOutputAttributeTypeMapToJSON,
} from './';

/**
 * 
 * @export
 * @interface LineItemStpWithParsedModelOutput
 */
export interface LineItemStpWithParsedModelOutput {
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    canonical_line_item_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    canonical_line_item_name?: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemStpWithParsedModelOutput
     */
    probability?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    execution_arn: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    file_key: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    canonical_vendor_id: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    line_item_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemStpWithParsedModelOutput
     */
    is_stp: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutput
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemStpWithParsedModelOutput
     */
    is_live_version: boolean;
    /**
     * 
     * @type {object}
     * @memberof LineItemStpWithParsedModelOutput
     */
    discriminator: object;
    /**
     * 
     * @type {Array<LineItemStpWithParsedModelOutputAttributeTypeMap>}
     * @memberof LineItemStpWithParsedModelOutput
     */
    attributeTypeMap: Array<LineItemStpWithParsedModelOutputAttributeTypeMap>;
}

export function LineItemStpWithParsedModelOutputFromJSON(json: any): LineItemStpWithParsedModelOutput {
    return LineItemStpWithParsedModelOutputFromJSONTyped(json, false);
}

export function LineItemStpWithParsedModelOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItemStpWithParsedModelOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonical_line_item_id': !exists(json, 'canonical_line_item_id') ? undefined : json['canonical_line_item_id'],
        'canonical_line_item_name': !exists(json, 'canonical_line_item_name') ? undefined : json['canonical_line_item_name'],
        'probability': !exists(json, 'probability') ? undefined : json['probability'],
        'execution_arn': json['execution_arn'],
        'bucket': json['bucket'],
        'file_key': json['file_key'],
        'canonical_vendor_id': json['canonical_vendor_id'],
        'line_item_id': json['line_item_id'],
        'is_stp': json['is_stp'],
        'version': json['version'],
        'is_live_version': json['is_live_version'],
        'discriminator': json['discriminator'],
        'attributeTypeMap': ((json['attributeTypeMap'] as Array<any>).map(LineItemStpWithParsedModelOutputAttributeTypeMapFromJSON)),
    };
}

export function LineItemStpWithParsedModelOutputToJSON(value?: LineItemStpWithParsedModelOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonical_line_item_id': value.canonical_line_item_id,
        'canonical_line_item_name': value.canonical_line_item_name,
        'probability': value.probability,
        'execution_arn': value.execution_arn,
        'bucket': value.bucket,
        'file_key': value.file_key,
        'canonical_vendor_id': value.canonical_vendor_id,
        'line_item_id': value.line_item_id,
        'is_stp': value.is_stp,
        'version': value.version,
        'is_live_version': value.is_live_version,
        'discriminator': value.discriminator,
        'attributeTypeMap': ((value.attributeTypeMap as Array<any>).map(LineItemStpWithParsedModelOutputAttributeTypeMapToJSON)),
    };
}


