import moment from 'moment';

export const formatDate = (date: any) => (date ? moment(date).format('YYYY-MM-DD') : date);

export const formatDateTime = (date: any) =>
  date ? moment(date).format('YYYY-MM-DD hh:mm a') : date;

export const assureTimeZone = (date: any) => {
  if (typeof date === 'string' && date.indexOf('Z') === -1 && date.indexOf('+') === -1) {
    // assume UTC if no timezone data present
    return `${date}Z`;
  }
  return null;
};
