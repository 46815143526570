import styled from 'styled-components';

import { color, font } from 'shared/styles';

export const MetricTitle = styled.span`
  ${font.medium}
  ${font.size(14)}
  display: inline-block;
  width: 100%;
`;

export const MetricSubtitle = styled.span`
  ${font.size(10)};
  color: ${color.text3};
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
`;
export const MetricValue = styled.span`
  ${font.regular}
  ${font.size(24)}
`;
export const MetricContainer = styled.div<{ cursor?: 'pointer' | 'default' }>`
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
`;
