import styled, { css } from 'styled-components';

import { color, font, padding, sizes } from 'shared/styles';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: ${sizes.navbarHeight}px 30px 50px;
`;
Container.displayName = 'ManageContainer';

export const Grid = styled.div<{ numCols?: number }>`
  display: grid;
  grid-gap: 26px;

  @media (max-width: 1300px) {
    // grid-template-columns: repeat(1, 1fr);
  }
  ${({ numCols }) => css`
    grid-template-columns: repeat(${numCols || 2}, 1fr);
  `}
`;
Grid.displayName = 'Grid';

export const Box = styled.div<{ columnStart?: number; clickable?: boolean }>`
  background: ${color.background3};
  border-radius: 6px;
  padding: 32px;
  grid-template-rows: repeat(2, 1fr);
  grid-column-start: ${(props) => props.columnStart};
  ${(props) => props.clickable && 'cursor: pointer;'}
`;
Box.displayName = 'Box';

export const ExternalLink = styled.div`
  width: 100%;
  a {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
ExternalLink.displayName = 'ExternalLink';

export const PipelineTopLevelTitle = styled.h2`
  ${font.size(20)};
  ${font.medium}
  margin-bottom: ${padding.med}
`;
