/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanonicalVendorT
 */
export interface CanonicalVendorT {
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    legal_name: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    email_address?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    sector?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    industry_group?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    sub_industry?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CanonicalVendorT
     */
    founded_year?: number;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    location_clearbit?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    zip_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    street_address_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorT
     */
    street_address_2?: string;
}

export function CanonicalVendorTFromJSON(json: any): CanonicalVendorT {
    return CanonicalVendorTFromJSONTyped(json, false);
}

export function CanonicalVendorTFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalVendorT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display_name': json['display_name'],
        'legal_name': json['legal_name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'industry_group': !exists(json, 'industry_group') ? undefined : json['industry_group'],
        'sub_industry': !exists(json, 'sub_industry') ? undefined : json['sub_industry'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'founded_year': !exists(json, 'founded_year') ? undefined : json['founded_year'],
        'location_clearbit': !exists(json, 'location_clearbit') ? undefined : json['location_clearbit'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zip_code': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street_address_1': !exists(json, 'street_address_1') ? undefined : json['street_address_1'],
        'street_address_2': !exists(json, 'street_address_2') ? undefined : json['street_address_2'],
    };
}

export function CanonicalVendorTToJSON(value?: CanonicalVendorT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_name': value.display_name,
        'legal_name': value.legal_name,
        'domain': value.domain,
        'phone_number': value.phone_number,
        'email_address': value.email_address,
        'sector': value.sector,
        'industry': value.industry,
        'industry_group': value.industry_group,
        'sub_industry': value.sub_industry,
        'description': value.description,
        'founded_year': value.founded_year,
        'location_clearbit': value.location_clearbit,
        'logo': value.logo,
        'country': value.country,
        'zip_code': value.zip_code,
        'state': value.state,
        'city': value.city,
        'street_address_1': value.street_address_1,
        'street_address_2': value.street_address_2,
    };
}


