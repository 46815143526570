import React, { useLayoutEffect, useState } from 'react';

import { Spinner } from '@glean/glean-ui.atoms.spinner';

import * as api from 'shared/api';
import { Avatar } from 'shared/components/avatar';
import { InputDebounced } from 'shared/components/input-debounced';

import { NoResults, Result, Results, VendorName } from './styles';

type VendorT = { canonical_vendor_id: string; logo: string; display_name: string };

export const SearchForm = ({
  defaultQuery,
  onVendorSelect,
  onRender = () => {},
}: {
  defaultQuery?: string;
  onVendorSelect: (vendor: VendorT) => void;
  onRender: () => void;
}) => {
  const [searchQuery, setSearchQuery] = useState(defaultQuery || '');

  const {
    data: vendors,
    isLoading,
  }: {
    data?: VendorT[];
    isLoading: boolean;
  } = api.useQuery({
    queryKey: searchQuery && ['searchCanonicalVendors', searchQuery],
    queryFn: () => api.searchCanonicalVendors(searchQuery),
    validateData: (res: any) => !!res && !!res.data && !!res.data.canonical_vendors,
    transformData: (res: any) => res.data.canonical_vendors,
  });

  useLayoutEffect(onRender);

  return (
    <>
      <InputDebounced
        type="text"
        placeholder="Search..."
        iconStart="search"
        value={searchQuery}
        onChange={setSearchQuery}
      />

      <Results>
        {isLoading && <Spinner />}

        {!isLoading && searchQuery && vendors && vendors.length === 0 && (
          <NoResults>We could find no vendors matching your search query</NoResults>
        )}

        {vendors &&
          vendors.length > 0 &&
          vendors.map((vendor) => (
            <Result key={vendor.canonical_vendor_id} onClick={() => onVendorSelect(vendor)}>
              <Avatar src={vendor.logo} alt={vendor.display_name} size={28} />
              <VendorName>{vendor.display_name}</VendorName>
            </Result>
          ))}
      </Results>
    </>
  );
};
