import React, { useContext, useEffect, useState } from 'react';

import { history } from 'browserHistory';
import { uniqBy } from 'lodash';
import moment from 'moment';

import { useGetCompanies, usePipelineAggregations } from 'shared/api';
import { DateRollupT } from 'shared/api/generated';
import { ExecutionTableContext } from 'shared/components/execution-table';
import { PagedBarChart } from 'shared/components/paged-bar-chart';
import { Filter } from 'shared/hooks/filters';
import { pipelineStepPrettyName } from 'shared/utils/pipeline-status-pretty-name';

import { PipelineMetrics } from '../shared/pipeline-metrics';
import { Box, Grid } from '../shared/styles';
import { ExecutionsModal } from './executions-modal';
import { Heading } from './styles';
import { formatDateSeries, sortDateSeries } from './utils';

export const PipelineStatus = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const context = useContext(ExecutionTableContext);
  useEffect(() => {
    context?.setItemsPerPage(6);
    context?.setHideActions(true);
    context?.setReadOnlyFilters(true);
    return () => {
      context?.setItemsPerPage(20);
      context?.setHideActions(false);
      context?.setReadOnlyFilters(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ExecutionsModal
        title={'Execution'}
        isOpen={modalOpen}
        onClose={() => {
          context?.filters.onChange([]);
          setModalOpen(false);
        }}
        onExploreClick={() => {
          setModalOpen(false);
          history.push('/manage/execution-list');
        }}
      />

      <PipelineMetrics openModal={() => setModalOpen(true)} />
      <Aggregations openModal={() => setModalOpen(true)} />
    </>
  );
};

const Aggregations = ({ openModal }: { openModal: () => void }) => {
  const { data: aggregationsRes, isFetching: isFetchingAggregations } = usePipelineAggregations();
  const context = useContext(ExecutionTableContext);
  const loadingAggregations = !aggregationsRes && isFetchingAggregations;
  const companiesQuery = useGetCompanies();
  const companyIdToCompanyName = (companyId: string) => {
    if (!companiesQuery.data) return '...';

    return (
      companiesQuery.data.find((company: { id: string }) => company.id === companyId)
        ?.display_name || 'Unknown company'
    );
  };
  return (
    <Grid numCols={2}>
      <SingleSeriesRollup
        title={'Header Pipeline Executions by Step'}
        series={aggregationsRes?.header?.activeExecutionStatus}
        xProperty={'key'}
        yProperty={'doc_count'}
        loading={loadingAggregations}
        labelFormatter={pipelineStepPrettyName}
        onColumnClick={(point) => {
          openModal();
          context?.filters.onChange([
            { filterKey: 'pipeline', filterValue: 'Header' },
            { filterKey: 'executionStatus', filterValue: 'Running' },
            { filterKey: 'executionStep', filterValue: pipelineStepPrettyName(point.name) },
          ]);
        }}
      />
      <SingleSeriesRollup
        title={'Detail Pipeline Executions by Step'}
        series={aggregationsRes?.detail?.activeExecutionStatus}
        xProperty={'key'}
        yProperty={'doc_count'}
        loading={loadingAggregations}
        labelFormatter={pipelineStepPrettyName}
        onColumnClick={(point) => {
          openModal();
          context?.filters.onChange([
            { filterKey: 'pipeline', filterValue: 'Detail' },
            { filterKey: 'executionStatus', filterValue: 'Running' },
            { filterKey: 'executionStep', filterValue: pipelineStepPrettyName(point.name) },
          ]);
        }}
      />
      <SingleSeriesRollup
        title={'Header Pipeline Executions by Company'}
        series={aggregationsRes?.header?.executionCountByCompanyId}
        xProperty={'key'}
        yProperty={'doc_count'}
        loading={loadingAggregations}
        labelFormatter={companyIdToCompanyName}
        color="#3A3A46"
        onColumnClick={(point) => {
          openModal();
          context?.filters.onChange([
            { filterKey: 'pipeline', filterValue: 'Header' },
            { filterKey: 'executionStatus', filterValue: 'Running' },
            { filterKey: 'companyName', filterValue: companyIdToCompanyName(point.name) },
          ]);
        }}
      />
      <SingleSeriesRollup
        title={'Detail Pipeline Executions by Company'}
        series={aggregationsRes?.detail?.executionCountByCompanyId}
        xProperty={'key'}
        yProperty={'doc_count'}
        loading={loadingAggregations}
        labelFormatter={companyIdToCompanyName}
        color="#3A3A46"
        onColumnClick={(point) => {
          openModal();
          context?.filters.onChange([
            { filterKey: 'pipeline', filterValue: 'Detail' },
            { filterKey: 'executionStatus', filterValue: 'Running' },
            { filterKey: 'companyName', filterValue: companyIdToCompanyName(point.name) },
          ]);
        }}
      />
      <LastSevenDaysChart
        title="Completed Header Executions (Past 7 Days)"
        loading={loadingAggregations}
        succeededByDay={aggregationsRes?.header?.succeededByDay}
        failedByDay={aggregationsRes?.header?.failedByDay}
        abortedByDay={aggregationsRes?.header?.abortedByDay}
        onColumnClick={(filters) => {
          context?.filters.onChange([...filters, { filterKey: 'pipeline', filterValue: 'Header' }]);
          openModal();
        }}
      />
      <LastSevenDaysChart
        title="Completed Detail Executions (Past 7 Days)"
        loading={loadingAggregations}
        succeededByDay={aggregationsRes?.detail?.succeededByDay}
        failedByDay={aggregationsRes?.detail?.failedByDay}
        abortedByDay={aggregationsRes?.detail?.abortedByDay}
        onColumnClick={(filters) => {
          context?.filters.onChange([...filters, { filterKey: 'pipeline', filterValue: 'Detail' }]);
          openModal();
        }}
      />
      <SingleSeriesRollup
        color="#E31940"
        title={'Failed Header Executions by Step (Past 7 Days)'}
        series={aggregationsRes?.header?.failureSteps}
        xProperty={'key'}
        yProperty={'doc_count'}
        loading={loadingAggregations}
        labelFormatter={pipelineStepPrettyName}
        onColumnClick={(point) => {
          openModal();
          context?.filters.onChange([
            { filterKey: 'pipeline', filterValue: 'Header' },
            { filterKey: 'executionStatus', filterValue: 'Failed' },
            { filterKey: 'dateRange', filterValue: 'Last 7 Days' },
            { filterKey: 'executionStep', filterValue: pipelineStepPrettyName(point.name) },
          ]);
        }}
      />
      <SingleSeriesRollup
        color="#E31940"
        title={'Failed Detail Executions by Step (Past 7 Days)'}
        series={aggregationsRes?.detail?.failureSteps}
        xProperty={'key'}
        yProperty={'doc_count'}
        loading={loadingAggregations}
        labelFormatter={pipelineStepPrettyName}
        onColumnClick={(point) => {
          openModal();
          context?.filters.onChange([
            { filterKey: 'pipeline', filterValue: 'Detail' },
            { filterKey: 'executionStatus', filterValue: 'Failed' },
            { filterKey: 'dateRange', filterValue: 'Last 7 Days' },
            { filterKey: 'executionStep', filterValue: pipelineStepPrettyName(point.name) },
          ]);
        }}
      />
    </Grid>
  );
};

function SingleSeriesRollup<T>({
  loading,
  series,
  xProperty,
  yProperty,
  title,
  labelFormatter = (str) => str,
  color = '#636681',
  onColumnClick,
}: {
  loading: boolean;
  series?: T[];
  xProperty: keyof T;
  yProperty: keyof T;
  title: string;
  labelFormatter?: (label: string) => string;
  color?: string;
  onColumnClick?: (point: any) => void;
}) {
  return (
    <Box>
      <Heading>{title}</Heading>
      <PagedBarChart
        hideToolTip
        seriesData={[
          {
            name: 'Executions',
            data: series || [],
            color,
            events: {
              click: (e: any) => {
                if (onColumnClick) {
                  onColumnClick(e.point);
                }
              },
            },
          },
        ]}
        labelFormatter={labelFormatter}
        xProperty={xProperty}
        yProperty={yProperty}
        loading={loading}
      />
    </Box>
  );
}

const LastSevenDaysChart = ({
  loading,
  succeededByDay,
  failedByDay,
  abortedByDay,
  title,
  onColumnClick,
}: {
  title: string;
  loading: boolean;
  succeededByDay?: DateRollupT[];
  failedByDay?: DateRollupT[];
  abortedByDay?: DateRollupT[];
  onColumnClick?: (filters: Filter[]) => void;
}) => {
  const dates = uniqBy(
    [
      ...(succeededByDay?.map((d: any) => ({
        key_as_string: d.key_as_string,
        key: d.key,
      })) || []),
      ...(failedByDay?.map((d: any) => ({
        key_as_string: d.key_as_string,
        key: d.key,
      })) || []),
      ...(abortedByDay?.map((d: any) => ({
        key_as_string: d.key_as_string,
        key: d.key,
      })) || []),
    ],
    'key',
  );
  for (let date of dates) {
    if (failedByDay?.filter((d: any) => d.key === date.key).length === 0) {
      failedByDay.push({ ...date, doc_count: 0 });
    }
    if (succeededByDay?.filter((d: any) => d.key === date.key).length === 0) {
      succeededByDay.push({ ...date, doc_count: 0 });
    }
    if (abortedByDay?.filter((d: any) => d.key === date.key).length === 0) {
      abortedByDay.push({ ...date, doc_count: 0 });
    }
  }
  const formattedSuccesses = succeededByDay?.map(formatDateSeries).sort(sortDateSeries);
  const formattedFailures = failedByDay?.map(formatDateSeries).sort(sortDateSeries);
  const formattedAbortions = abortedByDay?.map(formatDateSeries).sort(sortDateSeries);

  return (
    <Box>
      <Heading>{title}</Heading>
      <PagedBarChart
        seriesData={[
          {
            name: 'Aborted',
            data: formattedAbortions || [],
            color: '#636681',
            borderRadiusTopLeft: '0',
            borderRadiusTopRight: '0',
            borderRadiusBottomLeft: '0',
            borderRadiusBottomRight: '0',
            events: {
              click: (e: any) => {
                if (onColumnClick) {
                  onColumnClick([
                    {
                      filterKey: 'stopDate',
                      filterValue: moment(e.point.name).utc().format('YYYY-MM-DD'),
                    },
                    {
                      filterKey: 'executionStatus',
                      filterValue: 'Aborted',
                    },
                  ]);
                }
              },
            },
          },
          {
            name: 'Failed',
            data: formattedFailures || [],
            color: '#E31940',
            borderRadiusTopLeft: '0',
            borderRadiusTopRight: '0',
            borderRadiusBottomLeft: '0',
            borderRadiusBottomRight: '0',
            events: {
              click: (e: { point: { name: string } }) => {
                if (onColumnClick) {
                  onColumnClick([
                    {
                      filterKey: 'stopDate',
                      filterValue: moment(e.point.name).utc().format('YYYY-MM-DD'),
                    },
                    {
                      filterKey: 'executionStatus',
                      filterValue: 'Failed',
                    },
                  ]);
                }
              },
            },
          },
          {
            name: 'Succeeded',
            data: formattedSuccesses || [],
            color: '#00A090',
            borderRadiusTopLeft: '0',
            borderRadiusTopRight: '0',
            borderRadiusBottomLeft: '0',
            borderRadiusBottomRight: '0',
            events: {
              click: (e: any) => {
                if (onColumnClick) {
                  onColumnClick([
                    {
                      filterKey: 'stopDate',
                      filterValue: moment(e.point.name).utc().format('YYYY-MM-DD'),
                    },
                    {
                      filterKey: 'executionStatus',
                      filterValue: 'Succeeded',
                    },
                  ]);
                }
              },
            },
          },
        ]}
        hideDataLabels={true}
        showLegend={true}
        labelFormatter={(value: any) => moment(value).utc().format('MMM DD')}
        xProperty={'key_as_string'}
        yProperty={'doc_count'}
        loading={loading}
      />
    </Box>
  );
};
