import styled from 'styled-components';

import { Icon } from 'shared/components/icon';
import { color, font, mixin } from 'shared/styles';

export const StyledField = styled.div`
  margin-top: 24px;

  &.noMargin {
    margin: 0;
  }
`;

export const FieldLabel = styled.label`
  display: inline-block;
  padding-bottom: 7px;
  vertical-align: middle;
  color: ${color.text2};
  ${font.size(14)}
`;

export const FieldTipIcon = styled(Icon)`
  display: inline-block;
  margin-left: 7px;
  vertical-align: middle;
  color: ${color.text3};
  transition: color 0.15s;
  ${mixin.clickable}
  &:hover {
    color: ${color.text2};
  }
`;

export const FieldError = styled.div`
  margin-top: 7px;
  line-height: 1;
  color: ${color.warning};
  ${font.size(13)}
`;
