import styled from 'styled-components';

import { color, mixin, sizes } from 'shared/styles';

export const Container = styled.div`
  display: flex;
  padding-top: ${sizes.navbarHeight}px;
  ${mixin.cover}
`;

export const Right = styled.div`
  background: ${color.background3};
  display: flex;
  flex-direction: column;
  width: ${sizes.sidebarWidth};
  ${mixin.scrollableY};
`;
Right.displayName = 'Right';

export const InvoicePDF = styled.div`
  flex: 1;
  height: 100%;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
`;
