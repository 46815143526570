import React, { useState } from 'react';

import { Image, Letter } from './styles';

type Props = {
  src?: string;
  alt?: string;
  size?: number;
  isSquare?: boolean;
};

export const Avatar = ({ src, alt, size = 32, isSquare = true, ...otherProps }: Props) => {
  const [error, setError] = useState(false);

  const sharedProps = { size, isSquare, ...otherProps };

  if (src && !error) {
    return (
      <>
        <Image src={src} {...sharedProps} />
        <img src={src} style={{ display: 'none' }} alt="hidden" onError={() => setError(true)} />
      </>
    );
  }
  return (
    <Letter {...sharedProps}>
      <span>{(alt || '').charAt(0)}</span>
    </Letter>
  );
};
