import styled from 'styled-components';

import { color, font, mixin } from 'shared/styles';

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  padding: 0 30px;
`;

export const SmallCaption = styled.div`
  padding-bottom: 2px;
  color: ${color.text2};
  ${font.size(14)}
`;

export const VendorName = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  ${font.bold}
  ${font.size(14)}
`;

export const Invoice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InvoiceNumber = styled.div`
  display: flex;
  align-items: center;
  color: ${color.text2};
  ${font.size(18)}

  span {
    max-width: 260px;
    padding: 0 12px;
    color: ${color.text1};
    ${font.bold}
    ${mixin.truncateText}
  }
`;

export const InvoicePDF = styled.div`
  flex: 1;
  padding-top: 25px;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
`;
