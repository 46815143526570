import { useLayoutEffect } from 'react';

import traverse from 'json-schema-traverse';
import { cloneDeep, get, isArray, isBoolean, isEmpty, mapValues, pick, set, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import gleanSchema, { GleanSchema } from '@gleanhq/schema';

import { isNumberOrNumberAsString } from 'shared/utils/numbers';
import { getFieldSchemaWithRef, isFormValueEmpty } from 'shared/utils/schema';

import { stepFieldNames, stepSections } from './constants';
import { CurrentStepT, ValidationFlagT } from './state';

export const invoiceSchema = pick(gleanSchema.properties, gleanSchema.required);
/**
 * empty strings trip up our validation logic, but are generated by all
 * the form fields. This function traverses a nested object structure
 * and removes keys whose value is exactly an empty string
 * @param {*} formData
 */
// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
export const removeEmptyValuesFromFormDataValues = (formData: any) => {
  const data = cloneDeep(formData);
  if (Array.isArray(data)) {
    if (data.length === 0) return;
    return data.map(removeEmptyValuesFromFormDataValues);
  } else if (typeof data === 'string') {
    if (data.length === 0) return;
    return data.trim();
  } else if (typeof data === 'object') {
    // @ts-expect-error ts-migrate(7022) FIXME: 'mappedObject' implicitly has type 'any' because i... Remove this comment to see the full error message
    const mappedObject = Object.entries(data)
      .map(([key, value]) => {
        // recursively traverse the tree to remove all fields
        // with an empty string as the value
        return [key, removeEmptyValuesFromFormDataValues(value)];
      })
      .reduce((obj, [key, value]) => {
        // filter out keys with undefined values
        // i.e. either undefined before filtering
        // or after
        if (typeof value !== 'undefined') {
          return { ...obj, [key]: value };
        } else {
          return obj;
        }
      }, {});
    return mappedObject;
  } else {
    return data;
  }
};

export const getLineItemJobErrors = (formData?: Partial<GleanSchema>) => {
  const errors: { LINE_ITEMS: string[] } = { LINE_ITEMS: [] };
  let hasErrors = false;
  if (isEmpty(formData?.line_items?.line_items)) {
    errors.LINE_ITEMS = [
      ...(errors?.LINE_ITEMS || []),
      'Line items missing. Please add at least 1 line item',
    ];
    hasErrors = true;
  }
  const lineItems = formData?.line_items?.line_items || [];
  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i];
    if (isEmpty(lineItem.line_item_type)) {
      errors.LINE_ITEMS = [
        ...(errors?.LINE_ITEMS || []),
        `Line item type missing for line item ${i + 1}/${lineItems.length}`,
      ];
      hasErrors = true;
    }
    const allTotalFieldsEmpty =
      isEmpty(lineItem.subtotal_amount) &&
      isEmpty(lineItem.credit_discount_amount) &&
      isEmpty(lineItem.fees_and_surcharges) &&
      isEmpty(lineItem.total_tax_amount);
    if (allTotalFieldsEmpty) {
      errors.LINE_ITEMS = [
        ...(errors?.LINE_ITEMS || []),
        `Line item ${i + 1}/${
          lineItems.length
        } is missing values for Subtotal, Credit/Discount, Fees & Surcharges and Total Tax. Please enter a value for at least one of these fields.`,
      ];
      hasErrors = true;
    }
  }

  return { errors, hasErrors };
};

export const getInitialFormValues = (invoiceData: Partial<GleanSchema>) => {
  const invoiceDataClone = cloneDeep(invoiceData);
  const initialFormValues: Partial<GleanSchema> = {};
  traverse(gleanSchema, {
    allKeys: true,
    cb: (schema, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, keyIndex) => {
      const path = jsonPtr.replace(/\//g, '.').replace('.properties.', '');
      const value = get(invoiceDataClone, path);
      if (value) set(initialFormValues, path, value);
    },
  });

  if (
    !initialFormValues?.line_items?.line_items ||
    initialFormValues?.line_items?.line_items.length === 0
  ) {
    initialFormValues.line_items = {
      line_items: [
        getInitialFormSubsectionValues('line_items.line_items', {
          line_item_id: uuidv4(),
        }),
      ],
    };
  } else {
    initialFormValues.line_items.line_items = initialFormValues.line_items.line_items.map(
      (lineItem) => ({
        ...getInitialFormSubsectionValues('line_items.line_items', {
          ...lineItem,
        }),
      }),
    );
  }
  return initialFormValues;
};
// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
export const getInitialFormSubsectionValues = (name: string, formData: any) =>
  mapValues(getFieldSchemaFromName(name).properties, (fieldSchemaWithoutRef, fieldKey) => {
    const fieldSchema = getFieldSchemaWithRef(fieldSchemaWithoutRef);
    const value = get(formData, fieldKey);
    if (fieldSchema.type === 'string') {
      return value || fieldSchema.default || '';
    }
    if (fieldSchema.type === 'number') {
      if (isNumberOrNumberAsString(value)) {
        return value.toString();
      }
      return isNumberOrNumberAsString(fieldSchema.default) ? fieldSchema.default.toString() : '';
    }
    if (fieldSchema.type === 'boolean') {
      if (isBoolean(value)) {
        return value;
      }
      return isBoolean(fieldSchema.default) ? fieldSchema.default : undefined;
    }
    if (fieldSchema.type === 'array' && fieldSchema.items.type !== 'object') {
      return value || fieldSchema.default || [];
    }
    if (fieldSchema.type === 'array' && fieldSchema.items.type === 'object') {
      if (isArray(value)) {
        return value.map((item) => getInitialFormSubsectionValues(`${name}.${fieldKey}`, item));
      }
      return fieldSchema.default || [];
    }
    return '';
  });
export const getFieldSchemaFromName = (fieldName: any) => {
  const schemaPath = `properties.${fieldName.replace(/\./g, '.properties.')}`.replace(
    /properties\.\d+\./g, // array item fieldName contains .{index}.
    'items.',
  );
  const fieldSchema = get(gleanSchema, schemaPath);
  try {
    if (fieldSchema.type === 'array' && fieldSchema.items.type === 'object') {
      return getFieldSchemaWithRef(fieldSchema.items);
    }
    return getFieldSchemaWithRef(fieldSchema);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log({ e, fieldName, schemaPath, gleanSchema, fieldSchema });
  }
};
export const getFieldValidationFlags = ({ name, validationFlags, values }: any) => {
  if (name.includes('line_items.line_items')) {
    const getFieldKey = (path = '') => path.split('.')[path.split('.').length - 1];
    const nameFieldKey = getFieldKey(name);
    const lineItemId = get(values, name.replace(nameFieldKey, 'line_item_id'));
    return (
      validationFlags?.filter((flag: any) => {
        return flag.line_item_id === lineItemId && flag.field_name.toLowerCase() === nameFieldKey;
      }) || []
    );
  }
  return (
    validationFlags?.filter(
      (flag: any) =>
        `${flag.invoice_part}.${flag.field_section}.${flag.field_name}`.toLowerCase() === name,
    ) || []
  );
};
export const useIfNotEmptyOrHasValidationFlags = (props: any, then: any) => {
  useLayoutEffect(() => {
    if (isNotEmptyOrHasValidationFlags(props)) {
      then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export const isNotEmptyOrHasValidationFlags = ({ fieldNames, values, validationFlags }: any) => {
  const areAllEmpty = fieldNames.every((name: any) => isFormValueEmpty(get(values, name)));
  const hasAnyValidationFlags = fieldNames.some(
    (name: any) => getFieldValidationFlags({ name, validationFlags, values }).length > 0,
  );
  return !areAllEmpty || hasAnyValidationFlags;
};
export const resetFieldValues = ({ fieldsToReset, values, setFieldValue }: any) => {
  fieldsToReset.forEach((name: any) => {
    if (get(values, name)) {
      setFieldValue(name, '');
    }
  });
};
export const sortValidationFlags = (
  validationFlags: ValidationFlagT[],
  lineItems: GleanSchema['line_items']['line_items'] = [],
) => {
  const allStepsFieldNames = uniq(
    Object.values(stepSections)
      .flat()
      .map((section) => {
        const name = section === 'line_items' ? 'line_items.line_items' : section;
        const sectionSchema = getFieldSchemaFromName(name);
        return Object.keys(sectionSchema.properties).map((key) => `${name}.${key}`);
      })
      .flat(),
  );

  const flagsSortedBySchema = allStepsFieldNames
    .map((name) =>
      validationFlags.filter(
        (flag: any) =>
          `${flag.invoice_part}.${flag.field_section}.${flag.field_name}`.toLowerCase() === name,
      ),
    )
    .flat();

  const flagsSortedBySchemaAndIndex = [...flagsSortedBySchema].sort((flagA, flagB) => {
    let indexA = 0;
    let indexB = 0;

    for (let i = 0; i < lineItems.length || 0; i++) {
      if (lineItems[i].line_item_id === flagA.line_item_id) indexA = i;
      if (lineItems[i].line_item_id === flagB.line_item_id) indexB = i;
    }

    return indexA - indexB;
  });

  return flagsSortedBySchemaAndIndex;
};
export const isFlagActive = (flag: ValidationFlagT) => !flag.is_resolved && !flag.is_overridden;
export const getActiveValidationFlags = (flags: ValidationFlagT[]) =>
  flags?.filter(isFlagActive) || [];
export const getActiveValidationFlagsForStep = (flags: any, step: any, pipeline: any) => {
  return getActiveValidationFlags(flags)?.filter(
    (flag: any) =>
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      stepSections[pipeline][step].includes(
        `${flag.invoice_part}.${flag.field_section}`.toLowerCase(),
      ) || [],
  );
};
export const getValidationFlagStep = (flag: ValidationFlagT): CurrentStepT => {
  if (flag.invoice_part !== 'LINE_ITEMS') {
    const flagFieldName = `${flag.invoice_part}.${flag.field_section}.${flag.field_name}`.toLowerCase();
    const [step] =
      Object.entries(stepFieldNames)?.find(([_step, fieldNames]) => {
        const hasFieldName = fieldNames.includes(flagFieldName);

        return hasFieldName;
      }) || [];

    return step as CurrentStepT;
  } else {
    return 'LINE_ITEMS';
  }
};
