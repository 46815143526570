import { isFinite } from 'lodash';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (value: any) => {
  const num = toNumberOrNull(value);
  return num === null ? 'n/a' : currencyFormatter.format(num);
};

export const formatCurrencyDelta = (value1: any, value2: any) =>
  isNumberOrNumberAsString(value1) && isNumberOrNumberAsString(value2)
    ? formatCurrency(Number(value1) - Number(value2))
    : 'n/a';

export const isNumberOrNumberAsString = (value: any): value is string | number =>
  toNumberOrNull(value) !== null;

const toNumberOrNull = (value: any): number | null => {
  if (isFinite(value)) return value;

  if (typeof value === 'string' && value.trim() !== '') {
    return isFinite(Number(value)) ? Number(value) : null;
  }
  return null;
};
