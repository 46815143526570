import React, { createContext, useContext, useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { Auth0LockPasswordless } from 'auth0-lock';
import { history } from 'browserHistory';
import decode from 'jwt-decode';

import { auth0Config } from 'shared/constants';
import { useMergeState } from 'shared/hooks/merge-state';

const lock = new Auth0LockPasswordless(auth0Config.clientId, auth0Config.domain, {
  allowedConnections: ['email'],
  passwordlessMethod: 'code',
  auth: {
    redirect: false,
    redirectUrl: window.location.origin,
    responseType: 'token id_token',
  },
  theme: {
    logo: '/favicons/android-chrome-192x192.png',
  },
  languageDictionary: {
    title: 'Glean Proof',
  },
});

type AuthContextT = {
  auth: AuthResult | null;
  lock: typeof Auth0LockPasswordless;
  user: Auth0IdTokenPayload | null;
  permissions: string[];
  redirect: string;
};

interface AuthResult {
  accessToken: string;
  appState?: Record<string, unknown>;
  expiresIn: number;
  idToken: string;
  idTokenPayload: Auth0IdTokenPayload;
  refreshToken?: string;
  scope?: string;
  state: string;
  tokenType: string;
}

interface Auth0IdTokenPayload {
  name?: string;
  nickname?: string;
  picture?: string;
  email?: string;
  email_verified?: boolean;
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  acr?: string;
  amr?: string[];
  [key: string]: unknown;
}

const AuthContext = createContext<AuthContextT>({
  auth: null,
  lock,
  user: null,
  permissions: [],
  redirect: '',
});
export const logout = () => {
  lock.logout({ returnTo: window.location.origin + '/log-in' });
};
export const getAuth = () => {
  return JSON.parse(localStorage.getItem('auth') || '{}');
};
export const useAuth = () => {
  return useContext(AuthContext);
};
// Some 3rd party hooks that require a user's
// email address to complete
const identify = (userId: string) => {
  // Identify the user with segment
  const segment = (window as any).analytics;
  if (segment && typeof segment.identify === 'function') {
    segment.identify(userId);
  } else {
    // eslint-disable-next-line no-console
    console.warn('Could not initialize Segment.');
  }
};
type AuthProviderProps = {
  children?: React.ReactNode;
};
// these lines are a pretty complete debug mode for auth0 lock
// lock.validEvents.map((event) => {
//   lock.on(event, (...args) => console.log(`Auth0 fires ${event}`, args));
// });

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<AuthResult | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [userState, setUserState] = useMergeState<{
    user: null | Auth0IdTokenPayload;
    isLoading: boolean;
  }>({ user: null, isLoading: true });
  const [redirect, setRedirect] = useState('/home');
  const { user, isLoading } = userState;
  useEffect(() => {
    if (window.location.pathname !== '/log-in') {
      setRedirect(window.location.pathname + window.location.search);
    }
    // keep the value in the auth token in sync in local storage
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);
  useEffect(() => {
    if (user) {
      datadogRum.setUser(user);
      user.email && identify(user.email); // Identify the user by their email to FS and segment
    }
  }, [user]);
  useEffect(() => {
    const onAuth = (payload: AuthResult) => {
      setAuth(payload);
      setUserState({ user: payload.idTokenPayload });
      const access = decode<{ permissions: string[] }>(payload.accessToken);

      setPermissions(access['permissions'] || []);
      lock.hide();
    };
    lock.on('authenticated', onAuth);
    const checkSession = (isInitial: any) => {
      setUserState({ isLoading: !!isInitial });
      lock.checkSession({}, (err, payload) => {
        if (err) {
          setAuth(null);
          setUserState({ user: null, isLoading: false });
          setPermissions([]);
        } else {
          setAuth(payload || null);
          setUserState({ user: payload?.idTokenPayload, isLoading: false });
          const access = decode<{ permissions: string[] }>(payload?.accessToken || '');
          setPermissions(access.permissions || []);
        }
      });
    };
    checkSession(true);
    const interval = setInterval(checkSession, 5 * 60 * 1000);
    return () => {
      lock.off('authenticated', onAuth);
      clearInterval(interval);
    };
  }, [setUserState]);
  useEffect(() => {
    if (!isLoading && !user) {
      history.push('/log-in');
    }
    if (!isLoading && user) {
      // explicitly hide the login screen if we have a user
      lock.hide();
    }
  }, [isLoading, user]);
  return (
    <AuthContext.Provider value={{ auth, lock, user, permissions, redirect }}>
      {isLoading ? null : children}
    </AuthContext.Provider>
  );
};
