import React from 'react';

type Props = {
  label: string;
  symbol: string;
};

export const Emoji = ({ label, symbol }: Props) => (
  <span role="img" aria-label={label ? label : ''} aria-hidden={label ? 'false' : 'true'}>
    {symbol}
  </span>
);
