import React from 'react';

import schema from '@gleanhq/schema';

import { Form } from 'shared/components/form';
import { assertNever } from 'shared/utils/types';

import { getFieldType } from './edit-invoice-form-utils';

const convertEnumToOptions = ({
  enum: enumValues,
  enumNames,
}: {
  enum: string[];
  enumNames?: string[];
}) => {
  const output = [];
  for (let i = 0; i < enumValues.length; i++) {
    const value = enumValues[i];
    const label = enumNames?.[i] || enumValues[i];
    output.push({
      value,
      label,
    });
  }
  return output;
};

const billingFrequencyOptions = convertEnumToOptions(
  schema.properties.line_items.properties.line_items.items.properties.billing_frequency,
);
const recordTypeOptions = convertEnumToOptions(
  schema.properties.document.properties.type.properties.document_type,
);
const currencyOptions = convertEnumToOptions(
  schema.properties.invoice.properties.summary.properties.payment_currency,
);
const paymentTermsOptions = convertEnumToOptions(
  schema.properties.invoice.properties.summary.properties.payment_terms,
);
const lineItemTypeOptions = convertEnumToOptions(
  schema.properties.line_items.properties.line_items.items.properties.line_item_type,
);

const commitmentTermOptions = convertEnumToOptions(
  schema.properties.line_items.properties.line_items.items.properties.commitment_term,
);

const isProratedOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const InputValueField: React.FC<{
  valueFieldType: ReturnType<typeof getFieldType>;
  name: string;
}> = ({ valueFieldType, name }) => {
  switch (valueFieldType) {
    case 'amount':
    case 'number':
      return <Form.Field.InputNumber className="noMargin" name={name} />;
    case 'date':
      return <Form.Field.InputDate className="noMargin" name={name} />;
    case 'string':
      return <Form.Field.Input className="noMargin" name={name} />;
    case 'recordType':
      return <Form.Field.Select className="noMargin" options={recordTypeOptions} name={name} />;
    case 'paymentCurrency':
      return <Form.Field.Select className="noMargin" options={currencyOptions} name={name} />;
    case 'paymentTerms':
      return <Form.Field.Select className="noMargin" options={paymentTermsOptions} name={name} />;
    case 'billingFrequency':
      return (
        <Form.Field.Select className="noMargin" options={billingFrequencyOptions} name={name} />
      );
    case 'lineItemType':
      return <Form.Field.Select className="noMargin" options={lineItemTypeOptions} name={name} />;
    case 'commitmentTerm':
      return <Form.Field.Select className="noMargin" options={commitmentTermOptions} name={name} />;
    case 'isProrated':
      return <Form.Field.Select className="noMargin" options={isProratedOptions} name={name} />;
    case null:
      return <p>...</p>;
    default:
      try {
        assertNever(valueFieldType);
      } catch (e) {
        /** If this line is erroring it means a new return value was added to `getFieldType` which was not accounted for here */
      }
      return <p>An error occurred</p>;
  }
};
