import { useMutation, useQuery } from 'react-query';

import { apiClient } from '..';
import { EditGetInvoiceDTO, EditServiceEditInvoiceRequest } from '../generated';

export const useGetInvoice = ({ invoiceId }: { invoiceId: string }) =>
  useQuery<EditGetInvoiceDTO, Response>({
    queryKey: ['invoice', invoiceId],
    queryFn: () => apiClient.editServiceGetInvoice({ id: invoiceId }),
  });

export const useUpdateInvoice = () =>
  useMutation((requestParameters: EditServiceEditInvoiceRequest) =>
    apiClient.editServiceEditInvoice(requestParameters),
  );
