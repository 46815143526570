export const executionReprocessCategory = [
  {
    label: 'Invoice date is wrong',
    value: 'HEADER_INVOICE_DATE_IS_WRONG',
    category: 'Invoice',
  },
  {
    label: 'Due date is wrong',
    value: 'HEADER_DUE_DATE_IS_WRONG',
    category: 'Invoice',
  },
  {
    label: 'Service date are wrong',
    value: 'HEADER_SERVICE_DATES_ARE_WRONG',
    category: 'Invoice',
  },
  {
    label: 'Totals are wrong',
    value: 'HEADER_TOTALS_ARE_WRONG',
    category: 'Invoice',
  },
  {
    label: 'Other',
    value: 'HEADER_OTHER',
    category: 'Invoice',
  },
  {
    label: 'Incorrect number of extracted line items',
    value: 'LINE_ITEMS_INCORRECT_NUMBER',
    category: 'Line Item',
  },
  {
    label: 'Service dates are wrong',
    value: 'LINE_ITEMS_SERVICE_DATES_ARE_WRONG',
    category: 'Line Item',
  },
  {
    label: 'Quantity is wrong',
    value: 'LINE_ITEMS_QUANTITY_IS_WRONG',
    category: 'Line Item',
  },
  {
    label: 'Unit price is wrong',
    value: 'LINE_ITEMS_UNIT_PRICE_IS_WRONG',
    category: 'Line Item',
  },
  {
    label: 'Totals are wrong',
    value: 'LINE_ITEMS_TOTALS_ARE_WRONG',
    category: 'Line Item',
  },
  {
    label: 'Other',
    value: 'LINE_ITEMS_OTHER',
    category: 'Line Item',
  },
];
