import React, { useState } from 'react';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { Container, DropdownHandle, Heading, Section } from './styles';

type Props = {
  escalations: {
    commentText?: string;
    reason?: string;
  }[];
  restartCategory?: string;
  restartReason?: string;
  startingStep?: string;
};

export const ExecutionEvents = ({
  escalations,
  restartCategory,
  restartReason,
  startingStep,
}: Props) => {
  const [open, setOpen] = useState(true);

  return (
    <Container>
      <DropdownHandle
        onClick={() => {
          setOpen(!open);
        }}
      >
        Details
        <Icon icon={open ? 'chevronUp' : 'chevronDown'} />
      </DropdownHandle>
      {open && (
        <>
          <Section>
            <Heading>Restart Reason: </Heading>
            <div>{restartCategory ? restartCategory : '-'}</div>
            {restartReason && <div className="subText">{restartReason}</div>}
          </Section>
          <Section>
            <Heading>Restart Step: </Heading>
            <div>{restartCategory ? startingStep : '-'}</div>
          </Section>
          <Section>
            <Heading>Escalation Reasons: </Heading>

            {escalations?.length > 0 ? (
              <ul>
                {escalations.map((escalation, idx) => (
                  <li key={`escalation-${idx}`}>
                    <div>{escalation.reason}</div>
                    <p className="subText">{escalation.commentText}</p>
                  </li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </Section>
        </>
      )}
    </Container>
  );
};
