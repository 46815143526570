import { uniq } from 'lodash';

import { Job as JobT } from 'shared/types';

import { steps } from './constants';
import { CurrentStepT, ValidationFlagT } from './state';
import { getValidationFlagStep } from './utils';

const getJobLayout = (job: JobT): keyof typeof steps => {
  const pipeline = job.jobType.indexOf('header') === 0 ? 'HEADER' : 'DETAIL';
  if (pipeline === 'DETAIL' && job.showAdditionalValidateFields) {
    return 'DETAIL_PLUS';
  }
  return pipeline;
};

const canonicalOrdering: CurrentStepT[] = [
  'SUMMARY',
  'PAYMENT_ACH',
  'PAYMENT_CHECK',
  'DETAIL_COSTS',
  'SHIPPING',
  'LINE_ITEMS',
];

export const getJobSteps = (job: JobT, flags: ValidationFlagT[] = []) => {
  const additionalSteps = flags.map((flag) => getValidationFlagStep(flag));
  const jobLayout = getJobLayout(job);
  const jobSteps = steps[jobLayout];

  const allSteps = uniq([...jobSteps, ...additionalSteps]);

  const sorted = [...allSteps].sort((stepA, stepB) => {
    const indexA = canonicalOrdering.indexOf(stepA);
    const indexB = canonicalOrdering.indexOf(stepB);

    return indexA - indexB;
  });
  return sorted;
};
