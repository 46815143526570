import React, { forwardRef } from 'react';

import { uniqueId } from 'lodash';

import { CheckIcon, HiddenInput, Label } from './styles';

type Props = {
  className?: string;
  invalid?: boolean;
  checkboxLabel?: string;
  value?: boolean;
  checked?: boolean;
  onChange?: (value: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    { className, invalid = false, checkboxLabel, value, onChange = () => {}, ...checkboxProps },
    ref,
  ) => {
    const checkboxId = uniqueId('proof-checkbox-');

    return (
      <div className={className}>
        <HiddenInput
          {...checkboxProps}
          type="checkbox"
          id={checkboxId}
          checked={value === true || value === false ? value : undefined}
          onChange={(event) => onChange(event.target.checked, event)}
          ref={ref}
        />

        <Label tabIndex={0} htmlFor={checkboxId} invalid={invalid}>
          {checkboxLabel}
          <CheckIcon type="check" />
        </Label>
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';
