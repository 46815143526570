import styled from 'styled-components';

import { border, color, font } from 'shared/styles';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
`;
export const MessageBox = styled.div<{ padding: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(props) => props.padding}px 50px;
  text-align: center;
  line-height: 1.4;
  border-radius: ${border.borderRadius};
  color: ${color.text1};
`;
export const Message = styled.p`
  padding-top: 20px;
  ${font.bold}
  ${font.size(20)}
`;
export const Action = styled.div`
  padding-top: 20px;
`;
