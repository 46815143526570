import React from 'react';

import { Icon, IconProps } from '@glean/glean-ui.atoms.icon';
import { Spinner } from '@glean/glean-ui.atoms.spinner';
import { Button } from '@glean/glean-ui.molecules.button';

import { useJobMetrics } from 'shared/api';
import { logout, useAuth } from 'shared/auth';

import {
  Container,
  Grid,
  Heading,
  JobCount,
  JobLink,
  JobLinkIconContainer,
  JobSectionTitle,
  JobTitle,
  LogoutContainer,
} from './styles';

export const Home = () => {
  const { permissions, user } = useAuth();

  if (permissions.length === 0) {
    return <NoPermissionsHome email={user?.email} />;
  }
  return <HomeMain permissions={permissions} />;
};

const NoPermissionsHome = ({ email }: { email?: string }) => (
  <Container>
    <Heading>Please contact Glean Admin</Heading>
    <p>{email} does not have permission to access this resource. </p>
    <LogoutContainer>
      <Button variant="outlined" trailingIcon="logout" onClick={logout}>
        Log out
      </Button>
    </LogoutContainer>
  </Container>
);

const PipelineJobCount = ({ loading, count }: { loading: boolean; count?: number }) => {
  if (loading) return <Spinner size={14 * 1.5} />;
  return <JobCount> {count || 0}</JobCount>;
};

const JobLinkWithCount = ({
  icon,
  to,
  loading,
  count,
  displayName,
}: {
  icon: IconProps['icon'];
  to: string;
  loading: boolean;
  count?: number;
  displayName: string;
}) => {
  return (
    <JobLink to={to}>
      <JobLinkIconContainer>
        <Icon icon={icon} />
      </JobLinkIconContainer>

      <JobTitle>{displayName}</JobTitle>
      <PipelineJobCount loading={loading} count={count} />
    </JobLink>
  );
};

const JobLinkWithoutCount = ({
  icon,
  to,
  displayName,
}: {
  icon: IconProps['icon'];
  to: string;
  displayName: string;
}) => {
  return (
    <JobLink to={to}>
      <JobLinkIconContainer>
        <Icon icon={icon} />
      </JobLinkIconContainer>

      <JobTitle>{displayName}</JobTitle>
      <div style={{ height: 16 * 1.5 }}></div>
    </JobLink>
  );
};

const HomeMain = ({ permissions }: { permissions: string[] }) => {
  const { data: jobMetrics, status } = useJobMetrics();
  const loading = status === 'loading';
  return (
    <Container maxWidth={950}>
      <div>
        <JobSectionTitle>Header</JobSectionTitle>
        <Grid justify="flex-start">
          {permissions.includes('proof:identify') && (
            <JobLinkWithCount
              to="/job/header-identify"
              icon="eye"
              displayName="Identify"
              loading={loading}
              count={jobMetrics?.header.identify}
            />
          )}
          {permissions.includes('proof:map') && (
            <JobLinkWithCount
              to="/job/header-map"
              icon="list"
              displayName="Map"
              loading={loading}
              count={jobMetrics?.header.map}
            />
          )}
          {permissions.includes('proof:curate') && (
            <JobLinkWithCount
              to="/job/header-curate"
              icon="checkSquare"
              displayName="Curate"
              loading={loading}
              count={jobMetrics?.header.curate}
            />
          )}
          {permissions.includes('proof:extract') && (
            <JobLinkWithCount
              to="/job/header-extract"
              icon="upload"
              displayName="Extract"
              loading={loading}
              count={jobMetrics?.header.extract}
            />
          )}
          {permissions.includes('proof:validate') && (
            <JobLinkWithCount
              to="/job/header-validate"
              icon="checkSquare"
              displayName="Validate"
              loading={loading}
              count={jobMetrics?.header.validate}
            />
          )}
        </Grid>
      </div>
      <div>
        <JobSectionTitle>Detail</JobSectionTitle>
        <Grid justify="flex-start">
          {permissions.includes('proof:extract') && (
            <JobLinkWithCount
              to="/job/detail-extract"
              icon="upload"
              displayName="Extract"
              loading={loading}
              count={jobMetrics?.detail.extract}
            />
          )}
          {permissions.includes('proof:validate') && (
            <JobLinkWithCount
              to="/job/detail-validate"
              icon="checkSquare"
              displayName="Validate"
              loading={loading}
              count={jobMetrics?.detail.validate}
            />
          )}
          {permissions.includes('proof:map') && (
            <JobLinkWithCount
              to="/job/detail-map"
              icon="list"
              displayName="Map"
              loading={loading}
              count={jobMetrics?.detail.map}
            />
          )}
          {permissions.includes('proof:curate') && (
            <JobLinkWithCount
              to="/job/detail-curate"
              icon="checkSquare"
              displayName="Curate"
              loading={loading}
              count={jobMetrics?.detail.curate}
            />
          )}
        </Grid>
      </div>
      {permissions.includes('proof:vendor-request-map') && (
        <div>
          <JobSectionTitle>Vendor Request</JobSectionTitle>
          <Grid justify="flex-start">
            <JobLinkWithoutCount to="/job/vendor-request-map" icon="list" displayName="Map" />
          </Grid>
        </div>
      )}
      {permissions.includes('proof:manage') && (
        <div>
          <JobSectionTitle>Manage</JobSectionTitle>
          <Grid justify="flex-start">
            <JobLinkWithoutCount to="manage" icon="upload" displayName="Manage" />
            {permissions.includes('proof:deescalate') && (
              <>
                <JobLinkWithCount
                  to="/deescalate/"
                  icon="eye"
                  displayName={'De-escalate'}
                  loading={loading}
                  count={jobMetrics?.escalated}
                />
                <JobLinkWithCount
                  to="/job/multi-invoice"
                  icon="search"
                  displayName={'Multi-Invoice Check'}
                  loading={loading}
                  count={jobMetrics?.header.multiInvoice}
                />
              </>
            )}
          </Grid>
        </div>
      )}
    </Container>
  );
};
