import { useQuery } from 'react-query';

import { apiClient } from '..';

export const useJobMetrics = () => {
  return useQuery({
    queryKey: ['job-metrics'],
    queryFn: () => apiClient.jobMetricsServiceGetJobMetrics(),
  });
};

export const usePipelineAggregations = () =>
  useQuery({
    queryKey: ['pipeline-aggregations'],
    queryFn: () => apiClient.aggregationsServiceGetManagePipelineAggregations(),
  });

export const usePipelineMetrics = () =>
  useQuery({
    queryKey: ['pipeline-metrics'],
    queryFn: () => apiClient.pipelineMetricsServiceGetPipelineMetrics(),
  });
