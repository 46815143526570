import styled from 'styled-components';

import { color, font, mixin } from 'shared/styles';

export const TabLinks = styled.div<{ large?: boolean; fullWidth?: boolean }>`
  display: flex;

  & > div,
  & > a {
    ${(props) => props.large && `${font.size(16)}`}
    ${(props) => (props.fullWidth ? `flex: 1;` : `margin-right: 20px;`)}
  }
`;
export const TabLink = styled.div<{ active?: boolean }>`
  display: block;
  text-align: center;
  padding: 0 12px 12px;
  text-transform: uppercase;
  ${mixin.clickable}
  ${font.size(12)}
  ${font.bold}

  &.active {
    border-bottom: 2px solid ${color.primary};
  }

  ${(props) => props.active && `border-bottom: 2px solid ${color.primary};`}

  &.leftAlign {
    padding-left: 0px;
  }
`;
