import { CurrentStepT } from './state';

export const steps: Record<string, CurrentStepT[]> = {
  HEADER: ['SUMMARY', 'PAYMENT_ACH', 'PAYMENT_CHECK'],
  DETAIL: ['DETAIL_COSTS', 'SHIPPING', 'LINE_ITEMS'],
  DETAIL_PLUS: [
    'SUMMARY',
    'PAYMENT_ACH',
    'PAYMENT_CHECK',
    'DETAIL_COSTS',
    'SHIPPING',
    'LINE_ITEMS',
  ],
};

export const stepSections: Record<CurrentStepT, string[]> = {
  SUMMARY: ['invoice.summary', 'invoice.cost'],
  SHIPPING: ['invoice.shipping'],
  PAYMENT_ACH: ['vendor.payment_bank'],
  PAYMENT_CHECK: ['vendor.pay_by_check', 'vendor.credit_card'],
  DETAIL_COSTS: ['invoice.cost'],
  LINE_ITEMS: ['line_items.line_items'],
};

export const stepFieldNames: Record<CurrentStepT, string[]> = {
  SUMMARY: [
    'invoice.summary.invoice_number',
    'invoice.summary.customer_bill_to_name',
    'invoice.summary.invoice_date',
    'invoice.summary.due_date',
    'invoice.summary.period_start_date',
    'invoice.summary.period_end_date',
    'invoice.summary.payment_terms',
    'invoice.summary.payment_currency',
    'invoice.cost.total_billed_amount',
    'invoice.cost.amount_due',
  ],
  PAYMENT_ACH: [
    'vendor.payment_bank.account_name',
    'vendor.payment_bank.bank_name',
    'vendor.payment_bank.bank_address',
    'vendor.payment_bank.ach_routing_number',
    'vendor.payment_bank.wire_account_number',
    'vendor.payment_bank.swift_code',
  ],
  PAYMENT_CHECK: [
    'vendor.pay_by_check.pay_by_check_make_check_payable_to',
    'vendor.pay_by_check.pay_by_check_address',
    'vendor.credit_card.link',
  ],
  DETAIL_COSTS: [
    'invoice.cost.subtotal_amount',
    'invoice.cost.fees_and_surcharges',
    'invoice.cost.credit_discount_amount',
    'invoice.cost.total_tax_amount',
    'invoice.cost.beginning_balance',
    'invoice.cost.amount_paid',
    'invoice.cost.ending_balance',
  ],
  LINE_ITEMS: [
    'line_items.line_items.period_start_date',
    'line_items.line_items.period_end_date',
    'line_items.line_items.name',
    'line_items.line_items.description',
    'line_items.line_items.line_item_type',
    'line_items.line_items.billing_frequency',
    'line_items.line_items.quantity',
    'line_items.line_items.unit_price',
    'line_items.line_items.subtotal_amount',
    'line_items.line_items.credit_discount_amount',
    'line_items.line_items.fees_and_surcharges',
    'line_items.line_items.total_tax_amount',
  ],
  SHIPPING: [
    'invoice.shipping.ship_from_name',
    'invoice.shipping.ship_to_address',
    'invoice.shipping.ship_to_name',
    'invoice.shipping.shipper',
    'invoice.shipping.tracking_number',
  ],
};
