import React, { useMemo, useState } from 'react';

import { FormikProps } from 'formik';
import { mapValues } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@glean/glean-ui.molecules.button';

import { Form, Popover } from 'shared/components';
import { invoicePDFCDNHost } from 'shared/constants';

import { getEditDistance, isNewCliNameSimilarToExistingClis } from '../utils';
import { CreateConfirmationModal } from './confirmation-modal';
import { Buttons } from './styles';

type InitialFormValuesT = {
  name: string;
  has_constrained_capacity: boolean;
  is_overage: boolean;
  files: string[];
  canonical_line_item_type: string;
};

export const CreateForm = ({
  job,
  onCancel,
  onCreate,
  vendorClis,
  onCanonicalLineItemSelect,
}: {
  job: { fileKey: string };
  onCancel: () => void;
  onCreate: (
    args: {
      isNew: boolean;
      canonical_line_item_id: string;
    } & InitialFormValuesT,
  ) => void;
  vendorClis: any[];
  onCanonicalLineItemSelect: (cli: any) => void;
}) => {
  const [isCreateConfirmationModalOpen, setIsCreateConfirmationModalOpen] = useState(false);
  const [suggestedClis, setSuggestedClis] = useState<any[]>([]);
  const existingCliNames = useMemo(() => vendorClis.map((cli) => cli.name), [vendorClis]);

  return (
    <Form
      initialValues={{
        name: '',
        has_constrained_capacity: false,
        is_overage: false,
        files: [`${invoicePDFCDNHost}/${job.fileKey}`],
        canonical_line_item_type: '',
      }}
      validations={{
        name: Form.is.required(),
        canonical_line_item_type: Form.is.required(),
      }}
      onSubmit={(formData) => {
        const trimmedFormData = mapValues(formData, (value) =>
          typeof value === 'string' ? value.trim() : value,
        ) as typeof formData;

        const newCliName = trimmedFormData.name;

        const promptConfirmationAndSuggestedClis = isNewCliNameSimilarToExistingClis({
          newCliName,
          existingClis: vendorClis,
        });

        const threeClosestClisByEditDistance = [...vendorClis]
          .sort((a, b) => {
            const stringToCompare = newCliName.toLowerCase();
            const editDistanceOfCliA = getEditDistance(a.name.toLowerCase(), stringToCompare);
            const editDistanceOfCliB = getEditDistance(b.name.toLowerCase(), stringToCompare);

            if (editDistanceOfCliA < editDistanceOfCliB) return -1;
            if (editDistanceOfCliA > editDistanceOfCliB) return 1;
            return 0;
          })
          .slice(0, 3);

        // trigger the confirmation modal if there are any clis with similar names
        if (!isCreateConfirmationModalOpen && promptConfirmationAndSuggestedClis) {
          setSuggestedClis(threeClosestClisByEditDistance);
          setIsCreateConfirmationModalOpen(true);
          return;
        }

        onCreate({ ...trimmedFormData, isNew: true, canonical_line_item_id: uuidv4() });
      }}
    >
      {({ values, handleSubmit }: FormikProps<InitialFormValuesT>) => {
        const cliAlreadyExists = existingCliNames.find(
          (name) => name.toLowerCase() === values.name.toLowerCase(),
        );

        const SubmitButton = (
          <Button type="submit" disabled={!!cliAlreadyExists}>
            Submit
          </Button>
        );

        return isCreateConfirmationModalOpen ? (
          <CreateConfirmationModal
            newCliName={values.name}
            suggestedClis={suggestedClis}
            onSubmit={handleSubmit}
            onCanonicalLineItemSelect={onCanonicalLineItemSelect}
            onCancel={() => setIsCreateConfirmationModalOpen(false)}
          />
        ) : (
          <Form.Element>
            <Form.Field.Input name="name" label="Name *" />
            <Form.Field.Select
              name="canonical_line_item_type"
              label="Line Item Type"
              options={[
                {
                  label: 'Select',
                  value: '',
                },
                {
                  label: 'By Day',
                  value: 'BY_DAY',
                },
                {
                  label: 'By Volume',
                  value: 'BY_VOLUME',
                },
                {
                  label: 'Consolidated',
                  value: 'CONSOLIDATED',
                },
              ]}
            />
            <Form.Field.Checkbox
              name="has_constrained_capacity"
              checkboxLabel="Capacity Constrained"
            />
            <Form.Field.Checkbox name="is_overage" checkboxLabel="Overage" />

            <Buttons>
              <Button variant="outlined" icon="arrowLeft" onClick={onCancel}>
                Cancel
              </Button>

              {cliAlreadyExists ? (
                <Popover content={'This CLI already exists'} interaction="hover">
                  <div>{SubmitButton}</div>
                </Popover>
              ) : (
                SubmitButton
              )}
            </Buttons>
          </Form.Element>
        );
      }}
    </Form>
  );
};
