/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutionsByStepDTO
 */
export interface ExecutionsByStepDTO {
    /**
     * 
     * @type {string}
     * @memberof ExecutionsByStepDTO
     */
    step: ExecutionsByStepDTOStepEnum;
    /**
     * 
     * @type {object}
     * @memberof ExecutionsByStepDTO
     */
    data: object;
}

export function ExecutionsByStepDTOFromJSON(json: any): ExecutionsByStepDTO {
    return ExecutionsByStepDTOFromJSONTyped(json, false);
}

export function ExecutionsByStepDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionsByStepDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': json['step'],
        'data': json['data'],
    };
}

export function ExecutionsByStepDTOToJSON(value?: ExecutionsByStepDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': value.step,
        'data': value.data,
    };
}

/**
* @export
* @enum {string}
*/
export enum ExecutionsByStepDTOStepEnum {
    OCRGLEANPDFREADERRAW = 'OCR_GLEAN_PDF_READER_RAW',
    OCRGLEANPDFREADER = 'OCR_GLEAN_PDF_READER',
    OCRGLEANLAYOUTLMRAWEXTRACT = 'OCR_GLEAN_LAYOUTLM_RAW_EXTRACT',
    OCRGLEANLAYOUTLMV2RAWEXTRACT = 'OCR_GLEAN_LAYOUTLM_V2_RAW_EXTRACT',
    OCRGLEANLAYOUTLMV3RAWEXTRACT = 'OCR_GLEAN_LAYOUTLM_V3_RAW_EXTRACT',
    OCRGLEANLAYOUTLMEXTRACT = 'OCR_GLEAN_LAYOUTLM_EXTRACT',
    OCRGLEANLAYOUTLMV2EXTRACT = 'OCR_GLEAN_LAYOUTLM_V2_EXTRACT',
    OCRGLEANLAYOUTLMV3EXTRACT = 'OCR_GLEAN_LAYOUTLM_V3_EXTRACT',
    OCRGLEANTEXTBASEDDOCCLF = 'OCR_GLEAN_TEXT_BASED_DOC_CLF',
    OCRGLEANRAWTEXTBASEDDOCCLF = 'OCR_GLEAN_RAW_TEXT_BASED_DOC_CLF',
    OCRGLEANTEXTBASEDCURRENCYCLF = 'OCR_GLEAN_TEXT_BASED_CURRENCY_CLF',
    OCRGLEANRAWTEXTBASEDCURRENCYCLF = 'OCR_GLEAN_RAW_TEXT_BASED_CURRENCY_CLF',
    OCRGLEANLAYOUTLMANNOTATION = 'OCR_GLEAN_LAYOUTLM_ANNOTATION',
    OCRGLEANLAYOUTLMV2ANNOTATION = 'OCR_GLEAN_LAYOUTLM_V2_ANNOTATION',
    OCRGLEANSKIMFORMEREXTRACT = 'OCR_GLEAN_SKIMFORMER_EXTRACT',
    OCRGLEANSKIMFORMERRAWEXTRACT = 'OCR_GLEAN_SKIMFORMER_RAW_EXTRACT',
    OCRGLEANLGBMPDFFEATURES = 'OCR_GLEAN_LGBM_PDF_FEATURES',
    OCRGLEANLGBMRAWEXTRACT = 'OCR_GLEAN_LGBM_RAW_EXTRACT',
    OCRGLEANLGBMEXTRACT = 'OCR_GLEAN_LGBM_EXTRACT',
    OCRSCRYRAWEXTRACT = 'OCR_SCRY_RAW_EXTRACT',
    OCRSCRYEXTRACT = 'OCR_SCRY_EXTRACT',
    OCRAZURERAWEXTRACT = 'OCR_AZURE_RAW_EXTRACT',
    OCRAZUREEXTRACT = 'OCR_AZURE_EXTRACT',
    OCRVERYFIRAWEXTRACT = 'OCR_VERYFI_RAW_EXTRACT',
    OCRVERYFIEXTRACT = 'OCR_VERYFI_EXTRACT',
    OCRROSSUMRAWEXTRACT = 'OCR_ROSSUM_RAW_EXTRACT',
    OCRROSSUMEXTRACT = 'OCR_ROSSUM_EXTRACT',
    OCRFMEXTRACT = 'OCR_FM_EXTRACT',
    OCRDATAENSEMBLE = 'OCR_DATA_ENSEMBLE',
    DATASTP = 'DATA_STP',
    PROOFIDENTIFY = 'PROOF_IDENTIFY',
    DATAAUTOMAP = 'DATA_AUTOMAP',
    PROOFEXTRACT = 'PROOF_EXTRACT',
    WIPPROOFEXTRACT = 'WIP_PROOF_EXTRACT',
    DATAIMPUTE = 'DATA_IMPUTE',
    DATAVALIDATE = 'DATA_VALIDATE',
    PROOFVALIDATE = 'PROOF_VALIDATE',
    WIPPROOFVALIDATE = 'WIP_PROOF_VALIDATE',
    DATANORMALIZE = 'DATA_NORMALIZE',
    PROOFMAP = 'PROOF_MAP',
    WIPPROOFMAP = 'WIP_PROOF_MAP',
    INSERTCANONSTAGING = 'INSERT_CANON_STAGING',
    PROOFCURATE = 'PROOF_CURATE',
    WIPPROOFCURATE = 'WIP_PROOF_CURATE',
    DATAQUALITY = 'DATA_QUALITY',
    OCRDATAQUALITY = 'OCR_DATA_QUALITY',
    INSERTCANON = 'INSERT_CANON',
    DATAENRICH = 'DATA_ENRICH',
    INSERTDATA = 'INSERT_DATA',
    UPDATEDATA = 'UPDATE_DATA'
}


