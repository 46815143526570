/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    domain_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    email_ingestion_id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    id: string;
}

export function CompanyDTOFromJSON(json: any): CompanyDTO {
    return CompanyDTOFromJSONTyped(json, false);
}

export function CompanyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display_name': json['display_name'],
        'domain_name': json['domain_name'],
        'email_ingestion_id': json['email_ingestion_id'],
        'id': json['id'],
    };
}

export function CompanyDTOToJSON(value?: CompanyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_name': value.display_name,
        'domain_name': value.domain_name,
        'email_ingestion_id': value.email_ingestion_id,
        'id': value.id,
    };
}


