import React from 'react';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { Action, Container, Message, MessageBox } from './styles';

export const PageMessage = ({
  padding = 80,
  message = 'There was a problem fetching the data.',
  icon = 'error',
  action,
}: any) => (
  <Container>
    <MessageBox padding={padding}>
      {!!icon && <Icon icon={icon} size={3} />}
      {message && <Message>{message}</Message>}
      {action && <Action>{action}</Action>}
    </MessageBox>
  </Container>
);
