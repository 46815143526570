import styled from 'styled-components';

import { Button } from '@glean/glean-ui.molecules.button';

import { border, color, font, mixin, padding, sizes } from 'shared/styles';

export const Buttons = styled.div`
  ${padding.sidebarSection};
`;
Buttons.displayName = 'Buttons';

export const Container = styled.div`
  display: flex;
  padding-top: ${sizes.navbarHeight}px;
  ${mixin.cover}
`;
Container.displayName = 'Container';

export const Instructions = styled.div`
  padding: ${padding.med} ${padding.lrg};
  h5 {
    padding: 12px;
    ${font.size(15)}
    ${font.medium}
  }
  ul {
    padding: 0 0 25px 20px;
    list-style: disc;
  }
  li {
    padding: 3px 0;
    line-height: 1.5;
    ${font.size(14)}
  }
`;
Instructions.displayName = 'Instructions';

export const InvoicePDF = styled.div`
  flex: 1;
  height: 100%;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
`;
InvoicePDF.displayName = 'InvoicePDF';

export const JobId = styled.div`
  border-bottom: ${border.section};
  ${font.size(15)};
  ${padding.sidebarSection};
`;
JobId.displayName = 'JobId';

export const Right = styled.div`
  background: ${color.background3};
  display: flex;
  flex-direction: column;
  width: ${sizes.sidebarWidth};
  ${mixin.scrollableY};
`;
Right.displayName = 'Right';

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${padding.med};
`;
StyledButton.displayName = 'StyledButton';
