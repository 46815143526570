import React from 'react';

import {
  Formik,
  FormikConfig,
  Field as FormikField,
  Form as FormikForm,
  FormikValues,
} from 'formik';
import { get, mapValues } from 'lodash';

import { FieldValidators, generateErrors, is } from 'shared/utils/validation';

import { Field } from './field';

export const Form = <Values extends FormikValues>({
  validate,
  validations,
  validateOnBlur = false,
  ...otherProps
}: { validations?: FieldValidators | ((values: Values) => FieldValidators) } & FormikConfig<
  Values
>) => (
  <Formik
    {...otherProps}
    validateOnBlur={validateOnBlur}
    validate={(values) => {
      if (validate) {
        return validate(values);
      }
      if (validations) {
        return generateErrors(values, validations);
      }
      return {};
    }}
  />
);

// eslint-disable-next-line react/display-name
Form.Field = mapValues(Field, (FieldComponent) => ({ name, onChange, ...props }: any) => (
  <FormikField name={name}>
    {({ field, form: { touched, errors, setFieldValue } }: any) => (
      <FieldComponent
        {...field}
        {...props}
        name={name}
        error={get(touched, name) && get(errors, name)}
        onChange={(value: any) => {
          if (onChange) onChange(value);
          setFieldValue(name, value);
        }}
      />
    )}
  </FormikField>
));

Form.initialValues = (data: any, getFieldValues: any) =>
  getFieldValues((key: any, defaultValue = '') => {
    const value = get(data, key);
    return value === undefined || value === null ? defaultValue : value;
  });

Form.Element = FormikForm;
Form.is = is;
