import Dinero, { Currency } from 'dinero.js';

/**
 * safely sum together a subtotal, fee and tax amount, subtracting a credit amount
 * without introducing floating point precision errors.
 *
 * This assumes that the incoming arguments are number type variables representing
 * currencies with two decimal places of precision (USD, EUR).
 *
 * This will break if we ever try to process invoices in currencies with subdivisions greater than 100
 * (such as the Iraqi دينار, which subdivides into 1,000 فلوس for example.)
 */

const exponent = 100;
export const sumTotal = ({
  subtotal,
  credit,
  fees,
  tax,
  currency = 'USD',
}: {
  subtotal: number;
  credit: number;
  fees: number;
  tax: number;
  currency?: Currency;
}): number | null => {
  try {
    const subtotalD = Dinero({ amount: Math.round(subtotal * exponent), currency });
    const creditD = Dinero({ amount: Math.round(credit * exponent), currency });
    const feesD = Dinero({ amount: Math.round(fees * exponent), currency });
    const taxD = Dinero({ amount: Math.round(tax * exponent), currency });

    const totalAmountD = subtotalD.subtract(creditD).add(feesD).add(taxD);

    return totalAmountD.getAmount() / exponent;
  } catch (e) {
    return null;
  }
};
