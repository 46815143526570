import styled from 'styled-components';

import { Form } from 'shared/components/form';
import { color, font, mixin, padding } from 'shared/styles';

export const JobId = styled.div`
  border-bottom: 1px solid ${color.border2};
  ${font.size(15)}
  ${padding.sidebarSection}
`;
JobId.displayName = 'JobId';

export const StyledFormElement = styled(Form.Element)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
StyledFormElement.displayName = 'StyledFormElement';

export const FormFields = styled.div`
  flex: 1;
  padding: 0 ${padding.lrg} ${padding.lrg};
  ${mixin.customScrollbar()}
  ${mixin.scrollableY}
`;
FormFields.displayName = 'FormFields';

export const FormActions = styled.div`
  border-top: 1px solid ${color.border2};
  display: flex;
  justify-content: flex-end;
  ${padding.sidebarSection}
  button {
    margin-left: 15px;
  }
`;
FormActions.displayName = 'FormActions';
