import { noop } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';

import { apiClient } from '..';
import {
  ExecutionsServiceGetExecutionInvoiceJsonsForStepStepEnum,
  RestartStepsDTO,
  SingleExecutionDTO,
} from '../generated';

export const useGetPipelineExecutions = () =>
  useQuery({
    queryKey: ['pipeline-executions'],
    queryFn: () => apiClient.executionsServiceGetExecutions(),
    // refetchInterval: 3_000,
  });

export const useGetPipelineExecution = ({
  executionArn,
  onSuccess = noop,
}: {
  executionArn: string;
  onSuccess?: (data: SingleExecutionDTO) => void;
}) =>
  useQuery({
    queryKey: ['pipeline-executions', executionArn],
    queryFn: () => apiClient.executionsServiceGetExecution({ executionArn }),
    onSuccess: (data) => {
      onSuccess(data);
    },
    refetchInterval: 1_000,
  });

export const useGetExecutionInvoiceJsons = ({ executionArn }: { executionArn: string }) =>
  useQuery({
    queryKey: ['pipeline-executions', executionArn, 'invoice-jsons'],
    queryFn: () => apiClient.executionsServiceGetExecutionInvoiceJsons({ executionArn }),
  });

const isStepEnum = (
  step: string,
): step is ExecutionsServiceGetExecutionInvoiceJsonsForStepStepEnum => {
  return Object.values(ExecutionsServiceGetExecutionInvoiceJsonsForStepStepEnum).includes(
    step as ExecutionsServiceGetExecutionInvoiceJsonsForStepStepEnum,
  );
};
export const useGetExecutionInvoiceJsonsForStep = ({
  executionArn,
  step,
}: {
  executionArn?: string;
  step?: string;
}) =>
  useQuery({
    queryKey: ['pipeline-executions', executionArn, 'invoice-jsons', step],
    queryFn: () => {
      if (!executionArn || !step || !isStepEnum(step)) {
        return Promise.resolve([]);
      } else {
        return apiClient.executionsServiceGetExecutionInvoiceJsonsForStep({ executionArn, step });
      }
    },
    retry: false,
  });

export const usePipelineExecutionRestartSteps = ({
  executions,
}: {
  executions: { executionArn: string; lastUpdatedDate?: Date }[];
}) =>
  useQuery<RestartStepsDTO[]>({
    queryKey: ['restart-steps', executions.map(({ executionArn }) => executionArn).join('-')],
    queryFn: () => {
      const promises: Promise<RestartStepsDTO>[] = [];

      for (let { lastUpdatedDate, executionArn } of executions) {
        if (lastUpdatedDate && moment(lastUpdatedDate).isBefore(moment().subtract(2, 'months'))) {
          promises.push(Promise.resolve({ availableRestartSteps: ['PipelineStart'] }));
        } else {
          promises.push(apiClient.executionsServiceGetExecutionRestartSteps({ executionArn }));
        }
      }

      return Promise.all(promises);
    },
  });
