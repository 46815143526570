import React, { useState } from 'react';

import { ModalPropT } from '../modal';
import { Actions, Message, StyledButton, StyledConfirmModal, Title } from './styles';

type Props = {
  className?: string;
  color?: 'primary' | 'danger';
  title?: string;
  message?: string | React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm: (...args: any[]) => any;
  renderHandle?: (...args: any[]) => any;
} & Omit<ModalPropT, 'renderContent'>;

export const ConfirmModal = ({
  className,
  color = 'primary',
  title = 'Warning',
  message = 'Are you sure you want to continue with this action?',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  renderHandle,
  ...modalProps
}: Props) => {
  const [isWorking, setWorking] = useState(false);

  const handleConfirm = (modal: any) => {
    setWorking(true);
    onConfirm({
      close: () => {
        modal.close();
        setWorking(false);
      },
    });
  };

  return (
    <StyledConfirmModal
      {...modalProps}
      className={className}
      withCloseIcon={false}
      renderHandle={renderHandle}
      renderContent={(modal) => (
        <>
          <Title>{title}</Title>
          {message && <Message>{message}</Message>}
          <Actions>
            <StyledButton variant="outlined" onClick={modal.close}>
              {cancelText}
            </StyledButton>
            <StyledButton color={color} isWorking={isWorking} onClick={() => handleConfirm(modal)}>
              {confirmText}
            </StyledButton>
          </Actions>
        </>
      )}
    />
  );
};
