/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateRollupT
 */
export interface DateRollupT {
    /**
     * 
     * @type {number}
     * @memberof DateRollupT
     */
    key: number;
    /**
     * 
     * @type {string}
     * @memberof DateRollupT
     */
    key_as_string: string;
    /**
     * 
     * @type {number}
     * @memberof DateRollupT
     */
    doc_count: number;
}

export function DateRollupTFromJSON(json: any): DateRollupT {
    return DateRollupTFromJSONTyped(json, false);
}

export function DateRollupTFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRollupT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'key_as_string': json['key_as_string'],
        'doc_count': json['doc_count'],
    };
}

export function DateRollupTToJSON(value?: DateRollupT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'key_as_string': value.key_as_string,
        'doc_count': value.doc_count,
    };
}


