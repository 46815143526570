import React from 'react';

import { Spinner } from '@glean/glean-ui.atoms.spinner';

import { Message, StyledPageLoader } from './styles';

export const PageLoader = ({ padding = 140, message }: any) => (
  <StyledPageLoader padding={padding}>
    <Spinner size={60} />
    {message && <Message>{message}</Message>}
  </StyledPageLoader>
);
