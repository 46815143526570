import React, { useEffect, useState } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';
import { GleanSchema } from '@gleanhq/schema';

import * as api from 'shared/api';
import { useLineItemStp } from 'shared/api/api-hooks/stp';
import { PageLoader } from 'shared/components';
import { CommentsStream } from 'shared/components/comments-stream';
import { NavBar } from 'shared/components/nav-bar';
import { StatusBadge } from 'shared/components/status-badge/styles';
import { toast } from 'shared/components/toast';
import { useCommentsData } from 'shared/hooks/comments-meta-data';
import { useMergeState } from 'shared/hooks/merge-state';
import { JobT } from 'shared/types/job';

import { MapLineItem } from './map-line-item';
import {
  LineItemStepStateT,
  canonicalPayloadToLocalLineItemState,
  localStateToCanonicalPayload,
  parseStpOutput,
  useCanonicalVendorId,
} from './utils';

const cleanMapCurateData = ({
  originalInvoiceData,
  mapPayload,
}: {
  originalInvoiceData: GleanSchema;
  mapPayload: Pick<GleanSchema, 'canonical_line_items' | 'canonical_line_items_map'>;
}) => {
  const clonedOriginalInvoiceData = { ...originalInvoiceData };
  delete clonedOriginalInvoiceData.canonical_line_items;
  delete clonedOriginalInvoiceData.canonical_line_items_map;
  const invoice = { ...clonedOriginalInvoiceData, ...mapPayload };
  return invoice;
};

export type JobProps = {
  data: {
    invoiceData: GleanSchema;
    job: JobT;
  };
  fetchJob: (...args: any[]) => any;
  deescalating?: boolean;
};

export const Job = ({ data, fetchJob, deescalating }: JobProps) => {
  const { job, invoiceData } = data;
  const { executionArn, jobType } = job;
  const canonicalVendorId = useCanonicalVendorId(
    invoiceData.canonical_vendor_map?.canonical_vendor_id || job.canonicalVendorId!,
  );

  const { data: lineItemStpData, isLoading: isLoadingLineItemStpData } = useLineItemStp({
    executionArn: job.executionArn,
    fileKey: job.fileKey,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mapPayload, setMapPayload] = useState<
    Pick<GleanSchema, 'canonical_line_items_map' | 'canonical_line_items' | 'canonical_map'>
  >({});
  const isLineItemsStepValid =
    invoiceData.line_items?.line_items?.length ===
    Object.values((mapPayload as any).canonical_map?.canonical_line_items || {}).filter((v) => !!v)
      .length;

  const { mappedClis, originLineItems } = canonicalPayloadToLocalLineItemState(
    invoiceData,
    parseStpOutput(lineItemStpData),
  );

  const initialLineItemsStepState: LineItemStepStateT = {
    mappedClis,
    areMappedClisLoaded: false,
    areRecommendationsLoaded: false,
    vendorClis: [],
    areVendorClisLoaded: false,
  };
  const [lineItemsStepState, setLineItemsStepState] = useMergeState(initialLineItemsStepState);

  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    setMapPayload(
      localStateToCanonicalPayload({
        invoiceData,
        lineItemsStepState,
        canonicalVendorId,
        step: job.jobType.toUpperCase().indexOf('MAP') > -1 ? 'MAP' : 'CURATE',
      }),
    );
  }, [job, invoiceData, lineItemsStepState, canonicalVendorId]);
  const handleFinalSubmit = async () => {
    setIsSubmitting(true);
    const invoice = cleanMapCurateData({ originalInvoiceData: invoiceData, mapPayload });
    await api.createJobCompletion({
      invoice,
      job,
    });
    setIsSubmitting(false);
    toast.success('The job was completed successfully.');

    await fetchJob();
  };
  const handleSave = async () => {
    setIsSaving(true);
    const invoice = cleanMapCurateData({ originalInvoiceData: invoiceData, mapPayload });
    try {
      await api.createInvoiceSnapshot({
        job,
        invoice,
      });
      toast.success('Saved.');
    } catch (e) {
      toast.danger(e.message);
      toast.danger('Unable to save.');
    } finally {
      setIsSaving(false);
    }
  };
  const handleEscalate = async () => {
    setIsSaving(true);
    const invoice = cleanMapCurateData({ originalInvoiceData: invoiceData, mapPayload });
    await api.createInvoiceSnapshot({
      job,
      invoice,
    });

    await fetchJob();
    toast.success('This job has been escalated.');
  };

  const { data: executionEvents, isFetching, error, refetch: refetchComments } = api.useQuery({
    queryKey: `execution-comments-${executionArn}`,
    queryFn: () => api.getComments({ executionArn }),
    transformData: (data: any) => {
      const { comments, escalations, restartCategory, restartReason, startingStep } = data?.data;
      return {
        comments,
        escalations,
        restartCategory,
        restartReason,
        startingStep,
      };
    },
  });
  const { commentsData, setCommentsData } = useCommentsData({
    comments: executionEvents?.comments,
    restartCategory: executionEvents?.restartCategory,
  });
  const [commentAdded, setCommentAdded] = useState(false);
  const handleSubmitComment = async (value: any) => {
    try {
      await api.addComment({ executionArn, jobType, commentText: value, deescalating });
      setCommentAdded(true);
      refetchComments();
    } catch (e) {
      toast.danger(e.message);
    }
  };
  if (isLoadingLineItemStpData) return <PageLoader message="Loading job data..." />;
  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.GridLeft>
              <NavBar.ExitJob job={job} />
              {deescalating && <StatusBadge backgroundColor="danger">Escalated</StatusBadge>}
            </NavBar.GridLeft>
            <NavBar.Heading>
              {job.jobType === 'detail-map' ? 'Detail Map' : 'Detail Curate'}
            </NavBar.Heading>

            <NavBar.GridRight>
              <NavBar.IconBtnGroup>
                <NavBar.Comments
                  className={commentsData.commentsOpen ? 'selected' : ''}
                  onClick={() =>
                    setCommentsData({ ...commentsData, commentsOpen: !commentsData.commentsOpen })
                  }
                />
                <NavBar.Help />
              </NavBar.IconBtnGroup>
              <NavBar.TextBtnGroup>
                <NavBar.SaveJob onSave={handleSave} isWorking={isSaving} />
                {!deescalating && <NavBar.EscalateJob onEscalate={handleEscalate} job={job} />}
                <NavBar.ViewExecution arn={executionArn} />

                {deescalating ? (
                  <NavBar.DeescalateJob
                    onDeescalated={fetchJob}
                    job={job}
                    commentAdded={commentAdded}
                  />
                ) : (
                  <Button
                    disabled={!isLineItemsStepValid}
                    isWorking={isSubmitting}
                    onClick={handleFinalSubmit}
                  >
                    Submit
                  </Button>
                )}
              </NavBar.TextBtnGroup>
            </NavBar.GridRight>
          </>
        }
      />
      <MapLineItem
        data={data}
        canonicalVendorId={canonicalVendorId}
        lineItemsStepState={lineItemsStepState}
        setLineItemsStepState={setLineItemsStepState}
        originLineItems={originLineItems}
        isReprocessed={Boolean(executionEvents?.restartCategory || executionEvents?.restartReason)}
      />

      <CommentsStream
        error={error}
        comments={executionEvents?.comments || []}
        executionEvents={executionEvents}
        handleSubmitComment={handleSubmitComment}
        isFetching={isFetching}
        open={commentsData.commentsOpen}
      />
    </>
  );
};
