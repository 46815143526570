/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RestartStepsDTO
 */
export interface RestartStepsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestartStepsDTO
     */
    availableRestartSteps: Array<string>;
}

export function RestartStepsDTOFromJSON(json: any): RestartStepsDTO {
    return RestartStepsDTOFromJSONTyped(json, false);
}

export function RestartStepsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestartStepsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableRestartSteps': json['availableRestartSteps'],
    };
}

export function RestartStepsDTOToJSON(value?: RestartStepsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableRestartSteps': value.availableRestartSteps,
    };
}


