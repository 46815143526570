/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RestartExecutionInputT
 */
export interface RestartExecutionInputT {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestartExecutionInputT
     */
    manualSteps?: Array<RestartExecutionInputTManualStepsEnum>;
    /**
     * 
     * @type {string}
     * @memberof RestartExecutionInputT
     */
    startingStep: string;
    /**
     * 
     * @type {string}
     * @memberof RestartExecutionInputT
     */
    restartCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof RestartExecutionInputT
     */
    restartReason?: string;
}

export function RestartExecutionInputTFromJSON(json: any): RestartExecutionInputT {
    return RestartExecutionInputTFromJSONTyped(json, false);
}

export function RestartExecutionInputTFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestartExecutionInputT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manualSteps': !exists(json, 'manualSteps') ? undefined : json['manualSteps'],
        'startingStep': json['startingStep'],
        'restartCategory': !exists(json, 'restartCategory') ? undefined : json['restartCategory'],
        'restartReason': !exists(json, 'restartReason') ? undefined : json['restartReason'],
    };
}

export function RestartExecutionInputTToJSON(value?: RestartExecutionInputT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manualSteps': value.manualSteps,
        'startingStep': value.startingStep,
        'restartCategory': value.restartCategory,
        'restartReason': value.restartReason,
    };
}

/**
* @export
* @enum {string}
*/
export enum RestartExecutionInputTManualStepsEnum {
    ProofIdentify = 'proofIdentify',
    ProofExtract = 'proofExtract',
    ProofMap = 'proofMap'
}


