//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { useFeatureFlag } from 'flagg';

import { Button } from '@glean/glean-ui.molecules.button';
import { GleanSchema } from '@gleanhq/schema';

import * as api from 'shared/api';
import { Avatar } from 'shared/components/avatar';
import { IndicatorDot } from 'shared/components/indicatorDot';
import { PageLoader } from 'shared/components/page-loader';
import { PageMessage } from 'shared/components/page-message';
import { Popover } from 'shared/components/popover';
import { toast } from 'shared/components/toast';
import { VendorCreateForm } from 'shared/components/vendor-form';

import { HeaderMapCurateUiStateT } from '../..';
import { Invoice, InvoiceNumber, InvoicePDF, SmallCaption, Top, VendorName } from '../sharedStyles';
import { LineItemsTooltip } from './line-items-tooltip';
import { SearchForm } from './search-form';
import { Center, SwitchContainer, SwitchVendorHeading, Vendor, VendorInfo } from './styles';

export const CanonicalSection = ({
  data,
  originVendorName,
  originVendorLegalName,
  vendorStepState,
  onVendorStepStateChange,
}: {
  data: {
    invoiceData: GleanSchema;
    job: {
      executionArn: string;
      jobType: string;
    };
  };
  originVendorName: string;
  originVendorLegalName: string;
  vendorStepState: HeaderMapCurateUiStateT;
  onVendorStepStateChange: any;
}) => {
  // eslint-disable-next-line no-unused-vars
  const { invoiceData } = data;
  const {
    canonicalVendor,
    isCanonicalVendorLoaded,
    sampleInvoices,
    sampleLineItems,
    sampleInvoiceIndex,
  } = vendorStepState;

  const defaultSearchQuery = canonicalVendor ? '' : originVendorName;
  const sampleInvoice = sampleInvoices[sampleInvoiceIndex];

  const handleCanonicalVendorChange = ({
    invoices,
    line_items,
    ...vendorData
  }: {
    invoices?: HeaderMapCurateUiStateT['sampleInvoices'];
    line_items?: HeaderMapCurateUiStateT['sampleLineItems'];
  } & HeaderMapCurateUiStateT['canonicalVendor']) => {
    onVendorStepStateChange({
      canonicalVendor: vendorData,
      sampleInvoices: invoices || [],
      sampleLineItems: line_items || [],
      sampleInvoiceIndex: 0,
      isVendorStepValid: true,
    });
  };

  useFetchCanonicalVendor({
    isCanonicalVendorLoaded,
    invoiceData,
    handleCanonicalVendorChange,
    originVendorName,
    originVendorLegalName,
    onVendorStepStateChange,
  });

  const [confidenceScoreThresholdFF] = useFeatureFlag(
    'stp_ExtractValidateConfidenceScoresThreshold',
  );

  const { vendor_recommendations: vendorRecommendations } = data.invoiceData;

  const recommendation = vendorRecommendations?.find(
    (rec) => rec.canonical_vendor_id === canonicalVendor?.canonical_vendor_id,
  );

  const { confidence_score: confidenceScore } = recommendation || {};

  const threshold = confidenceScoreThresholdFF
    ? parseFloat(String(confidenceScoreThresholdFF))
    : 0.75;

  const showConfidenceScore = typeof confidenceScore !== 'undefined' && confidenceScore < threshold;

  const handleNextSampleInvoice = () => {
    const isLastIndex = sampleInvoiceIndex === sampleInvoices.length - 1;
    onVendorStepStateChange({ sampleInvoiceIndex: isLastIndex ? 0 : sampleInvoiceIndex + 1 });
  };

  const [isVendorFormOpen, setIsVendorFormOpen] = useState(false);

  if (!isCanonicalVendorLoaded) {
    return <PageLoader message="Fetching vendor candidate..." />;
  }

  if (isVendorFormOpen) {
    return (
      <Center>
        <VendorCreateForm
          onSubmit={(formData) => {
            handleCanonicalVendorChange({ ...formData, isNew: true });
            setIsVendorFormOpen(false);
          }}
          onCancel={() => {
            onVendorStepStateChange({ isVendorStepValid: !!canonicalVendor });
            setIsVendorFormOpen(false);
          }}
        />
      </Center>
    );
  }

  if (!canonicalVendor) {
    return (
      <Center>
        <SwitchVendor
          defaultSearchQuery={defaultSearchQuery}
          onVendorChange={handleCanonicalVendorChange}
          onCreateVendorClick={() => {
            onVendorStepStateChange({ isVendorStepValid: false });
            setIsVendorFormOpen(true);
          }}
        />
      </Center>
    );
  }

  return (
    <>
      <Top style={{ paddingLeft: 0 }}>
        <Vendor>
          <Avatar src={canonicalVendor.logo} alt={canonicalVendor.display_name} />
          <VendorInfo>
            <SmallCaption>Canonical Vendor</SmallCaption>
            <VendorName>
              {showConfidenceScore && (
                <Popover content="Confidence scores are low for this mapping" interaction="hover">
                  <div style={{ cursor: 'pointer', marginRight: '4px' }}>
                    <IndicatorDot />
                  </div>
                </Popover>
              )}
              {canonicalVendor.display_name}
              <Popover
                variant="dropdown"
                placement="bottom"
                maxWidth={400}
                renderContent={(popover) => (
                  <SwitchVendor
                    defaultSearchQuery={defaultSearchQuery}
                    onVendorChange={handleCanonicalVendorChange}
                    onCreateVendorClick={() => {
                      onVendorStepStateChange({ isVendorStepValid: false });
                      setIsVendorFormOpen(true);
                    }}
                    popover={popover}
                  />
                )}
              >
                <Button variant="outlined" size="small" style={{ marginLeft: 10 }}>
                  Switch
                </Button>
              </Popover>
            </VendorName>
          </VendorInfo>
        </Vendor>

        <Invoice>
          <InvoiceNumber>
            Invoice
            <span>{sampleInvoice ? sampleInvoice.invoice_number : 'n/a'}</span>
            {sampleInvoices.length > 1 && (
              <Button variant="outlined" size="small" onClick={handleNextSampleInvoice}>
                Show Next
              </Button>
            )}
          </InvoiceNumber>

          <LineItemsTooltip lineItems={sampleLineItems} />
        </Invoice>
      </Top>

      {sampleInvoice ? (
        <InvoicePDF>
          <iframe src={sampleInvoice.file_url} title="Invoice PDF" />
        </InvoicePDF>
      ) : (
        <PageMessage message="There are no sample invoices to show." icon="eye" />
      )}
    </>
  );
};

const SwitchVendor = ({ defaultSearchQuery, onVendorChange, onCreateVendorClick, popover }) => (
  <SwitchContainer>
    <SwitchVendorHeading>
      Find Canonical Vendor
      {popover && (
        <Button variant="outlined" size="small" iconEnd="close" onClick={popover.close}>
          Close
        </Button>
      )}
    </SwitchVendorHeading>

    <SearchForm
      defaultQuery={defaultSearchQuery}
      onVendorSelect={onVendorChange}
      onRender={(popover || {}).setPosition}
    />

    <Button
      variant="outlined"
      iconStart="plus"
      style={{ width: '100%' }}
      onClick={onCreateVendorClick}
    >
      Create canonical vendor
    </Button>
  </SwitchContainer>
);

/**
 * Decides, based on the canonical map payload from the invoice data, where to retrieve canonical vendor data
 * 1. if there is a canonical_vendor_id in the payload
 *    - fetch that canonical vendor
 * 2. if there is a canonical_Vendor in the payload (ie, if a new vendor was added in the payload)
 *    - render that vendor
 * 3. if there are automap recommendations in the payload
 *    - fetch that vendor
 * 3. otherwise
 *    - fetch a recommendation based on the origin vendor legal name/dba name
 */
const useFetchCanonicalVendor = ({
  isCanonicalVendorLoaded,
  invoiceData,
  handleCanonicalVendorChange,
  originVendorName,
  originVendorLegalName,
  onVendorStepStateChange,
}: {
  isCanonicalVendorLoaded: boolean;
  invoiceData: GleanSchema;
  handleCanonicalVendorChange: (
    args: {
      invoices?: HeaderMapCurateUiStateT['sampleInvoices'];
      line_items?: HeaderMapCurateUiStateT['sampleLineItems'];
    } & HeaderMapCurateUiStateT['canonicalVendor'],
  ) => void;
  originVendorName: string;
  originVendorLegalName: string;
  onVendorStepStateChange: (newState: Partial<HeaderMapCurateUiStateT>) => void;
}) => {
  useEffect(() => {
    if (isCanonicalVendorLoaded) return;

    const getDefaultCanonicalVendor = async () => {
      try {
        if (invoiceData?.canonical_vendor_map?.canonical_vendor_id) {
          // An existing vendor has already been mapped
          const vendor = await api.apiClient.mapServiceGetCanonicalVendor({
            canonicalVendorId: invoiceData.canonical_vendor_map.canonical_vendor_id,
          });
          if (vendor) {
            handleCanonicalVendorChange(vendor);
          }
        } else if (invoiceData?.canonical_vendor) {
          // A new vendor has been created in a previous map/curate session for this execution
          handleCanonicalVendorChange(invoiceData.canonical_vendor);
        } else if (invoiceData?.vendor_recommendations) {
          const [highestScoreRecommendation] = [
            ...(invoiceData?.vendor_recommendations || []),
          ].sort((a, b) => b.elastic_score - a.elastic_score);
          const vendor = await api.apiClient.mapServiceGetCanonicalVendor({
            canonicalVendorId: highestScoreRecommendation?.canonical_vendor_id,
          });
          if (vendor) {
            handleCanonicalVendorChange(vendor);
          }
        } else {
          // No canonical mapping exists yet. Fetch a recommendation
          const { data } = await api.recommendCanonicalVendors({
            originVendorDisplayName: originVendorName,
            originVendorLegalName: originVendorLegalName,
          });
          if (data.canonical_vendors[0]) {
            handleCanonicalVendorChange(data.canonical_vendors[0]);
          }
        }
      } catch {
        toast.danger('There was an issue fetching data.');
      }
      onVendorStepStateChange({ isCanonicalVendorLoaded: true });
    };

    getDefaultCanonicalVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
