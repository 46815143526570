import styled, { css } from 'styled-components';

import { border, color, font, padding } from 'shared/styles';

export const Wrapper = styled.div`
  position: relative;
`;
export const StyledTextarea = styled.textarea<{
  resizeDirection?: 'vertical' | 'horizontal';
  invalid?: boolean;
}>`
  height: 75px;
  max-height: 200px;
  width: 100%;
  padding: ${padding.sm};
  border-radius: ${border.borderRadius};
  color: ${color.text1};
  border: 1px solid ${color.background4};
  background: ${color.background4};
  transition: all 0.1s;
  ${font.regular}
  ${font.size(14)}
  &:hover:not(:focus) {
    border: 1px solid ${color.background5};
    background: ${color.background5};
  }
  &:focus {
    border: 1px solid ${color.borderFocus};
    background: ${color.background4};
    box-shadow: 0 0 0 1px ${color.borderFocus};
  }
  ${(props) =>
    props.invalid &&
    css`
      &,
      &:hover,
      &:focus {
        border: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}

  ${(props) => {
    if (props.resizeDirection === 'vertical') {
      return css`
        resize: vertical;
      `;
    } else if (props.resizeDirection === 'horizontal') {
      return css`
        resize: horizontal;
      `;
    }
  }}
`;

export const IconContainer = styled.div`
  position: absolute;
  top: ${padding.sm};
  right: ${padding.sm};
`;
