/**
 * Definitions for feature flags to be consumed by the front end.
 * Use underscores to separate into logical sections for
 * The Flagg Admin
 */
export const definitions = {
  app_companyPriority: {
    default: false,
    description:
      'enables a new tab for prioritizing the order in which invoices from different companies are processed',
  },
  stp_ExtractValidateConfidenceScoresThreshold: {
    default: '0.75',
    description:
      'a number between 0 and 1. the lowest confidence score that will trigger a "Low confidence score" message in extract validate',
  },
};
