import styled from 'styled-components';

import { Modal } from 'shared/components/modal';
import { Row } from 'shared/components/table';
import { color, font, mixin } from 'shared/styles';

export const TopActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
`;
TopActions.displayName = 'TopActions';

export const VendorFormModal = styled(Modal)`
  display: flex;
  justify-content: center;
  background: none;
`;
VendorFormModal.displayName = 'VendorFormModal';

export const ModalContent = styled.div`
  padding: 25px 30px;
`;
ModalContent.displayName = 'ModalContent';

export const MergeHeading = styled.h4`
  padding-bottom: 10px;
  ${font.size(16)}
  ${font.bold}
`;
MergeHeading.displayName = 'MergeHeading';

export const MergeTip = styled.p`
  color: ${color.text2};
  ${font.size(14)}
`;
MergeTip.displayName = 'MergeTip';

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;
Flex.displayName = 'Flex';

export const Label = styled.div`
  padding-right: 24px;
  white-space: nowrap;
  color: ${color.text1};
  ${font.size(14)}
`;
Label.displayName = 'Label';

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 30px;
  border-top: 1px solid ${color.border2};

  & > button {
    margin-left: 12px;
  }
`;
Actions.displayName = 'Actions';

export const StyledRow = styled(Row)`
  ${mixin.clickable}

  button {
    display: none;
  }
  &:hover {
    background: ${color.background4};
    button {
      display: inline-flex;
    }
  }
`;
StyledRow.displayName = 'StyledRow';
