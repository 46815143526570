import styled from 'styled-components';

import { color, font, mixin, sizes } from 'shared/styles';

export const StyledSidebar = styled.div`
  left: 0;
  top: 0;
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 100vh;
  width: 340px;
  margin-top: ${sizes.navbarHeight}px;
  background: ${color.background3};
`;
StyledSidebar.displayName = 'StyledSidebar';

export const Heading = styled.div`
  padding: 16px 40px;
  border-bottom: 1px solid ${color.border2};
  ${font.medium}
  ${font.size(16)}
`;
Heading.displayName = 'Heading';

export const List = styled.div`
  flex: 1;
  padding: 20px 40px;
  ${mixin.scrollableY}
  ${mixin.customScrollbar()}
`;
List.displayName = 'List';

export const LineItem = styled.div<{ isShaded?: boolean }>`
  display: flex;
  padding: 10px 0;
  line-height: 1.4;
  color: ${({ isShaded }) => (isShaded ? color.text2 : color.text1)};
  ${font.size(13)}
  ${mixin.clickable}

  &:hover {
    color: ${color.text1};
  }

  & > div:first-child {
    margin-right: 24px;
  }
  & > div:last-child {
    flex: 1;
  }
`;
LineItem.displayName = 'LineItem';
