import styled, { css } from 'styled-components';

import { animation, border, color, font, mixin, zIndexValues } from 'shared/styles';

export const StyledPopover = styled.div<{ maxWidth: number; variant?: 'dropdown' | 'tooltip' }>`
  z-index: ${zIndexValues.popover};
  position: fixed;
  max-width: ${(props) => props.maxWidth}px;
  ${mixin.hardwareAccelerate}

  ${(props) => props.variant === 'dropdown' && `width: 100%;`}
`;
export const AnimatedContent = styled.div<{ variant?: 'dropdown' | 'tooltip' }>`
  height: 100%;
  border-radius: ${border.borderRadius};
  color: ${color.text1};
  animation: ${animation.scaleIn} 0.15s ease;
  ${mixin.scrollableY}
  ${mixin.customScrollbar()}

  ${(props) =>
    props.variant === 'tooltip' &&
    css`
      padding: 7px 10px;
      line-height: 1.6;
      white-space: pre-wrap;
      background: ${color.background6};
      ${font.size(12)}

      p:not(:last-of-type) {
        margin-bottom: 18px;
      }
    `}

  ${(props) =>
    props.variant === 'dropdown' &&
    css`
      width: 100%;
      background: ${color.background3};
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    `}
`;
