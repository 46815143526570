/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceJsonsDTO
 */
export interface InvoiceJsonsDTO {
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_SCRY_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_VERYFI_RAW_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_VERYFI_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_ROSSUM_RAW_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_ROSSUM_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_DATA_ENSEMBLE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    PROOF_IDENTIFY?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    DATA_AUTOMAP?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    PROOF_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    WIP_PROOF_EXTRACT?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    DATA_IMPUTE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    DATA_VALIDATE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    PROOF_VALIDATE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    WIP_PROOF_VALIDATE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    DATA_NORMALIZE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    PROOF_MAP?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    WIP_PROOF_MAP?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    PROOF_CURATE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    WIP_PROOF_CURATE?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    DATA_QUALITY?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    OCR_DATA_QUALITY?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    INSERT_CANON?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    DATA_ENRICH?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    INSERT_DATA?: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceJsonsDTO
     */
    UPDATE_DATA?: object;
}

export function InvoiceJsonsDTOFromJSON(json: any): InvoiceJsonsDTO {
    return InvoiceJsonsDTOFromJSONTyped(json, false);
}

export function InvoiceJsonsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceJsonsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'OCR_SCRY_EXTRACT': !exists(json, 'OCR_SCRY_EXTRACT') ? undefined : json['OCR_SCRY_EXTRACT'],
        'OCR_VERYFI_RAW_EXTRACT': !exists(json, 'OCR_VERYFI_RAW_EXTRACT') ? undefined : json['OCR_VERYFI_RAW_EXTRACT'],
        'OCR_VERYFI_EXTRACT': !exists(json, 'OCR_VERYFI_EXTRACT') ? undefined : json['OCR_VERYFI_EXTRACT'],
        'OCR_ROSSUM_RAW_EXTRACT': !exists(json, 'OCR_ROSSUM_RAW_EXTRACT') ? undefined : json['OCR_ROSSUM_RAW_EXTRACT'],
        'OCR_ROSSUM_EXTRACT': !exists(json, 'OCR_ROSSUM_EXTRACT') ? undefined : json['OCR_ROSSUM_EXTRACT'],
        'OCR_DATA_ENSEMBLE': !exists(json, 'OCR_DATA_ENSEMBLE') ? undefined : json['OCR_DATA_ENSEMBLE'],
        'PROOF_IDENTIFY': !exists(json, 'PROOF_IDENTIFY') ? undefined : json['PROOF_IDENTIFY'],
        'DATA_AUTOMAP': !exists(json, 'DATA_AUTOMAP') ? undefined : json['DATA_AUTOMAP'],
        'PROOF_EXTRACT': !exists(json, 'PROOF_EXTRACT') ? undefined : json['PROOF_EXTRACT'],
        'WIP_PROOF_EXTRACT': !exists(json, 'WIP_PROOF_EXTRACT') ? undefined : json['WIP_PROOF_EXTRACT'],
        'DATA_IMPUTE': !exists(json, 'DATA_IMPUTE') ? undefined : json['DATA_IMPUTE'],
        'DATA_VALIDATE': !exists(json, 'DATA_VALIDATE') ? undefined : json['DATA_VALIDATE'],
        'PROOF_VALIDATE': !exists(json, 'PROOF_VALIDATE') ? undefined : json['PROOF_VALIDATE'],
        'WIP_PROOF_VALIDATE': !exists(json, 'WIP_PROOF_VALIDATE') ? undefined : json['WIP_PROOF_VALIDATE'],
        'DATA_NORMALIZE': !exists(json, 'DATA_NORMALIZE') ? undefined : json['DATA_NORMALIZE'],
        'PROOF_MAP': !exists(json, 'PROOF_MAP') ? undefined : json['PROOF_MAP'],
        'WIP_PROOF_MAP': !exists(json, 'WIP_PROOF_MAP') ? undefined : json['WIP_PROOF_MAP'],
        'PROOF_CURATE': !exists(json, 'PROOF_CURATE') ? undefined : json['PROOF_CURATE'],
        'WIP_PROOF_CURATE': !exists(json, 'WIP_PROOF_CURATE') ? undefined : json['WIP_PROOF_CURATE'],
        'DATA_QUALITY': !exists(json, 'DATA_QUALITY') ? undefined : json['DATA_QUALITY'],
        'OCR_DATA_QUALITY': !exists(json, 'OCR_DATA_QUALITY') ? undefined : json['OCR_DATA_QUALITY'],
        'INSERT_CANON': !exists(json, 'INSERT_CANON') ? undefined : json['INSERT_CANON'],
        'DATA_ENRICH': !exists(json, 'DATA_ENRICH') ? undefined : json['DATA_ENRICH'],
        'INSERT_DATA': !exists(json, 'INSERT_DATA') ? undefined : json['INSERT_DATA'],
        'UPDATE_DATA': !exists(json, 'UPDATE_DATA') ? undefined : json['UPDATE_DATA'],
    };
}

export function InvoiceJsonsDTOToJSON(value?: InvoiceJsonsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OCR_SCRY_EXTRACT': value.OCR_SCRY_EXTRACT,
        'OCR_VERYFI_RAW_EXTRACT': value.OCR_VERYFI_RAW_EXTRACT,
        'OCR_VERYFI_EXTRACT': value.OCR_VERYFI_EXTRACT,
        'OCR_ROSSUM_RAW_EXTRACT': value.OCR_ROSSUM_RAW_EXTRACT,
        'OCR_ROSSUM_EXTRACT': value.OCR_ROSSUM_EXTRACT,
        'OCR_DATA_ENSEMBLE': value.OCR_DATA_ENSEMBLE,
        'PROOF_IDENTIFY': value.PROOF_IDENTIFY,
        'DATA_AUTOMAP': value.DATA_AUTOMAP,
        'PROOF_EXTRACT': value.PROOF_EXTRACT,
        'WIP_PROOF_EXTRACT': value.WIP_PROOF_EXTRACT,
        'DATA_IMPUTE': value.DATA_IMPUTE,
        'DATA_VALIDATE': value.DATA_VALIDATE,
        'PROOF_VALIDATE': value.PROOF_VALIDATE,
        'WIP_PROOF_VALIDATE': value.WIP_PROOF_VALIDATE,
        'DATA_NORMALIZE': value.DATA_NORMALIZE,
        'PROOF_MAP': value.PROOF_MAP,
        'WIP_PROOF_MAP': value.WIP_PROOF_MAP,
        'PROOF_CURATE': value.PROOF_CURATE,
        'WIP_PROOF_CURATE': value.WIP_PROOF_CURATE,
        'DATA_QUALITY': value.DATA_QUALITY,
        'OCR_DATA_QUALITY': value.OCR_DATA_QUALITY,
        'INSERT_CANON': value.INSERT_CANON,
        'DATA_ENRICH': value.DATA_ENRICH,
        'INSERT_DATA': value.INSERT_DATA,
        'UPDATE_DATA': value.UPDATE_DATA,
    };
}


