/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditCompanyPriorityReturnT
 */
export interface EditCompanyPriorityReturnT {
    /**
     * 
     * @type {string}
     * @memberof EditCompanyPriorityReturnT
     */
    status: EditCompanyPriorityReturnTStatusEnum;
    /**
     * 
     * @type {Company}
     * @memberof EditCompanyPriorityReturnT
     */
    company?: Company;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyPriorityReturnT
     */
    error?: string;
}

export function EditCompanyPriorityReturnTFromJSON(json: any): EditCompanyPriorityReturnT {
    return EditCompanyPriorityReturnTFromJSONTyped(json, false);
}

export function EditCompanyPriorityReturnTFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditCompanyPriorityReturnT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function EditCompanyPriorityReturnTToJSON(value?: EditCompanyPriorityReturnT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'company': CompanyToJSON(value.company),
        'error': value.error,
    };
}

/**
* @export
* @enum {string}
*/
export enum EditCompanyPriorityReturnTStatusEnum {
    Ok = 'ok',
    Error = 'error'
}


