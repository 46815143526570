import React from 'react';

import { Spinner } from '@glean/glean-ui.atoms.spinner';
import { Button } from '@glean/glean-ui.molecules.button';

import { ExecutionTable } from 'shared/components/execution-table';
import { Modal, ModalPropT } from 'shared/components/modal';

import {
  ExecutionModalButtonRow,
  ExecutionsModalContent,
  ExecutionsModalLoadingContainer,
  ExecutionsModalTitle,
  ExecutionsModalTopRow,
} from './styles';

export const ExecutionsModal = ({
  title,
  loadingExecutions,
  onClose,
  onExploreClick,
  ...rest
}: {
  title: string;
  loadingExecutions?: boolean;
  onClose: () => void;
  onExploreClick: () => void;
} & Omit<ModalPropT, 'renderContent'>) => {
  return (
    <Modal
      maxWidth={1024}
      withCloseIcon={false}
      {...rest}
      renderContent={() => {
        return (
          <ExecutionsModalContent>
            <ExecutionsModalTopRow>
              <ExecutionsModalTitle> {title} </ExecutionsModalTitle>
              <Button icon="close" onClick={onClose} variant="unstyled" />
            </ExecutionsModalTopRow>
            {loadingExecutions ? (
              <ExecutionsModalLoadingContainer>
                <Spinner />
              </ExecutionsModalLoadingContainer>
            ) : (
              <ExecutionTable />
            )}
            <ExecutionModalButtonRow bottom>
              <Button onClick={onExploreClick}> Explore </Button>
            </ExecutionModalButtonRow>
          </ExecutionsModalContent>
        );
      }}
    />
  );
};
