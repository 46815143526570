import styled from 'styled-components';

import { padding, sizes } from 'shared/styles';

export const Container = styled.div`
  padding: ${sizes.navbarHeight}px 0 0 340px;
`;
Container.displayName = 'Container';

export const LineItems = styled.div`
  margin: 0 auto;
  max-width: 800px;
  min-width: 500px;
  padding: ${padding.xlrg} ${padding.lrg};
  width: 100%;
`;
LineItems.displayName = 'LineItems';

export const RecommendationsLoading = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 6px;
  bottom: 0;
  display: flex;
  padding: ${padding.med};
  right: 0;

  > * {
    margin: 6px;
  }
`;
RecommendationsLoading.displayName = 'RecommendationsLoading';
