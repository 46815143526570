import { useState } from 'react';

import { sortCollection } from 'shared/utils/javascript';

export type SortDir = 'ASC' | 'DESC' | '';

export type SortByDirection = {
  sortBy: string;
  sortDirection: SortDir;
};

export type SortProps = SortByDirection & {
  defaultSort?: SortByDirection;
  onChange: (sort: SortByDirection) => void;
};

export const useSort = <T extends any>({
  items,
  fieldSorters = {},
  disabled,
  defaultSort = { sortBy: '', sortDirection: '' },
}: {
  items: T[];
  fieldSorters?: {
    [key: string]: (items: T[], sortDirection: SortDir) => T[];
  };
  disabled?: boolean;
  defaultSort?: SortByDirection;
}) => {
  const [{ sortBy, sortDirection }, setSort] = useState(defaultSort);

  let sortedItems = [...items];

  if (!disabled) {
    const sortByToUse = sortBy || defaultSort.sortBy;
    const sortDirectionToUse = sortDirection || defaultSort.sortDirection;

    if (fieldSorters[sortByToUse]) {
      sortedItems = fieldSorters[sortByToUse](sortedItems, sortDirectionToUse);
    } else if (sortByToUse) {
      sortedItems = sortCollection(sortedItems, sortByToUse, sortDirectionToUse);
    }
  }

  const sort: SortProps = {
    sortBy,
    sortDirection,
    defaultSort,
    onChange: setSort,
  };

  return { sort, sortedItems };
};
