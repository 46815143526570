/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineItemStpWithParsedModelOutputAttributeTypeMap
 */
export interface LineItemStpWithParsedModelOutputAttributeTypeMap {
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutputAttributeTypeMap
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutputAttributeTypeMap
     */
    baseName?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutputAttributeTypeMap
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemStpWithParsedModelOutputAttributeTypeMap
     */
    format?: string;
}

export function LineItemStpWithParsedModelOutputAttributeTypeMapFromJSON(json: any): LineItemStpWithParsedModelOutputAttributeTypeMap {
    return LineItemStpWithParsedModelOutputAttributeTypeMapFromJSONTyped(json, false);
}

export function LineItemStpWithParsedModelOutputAttributeTypeMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItemStpWithParsedModelOutputAttributeTypeMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'baseName': !exists(json, 'baseName') ? undefined : json['baseName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'format': !exists(json, 'format') ? undefined : json['format'],
    };
}

export function LineItemStpWithParsedModelOutputAttributeTypeMapToJSON(value?: LineItemStpWithParsedModelOutputAttributeTypeMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'baseName': value.baseName,
        'type': value.type,
        'format': value.format,
    };
}


