import React, { forwardRef } from 'react';

import { IconContainer, StyledTextarea, Wrapper } from './styles';

type Props = {
  value?: string;
  invalid?: boolean;
  iconEnd?: React.ReactNode;
  onChange?: (...args: any[]) => any;
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ invalid, onChange = () => {}, iconEnd, ...inputProps }, ref) => {
    return (
      <Wrapper>
        <StyledTextarea
          {...inputProps}
          invalid={invalid}
          onChange={(event) => onChange(event.target.value, event)}
          ref={ref}
        />
        <IconContainer>{iconEnd}</IconContainer>
      </Wrapper>
    );
  },
);

Textarea.displayName = 'Textarea';
