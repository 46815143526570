/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanonicalVendorDetailT
 */
export interface CanonicalVendorDetailT {
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    legal_name: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    email_address?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    sector?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    industry_group?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    sub_industry?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CanonicalVendorDetailT
     */
    founded_year?: number;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    location_clearbit?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    zip_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    street_address_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    street_address_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    canonical_vendor_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDetailT
     */
    stp_level: CanonicalVendorDetailTStpLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalVendorDetailT
     */
    is_staging?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CanonicalVendorDetailT
     */
    canonical_line_item_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CanonicalVendorDetailT
     */
    invoice_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CanonicalVendorDetailT
     */
    company_count?: number;
}

export function CanonicalVendorDetailTFromJSON(json: any): CanonicalVendorDetailT {
    return CanonicalVendorDetailTFromJSONTyped(json, false);
}

export function CanonicalVendorDetailTFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalVendorDetailT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display_name': json['display_name'],
        'legal_name': json['legal_name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'industry_group': !exists(json, 'industry_group') ? undefined : json['industry_group'],
        'sub_industry': !exists(json, 'sub_industry') ? undefined : json['sub_industry'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'founded_year': !exists(json, 'founded_year') ? undefined : json['founded_year'],
        'location_clearbit': !exists(json, 'location_clearbit') ? undefined : json['location_clearbit'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zip_code': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street_address_1': !exists(json, 'street_address_1') ? undefined : json['street_address_1'],
        'street_address_2': !exists(json, 'street_address_2') ? undefined : json['street_address_2'],
        'canonical_vendor_id': !exists(json, 'canonical_vendor_id') ? undefined : json['canonical_vendor_id'],
        'stp_level': json['stp_level'],
        'is_staging': !exists(json, 'is_staging') ? undefined : json['is_staging'],
        'canonical_line_item_count': !exists(json, 'canonical_line_item_count') ? undefined : json['canonical_line_item_count'],
        'invoice_count': !exists(json, 'invoice_count') ? undefined : json['invoice_count'],
        'company_count': !exists(json, 'company_count') ? undefined : json['company_count'],
    };
}

export function CanonicalVendorDetailTToJSON(value?: CanonicalVendorDetailT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_name': value.display_name,
        'legal_name': value.legal_name,
        'domain': value.domain,
        'phone_number': value.phone_number,
        'email_address': value.email_address,
        'sector': value.sector,
        'industry': value.industry,
        'industry_group': value.industry_group,
        'sub_industry': value.sub_industry,
        'description': value.description,
        'founded_year': value.founded_year,
        'location_clearbit': value.location_clearbit,
        'logo': value.logo,
        'country': value.country,
        'zip_code': value.zip_code,
        'state': value.state,
        'city': value.city,
        'street_address_1': value.street_address_1,
        'street_address_2': value.street_address_2,
        'canonical_vendor_id': value.canonical_vendor_id,
        'stp_level': value.stp_level,
        'is_staging': value.is_staging,
        'canonical_line_item_count': value.canonical_line_item_count,
        'invoice_count': value.invoice_count,
        'company_count': value.company_count,
    };
}

/**
* @export
* @enum {string}
*/
export enum CanonicalVendorDetailTStpLevelEnum {
    DEFAULT = 'DEFAULT',
    HEADER = 'HEADER',
    DETAIL = 'DETAIL'
}


