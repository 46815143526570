import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { PaginationProps } from 'shared/hooks/pagination';

import { Container, Text } from './styles';

export const PaginationControls = ({ currentPage, pageCount, onChange }: PaginationProps) => (
  <Container>
    <Text>{pageCount > 0 ? `${currentPage} of ${pageCount}` : 'No Results'}</Text>
    <Button
      icon="arrowLeft"
      color="background6"
      disabled={currentPage === 1}
      onClick={() => onChange(currentPage - 1)}
      variant="outlined"
    />
    <Button
      trailingIcon="arrowRight"
      color="background6"
      style={{ marginLeft: 10 }}
      disabled={currentPage >= pageCount}
      onClick={() => onChange(currentPage + 1)}
      variant="outlined"
    />
  </Container>
);
