import React, { useEffect, useRef } from 'react';

import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';

import { Spinner } from '@glean/glean-ui.atoms.spinner';

import * as api from 'shared/api';
import { PageLoader } from 'shared/components/page-loader';
import { toast } from 'shared/components/toast';
import { invoicePDFCDNHost } from 'shared/constants';
import { scrollToElement } from 'shared/utils/dom';
import { fuzzyListSearch } from 'shared/utils/javascript';

import { JobProps } from '..';
import { LineItemStepStateT, LocalCLIState, OriginLineItemT } from '../utils';
import { LineItem } from './line-item';
import { Sidebar } from './sidebar';
import { Container, LineItems, RecommendationsLoading } from './styles';

export const MapLineItem = ({
  data,
  canonicalVendorId,
  lineItemsStepState,
  setLineItemsStepState,
  originLineItems,
  isReprocessed,
}: {
  data: JobProps['data'];
  canonicalVendorId: string;
  lineItemsStepState: LineItemStepStateT;
  setLineItemsStepState: (
    newState:
      | Partial<LineItemStepStateT>
      | ((input: LineItemStepStateT) => Partial<LineItemStepStateT>),
  ) => void;
  originLineItems: OriginLineItemT[];
  isReprocessed: boolean;
}) => {
  const { job, invoiceData } = data;
  const {
    mappedClis,
    areMappedClisLoaded,
    vendorClis,
    areVendorClisLoaded,
    areRecommendationsLoaded,
  } = lineItemsStepState;

  const areVendorClisWaitingToBeLoaded = canonicalVendorId && !areVendorClisLoaded;
  const mappedClisRef = useRef(mappedClis);
  const initialNumberOfLineItemsToFetch = 5;
  const setMappedClis = (newMappedClis: any) => {
    setLineItemsStepState({ mappedClis: newMappedClis, areMappedClisLoaded: true });
  };
  const addToVendorClis = (newVendorClis: any) => {
    setLineItemsStepState((state: any) => ({
      vendorClis: uniqBy([...newVendorClis, ...state.vendorClis], 'canonical_line_item_id'),
      areVendorClisLoaded: true,
    }));
  };

  useQuery({
    queryKey: ['searchCanonicalVendors', canonicalVendorId, 'lineItems'],
    queryFn: async () => {
      if (canonicalVendorId) {
        return api.getCanonicalLineItemsForCanonicalVendorID({ canonicalVendorId });
      } else {
        return {
          data: {
            canonical_line_items: [],
          },
        };
      }
    },
    enabled: !areVendorClisLoaded,
    onSuccess: (res: any) => {
      const clis = res.data.canonical_line_items?.map((cli: any) => ({
        files: [],
        names: [],
        ...cli,
      }));
      addToVendorClis(clis);
    },
    onError: () => {
      addToVendorClis([]);
      toast.danger('There was a problem retrieving line item data.');
    },
  });

  useEffect(() => {
    mappedClisRef.current = mappedClis;
  });
  // The canonical line item recommendation endpoint gets bottlenecked
  // easily. As a stopgap, we are only querying for recommendations for the
  // first n originLineItems (where n = initialNumberOfLineItemsToFetch).
  // All subsequent recommendations are loaded by calling fetchRemainingLineItems
  // which will kickoff as soon as the initial recommendations are loaded.
  // subsequent recommendations resolve sequentially, until all recommendations
  // have been fetched.
  const fetchRemainingLineItems = async (originLineItems: any) => {
    let index = initialNumberOfLineItemsToFetch;
    while (index < originLineItems.length) {
      const oli = originLineItems[index];
      const cliRes = await api.recommendCanonicalLineItem({
        canonicalVendorId,
        originLineItemName: oli.name,
        originLineItemDescription: oli.description,
      });
      let cli;
      if ((cliRes as any).data?.match) {
        cli = (cliRes as any).data.canonical_line_items[0];
      } else {
        const [bestMatch] = fuzzyListSearch(vendorClis, oli.name || '', { keys: ['name'] });
        cli = bestMatch?.item || null;
      }
      let previouslyMappedClis = [...mappedClisRef.current];
      previouslyMappedClis[index] = cli;
      setMappedClis(previouslyMappedClis);
      index++;
    }
    setLineItemsStepState({ areRecommendationsLoaded: true });
  };
  useEffect(() => {
    if (areMappedClisLoaded || areVendorClisWaitingToBeLoaded) return;
    const areClisMapped =
      Object.entries(invoiceData?.canonical_line_items_map?.canonical_line_items || {}).filter(
        ([key, value]) => !!value,
      ).length > 0;

    if (!areClisMapped) {
      if (canonicalVendorId) {
        Promise.allSettled(
          originLineItems?.slice(0, initialNumberOfLineItemsToFetch).map((oli) =>
            api.recommendCanonicalLineItem({
              canonicalVendorId,
              originLineItemName: oli.name,
              originLineItemDescription: oli.description,
            }),
          ) || [],
        ).then((responses) => {
          setMappedClis(
            responses.map((oli: any, idx: any) => {
              if ((responses[idx] as any).value.data?.match) {
                return (responses[idx] as any).value.data.canonical_line_items[0] || null;
              } else {
                const [bestMatch] = fuzzyListSearch(vendorClis, oli.name || '', { keys: ['name'] });
                return bestMatch?.item || null;
              }
            }),
          );
          return fetchRemainingLineItems(originLineItems);
        });
      } else {
        setMappedClis(
          originLineItems?.map((oli) => {
            const [bestMatch] = fuzzyListSearch(vendorClis, oli.name || '', { keys: ['name'] });
            return bestMatch?.item || null;
          }),
        );
        setLineItemsStepState({ areRecommendationsLoaded: true });
      }
    } else {
      const clisToMap = originLineItems?.map((oli) => {
        const mappedCliId =
          invoiceData.canonical_line_items_map?.canonical_line_items?.[oli.line_item_id];
        const existingCli = vendorClis.find(
          ({ canonical_line_item_id }: any) => canonical_line_item_id === mappedCliId,
        );
        let createdCli: LocalCLIState | undefined = invoiceData.canonical_line_items?.find(
          (item: any) => item.canonical_line_item_id === mappedCliId,
        );
        if (createdCli) {
          createdCli = {
            ...createdCli,
            isNew: true,
            canonical_line_item_id: createdCli.canonical_line_item_id,
            files: [`${invoicePDFCDNHost}/${job.fileKey}`],
          };
        }
        return existingCli || createdCli || null;
      });
      setMappedClis(clisToMap);
      addToVendorClis(clisToMap?.filter((cli) => cli?.isNew));
      setLineItemsStepState({ areRecommendationsLoaded: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areVendorClisWaitingToBeLoaded]);

  const firstNonShadedLi = originLineItems.find((lineItem) => !lineItem.isShaded);
  firstNonShadedLi && scrollToElement(`proof-line-item-${firstNonShadedLi.line_item_id}`);

  if (!areMappedClisLoaded) return <PageLoader message="Loading line item data..." />;
  return (
    <Container>
      <Sidebar originLineItems={originLineItems} />

      <LineItems>
        {originLineItems?.map((oli, index) => (
          <LineItem
            key={oli.line_item_id}
            isShaded={oli.isShaded}
            isReprocessed={isReprocessed}
            index={index}
            data={data}
            vendorClis={vendorClis}
            addToVendorClis={addToVendorClis}
            cli={mappedClis[index]}
            oli={oli}
            mappedClis={mappedClis}
            setMappedClis={setMappedClis}
          />
        ))}
      </LineItems>
      {!areRecommendationsLoaded && (
        <RecommendationsLoading>
          Loading Recommendations...
          <Spinner />
        </RecommendationsLoading>
      )}
    </Container>
  );
};
