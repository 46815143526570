import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { NavBar } from 'shared/components/nav-bar';
import { StatusBadge } from 'shared/components/status-badge/styles';

export const MapCurateNavBar = ({
  commentAdded,
  commentsData,
  isDeescalating,
  fetchJob,
  isSaving,
  isSubmitting,
  job,
  isSubmitEnabled,
  handleEscalate,
  handleFinalSubmit,
  handleSave,
  setCommentsData,
}: {
  commentAdded: boolean;
  commentsData: {
    hasComments: boolean;
    commentsOpen: boolean | null;
  };
  fetchJob: () => Promise<void>;
  isDeescalating: boolean;
  isSaving: boolean;
  isSubmitting: boolean;
  job: unknown;
  isSubmitEnabled: boolean;
  handleEscalate: () => Promise<void>;
  handleFinalSubmit: () => Promise<void>;
  handleSave: () => Promise<void>;
  setCommentsData: React.Dispatch<
    React.SetStateAction<{
      hasComments: boolean;
      commentsOpen: boolean | null;
    }>
  >;
}) => (
  <NavBar
    content={
      <>
        <NavBar.GridLeft>
          <NavBar.ExitJob job={job} />

          {isDeescalating && <StatusBadge backgroundColor="danger">Escalated</StatusBadge>}
        </NavBar.GridLeft>
        <NavBar.Heading>Vendor Request Map</NavBar.Heading>
        <NavBar.GridRight>
          <NavBar.IconBtnGroup>
            <NavBar.Comments
              className={commentsData.commentsOpen ? 'selected' : ''}
              onClick={() =>
                setCommentsData({ ...commentsData, commentsOpen: !commentsData.commentsOpen })
              }
            />
            <NavBar.Help />
          </NavBar.IconBtnGroup>
          <NavBar.TextBtnGroup>
            <NavBar.SaveJob onSave={handleSave} isWorking={isSaving} />
            {!isDeescalating && <NavBar.EscalateJob onEscalate={handleEscalate} job={job} />}
            <NavBar.ViewExecution arn={(job as any).executionArn} />
            {isDeescalating ? (
              <NavBar.DeescalateJob
                onDeescalated={fetchJob}
                job={job}
                commentAdded={commentAdded}
              />
            ) : (
              <>
                <Button
                  disabled={!isSubmitEnabled}
                  isWorking={isSubmitting}
                  onClick={handleFinalSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </NavBar.TextBtnGroup>
        </NavBar.GridRight>
      </>
    }
  />
);
