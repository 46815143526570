import React, { useState } from 'react';

import { abortManageExecution } from 'shared/api';
import { Modal } from 'shared/components/modal';
import { ModalControls } from 'shared/components/modal-controls';
import { toast } from 'shared/components/toast';

import { ExecutionModalContainer, ExecutionModalTitle } from './styles';

export const AbortModal = ({
  executionsToAbort,
  onComplete = () => {},
  onClose = () => {},
  ...rest
}: any) => {
  const [requesting, setRequesting] = useState(false);
  return (
    <Modal
      onClose={() => {
        !requesting && onClose();
      }}
      {...rest}
      renderContent={(modal) => (
        <ExecutionModalContainer>
          <ExecutionModalTitle>
            Are you sure you want to abort{' '}
            {executionsToAbort.length > 1
              ? `these ${executionsToAbort.length} executions?`
              : 'this execution?'}
          </ExecutionModalTitle>

          <ModalControls.Container>
            <ModalControls.CancelButton onClick={modal.close} disabled={requesting}>
              Cancel
            </ModalControls.CancelButton>
            <ModalControls.ConfirmButton
              isWorking={requesting}
              onClick={() => {
                setRequesting(true);
                Promise.allSettled(executionsToAbort.map((arn: any) => abortManageExecution(arn)))
                  .then((results) => {
                    const fulfilled = results.filter(
                      (res): res is PromiseFulfilledResult<any> => res.status === 'fulfilled',
                    );
                    const rejected = results.filter(
                      (res): res is PromiseRejectedResult => res.status === 'rejected',
                    );
                    if (fulfilled.length === results.length) {
                      toast.success(`${executionsToAbort.length} executions stopped.`);
                    } else {
                      const message =
                        rejected.length === 1
                          ? '1 execution was not stopped.'
                          : `${rejected.length} executions were not stopped.`;
                      toast.danger(message);
                      for (let r of rejected) {
                        toast.danger(r.reason.response.data.error);
                      }
                    }
                    setRequesting(false);
                    modal.close();
                    onComplete();
                  })
                  .catch((e) => {
                    toast.danger(e.message);
                    modal.close();
                  });
              }}
            >
              Abort
            </ModalControls.ConfirmButton>
          </ModalControls.Container>
        </ExecutionModalContainer>
      )}
    ></Modal>
  );
};
