import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { Modal } from 'shared/components/modal';

import { ExtractValidateStateT } from '../state';
import { Bottom, Inner } from './styles';

export const ErrorsModal = ({
  errors,
  onClose,
  currentStep,
}: {
  errors?: ExtractValidateStateT['errors'];
  onClose: () => void;
  currentStep: ExtractValidateStateT['currentStep'];
}) => {
  //@ts-expect-error
  const currentErrors: string[] = errors?.errors?.[currentStep] || [];
  return (
    <Modal
      isOpen
      onClose={onClose}
      renderContent={(modal) => (
        <Inner>
          <h3>Incomplete Information</h3>
          <p>In order to submit this job, please fill in data for the following sections:</p>
          <ul>
            {currentErrors.map((error, idx) => (
              <li key={`error-${idx}`}>{error}</li>
            ))}
          </ul>

          <Bottom>
            <Button onClick={modal.close}>OK</Button>
          </Bottom>
        </Inner>
      )}
    />
  );
};
