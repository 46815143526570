import React from 'react';

import { scrollToElement } from 'shared/utils/dom';

import { OriginLineItemT } from '../../utils';
import { Heading, LineItem, List, StyledSidebar } from './styles';

export const Sidebar = ({ originLineItems }: { originLineItems: OriginLineItemT[] }) => (
  <StyledSidebar>
    <Heading>Line Items ({originLineItems.length})</Heading>

    <List>
      {originLineItems.map((oli, index) => (
        <LineItem
          key={oli.line_item_id}
          isShaded={oli.isShaded}
          onClick={() => scrollToElement(`proof-line-item-${oli.line_item_id}`)}
        >
          <div>{index + 1}</div>
          <div>{oli.name}</div>
        </LineItem>
      ))}
    </List>
  </StyledSidebar>
);
