export const calcPopoverPosition = (
  popoverRef: any,
  linkRef: any,
  placement: 'top' | 'right' | 'bottom' | 'left',
  maxHeight: any,
) => {
  const margin = 20;

  const popoverRect = popoverRef.current.getBoundingClientRect();
  const linkRect = linkRef.current.getBoundingClientRect();

  let popoverHeight = popoverRect.height;

  if (popoverRect.height > window.innerHeight - 10) {
    popoverHeight = window.innerHeight - 10;
  } else if (maxHeight && popoverHeight > maxHeight) {
    popoverHeight = maxHeight;
  }

  const linkCenterY = linkRect.top + linkRect.height / 2;
  const linkCenterX = linkRect.left + linkRect.width / 2;

  const placements = {
    top: {
      top: linkRect.top - margin - popoverRect.height,
      left: linkCenterX - popoverRect.width / 2,
    },
    right: {
      top: linkCenterY - popoverRect.height / 2,
      left: linkRect.right + margin,
    },
    bottom: {
      top: linkRect.bottom + margin,
      left: linkCenterX - popoverRect.width / 2,
    },
    left: {
      top: linkCenterY - popoverRect.height / 2,
      left: linkRect.left - margin - popoverRect.width,
    },
  };

  return restrainPositionToWindowBounds(popoverRect.width, popoverHeight, {
    top: placements[placement].top,
    left: placements[placement].left,
  });
};

const restrainPositionToWindowBounds = (popoverWidth: any, popoverHeight: any, position: any) => {
  const boundPosition = { ...position };

  if (position.top < 0) {
    boundPosition.top = 15;
  }
  if (position.left + popoverWidth + 25 > window.innerWidth) {
    boundPosition.left = window.innerWidth - popoverWidth - 25;
  }
  if (position.top + popoverHeight + 0 > window.innerHeight) {
    boundPosition.top = window.innerHeight - popoverHeight - 0;
  }
  if (position.left < 15) {
    boundPosition.left = 15;
  }
  return { ...boundPosition, height: popoverHeight };
};
