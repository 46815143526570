/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanonicalVendorDTOInvoices
 */
export interface CanonicalVendorDTOInvoices {
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDTOInvoices
     */
    file_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDTOInvoices
     */
    invoice_number?: string;
}

export function CanonicalVendorDTOInvoicesFromJSON(json: any): CanonicalVendorDTOInvoices {
    return CanonicalVendorDTOInvoicesFromJSONTyped(json, false);
}

export function CanonicalVendorDTOInvoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalVendorDTOInvoices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file_url': !exists(json, 'file_url') ? undefined : json['file_url'],
        'invoice_number': !exists(json, 'invoice_number') ? undefined : json['invoice_number'],
    };
}

export function CanonicalVendorDTOInvoicesToJSON(value?: CanonicalVendorDTOInvoices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file_url': value.file_url,
        'invoice_number': value.invoice_number,
    };
}


