import styled from 'styled-components';

import { font, mixin, padding } from 'shared/styles';

export const Container = styled.div`
  padding-top: ${padding.lrg};
`;

export const NewCliName = styled.p`
  text-align: center;
  ${font.size(14)};
  padding: ${padding.med} 0;
`;

export const Header = styled.p`
  ${font.size(16)};
  ${font.medium};
  padding-bottom: ${padding.sm};
`;

export const StyledRow = styled.li`
  ${font.size(14)};
  ${mixin.clickable};
  padding: ${padding.sm};

  &:hover {
    ${font.bold}
  }
`;
