import styled from 'styled-components';

import { color, font } from 'shared/styles';

export const ExecutionsModalLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 632px;
`;
export const ExecutionsModalContent = styled.div``;
export const ExecutionsModalTitle = styled.h1`
  ${font.size(20)};
  ${font.medium};
`;
export const ExecutionsModalTopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px ${32 - 25}px 24px;
`;
export const ExecutionModalButtonRow = styled.div<{ bottom?: boolean }>`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${color.border2};
  padding: 32px 24px;

  ${(props) => props.bottom && 'padding: 16px 24px;'}
`;
