import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { useEditCompanyPriority, useGetCompanies, useGetCompanyPriorities } from 'shared/api';
import { Form } from 'shared/components/form';
import { Modal } from 'shared/components/modal';
import { ModalControls } from 'shared/components/modal-controls';
import { PageLoader } from 'shared/components/page-loader';
import { Cell, Row, Table } from 'shared/components/table';
import { useTable } from 'shared/hooks/table';

import { ModalContainer } from './styles';

export const CompanyPriority = () => {
  const {
    isFetching: isLoadingPriorities,
    data: priorities,
    refetch: fetchPriorities,
  } = useGetCompanyPriorities();

  const { isLoading: isLoadingCompanies, data: companiesData } = useGetCompanies();

  const companiesWithPriority = companiesData?.map((company) => {
    const priority = priorities?.companies?.filter(
      ({ companyId }: any) => company.id === companyId,
    )[0]?.priority;
    return {
      ...company,
      priority,
    };
  });

  const { tableItems, sort, search, pagination } = useTable({
    items: companiesWithPriority || [],
    search: {
      indexFields: ['display_name'],
      entityLabel: 'Company',
    },
    pagination: {
      itemsPerPage: 20,
    },
    sort: {
      fieldSorters: {
        company: (items: any, direction: any) => {
          return items.sort((a: any, b: any) => {
            const nameA = a.display_name;
            const nameB = b.display_name;
            if (direction === 'ASC') {
              return nameA.localeCompare(nameB);
            }
            return nameB.localeCompare(nameA);
          });
        },
        priority: (items: any, direction: any) => {
          return items.sort((a: any, b: any) => {
            const priorityA = a.priority || 1000;
            const priorityB = b.priority || 1000;
            if (direction === 'ASC') {
              return priorityA - priorityB;
            }
            return priorityB - priorityA;
          });
        },
      },
      defaultSort: { sortBy: 'priority', sortDirection: 'ASC' },
    },
  });
  if (isLoadingPriorities || isLoadingCompanies) return <PageLoader message="Loading..." />;
  return (
    <>
      <Table
        pagination={pagination}
        search={search}
        columns={[
          { name: 'Company', sortKey: 'company', width: '30%' },
          { name: 'Priority', sortKey: 'priority', width: '30%' },
          { width: '75px' },
        ]}
        sort={sort}
        body={tableItems.map(({ display_name: displayName, id: companyId, priority }) => (
          <Row key={companyId}>
            <Cell>{displayName}</Cell>
            <Cell>{priority ? priority : <span>Processed last</span>}</Cell>
            <Cell>
              <EditCompanyPriorityModal
                companyId={companyId}
                priority={priority}
                companyName={displayName}
                refetch={fetchPriorities}
              />
            </Cell>
          </Row>
        ))}
      />
    </>
  );
};

type EditCompanyPriorityModalProps = {
  companyId?: string;
  priority?: number;
  companyName?: string;
  refetch: () => Promise<unknown>;
};

const EditCompanyPriorityModal = ({
  companyId,
  priority,
  companyName,
  refetch,
}: EditCompanyPriorityModalProps) => {
  const { mutate: fireMutation } = useEditCompanyPriority();
  return (
    <Modal
      renderContent={({ close }) => (
        <Form
          initialValues={{
            priority,
            companyId,
          }}
          onSubmit={async ({ priority, companyId }: any) => {
            await fireMutation({ companyId, priority });
            await refetch();
            close();
          }}
        >
          {({ isSubmitting, submitForm }: any) => (
            <ModalContainer>
              <h2>Edit queue priority</h2>
              {companyName}
              <Form.Field.Input type="number" name="priority" label="Processing Priority" />
              <ModalControls.Container>
                <div></div>
                <div>
                  <ModalControls.CancelButton onClick={close}>Cancel</ModalControls.CancelButton>
                  <ModalControls.ConfirmButton onClick={submitForm} isWorking={isSubmitting}>
                    Edit
                  </ModalControls.ConfirmButton>
                </div>
              </ModalControls.Container>
            </ModalContainer>
          )}
        </Form>
      )}
      renderHandle={() => <Button icon="edit" variant="outlined" />}
    />
  );
};
