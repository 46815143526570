import styled from 'styled-components';

import { color } from 'shared/styles';

export const SmallIndicatorDot = styled.span`
  background-color: ${color.danger};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: block;
`;
