import styled from 'styled-components';

import { color, font, mixin } from 'shared/styles';

export const Results = styled.div`
  padding: 12px 0;
`;

export const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  ${mixin.clickable}
  &:hover {
    background: ${color.primary};
  }
`;

export const VendorName = styled.div`
  padding-left: 12px;
  ${font.size(14)}
`;

export const NoResults = styled.p`
  color: ${color.text2};
  ${font.size(14)}
`;
