/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRollupT,
    DateRollupTFromJSON,
    DateRollupTFromJSONTyped,
    DateRollupTToJSON,
    RollupT,
    RollupTFromJSON,
    RollupTFromJSONTyped,
    RollupTToJSON,
} from './';

/**
 * 
 * @export
 * @interface PipelineRollupsT
 */
export interface PipelineRollupsT {
    /**
     * 
     * @type {Array<RollupT>}
     * @memberof PipelineRollupsT
     */
    activeExecutionStatus: Array<RollupT>;
    /**
     * 
     * @type {Array<RollupT>}
     * @memberof PipelineRollupsT
     */
    executionCountByCompanyId: Array<RollupT>;
    /**
     * 
     * @type {Array<RollupT>}
     * @memberof PipelineRollupsT
     */
    failureSteps: Array<RollupT>;
    /**
     * 
     * @type {Array<DateRollupT>}
     * @memberof PipelineRollupsT
     */
    succeededByDay: Array<DateRollupT>;
    /**
     * 
     * @type {Array<DateRollupT>}
     * @memberof PipelineRollupsT
     */
    failedByDay: Array<DateRollupT>;
    /**
     * 
     * @type {Array<DateRollupT>}
     * @memberof PipelineRollupsT
     */
    abortedByDay: Array<DateRollupT>;
    /**
     * 
     * @type {Array<DateRollupT>}
     * @memberof PipelineRollupsT
     */
    restartedByDay: Array<DateRollupT>;
}

export function PipelineRollupsTFromJSON(json: any): PipelineRollupsT {
    return PipelineRollupsTFromJSONTyped(json, false);
}

export function PipelineRollupsTFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineRollupsT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeExecutionStatus': ((json['activeExecutionStatus'] as Array<any>).map(RollupTFromJSON)),
        'executionCountByCompanyId': ((json['executionCountByCompanyId'] as Array<any>).map(RollupTFromJSON)),
        'failureSteps': ((json['failureSteps'] as Array<any>).map(RollupTFromJSON)),
        'succeededByDay': ((json['succeededByDay'] as Array<any>).map(DateRollupTFromJSON)),
        'failedByDay': ((json['failedByDay'] as Array<any>).map(DateRollupTFromJSON)),
        'abortedByDay': ((json['abortedByDay'] as Array<any>).map(DateRollupTFromJSON)),
        'restartedByDay': ((json['restartedByDay'] as Array<any>).map(DateRollupTFromJSON)),
    };
}

export function PipelineRollupsTToJSON(value?: PipelineRollupsT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeExecutionStatus': ((value.activeExecutionStatus as Array<any>).map(RollupTToJSON)),
        'executionCountByCompanyId': ((value.executionCountByCompanyId as Array<any>).map(RollupTToJSON)),
        'failureSteps': ((value.failureSteps as Array<any>).map(RollupTToJSON)),
        'succeededByDay': ((value.succeededByDay as Array<any>).map(DateRollupTToJSON)),
        'failedByDay': ((value.failedByDay as Array<any>).map(DateRollupTToJSON)),
        'abortedByDay': ((value.abortedByDay as Array<any>).map(DateRollupTToJSON)),
        'restartedByDay': ((value.restartedByDay as Array<any>).map(DateRollupTToJSON)),
    };
}


