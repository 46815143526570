/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CanonicalVendorDTOInvoices,
    CanonicalVendorDTOInvoicesFromJSON,
    CanonicalVendorDTOInvoicesFromJSONTyped,
    CanonicalVendorDTOInvoicesToJSON,
} from './';

/**
 * 
 * @export
 * @interface CanonicalVendorDTO
 */
export interface CanonicalVendorDTO {
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDTO
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDTO
     */
    legal_name: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalVendorDTO
     */
    stp_level: CanonicalVendorDTOStpLevelEnum;
    /**
     * 
     * @type {Array<CanonicalVendorDTOInvoices>}
     * @memberof CanonicalVendorDTO
     */
    invoices: Array<CanonicalVendorDTOInvoices>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanonicalVendorDTO
     */
    line_items: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    domain?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    phone_number?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    email_address?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    sector?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    industry?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    industry_group?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    sub_industry?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    description?: object;
    /**
     * 
     * @type {number}
     * @memberof CanonicalVendorDTO
     */
    founded_year?: number;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    location_clearbit?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    logo?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    country?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    zip_code?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    state?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    city?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    street_address_1?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    street_address_2?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    canonical_vendor_id?: object;
    /**
     * 
     * @type {object}
     * @memberof CanonicalVendorDTO
     */
    is_staging?: object;
}

export function CanonicalVendorDTOFromJSON(json: any): CanonicalVendorDTO {
    return CanonicalVendorDTOFromJSONTyped(json, false);
}

export function CanonicalVendorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalVendorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display_name': json['display_name'],
        'legal_name': json['legal_name'],
        'stp_level': json['stp_level'],
        'invoices': ((json['invoices'] as Array<any>).map(CanonicalVendorDTOInvoicesFromJSON)),
        'line_items': json['line_items'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'industry_group': !exists(json, 'industry_group') ? undefined : json['industry_group'],
        'sub_industry': !exists(json, 'sub_industry') ? undefined : json['sub_industry'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'founded_year': !exists(json, 'founded_year') ? undefined : json['founded_year'],
        'location_clearbit': !exists(json, 'location_clearbit') ? undefined : json['location_clearbit'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zip_code': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street_address_1': !exists(json, 'street_address_1') ? undefined : json['street_address_1'],
        'street_address_2': !exists(json, 'street_address_2') ? undefined : json['street_address_2'],
        'canonical_vendor_id': !exists(json, 'canonical_vendor_id') ? undefined : json['canonical_vendor_id'],
        'is_staging': !exists(json, 'is_staging') ? undefined : json['is_staging'],
    };
}

export function CanonicalVendorDTOToJSON(value?: CanonicalVendorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_name': value.display_name,
        'legal_name': value.legal_name,
        'stp_level': value.stp_level,
        'invoices': ((value.invoices as Array<any>).map(CanonicalVendorDTOInvoicesToJSON)),
        'line_items': value.line_items,
        'domain': value.domain,
        'phone_number': value.phone_number,
        'email_address': value.email_address,
        'sector': value.sector,
        'industry': value.industry,
        'industry_group': value.industry_group,
        'sub_industry': value.sub_industry,
        'description': value.description,
        'founded_year': value.founded_year,
        'location_clearbit': value.location_clearbit,
        'logo': value.logo,
        'country': value.country,
        'zip_code': value.zip_code,
        'state': value.state,
        'city': value.city,
        'street_address_1': value.street_address_1,
        'street_address_2': value.street_address_2,
        'canonical_vendor_id': value.canonical_vendor_id,
        'is_staging': value.is_staging,
    };
}

/**
* @export
* @enum {string}
*/
export enum CanonicalVendorDTOStpLevelEnum {
    DEFAULT = 'DEFAULT',
    HEADER = 'HEADER',
    DETAIL = 'DETAIL'
}


