import axios, { AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import {
  QueryClient,
  useMutation as reactUseMutation,
  useQuery as reactUseQuery,
} from 'react-query';

import { getAuth } from 'shared/auth';
import { parseDomainFromUrl } from 'shared/utils/url';

import { CanonicalLineItemDTO, CanonicalVendorDTO, Configuration, DefaultApi } from './generated';

export * from './api-hooks/canonical';
export * from './api-hooks/company';
export * from './api-hooks/executions';
export * from './api-hooks/pipeline-stats';

export const baseApiUrl =
  import.meta.env.VITE_STAGE === 'production'
    ? 'https://9mvukg4qrk.execute-api.us-east-2.amazonaws.com/prod'
    : import.meta.env.VITE_USE_LOCAL_SERVER
    ? 'http://localhost:1235/dev'
    : 'https://hag3lrwsv7.execute-api.us-east-2.amazonaws.com/dev';
const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAuth().accessToken}`,
});

const config = new Configuration({
  basePath: baseApiUrl,
  fetchApi: (url, init) => fetch(url, { ...init, headers: getDefaultHeaders() }),
});
export const apiClient = new DefaultApi(config);

export const queryClient = new QueryClient();

const request = (
  method: AxiosRequestConfig['method'],
  url: AxiosRequestConfig['url'],
  options: Omit<AxiosRequestConfig, 'method' | 'url'> = {},
) =>
  new Promise((resolve, reject) => {
    const { headers, ...axiosOptions } = options;
    axios({
      method,
      baseURL: baseApiUrl,
      url,
      headers: { ...getDefaultHeaders(), ...headers },
      ...axiosOptions,
    }).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
const client = {
  get: (url: AxiosRequestConfig['url'], options: Omit<AxiosRequestConfig, 'method' | 'url'> = {}) =>
    request('get', url, options),
  post: (
    url: AxiosRequestConfig['url'],
    options: Omit<AxiosRequestConfig, 'method' | 'url'> = {},
  ) => request('post', url, options),
  put: (url: AxiosRequestConfig['url'], options: Omit<AxiosRequestConfig, 'method' | 'url'> = {}) =>
    request('put', url, options),
  patch: (
    url: AxiosRequestConfig['url'],
    options: Omit<AxiosRequestConfig, 'method' | 'url'> = {},
  ) => request('patch', url, options),
  delete: (
    url: AxiosRequestConfig['url'],
    options: Omit<AxiosRequestConfig, 'method' | 'url'> = {},
  ) => request('delete', url, options),
};

const useQueryFunc = (queryHook: any) => ({
  queryFn,
  config,
  transformData = (data: any) => data,
  validateData = () => true,
  errorMessage = 'There was a problem fetching your data.',
  ...props
}: any) => {
  const state = queryHook({
    ...props,
    config: { refetchOnWindowFocus: false, retry: 1, ...config },
    queryFn: () =>
      new Promise((resolve, reject) => {
        queryFn().then(
          (data: any) => {
            const validationResult = validateData(data);
            if (!validationResult) {
              return reject({
                error: new Error(
                  `Data did not match provided validation function. Expected a truthy result but received ${JSON.stringify(
                    validationResult,
                  )}`,
                ),
                errorMessage,
              });
            }
            resolve(transformData(data));
          },
          (e: any) => reject({ error: e, errorMessage }),
        );
      }),
  });
  return { ...state, isLoading: state.status === 'loading' };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useQuery = useQueryFunc(reactUseQuery);
export const useMutation = reactUseMutation;

export const createJobCompletion = ({ invoice, job }: any) => {
  return client.post('/job-completions', { data: { invoice, job } });
};
export const createInvoiceSnapshot = ({ invoice, job }: any) => {
  return client.post('/invoice-snapshots', { data: { invoice, job } });
};
export const createJobCancellation = ({ job, reason }: any) => {
  return client.post('/job-cancellations', { data: { job, reason } });
};
export const updateJobExecution = ({ jobId }: any) => {
  return client.patch('/job-executions', { data: { jobId } });
};
export const escalateJob = ({ jobId, reason, commentText }: any) => {
  return client.post('/job-escalations', {
    data: { jobId, reason, commentText, idToken: getAuth().idToken },
  });
};

type GetCommentsT = {
  comments?: {
    commentId: string;
    author: string;
    commentText: string;
    createdAt: string;
    executionArn: string;
    gleanResponse: boolean;
    jobType: string;
  }[];
  escalations?: any[];
  executionArn?: string;
  restartCategory?: null | string;
  restartReason?: null | string;
  startingStep?: string;
  status?: 'string';
};
export const getComments = ({ executionArn }: { executionArn: string }): Promise<GetCommentsT> => {
  return (client.get(`/execution/${executionArn}/comments`) as unknown) as Promise<GetCommentsT>;
};
export const addComment = ({ executionArn, jobType, commentText, deescalating }: any) => {
  if (deescalating) {
    return client.post(`/execution/${executionArn}/deescalate-comments`, {
      data: { jobType, commentText },
    });
  } else {
    return client.post(`/execution/${executionArn}/comments`, { data: { jobType, commentText } });
  }
};
export const getQueuedJob = ({ jobType }: any) => {
  // return mocks.mockMapJobRequest();
  return client.get(`/queuedJob/${jobType}`);
};
export const getQueuedDeescalateJob = () => {
  // return mocks.mockMapJobRequest();
  return client.get(`/escalated-job-from-queue/`);
};
export const getJobById = ({ jobType, jobId }: any) => {
  return client.get(`/jobs/${jobType}/${jobId}`);
};
export const getDeescalateJobById = ({ jobId }: any) => {
  return client.get(`/escalated-job/${jobId}`);
};
export const deescalateJob = ({ jobId }: any) => {
  return client.post(`/job-deescalations/`, { data: { jobId } });
};
export const getCanonicalLineItemsForCanonicalVendorID = async ({ canonicalVendorId }: any) => {
  let lineItems: CanonicalLineItemDTO[] = [];

  let fetchMore = true;
  let offset = 0;
  const limit = 50;
  while (fetchMore) {
    const lineItemsRes = await apiClient.mapServiceGetCanonicalVendorLineItems({
      canonicalVendorId,
      offset,
      limit,
    });
    lineItems = lineItems.concat(lineItemsRes?.canonicalLineItems || []);
    fetchMore = !!lineItemsRes.hasMore;
    offset += limit;
  }

  return { data: { canonical_line_items: lineItems } };
};
export const recommendCanonicalLineItem = async ({
  canonicalVendorId,
  originLineItemName,
  originLineItemDescription,
}: any) => {
  // recommendations returns canonical vendor ids ordered from
  // most likely to least likely.
  const rankedLineItemIdsRes = await client.get(`/recommendation/line-item`, {
    params: { canonicalVendorId, originLineItemName, originLineItemDescription, limit: 5 },
  });
  const rankedLineItemIds: string[] = get(rankedLineItemIdsRes, 'data.lineItemRecommendations', []);
  if (rankedLineItemIds.length === 0) {
    return {
      data: {
        match: false,
        canonical_line_items: [],
      },
    };
  }

  const lineItems = await apiClient.mapServiceGetCanonicalVendorLineItemsById({
    canonicalVendorId,
    lineItemIds: rankedLineItemIds,
  });

  // hub returns line items in an arbitrary order
  // so the response needs to be reordered in
  // terms of the rank that recommendations
  // reported
  const sortedCanonicalLineItems = lineItems.sort((a, b) => {
    return (
      rankedLineItemIds.indexOf(a.canonical_line_item_id!) -
      rankedLineItemIds.indexOf(b.canonical_line_item_id!)
    );
  });
  return {
    data: {
      match: true,
      canonical_line_items: sortedCanonicalLineItems,
    },
  };
};

export const recommendCanonicalVendors = async ({
  originVendorDisplayName,
  originVendorLegalName,
}: any) => {
  // recommendations returns canonical vendor ids ordered from
  // most likely to least likely.
  const rankedVendorIdsRes = await client.get(`/recommendation/vendor`, {
    params: {
      originVendorDisplayName: String(originVendorDisplayName),
      originVendorLegalName: String(originVendorLegalName),
      limit: 5,
    },
  });
  const rankedVendorIds: string[] = get(rankedVendorIdsRes, 'data.vendorRecommendations', []);
  if (rankedVendorIds.length === 0) {
    return {
      data: {
        match: false,
        canonical_vendors: [],
      },
    };
  }
  const vendors = (
    await Promise.allSettled(
      rankedVendorIds.map((canonicalVendorId) =>
        apiClient.mapServiceGetCanonicalVendor({ canonicalVendorId }),
      ),
    )
  )
    .filter(
      (result): result is PromiseFulfilledResult<CanonicalVendorDTO> =>
        result.status === 'fulfilled',
    )
    .map((result) => result.value);

  // hub returns vendors in an arbitrary order
  // so the response needs to be reordered in
  // terms of the rank that recommendations
  // reported
  const sortedVendors = vendors.sort((a: any, b: any) => {
    return (
      rankedVendorIds.indexOf(a.canonical_vendor_id) -
      rankedVendorIds.indexOf(b.canonical_vendor_id)
    );
  });
  return {
    data: {
      match: true,
      canonical_vendors: sortedVendors,
    },
  };
};
export const searchCanonicalVendors = async (searchQuery: string) => {
  // recommendations returns canonical vendor ids ordered from
  // most likely to least likely.
  const rankedVendorIdsRes = await client.get(`/recommendation/vendor`, {
    params: {
      originVendorDisplayName: searchQuery,
      originVendorLegalName: searchQuery,
      limit: 5,
    },
  });

  const rankedVendorIds: string[] = get(rankedVendorIdsRes, 'data.vendorRecommendations', []);
  if (rankedVendorIds.length === 0) {
    return {
      data: {
        match: false,
        canonical_vendors: [],
      },
    };
  }
  const vendors = (
    await Promise.all(
      rankedVendorIds.map(async (canonicalVendorId) => {
        try {
          return await apiClient.mapServiceGetCanonicalVendor({ canonicalVendorId });
        } catch (e) {
          return null;
        }
      }),
    )
  ).filter((vendor) => !!vendor) as CanonicalVendorDTO[];

  // hub returns vendors in an arbitrary order
  // so the response needs to be reordered in
  // terms of the rank that recommendations
  // reported
  const sortedVendors = vendors.sort((a: any, b: any) => {
    return (
      rankedVendorIds.indexOf(a.canonical_vendor_id) -
      rankedVendorIds.indexOf(b.canonical_vendor_id)
    );
  });
  return {
    data: {
      match: true,
      canonical_vendors: sortedVendors,
    },
  };
};

export const handleNotInvoice = ({ job }: any) => {
  return client.post(`/not-invoice`, { data: { job } });
};
export const getClearbitCompany = (domainOrUrl: string) => {
  return axios.get(
    `https://company.clearbit.com/v2/companies/find?domain=${parseDomainFromUrl(domainOrUrl)}`,
    {
      headers: {
        Authorization: 'Bearer sk_1dbc411f8297b82d4a5902faa62e209e',
      },
    },
  );
};

export const getManageExecutionByArn = (executionArn: any) => {
  return client.get(`/manage/execution/${executionArn}`);
};
export const fetchProofJSONFilesByArn = (executionArn: any) => {
  return client.get(`/manage/execution/${executionArn}/data-files`);
};
export const restartManageExecutions = ({
  executionList,
}: {
  executionList: {
    executionArn: string;
    startingStep: string;
    restartCategory: string;
    restartReason: string;
    inputStep?: string;
  }[];
}): Promise<{ data: { successful: any[]; failed: any[] } }> => {
  return client.post(`/manage/executions/restart-executions`, { data: { executionList } }) as any;
};
export const deleteManageExecutionByArn = (executionArn: any) => {
  return client.delete(`/manage/execution/${executionArn}`);
};
export const getManageExecutionRestartSteps = (executionArn: any) => {
  return client.get(`/manage/execution/${executionArn}/restart-steps`);
};
export const abortManageExecution = (executionArn: any) => {
  return client.post(`/manage/execution/${executionArn}/abort-execution`);
};
