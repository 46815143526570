/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LineItemStpWithParsedModelOutput,
    LineItemStpWithParsedModelOutputFromJSON,
    LineItemStpWithParsedModelOutputFromJSONTyped,
    LineItemStpWithParsedModelOutputToJSON,
    LineItemStpWithParsedModelOutputAttributeTypeMap,
    LineItemStpWithParsedModelOutputAttributeTypeMapFromJSON,
    LineItemStpWithParsedModelOutputAttributeTypeMapFromJSONTyped,
    LineItemStpWithParsedModelOutputAttributeTypeMapToJSON,
} from './';

/**
 * 
 * @export
 * @interface LineItemStpResponsePagination
 */
export interface LineItemStpResponsePagination {
    /**
     * 
     * @type {Array<LineItemStpWithParsedModelOutput>}
     * @memberof LineItemStpResponsePagination
     */
    events?: Array<LineItemStpWithParsedModelOutput>;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemStpResponsePagination
     */
    has_more: boolean;
    /**
     * 
     * @type {object}
     * @memberof LineItemStpResponsePagination
     */
    discriminator: object;
    /**
     * 
     * @type {Array<LineItemStpWithParsedModelOutputAttributeTypeMap>}
     * @memberof LineItemStpResponsePagination
     */
    attributeTypeMap: Array<LineItemStpWithParsedModelOutputAttributeTypeMap>;
}

export function LineItemStpResponsePaginationFromJSON(json: any): LineItemStpResponsePagination {
    return LineItemStpResponsePaginationFromJSONTyped(json, false);
}

export function LineItemStpResponsePaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItemStpResponsePagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(LineItemStpWithParsedModelOutputFromJSON)),
        'has_more': json['has_more'],
        'discriminator': json['discriminator'],
        'attributeTypeMap': ((json['attributeTypeMap'] as Array<any>).map(LineItemStpWithParsedModelOutputAttributeTypeMapFromJSON)),
    };
}

export function LineItemStpResponsePaginationToJSON(value?: LineItemStpResponsePagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(LineItemStpWithParsedModelOutputToJSON)),
        'has_more': value.has_more,
        'discriminator': value.discriminator,
        'attributeTypeMap': ((value.attributeTypeMap as Array<any>).map(LineItemStpWithParsedModelOutputAttributeTypeMapToJSON)),
    };
}


