import styled, { css } from 'styled-components';

import { Checkbox } from 'shared/components/checkbox';
import { Form } from 'shared/components/form';
import { Icon } from 'shared/components/icon';
import { InputDebounced } from 'shared/components/input-debounced';
import { border, color, font, mixin } from 'shared/styles';

export const TableContainer = styled.div`
  border-radius: 5px;
  background: ${color.background3};
`;
export const Controls = styled.div`
  padding: 25px;
`;
export const ControlsTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const SelectActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 25px;
  border-radius: 5px;
  background: ${color.background6};
  ${font.size(13)}
`;
export const SelectActions = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledSelectAction = styled.div`
  padding: 10px 0;
  margin-right: 20px;
  ${mixin.clickable}
  ${font.medium}
  &:hover {
    text-decoration: underline;
  }
`;
export const SelectedCount = styled.div`
  display: flex;
  align-items: center;
  ${mixin.clickable}

  svg {
    margin-left: 4px;
  }
`;
export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;
export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 50px;
  color: ${color.text2};
  ${font.size(16)}
`;
export const SearchInput = styled(InputDebounced)`
  margin-right: 20px;
  width: 160px;
  background: ${color.background6};
  ${mixin.placeholderColor(color.text2)};
  &:hover:not(:focus) {
    background: ${mixin.lighten(color.background6, 0.1)};
  }
  &:focus {
    width: 240px;
  }
`;
export const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  thead tr {
    border-top: 1px solid ${color.border2};
  }
`;
export const StyledRow = styled.tr`
  border-top: 1px solid ${color.border2};
`;
export const Header = styled.th`
  height: 50px;
  padding-left: 25px;
  text-align: ${(props) => props.align || 'left'};
  ${font.size(13)}
  ${font.bold}
`;
export const StyledCell = styled.td<{ truncate?: boolean }>`
  height: 50px;
  padding-left: 25px;
  ${font.size(13)}

  ${(props) =>
    props.truncate &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

  text-align: ${(props) => props.align || 'left'};
`;
export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  label:before {
    background: ${color.background6};
  }
`;
export const StyledSortHandle = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  user-select: none;
`;
export const SortIcon = styled(Icon)`
  margin-left: 5px;
  width: 10px;
  min-width: 10px;
`;
export const FiltersContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, auto);
  grid-gap: 16px;
`;
export const FiltersFormContainer = styled.div`
  position: absolute;
  width: 550px;
  top: 50px;
  border-radius: 6px;
  background-color: ${color.background3};
  display: grid;
  grid-template-columns: 1fr;
  z-index: 1;
  padding: 8px 24px 24px 24px;
  grid-row-gap: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
`;
export const FilterGroup = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export const FilterBadge = styled.div<{ readOnly?: boolean }>`
  background: #45475b;
  border-radius: ${border.borderRadius};
  padding: 9px;
  font-size: 13px;
  display: flex;
  align-items: center;
  svg {
    display: none;
    margin-left: 4px;
  }
  ${(props) =>
    props.readOnly
      ? `
    background: transparent;
    padding: 9px 0px;
  `
      : `
   ${mixin.clickable}
    &:hover {
      svg {
        display: block;
      }
    }
  `}
`;
export const FilterBadgeKey = styled.span`
  ${font.bold}
`;
export const FilterBadgeValue = styled.span`
  ${font.regular}
  margin-left: 0.5ch;
`;
export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px 0px;
`;
export const ReadOnlyFilterBadge = styled.div`
  ${font.size(14)}
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`;
const filterFormStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 65%;
  }
  label {
    padding: 0;
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    ${font.size(13)}
  }
`;
export const StyledSelect = styled(Form.Field.Select)`
  ${filterFormStyles}
`;
export const StyledDateInput = styled(Form.Field.InputDate)`
  ${filterFormStyles}
`;
export const StyledNumberInput = styled(Form.Field.InputNumber)`
  ${filterFormStyles}
`;
