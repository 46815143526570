import React, { useState } from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { BarChart } from 'shared/components/bar-chart';

import { EmptyState } from '../table/styles';
import { Center, ChartContainer } from './styles';

type PagedBarChartPropT<T> = {
  seriesData: {
    name: string;
    data: T[];
    color: string;
    events: {
      click: (e: any) => void;
    };
    borderRadiusTopLeft?: string;
    borderRadiusTopRight?: string;
    borderRadiusBottomLeft?: string;
    borderRadiusBottomRight?: string;
  }[];
  hideDataLabels?: boolean;
  hideToolTip?: boolean;
  showLegend?: boolean;
  yProperty: keyof T;
  xProperty: keyof T;
  labelFormatter: (label: string) => string;
  loading: boolean;
};
export function PagedBarChart<T>({ seriesData = [], ...props }: PagedBarChartPropT<T>) {
  const [idx, updateIdx] = useState(0);
  // This assumes that each series has equal length.
  // Data is currently being normalized before being passed to the component
  // but maybe this could be responsible for that.
  const maxColumns = 7;
  const totalColumns = (seriesData[0] as any)?.data?.length || 0;
  const visibleColumns = Math.min(maxColumns, totalColumns);
  const showButtons = totalColumns > maxColumns;
  let slicedSeriesData = seriesData.map((series) => ({
    ...series,
    data: series.data?.slice(idx, idx + visibleColumns) || [],
  }));
  const yAxisMax =
    seriesData?.reduce((max, series) => {
      const seriesHigh =
        (series as any)?.data?.reduce((localMax: any, point: any) => {
          return Math.max(localMax, point[props.yProperty]);
        }, 0) || 0;
      return Math.max(seriesHigh, max);
    }, 0) || 0;
  const pageLeft = () => {
    updateIdx((idx) => Math.max(idx - 1, 0));
  };
  const pageRight = () => {
    updateIdx((idx) => Math.min(idx + 1, totalColumns - visibleColumns));
  };
  if (!props.loading && visibleColumns === 0)
    return (
      <Center>
        <EmptyState> No data to display </EmptyState>
      </Center>
    );
  return (
    <ChartContainer>
      {showButtons && (
        <Button
          onClick={pageLeft}
          color="background6"
          disabled={idx === 0}
          size="small"
          trailingIcon="chevronLeft"
          variant="outlined"
        ></Button>
      )}
      {/* @ts-expect-error */}
      <BarChart seriesData={slicedSeriesData} {...props} yAxisMax={yAxisMax} />
      {showButtons && (
        <Button
          onClick={pageRight}
          color="background6"
          disabled={idx === seriesData[0].data?.length - visibleColumns}
          size="small"
          trailingIcon="chevronRight"
          variant="outlined"
        ></Button>
      )}
    </ChartContainer>
  );
}
