/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobMetricsReturnTDetail,
    JobMetricsReturnTDetailFromJSON,
    JobMetricsReturnTDetailFromJSONTyped,
    JobMetricsReturnTDetailToJSON,
    JobMetricsReturnTHeader,
    JobMetricsReturnTHeaderFromJSON,
    JobMetricsReturnTHeaderFromJSONTyped,
    JobMetricsReturnTHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobMetricsReturnT
 */
export interface JobMetricsReturnT {
    /**
     * 
     * @type {JobMetricsReturnTHeader}
     * @memberof JobMetricsReturnT
     */
    header: JobMetricsReturnTHeader;
    /**
     * 
     * @type {JobMetricsReturnTDetail}
     * @memberof JobMetricsReturnT
     */
    detail: JobMetricsReturnTDetail;
    /**
     * 
     * @type {number}
     * @memberof JobMetricsReturnT
     */
    escalated: number;
}

export function JobMetricsReturnTFromJSON(json: any): JobMetricsReturnT {
    return JobMetricsReturnTFromJSONTyped(json, false);
}

export function JobMetricsReturnTFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobMetricsReturnT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': JobMetricsReturnTHeaderFromJSON(json['header']),
        'detail': JobMetricsReturnTDetailFromJSON(json['detail']),
        'escalated': json['escalated'],
    };
}

export function JobMetricsReturnTToJSON(value?: JobMetricsReturnT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': JobMetricsReturnTHeaderToJSON(value.header),
        'detail': JobMetricsReturnTDetailToJSON(value.detail),
        'escalated': value.escalated,
    };
}


