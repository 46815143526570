import styled from 'styled-components';

import { color, font, padding } from 'shared/styles';

export const CostsGrid = styled.div`
  width: 100%;
`;

export const CostsRow = styled.div`
  align-items: flex-end;
  display: grid;
  grid-template-columns: 16px 1fr;
  justify-content: flex-start;
  width: 100%;
  gap: 0 ${padding.med};
  > svg {
    margin-bottom: ${padding.xs};
  }
`;

export const TotalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: 0 ${padding.sm};
  margin-top: ${padding.med};
`;

export const Total = styled.p`
  ${font.medium};
  ${font.size(20)};
`;

export const TotalLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TotalLabel = styled.p`
  ${font.regular};
  ${font.size(14)};
  color: ${color.text4};
  margin-bottom: ${padding.sm};
`;

export const Warning = styled.div`
  padding: 0 ${padding.sm};
  margin-top: ${padding.sm};
  color: ${color.danger};
  display: flex;
  align-items: center;
  gap: ${padding.xs};
  ${font.size(13)};
`;
