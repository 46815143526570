import styled from 'styled-components';

import { font } from 'shared/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  ${font.size(14)}
`;
