import styled from 'styled-components';

import { Icon } from 'shared/components/icon';
import { animation, border, color, mixin, zIndexValues } from 'shared/styles';

const verticalPadding = 20;
export const ScrollOverlay = styled.div`
  z-index: ${zIndexValues.modal};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${mixin.scrollableY}
`;
ScrollOverlay.displayName = 'ScrollOverlay';
export const ClickableOverlay = styled.div<{ variant: 'center' | 'aside' }>`
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: ${verticalPadding}px 30px;
  align-items: center;
  background: ${mixin.rgba(color.background1, 0.7)};
`;
ClickableOverlay.displayName = 'ClickableOverlay';
export const StyledModal = styled.div<{ maxWidth: number; fullHeight?: boolean }>`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: ${(props) => props.maxWidth}px;
  vertical-align: middle;
  border-radius: ${border.borderRadius};
  background: ${color.background3};
  animation: ${animation.scaleIn} 0.15s ${animation.timingFunc};
  ${(props) => props.fullHeight && `height: ${window.innerHeight - verticalPadding * 2}px;`}
  ${mixin.boxShadowLarge}
`;
StyledModal.displayName = 'StyledModal';
export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px;
  border-radius: ${border.borderRadius};
  color: ${color.text2};
  transition: all 0.1s;
  ${mixin.clickable}
  &:hover {
    background: ${color.background5};
  }
`;
StyledModal.displayName = 'StyledModal';
