import React, { InputHTMLAttributes, forwardRef } from 'react';

import IMask from 'imask';
import moment from 'moment';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { IMaskMixin } from 'react-imask';

import { Icon, IconPropT } from '../icon';
import { IconContainer, StyledInput, Wrapper } from './styles';

export type InputProps = {
  value?: string | number;
  iconStart?: IconPropT['type'];
  iconEnd?: IconPropT['type'];
  invalid?: boolean;
  onChange?: (value: HTMLInputElement['value'], event: React.ChangeEvent<HTMLInputElement>) => any;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

export const Input = forwardRef<any, InputProps>(
  ({ iconStart, iconEnd, invalid = false, onChange = () => {}, ...inputProps }, ref) => {
    const icon = iconStart || iconEnd;

    return (
      <Wrapper>
        <StyledInput
          {...inputProps}
          invalid={invalid}
          onChange={(event) => onChange(event.target.value, event)}
          withIconStart={!!iconStart}
          withIconEnd={!!iconEnd}
          ref={ref}
        />
        {icon && (
          <IconContainer isStart={!!iconStart}>
            {typeof icon === 'string' ? <Icon type={icon} size={18} /> : icon}
          </IconContainer>
        )}
      </Wrapper>
    );
  },
);
Input.displayName = 'Input';

const ImaskInput = IMaskMixin(({ inputRef, ...props }: any) => <Input {...props} ref={inputRef} />);

const dataFormat = 'YYYY-MM-DD';
const displayFormat = 'MM/DD/YYYY';

export const InputDate = ({ value, onChange, ...inputProps }: any) => (
  <ImaskInput
    {...inputProps}
    value={moment(value, dataFormat).format(displayFormat)}
    mask={Date}
    pattern="M/`D/`Y"
    onAccept={(newValue: any, mask: any) => {
      if (mask.masked.isComplete) {
        onChange(moment(newValue, displayFormat).format(dataFormat));
      } else if (newValue === 'mm/dd/yyyy') {
        onChange('');
      }
    }}
    lazy={false}
    autofix
    format={(date: any) => moment(date).format(displayFormat)}
    parse={(str: any) => moment(str, displayFormat)}
    blocks={{
      M: { mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2 },
      D: { mask: IMask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2 },
      Y: { mask: IMask.MaskedRange, placeholderChar: 'y', from: 1800, to: 9999, maxLength: 4 },
    }}
  />
);

export const InputNumber = ({ value, onChange, onBlur, ...inputProps }: any) => (
  <ImaskInput
    {...inputProps}
    value={`${value}`}
    mask={Number}
    thousandsSeparator=","
    radix="."
    scale={20}
    signed
    mapToRadix={['.']}
    normalizeZeros={false}
    padFractionalZeros={false}
    onBlur={(event: any) => {
      if (event.target.value === '-') {
        onChange('');
      }
    }}
    onAccept={(newValue: any) => {
      onChange(newValue.replace(/,/g, ''));
    }}
  />
);
