import styled from 'styled-components';

import { color, font, padding } from 'shared/styles';

export const Container = styled.main`
  display: grid;
  height: 100%;
  place-content: center;
  place-items: flex-start;
`;

export const Instructions = styled.section`
  display: grid;
  width: 450px;
`;

export const UserProvidedDetails = styled.section`
  padding: ${padding.med};
`;

export const Label = styled.div`
  padding-bottom: 2px;
  color: ${color.text2};
  ${font.size(14)}
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  ${font.bold}
  ${font.size(14)}
`;
