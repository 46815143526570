import queryString from 'query-string';

export const queryStringToObject = (str: string, options: queryString.ParseOptions = {}) =>
  queryString.parse(str, {
    arrayFormat: 'comma',
    ...options,
  });

export const objectToQueryString = (obj: any, options = {}) =>
  queryString.stringify(obj, {
    arrayFormat: 'comma',
    ...options,
  });

export const parseDomainFromUrl = (url?: string) => {
  try {
    return new URL(url || '').hostname.replace(/^www\./, '');
  } catch {
    return (url || '').replace(/^www\./, '');
  }
};
