import styled from 'styled-components';

import { Button } from '@glean/glean-ui.molecules.button';

import { border, color, font, mixin, padding, sizes } from 'shared/styles';

export const Container = styled.div`
  display: flex;
  padding-top: ${sizes.navbarHeight}px;
  ${mixin.cover}
`;

export const Right = styled.div`
  background: ${color.background3};
  display: flex;
  flex-direction: column;
  width: ${sizes.sidebarWidth};
  ${mixin.scrollableY};
`;
Right.displayName = 'Right';

export const InvoicePDF = styled.div`
  flex: 1;
  height: 100%;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
`;

export const JobId = styled.div`
  border-bottom: ${border.section};
  ${font.size(15)};
  ${padding.sidebarSection};
`;
JobId.displayName = 'JobId';

export const JobFormContainer = styled.div`
  padding: 0 ${padding.lrg} ${padding.lrg};
`;
JobFormContainer.displayName = 'JobFormContainer';

export const DropdownHandle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${padding.sidebarSection};
  ${font.size(16)};
  ${mixin.clickable};
`;
DropdownHandle.displayName = 'DropdownHandle';

export const Instructions = styled.div`
  padding: 0 ${padding.lrg};
  h5 {
    padding-bottom: 12px;
    ${font.size(15)}
    ${font.medium}
  }
  ul {
    padding: 0 0 25px 20px;
    list-style: disc;
  }
  li {
    padding: 3px 0;
    line-height: 1.5;
    ${font.size(14)}
  }
`;
Instructions.displayName = 'Instructions';

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${padding.med};
`;
StyledButton.displayName = 'StyledButton';

export const Buttons = styled.div`
  ${padding.sidebarSection};
`;
Buttons.displayName = 'Buttons';

export const IdentifyInfo = styled.div`
  background: #14141b;
  padding: 32px;
  * {
    margin-bottom: 24px;
  }
`;

IdentifyInfo.displayName = 'IdentifyInfo';
