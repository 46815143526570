/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditUpdateInvoiceDTO
 */
export interface EditUpdateInvoiceDTO {
    /**
     * 
     * @type {string}
     * @memberof EditUpdateInvoiceDTO
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof EditUpdateInvoiceDTO
     */
    lineItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUpdateInvoiceDTO
     */
    value?: string;
}

export function EditUpdateInvoiceDTOFromJSON(json: any): EditUpdateInvoiceDTO {
    return EditUpdateInvoiceDTOFromJSONTyped(json, false);
}

export function EditUpdateInvoiceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditUpdateInvoiceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'lineItemId': !exists(json, 'lineItemId') ? undefined : json['lineItemId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function EditUpdateInvoiceDTOToJSON(value?: EditUpdateInvoiceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'lineItemId': value.lineItemId,
        'value': value.value,
    };
}


