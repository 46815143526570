import styled from 'styled-components';

import { color, font, mixin, padding } from 'shared/styles';

export const CommentsContainer = styled.div`
  ${mixin.scrollableY}
  ${font.size(13)}
`;
CommentsContainer.displayName = 'CommentsContainer';
export const NoCommentsContainer = styled.div`
  margin: auto;
`;
export const Details = styled.div`
  color: ${color.text2};
  display: flex;
  padding: ${padding.xs} 0 0 0;
  ${font.bold};

  span {
    padding: 0 ${padding.xs} 0 0;
  }

  .author {
    margin: auto 0;
  }

  .gleanLogo {
    margin-right: ${padding.xs};
  }
`;
Details.displayName = 'Details';
export const StyledComment = styled.div<{ padding: keyof typeof padding }>`
  ${(props) => padding[props.padding]}
`;
StyledComment.displayName = 'StyledComment';
export const NoComments = styled.div`
  color: ${color.text2};
  text-align: center;
  ${font.size(26)}
  ${padding.sidebarReg};
`;
NoComments.displayName = 'NoComments';
