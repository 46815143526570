import React from 'react';

import { NavLink } from 'react-router-dom';

import { TabLink, TabLinks } from './styles';

const Links = ({ large, fullWidth, ...props }: any) => (
  <TabLinks large={large} fullWidth={fullWidth} {...props} />
);

const Link = ({ to, active, ...props }: any) => (
  <TabLink as={to ? NavLink : 'div'} to={to} active={active} {...props} />
);

export const Tabs = { Links, Link };
