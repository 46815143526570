/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';

import { history } from 'browserHistory';
import qs from 'query-string';
import { useLocation } from 'react-router';

/**
 * A single field in the filtering
 *
 */
export type FilterField<T> = {
  /**
   * fn: filtering function. Iterates over every item being filtered, and every active filter.
   *
   */
  fn: (item: T, filterValue: any) => boolean;
  /**
   * A display name for the filter field to show when it is active
   */
  displayName: string;
  /**
   * The type of view to implement for switching the filter
   */
  type: 'select' | 'number' | 'date' | 'dateRange';
  /**
   * a list of potential options to show when implementing
   * a select filter
   */
  options?: { label: string; value: string }[];
  /**
   * a function which formats the value passed
   * to the filter function in a human readable way
   */
  filterFormatter?: (filterValue: any) => string;
  /**
   * hide this filter from the user controllable form in the table
   * (set to true if controlling this filter with a ui element
   * outside of the table component)
   */
  hideInForm?: boolean;
};

export interface Filter {
  filterKey: string;
  filterValue: any;
}

export interface FilterOptions<ItemT> {
  filterFields: Record<string, FilterField<ItemT>>;
  readOnly: boolean;
  defaultFilter?: Filter[];
}

export interface FilterProps<ItemT> {
  filterFields?: Record<string, FilterField<ItemT>>;
  readOnly?: boolean;
  defaultFilter?: Filter[];
}

export const useFilters = <ItemT extends { [key: string]: any }>({
  items,
  filterFields = {} as Record<string, FilterField<ItemT>>,
  defaultFilter = [],
  readOnly = false,
}: { items: ItemT[] } & FilterProps<ItemT>): {
  filteredItems: ItemT[];
  filters: FilterOptions<ItemT> & {
    activeFilters: Filter[];
    onChange: (incomingFilters: Filter[]) => void;
  };
} => {
  const [activeFilters, setActiveFilters] = useState(defaultFilter);

  const filteredItems = items.filter((item) => {
    for (let { filterKey, filterValue } of activeFilters) {
      // if a filter function is defined invoke it
      if (filterFields[filterKey] && typeof filterFields[filterKey].fn === 'function') {
        if (!filterFields[filterKey].fn(item, filterValue)) {
          return false;
        }
      }
    }
    // If no filters have returned false, return the items
    return true;
  });
  const onChange = useCallback(
    (incomingFilters: Filter[]) => {
      const allowedKeys = Object.keys(filterFields);
      const allowedFilters = incomingFilters.filter(({ filterKey }) =>
        allowedKeys.includes(filterKey),
      );
      setActiveFilters(allowedFilters);
      const filterMap = Object.fromEntries(
        incomingFilters.map(({ filterKey, filterValue }) => [filterKey, filterValue]),
      );
      const queryString = qs.stringify(filterMap);
      // history.replace(`?${queryString}`);
    },
    [filterFields],
  );

  const { search } = useLocation();

  useEffect(() => {
    const parsed = qs.parse(search);
    const parsedFilters = Object.entries(parsed).map(([key, value]) => ({
      filterKey: key,
      filterValue: value,
    }));
    // onChange(parsedFilters); //TODO make this work again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    filteredItems,
    filters: {
      filterFields,
      activeFilters,
      readOnly,
      onChange: onChange,
    },
  };
};

// Getting the return value of a generic function is actually pretty tough
// without actually calling the function
// I've found this to be the least bad way
class Helper<T extends { [key: string]: any }> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  Return = useFilters<T>({ items: [] });
}

export type UseFiltersT<T extends { [key: string]: any }> = Helper<T>['Return'];
