import React from 'react';

import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { Checkbox } from 'shared/components/checkbox';
import { CheckboxGroup } from 'shared/components/checkbox-group';
import { Input, InputDate, InputNumber } from 'shared/components/input';
import { Popover } from 'shared/components/popover';
import { Select } from 'shared/components/select';
import { Textarea } from 'shared/components/textarea';

import { FieldError, FieldLabel, FieldTipIcon, StyledField } from './styles';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  tip: PropTypes.any,
  error: PropTypes.string,
};

const generateField = (FormComponent: any) => {
  const FieldComponent = ({ className, label, helperText, tip, error, ...otherProps }: any) => {
    const fieldId = uniqueId('proof-field-');

    return (
      <StyledField className={className}>
        {label && <FieldLabel htmlFor={fieldId}>{label}</FieldLabel>}
        {tip && (
          <Popover content={tip} interaction="click">
            <FieldTipIcon type="help" size={18} top={-3} />
          </Popover>
        )}
        <FormComponent id={fieldId} invalid={!!error} {...otherProps} />
        {error && <FieldError>{error}</FieldError>}
      </StyledField>
    );
  };

  FieldComponent.propTypes = propTypes;

  return FieldComponent;
};

export const Field = {
  Input: generateField(Input),
  InputDate: generateField(InputDate),
  InputNumber: generateField(InputNumber),
  Select: generateField(Select),
  Checkbox: generateField(Checkbox),
  CheckboxGroup: generateField(CheckboxGroup),
  Textarea: generateField(Textarea),
};
