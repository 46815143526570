import React, { useContext, useLayoutEffect, useRef } from 'react';

import { useFeatureFlag } from 'flagg';
import { get } from 'lodash';

import { Form } from 'shared/components/form';
import { GleanLogoIcon } from 'shared/components/glean-logo-icon';
import { IndicatorDot } from 'shared/components/indicatorDot';
import { Popover } from 'shared/components/popover';
import { isFormValueEmpty } from 'shared/utils/schema';

import { FormContext } from '../../..';
import { ExtractValidateContext, ValidationFlagT } from '../../../../state';
import {
  getActiveValidationFlags,
  getFieldSchemaFromName,
  getFieldValidationFlags,
} from '../../../../utils';
import { GridCell } from '../styles';
import { FlagContainer, ValidationFlagIcon } from './styles';
import { ValidationFlagsPopover } from './validation-flags-popover';

export const Field = (props: any) => {
  const [{ jobType }] = useContext(ExtractValidateContext);
  return jobType.indexOf('extract') > -1 ? (
    <ExtractField {...props} />
  ) : (
    <ValidateField {...props} />
  );
};

const ExtractField = ({ name, perRow, disabled, ...fieldProps }: any) => {
  const { values } = useContext(ExtractValidateContext);
  //@ts-expect-error need to migrate everything out of the untyped FormContext and into ExtractValidateContext
  const { initialFormValues, readOnly, confidenceScores } = useContext(FormContext);

  const [confidenceScoreThresholdFF] = useFeatureFlag(
    'stp_ExtractValidateConfidenceScoresThreshold',
  );

  const threshold = confidenceScoreThresholdFF
    ? parseFloat(String(confidenceScoreThresholdFF))
    : 0.75;
  const isValueEmpty = isFormValueEmpty(get(values, name));
  const isInitialValueChanged = get(values, name) !== get(initialFormValues, name);

  const confidenceScore = get(confidenceScores, name);
  const showConfidenceScoreIndicator =
    !!confidenceScore && confidenceScore < threshold && !isInitialValueChanged;
  const showAutoFilledLogo =
    !showConfidenceScoreIndicator && !isValueEmpty && !isInitialValueChanged;

  return (
    <GridCell perRow={perRow}>
      <FormField
        name={name}
        disabled={readOnly || disabled}
        iconEnd={
          <FlagContainer>
            {showConfidenceScoreIndicator && (
              <Popover content={`Confidence score is\nlow for this field`} interaction="hover">
                <div style={{ cursor: 'pointer' }}>
                  <IndicatorDot />
                </div>
              </Popover>
            )}
            {showAutoFilledLogo && (
              <Popover content={`This field has been\n filled in by Glean.`} interaction="hover">
                <div style={{ cursor: 'pointer' }}>
                  <GleanLogoIcon width={14} />
                </div>
              </Popover>
            )}
          </FlagContainer>
        }
        {...fieldProps}
      />
    </GridCell>
  );
};

const ValidateField = ({ name, perRow, disabled, ...fieldProps }: any) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ validationFlags, focusedFlag }, dispatch] = useContext(ExtractValidateContext);
  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusedFlag' does not exist on type '{}'... Remove this comment to see the full error message
    values,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'initialFormValues' does not exist on typ... Remove this comment to see the full error message
    initialFormValues,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFieldValue' does not exist on type '{... Remove this comment to see the full error message
    setFieldValue,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'readOnly' does not exist on type '{}'.
    readOnly,
  } = useContext(FormContext);

  const updateValidationFlag = (
    flagId: string,
    updateFn: (flag?: ValidationFlagT) => Partial<ValidationFlagT>,
  ) =>
    dispatch({
      type: 'UPDATE_VALIDATION_FLAG',
      payload: { flagId, updateFn },
    });
  const fieldFlags = getFieldValidationFlags({ name, validationFlags, values });
  const fieldActiveFlags = getActiveValidationFlags(fieldFlags);

  const hasFocusedActiveFlag =
    focusedFlag && fieldActiveFlags.some((flag) => flag.flag_id === focusedFlag.flag_id);

  useLayoutEffect(() => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (hasFocusedActiveFlag) ref.current.scrollIntoView();
  }, [hasFocusedActiveFlag]);

  // @ts-expect-error need to type the form context correctly
  const { confidenceScores } = useContext(FormContext);

  const confidenceScore = get(confidenceScores, name);

  const [confidenceScoreThresholdFF] = useFeatureFlag(
    'stp_ExtractValidateConfidenceScoresThreshold',
  );
  const threshold = confidenceScoreThresholdFF
    ? parseFloat(String(confidenceScoreThresholdFF))
    : 0.75;

  const showConfidenceScoreIndicator = !!confidenceScore && confidenceScore < threshold;

  return (
    <GridCell
      ref={ref}
      perRow={perRow}
      isValidate
      hasActiveFlags={fieldActiveFlags.length > 0}
      hasFocusedFlag={!!hasFocusedActiveFlag}
    >
      <FormField
        name={name}
        placeholder={isFormValueEmpty(get(values, name)) ? '-' : undefined}
        disabled={readOnly || disabled}
        iconEnd={
          <FlagContainer>
            {showConfidenceScoreIndicator && (
              <Popover content={`Confidence score is\nlow for this field`} interaction="hover">
                <div style={{ cursor: 'pointer' }}>
                  <IndicatorDot />
                </div>
              </Popover>
            )}
            {fieldFlags.length > 0 && (
              <ValidationFlagsPopover
                fieldFlags={fieldFlags}
                updateValidationFlag={updateValidationFlag}
                onRevertToInitialValueClick={() => {
                  fieldFlags.forEach(({ flag_id }: { flag_id: string }) => {
                    updateValidationFlag(flag_id, () => ({ is_resolved: false }));
                  });
                  setFieldValue(name, get(initialFormValues, name));
                }}
              >
                <ValidationFlagIcon
                  icon={fieldActiveFlags.length > 0 ? 'flag' : 'check'}
                  color={fieldActiveFlags.length > 0 ? 'warning' : 'success'}
                  size="small"
                />
              </ValidationFlagsPopover>
            )}
          </FlagContainer>
        }
        onChange={(newValue: any) => {
          const currentValue = get(values, name);
          const initialValue = get(initialFormValues, name);
          const justBecameResolved = currentValue === initialValue && newValue !== initialValue;
          const justBecameUnresolved = currentValue !== initialValue && newValue === initialValue;

          if (justBecameResolved || justBecameUnresolved) {
            fieldFlags.forEach(({ flag_id }: any) => {
              updateValidationFlag(flag_id, () => ({ is_resolved: justBecameResolved }));
            });
          }
        }}
        {...fieldProps}
      />
    </GridCell>
  );
};

const FormField = ({ name, textarea, ...fieldProps }: any) => {
  const fieldSchema = getFieldSchemaFromName(name);
  const label = fieldSchema.title;

  const props = {
    ...fieldProps,
    name,
    label,
    autoComplete: 'off', // some browsers will not honor this unfortunately
    'data-lpignore': true, // tell LastPass to ignore this field
  };

  if (fieldSchema.type === 'string' && fieldSchema.enum) {
    const options = [{ label: 'Select', value: '' }, ...getEnumOptions(fieldSchema)];
    return <Form.Field.Select {...props} options={options} />;
  }

  if (fieldSchema.type === 'string') {
    return textarea ? (
      <Form.Field.Textarea {...props} />
    ) : fieldSchema.format === 'date' ? (
      <Form.Field.InputDate {...props} />
    ) : (
      <Form.Field.Input {...props} />
    );
  }

  if (fieldSchema.type === 'number') {
    return <Form.Field.InputNumber {...props} />;
  }

  if (fieldSchema.type === 'boolean') {
    const { label, ...checkboxProps } = props;
    return <Form.Field.Checkbox {...checkboxProps} checkboxLabel={label} />;
  }

  if (
    fieldSchema.type === 'array' &&
    fieldSchema.items.type === 'string' &&
    fieldSchema.items.enum
  ) {
    return <Form.Field.CheckboxGroup {...props} options={getEnumOptions(fieldSchema.items)} />;
  }

  return null;
};

const getEnumOptions = ({ enum: enumerators, enumNames = [] }: { enum: any; enumNames: any[] }) =>
  enumerators.map((value: any, index: any) => ({
    label: enumNames[index] || value,
    value,
  }));
