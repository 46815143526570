import React, { createContext, useContext, useState } from 'react';

import { uniq } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useGetCompanies, useGetPipelineExecutions } from 'shared/api';
import { ExecutionSummaryDTO, SingleExecutionDTO } from 'shared/api/generated';
import { ExecutionDate } from 'shared/components/execution-date';
import { ExecutionStatusIndicator } from 'shared/components/execution-status-indicator';
import { AbortModal } from 'shared/components/manage-execution-modals/abort-modal';
import { DeleteModal } from 'shared/components/manage-execution-modals/delete-modal';
import { RestartModal } from 'shared/components/manage-execution-modals/restart-modal';
import { PageLoader } from 'shared/components/page-loader';
import { StatusBadge } from 'shared/components/status-badge/styles';
import { SelectAction, Table } from 'shared/components/table';
import { SortDir } from 'shared/hooks/sort';
import { UseTableT, useTable } from 'shared/hooks/table';
import { assureTimeZone } from 'shared/utils/date-time';
import { pipelineStepPrettyName } from 'shared/utils/pipeline-status-pretty-name';

import {
  ActionButtonsCell,
  ButtonContainer,
  StatusBadgeContainer,
  StyledButton,
  StyledCell,
  StyledRow,
} from './styles';

type TableItemsT = ExecutionSummaryDTO & {
  companyName: string;
};

export type ExecutionsTablePropT = {
  executions?: ExecutionSummaryDTO[];
  refetchExecutions?: () => void;
  isLoading?: boolean;
  hideActions?: boolean;
  readOnlyFilters?: boolean;
} & UseTableT<TableItemsT>;

export const useExecutionTableData = () => {
  const executionsRes = useGetPipelineExecutions();
  const executions = executionsRes.data?.executions || [];
  const loading = executionsRes.isLoading;
  const refetchExecutions = executionsRes.refetch;
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [hideActions, setHideActions] = useState(false);
  const [readOnlyFilters, setReadOnlyFilters] = useState(false);
  const companiesQuery = useGetCompanies();
  const companiesLoading = companiesQuery.isLoading;
  const companiesData = companiesQuery?.data;
  const companiesMap = companiesData?.reduce((acc, cur) => {
    let tempAcc = { ...acc };
    tempAcc[cur.id] = cur.display_name;
    return tempAcc;
  }, {} as Record<string, string>);

  const addCompanyName = <T extends SingleExecutionDTO | ExecutionSummaryDTO>(
    execution: T,
  ): T & { companyName: string } => ({
    ...execution,
    companyName:
      companiesMap && companiesMap[execution.companyId]
        ? companiesMap[execution.companyId]
        : execution.companyId,
  });
  const executionsWithCompanyName = executions?.map(addCompanyName) || [];
  const dateFilter = (key: 'startDate' | 'updatedAt' | 'stopDate', direction: SortDir) => (
    a: ExecutionSummaryDTO,
    b: ExecutionSummaryDTO,
  ) => {
    if (!b[key]) return -1;
    if (!a[key]) return 1;
    let dA = new Date(a[key] || 0);
    let dB = new Date(b[key] || 0);
    if (direction === 'ASC') {
      return dA.getTime() - dB.getTime();
    } else {
      return dB.getTime() - dA.getTime();
    }
  };
  const { tableItems, sort, search, pagination, filters } = useTable({
    items: executionsWithCompanyName,
    sort: {
      fieldSorters: {
        startDate: (items, direction) => {
          return items.sort(dateFilter('startDate', direction));
        },
        updatedAt: (items, direction) => {
          return items.sort(dateFilter('updatedAt', direction));
        },
        stopDate: (items, direction) => {
          return items.sort(dateFilter('stopDate', direction));
        },
      },
    },
    pagination: { itemsPerPage },
    filters: {
      readOnly: readOnlyFilters,
      filterFields: {
        tag: {
          displayName: 'Tag',
          type: 'select',
          options: [
            { label: 'Reprocessed', value: 'Reprocessed' },
            { label: 'Escalated', value: 'Escalated' },
          ],
          fn: (execution, tag) => {
            switch (tag) {
              case 'Reprocessed':
                return !!execution.restartedAt;
              case 'Escalated':
                return !!execution.escalatedAt && !execution.deescalatedAt && !execution.stopDate;
              default:
                return true;
            }
          },
        },
        pipeline: {
          displayName: 'Pipeline',
          type: 'select',
          options: [
            { label: 'Header', value: 'Header' },
            { label: 'Detail', value: 'Detail' },
          ],
          fn: (execution: ExecutionSummaryDTO, filterValue: 'Header' | 'Detail') => {
            if (
              execution.executionArn.indexOf('glean-header-pipeline') > -1 &&
              filterValue === 'Header'
            )
              return true;
            if (
              execution.executionArn.indexOf('glean-line-item-pipeline') > -1 &&
              filterValue === 'Detail'
            )
              return true;
            return false;
          },
        },
        executionStatus: {
          displayName: 'Status',
          type: 'select',
          options: [
            { label: 'Completed', value: 'Completed' },
            ...uniq(executionsWithCompanyName.map((execution) => execution.executionStatus))
              .sort()
              .map((executionStatus) => ({
                label: pipelineStepPrettyName(executionStatus),
                value: pipelineStepPrettyName(executionStatus),
              })),
          ],
          fn: (execution, executionStatus) => {
            if (executionStatus === 'Completed') {
              return execution.executionStatus !== 'RUNNING';
            } else {
              return pipelineStepPrettyName(execution.executionStatus) === executionStatus;
            }
          },
        },
        executionStep: {
          displayName: 'Step',
          type: 'select',
          options: uniq(executionsWithCompanyName.map((execution) => execution.executionStep))
            .sort()
            .map((executionStep) => ({
              label: pipelineStepPrettyName(executionStep),
              value: pipelineStepPrettyName(executionStep),
            })),
          fn: (execution, executionStep) =>
            pipelineStepPrettyName(execution.executionStep) === executionStep,
        },
        companyName: {
          displayName: 'Company',
          type: 'select',
          options: uniq(executionsWithCompanyName.map((execution) => execution.companyName))
            .sort()
            .map((companyName) => ({ label: companyName, value: companyName })),
          fn: (item, filterValue) => item.companyName === filterValue,
        },
        startDate: {
          displayName: 'Started At',
          type: 'date',
          fn: (execution, inputDate) => {
            const startDate = assureTimeZone(execution.startDate);
            if (!startDate) return false;
            return moment(startDate).utc().format('YYYY-MM-DD') === inputDate;
          },
        },
        stopDate: {
          displayName: 'Completed At',
          type: 'date',
          fn: (execution, inputDate) => {
            const stopDate = assureTimeZone(execution.stopDate);
            if (!stopDate) return false;
            return moment(stopDate).utc().format('YYYY-MM-DD') === inputDate;
          },
        },
        updatedAt: {
          displayName: 'Updated At',
          type: 'date',
          fn: (execution, inputDate) => {
            const updatedAt = assureTimeZone(execution.updatedAt);
            if (!updatedAt) return false;
            return moment(updatedAt).utc().format('YYYY-MM-DD') === inputDate;
          },
        },
        dateRange: {
          displayName: 'Date Range',
          type: 'select',
          options: [
            { label: 'Last 7 Days', value: 'Last 7 Days' },
            { label: 'All Time', value: 'All Time' },
          ],
          fn: (execution, dateRange) => {
            switch (dateRange) {
              case 'Last 7 Days': {
                if (!execution.stopDate) return false;
                const lastWeek = moment().utc().subtract(7, 'days').unix();
                const executionCompleted = execution.stopDate
                  ? new Date(execution.stopDate).getTime() / 1000
                  : 0;
                return lastWeek < executionCompleted;
              }
              default: {
                return true;
              }
            }
          },
        },
      },
    },
    search: { indexFields: ['executionArn'], fuzzySearch: false },
  });

  return {
    setItemsPerPage,
    setHideActions,
    setReadOnlyFilters,

    executions,
    refetchExecutions,
    hideActions,
    isLoading: loading || companiesLoading,
    filters,
    search,
    sort,
    pagination,
    tableItems,
  };
};

export const ExecutionTableContext = createContext<ReturnType<typeof useExecutionTableData> | null>(
  null,
);

export const ExecutionTableContextProvider = ({ children }: { children: React.ReactChild }) => {
  const tableProps = useExecutionTableData();
  return (
    <ExecutionTableContext.Provider value={tableProps}>{children}</ExecutionTableContext.Provider>
  );
};

export const ExecutionTable = () => {
  const context = useContext(ExecutionTableContext);
  const [restartModalOpen, setRestartModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [abortModalOpen, setAbortModalOpen] = useState(false);
  const [executionsToRestart, setExecutionsToRestart] = useState<string[]>([]);
  const [executionsToDelete, setExecutionsToDelete] = useState<string[]>([]);
  const [executionsToAbort, setExecutionsToAbort] = useState<string[]>([]);

  if (!context) return null;
  const {
    executions,
    refetchExecutions,
    hideActions,
    isLoading,
    filters,
    search,
    sort,
    pagination,
    tableItems,
  } = context;

  if (isLoading && (!executions || executions.length === 0))
    return <PageLoader message={'Loading Executions'} />;
  return (
    <>
      <RestartModal
        executionsToRestart={executionsToRestart.map((executionArn) => ({ executionArn }))}
        isOpen={restartModalOpen}
        onClose={() => setRestartModalOpen(false)}
        onComplete={refetchExecutions}
      />
      <DeleteModal
        executionsToDelete={executionsToDelete}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onComplete={refetchExecutions}
      />
      <AbortModal
        executionsToAbort={executionsToAbort}
        isOpen={abortModalOpen}
        onClose={() => setAbortModalOpen(false)}
        onComplete={refetchExecutions}
      />
      <Table
        filters={filters}
        search={search}
        sort={sort}
        pagination={pagination}
        select={
          hideActions
            ? undefined
            : {
                keys: tableItems.map((executions) => executions.executionArn),
                // eslint-disable-next-line react/display-name
                renderActions: (selectedKeys) => (
                  <>
                    <SelectAction
                      onClick={() => {
                        setRestartModalOpen(true);
                        setExecutionsToRestart(selectedKeys);
                      }}
                    >
                      Restart
                    </SelectAction>
                    <SelectAction
                      onClick={() => {
                        setAbortModalOpen(true);
                        setExecutionsToAbort(selectedKeys);
                      }}
                    >
                      Abort
                    </SelectAction>
                    <SelectAction
                      onClick={() => {
                        setDeleteModalOpen(true);
                        setExecutionsToDelete(selectedKeys);
                      }}
                    >
                      Delete
                    </SelectAction>
                  </>
                ),
              }
        }
        isEmpty={tableItems.length === 0}
        columns={[
          { name: 'ARN', sortKey: 'arn', width: hideActions ? '28%' : '50%' },
          { name: 'Status', sortKey: 'status', width: hideActions ? '13%' : '16%' },
          { name: 'Company Name', sortKey: 'companyName', width: '16%' },
          { name: 'Started At', sortKey: 'startDate', width: hideActions ? '14%' : '16%' },
          { name: 'Updated At', sortKey: 'updatedAt', width: hideActions ? '14%' : '16%' },
          { name: 'Completed At', sortKey: 'stopDate', width: hideActions ? '15%' : '16%' },
          { width: hideActions ? '0px' : '136px' },
        ]}
        body={tableItems.map((execution, idx) => {
          return (
            <StyledRow key={`executionTable-${idx}`} selectKey={execution.arn}>
              <StyledCell>
                <Link to={`/manage/view/${execution.arn}`}>
                  {execution.arn.split(':').slice(0, 6).join(':') + ':'}
                  <br />
                  {execution.arn.split(':').slice(6).join(':')}
                  <StatusBadgeContainer>
                    {execution.restartedAt && <StatusBadge>Reprocessed</StatusBadge>}
                    {execution.escalatedAt && !execution.deescalatedAt && !execution.stopDate && (
                      <StatusBadge backgroundColor="danger">Escalated</StatusBadge>
                    )}
                  </StatusBadgeContainer>
                </Link>
              </StyledCell>
              <StyledCell noTruncate={true}>
                <ExecutionStatusIndicator
                  executionStatus={execution.executionStatus}
                  executionStep={execution.executionStep}
                />
              </StyledCell>
              <StyledCell>{execution.companyName}</StyledCell>
              <StyledCell>
                <ExecutionDate date={execution.startDate} />
              </StyledCell>
              <StyledCell>
                <ExecutionDate date={execution.updatedAt} />
              </StyledCell>
              <StyledCell>
                <ExecutionDate date={execution.stopDate} />
              </StyledCell>
              {!hideActions && (
                <ActionButtonsCell>
                  <ButtonContainer>
                    {execution.stopDate === null && (
                      <StyledButton
                        variant="outlined"
                        color="background6"
                        icon="stop"
                        onClick={() => {
                          setAbortModalOpen(true);
                          setExecutionsToAbort([execution.arn]);
                        }}
                      />
                    )}
                    <StyledButton
                      variant="outlined"
                      color="background6"
                      icon="restart"
                      onClick={() => {
                        setRestartModalOpen(true);
                        setExecutionsToRestart([execution.arn]);
                      }}
                    />
                  </ButtonContainer>
                </ActionButtonsCell>
              )}
            </StyledRow>
          );
        })}
      />
    </>
  );
};
ExecutionTable.displayName = 'ExecutionTable';
