import React from 'react';

import { useQueryClient } from 'react-query';

import { Button } from '@glean/glean-ui.molecules.button';

import { useUpdateCanonicalVendor } from 'shared/api';
import { CanonicalVendorResponseT } from 'shared/api/generated';
import { toast } from 'shared/components/toast';
import { VendorEditForm } from 'shared/components/vendor-form';
import { constructLocation } from 'shared/utils/location';

import { Box, Caption, FlexEnd, Grid, Heading, Item, StyledModal, Value } from './styles';

export const Details = ({ vendor }: { vendor: CanonicalVendorResponseT }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateVendor } = useUpdateCanonicalVendor();
  return (
    <>
      <FlexEnd>
        <StyledModal
          maxWidth={800}
          withCloseIcon={false}
          renderHandle={() => <Button style={{ width: 80 }}>Edit</Button>}
          renderContent={(modal) => (
            <VendorEditForm
              vendorData={vendor}
              onSubmit={async (formData: any) => {
                try {
                  await updateVendor({
                    canonicalVendorId: String(vendor.canonical_vendor_id),
                    input: formData,
                  });
                  await queryClient.refetchQueries('manageVendor');
                  toast.success('Vendor updated successfully.');
                } catch {
                  toast.danger('Vendor could not be updated.');
                }
                modal.close();
              }}
              onCancel={modal.close}
            />
          )}
        />
      </FlexEnd>

      <Box>
        <Heading>About</Heading>
        <Grid>
          <Item>
            <Caption>Display Name</Caption>
            <Value>{vendor.display_name || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Legal Name</Caption>
            <Value>{vendor.legal_name || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Description</Caption>
            <Value>{vendor.description || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Industry Group</Caption>
            <Value>{vendor.industry_group || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Sector</Caption>
            <Value>{vendor.sector || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Founded Year</Caption>
            <Value>{vendor.founded_year || 'n/a'}</Value>
          </Item>
        </Grid>
      </Box>

      <Box>
        <Heading>Contact Information</Heading>
        <Grid>
          <Item>
            <Caption>Email Address</Caption>
            <Value>{vendor.email_address || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Phone Number</Caption>
            <Value>{vendor.phone_number || 'n/a'}</Value>
          </Item>
          <Item>
            <Caption>Address</Caption>
            <Value>{vendor.location_clearbit || constructLocation(vendor) || 'n/a'}</Value>
          </Item>
        </Grid>
      </Box>
    </>
  );
};
