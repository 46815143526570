import React, { forwardRef, useEffect, useState } from 'react';

import { uniq } from 'lodash';

import { Icon, IconPropT } from '../icon';
import { IconContainer, StyledSelect, Wrapper } from './styles';

type Props = {
  className?: string;
  iconEnd?: IconPropT['type'];
  iconStart?: IconPropT['type'];
  invalid?: boolean;
  isGroup?: boolean;
  onChange?: (...args: any[]) => any;
  options: {
    label?: string;
    value?: string | number;
    category?: string;
  }[];
  value?: string | number;
};

export const Select = forwardRef<any, Props>(
  (
    {
      iconEnd,
      iconStart,
      isGroup,
      invalid = false,
      onChange = () => {},
      options,
      value,
      ...selectProps
    },
    ref,
  ) => {
    const endIcon = iconEnd || 'chevronDown';

    const [isDefault, setIsDefault] = useState(false);
    const [selected, setSelected] = useState(value);
    useEffect(() => {
      selected === '' ? setIsDefault(true) : setIsDefault(false);
    }, [selected]);

    const handleChange = (event: any) => {
      setSelected(event.target.value);
      onChange(event.target.value, event);
    };

    const renderGroupedOptions = (
      options: {
        label?: string | undefined;
        value?: string | number | undefined;
        category?: string | undefined;
      }[],
    ) => {
      const categories = uniq(options.map((option: any) => option.category)).filter(
        (str) => str !== undefined,
      );
      const defaultOption = options.find((item: any) => item.value === '');

      return (
        <>
          {defaultOption && (
            <option value={defaultOption.value} hidden={true}>
              {defaultOption.label}
            </option>
          )}

          {categories.map((item) => (
            <optgroup label={item} key={item}>
              {options.map(({ label, value, category }: any) => {
                if (category === item) {
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                }
                return null;
              })}
            </optgroup>
          ))}
        </>
      );
    };

    return (
      <Wrapper>
        <StyledSelect
          {...selectProps}
          value={value}
          invalid={invalid}
          isDefault={isDefault}
          onChange={(event) => handleChange(event)}
          ref={ref}
          withIconEnd={!!iconEnd}
          withIconStart={!!iconStart}
        >
          {isGroup
            ? renderGroupedOptions(options)
            : options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
        </StyledSelect>

        {iconStart && (
          <IconContainer isStart>
            {typeof iconStart === 'string' ? <Icon type={iconStart} size={19} /> : iconStart}
          </IconContainer>
        )}

        <IconContainer style={{ pointerEvents: iconEnd ? 'auto' : 'none' }}>
          {typeof endIcon === 'string' ? <Icon type={endIcon} size={19} /> : endIcon}
        </IconContainer>
      </Wrapper>
    );
  },
);
Select.displayName = 'Select';
