import styled from 'styled-components';

import { color, font, mixin } from 'shared/styles';

export const Image = styled.div<{ src: string; size?: number; isSquare?: boolean }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  border-radius: ${(props) => (props.isSquare ? 6 : 999)}px;
  background-color: ${color.backgroundContrast};
  border: 3px solid ${color.backgroundContrast};
  ${(props) => mixin.backgroundImage(props.src)}
`;
export const Letter = styled.div<{ src?: string; size?: number; isSquare?: boolean }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  border-radius: ${(props) => (props.isSquare ? 2 : 999)}px;
  text-transform: uppercase;
  color: ${color.text1};
  background: ${color.background5};
  ${font.bold}
  ${(props) => font.size(Math.round(props.size || 0 / 1.7))}
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;
