import React from 'react';

import { Button, ButtonProps } from '@glean/glean-ui.molecules.button';

import { ExecutionModalButtonRow } from './styles';

const ConfirmButton = (props: Omit<ButtonProps, 'ref'>) => <Button {...props} />;
ConfirmButton.displayName = 'ConfirmButton';
const CancelButton = (props: Omit<ButtonProps, 'ref'>) => (
  <Button {...props} variant={props.variant || 'unstyled'} />
);
CancelButton.displayName = 'CancelButton';
export const ModalControls = {
  Container: ExecutionModalButtonRow,
  ConfirmButton,
  CancelButton,
};
