import React from 'react';

import { Container, Instructions, Label, UserProvidedDetails, Value } from './styles';

export const OriginSection = ({
  requestedVendorName,
  requestedVendorUrl,
}: {
  requestedVendorName: string;
  requestedVendorUrl: string;
}) => (
  <Container>
    <Instructions>
      <p>
        A user has requested a new vendor. Please map the details the user provided to a canonical
        vendor
      </p>
      <br />
      <p>The user submitted the following information:</p>
      <br />
    </Instructions>
    <UserProvidedDetails>
      <div>
        <Label>Vendor Name: </Label>
        <Value>{requestedVendorName} </Value>
      </div>
      <div>
        <Label>Vendor Website: </Label> <Value> {requestedVendorUrl}</Value>
      </div>
    </UserProvidedDetails>
    <Instructions>
      <br />
      <p>
        If the canonical vendor on the right hand side does not match the information provided
        above, please select a new canonical vendor.
      </p>
    </Instructions>
  </Container>
);
