import React, { createContext } from 'react';

import { GleanSchema } from '@gleanhq/schema';

import { getValidationFlagStep } from './utils';

export type ValidationFlagT = {
  flag_id: string;
  line_item_id?: string | null;
  is_resolved: boolean;
  is_overridden: boolean;
  invoice_part: 'INVOICE' | 'VENDOR' | 'LINE_ITEMS' | 'CUSTOMER';
  field_section:
    | 'ADDRESS'
    | 'ID'
    | 'CONTACT'
    | 'COURIER_PAY_BY_CHECK'
    | 'NOTES'
    | 'PAY_BY_CHECK'
    | 'PAYMENT_BANK'
    | 'PAYMENT_OPTIONS'
    | 'CUSTOMER'
    | 'SHIP_TO'
    | 'COST'
    | 'PURCHASE_ORDER'
    | 'SUMMARY'
    | 'LINE_ITEMS';
  field_name:
    | 'FEDERAL_TAX_ID'
    | 'DBA_NAME'
    | 'LEGAL_NAME'
    | 'BILL_TO_NAME'
    | 'CUSTOMER_BILL_TO_NAME'
    | 'NUMBER'
    | 'INVOICE_NUMBER'
    | 'INVOICE_DATE'
    | 'DUE_DATE'
    | 'PERIOD_START_DATE'
    | 'PERIOD_END_DATE'
    | 'TOTAL_AMOUNT'
    | 'TOTAL_BILLED_AMOUNT'
    | 'PRETAX_SUBTOTAL_AMOUNT'
    | 'SUBTOTAL_AMOUNT'
    | 'AMOUNT_DUE'
    | 'AMOUNT_PAID'
    | 'ENDING_BALANCE'
    | 'CONTACT_PRIMARY_PHONE_NUMBER'
    | 'CONTACT_PRIMARY_BILLING_SUPPORT_PHONE_NUMBER'
    | 'CONTACT_PRIMARY_CONTACT_PHONE_NUMBER'
    | 'CREDIT_CARD_PAYMENT_PHONE_NUMBER'
    | 'CONTACT_PHONE_NUMBER'
    | 'ZIP_CODE'
    | 'PAYMENT_BANK_ZIP_CODE'
    | 'PAY_BY_CHECK_ZIP_CODE'
    | 'COURIER_PAY_BY_CHECK_ZIP_CODE'
    | 'BILL_TO_ZIP_CODE'
    | 'SHIP_TO_ZIP_CODE'
    | 'CONTACT_PRIMARY_URL'
    | 'CONTACT_PRIMARY_BILLING_SUPPORT_URL'
    | 'CREDIT_CARD_PAYMENT_URL'
    | 'CONTACT_PRIMARY_BILLING_SUPPORT_EMAIL'
    | 'CONTACT_PRIMARY_CONTACT_EMAIL'
    | 'CONTACT_EMAIL_ADDRESS'
    | 'NAME'
    | 'QUANTITY_UNITS'
    | 'QUANTITY'
    | 'UNIT_PRICE'
    | 'LINE_ITEM_TYPE'
    | 'BILLING_FREQUENCY'
    | 'PAYMENT_TERMS'
    | 'PAYMENT_CURRENCY'
    | 'ACCOUNT_NAME'
    | 'BANK_NAME'
    | 'BANK_ADDRESS'
    | 'ACH_ROUTING_NUMBER'
    | 'WIRE_ACCOUNT_NUMBER'
    | 'SWIFT_CODE'
    | 'PAY_BY_CHECK_MAKE_CHECK_PAYABLE_TO'
    | 'PAY_BY_CHECK_ADDRESS'
    | 'fees_and_surcharges'
    | 'credit_discount_amount'
    | 'total_tax_amount'
    | 'beginning_balance'
    | 'period_end_date'
    | 'description';
};

export type CurrentStepT =
  | 'SUMMARY'
  | 'SHIPPING'
  | 'PAYMENT_ACH'
  | 'PAYMENT_CHECK'
  | 'DETAIL_COSTS'
  | 'LINE_ITEMS';

export type ExtractValidateStateT = {
  currentStep: CurrentStepT;
  formValues: Partial<GleanSchema>;
  errors?: { errors?: Record<'LINE_ITEMS', string[]>; hasErrors?: boolean };
  validationFlags: ValidationFlagT[];
  focusedFlag: null | ValidationFlagT;
  jobType: 'detail-extract' | 'detail-validate' | 'header-extract' | 'header-validate';
  jobAction: 'extract' | 'validate';
};

type MergeActionT = {
  type: 'MERGE';
  payload: Partial<ExtractValidateStateT>;
};

type ChangeFocusFlagActionT = {
  type: 'CHANGE_FOCUS_FLAG';
  payload: {
    flag: ValidationFlagT;
  };
};

type UpdateFlagActionT = {
  type: 'UPDATE_VALIDATION_FLAG';
  payload: {
    flagId: string;
    updateFn: (flag?: ValidationFlagT) => Partial<ValidationFlagT>;
  };
};

export type ExtractValidationActionT = MergeActionT | UpdateFlagActionT | ChangeFocusFlagActionT;

export const extractValidateReducer: React.Reducer<
  ExtractValidateStateT,
  ExtractValidationActionT
> = (state: ExtractValidateStateT, action: ExtractValidationActionT): ExtractValidateStateT => {
  switch (action.type) {
    case 'MERGE':
      return { ...state, ...action.payload };
    case 'UPDATE_VALIDATION_FLAG': {
      const { flagId, updateFn } = action.payload;
      return {
        ...state,
        validationFlags: state.validationFlags.map((flag) =>
          flag.flag_id === flagId ? { ...flag, ...updateFn(flag) } : flag,
        ),
      };
    }
    case 'CHANGE_FOCUS_FLAG': {
      const { flag: incomingFlag } = action.payload;
      const newCurrentStep = getValidationFlagStep(incomingFlag);

      return {
        ...state,
        currentStep: newCurrentStep,
        focusedFlag: incomingFlag,
      };
    }
    default:
      throw new Error(`Unrecognized action ${action}`);
  }
};

export const ExtractValidateContext = createContext<
  [ExtractValidateStateT, React.Dispatch<ExtractValidationActionT>]
>((null as unknown) as [ExtractValidateStateT, React.Dispatch<ExtractValidationActionT>]);
