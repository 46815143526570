import React, { useContext, useEffect, useState } from 'react';

import { get } from 'lodash';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { sumTotal } from 'shared/utils/money';

import { ExtractValidateContext, ValidationFlagT } from 'views/job/extract-validate/state';

import { FormContext } from '../..';
import { Divider, Field, Grid, Label } from '../shared';
import { ValidationFlagIcon } from '../shared/field/styles';
import { ValidationFlagsPopover } from '../shared/field/validation-flags-popover';
import {
  CostsGrid,
  CostsRow,
  Total,
  TotalContainer,
  TotalLabel,
  TotalLineContainer,
  Warning,
} from './styles';

const parseFloatOrEmptyString = (input: string): number => {
  const num = parseFloat(input);
  if (isNaN(num)) return 0;
  return num;
};

export const DetailCosts = () => {
  const [{ validationFlags, jobAction }, dispatch] = useContext(ExtractValidateContext);
  const totalBilledAmountFlags = validationFlags.filter(
    (flag) => flag.field_name === 'TOTAL_BILLED_AMOUNT',
  );
  const activeTotalBilledAmountFlags = totalBilledAmountFlags.filter(
    (flag) => !flag.is_overridden && !flag.is_resolved,
  );
  //@ts-expect-error
  const { values, initialFormValues, setFieldValue } = useContext(FormContext);
  const initialTotal = parseFloatOrEmptyString(
    get(initialFormValues, 'invoice.cost.total_billed_amount', 0),
  );
  const [totalInputted, setTotalInputted] = useState<number | undefined>();
  const [error, setError] = useState(false);
  useEffect(() => {
    const currency = get(values, 'invoice.summary.payment_currency');
    const subtotal = parseFloatOrEmptyString(get(values, 'invoice.cost.subtotal_amount', 0));
    const fees = parseFloatOrEmptyString(get(values, 'invoice.cost.fees_and_surcharges', 0));
    const credit = parseFloatOrEmptyString(get(values, 'invoice.cost.credit_discount_amount', 0));
    const tax = parseFloatOrEmptyString(get(values, 'invoice.cost.total_tax_amount', 0));

    const calculatedTotal = sumTotal({ subtotal, credit, fees, tax, currency });
    if (calculatedTotal !== null) {
      setTotalInputted(calculatedTotal);
      setError(calculatedTotal !== initialTotal);
    }
  }, [initialTotal, values]);

  const updateValidationFlag = (
    flagId: string,
    updateFn: (flag?: ValidationFlagT) => Partial<ValidationFlagT>,
  ) => {
    dispatch({
      type: 'UPDATE_VALIDATION_FLAG',
      payload: {
        flagId,
        updateFn,
      },
    });
  };

  useEffect(() => {
    for (let validationFlag of totalBilledAmountFlags) {
      dispatch({
        type: 'UPDATE_VALIDATION_FLAG',
        payload: {
          flagId: validationFlag.flag_id,
          updateFn: (flag) => ({ ...flag, is_resolved: !error }),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  return (
    <Grid>
      <Label>Costs</Label>
      <Divider />
      <CostsGrid>
        <CostsRow>
          <div />
          <Field name="invoice.cost.subtotal_amount" />
        </CostsRow>
        <CostsRow>
          <Icon icon="plus" color="secondary-2" />
          <Field name="invoice.cost.fees_and_surcharges" />
        </CostsRow>
        <CostsRow>
          <Icon icon="minus" color="secondary-2" />
          <Field name="invoice.cost.credit_discount_amount" />
        </CostsRow>
        <CostsRow>
          <Icon icon="plus" color="secondary-2" /> <Field name="invoice.cost.total_tax_amount" />
        </CostsRow>
        <CostsRow>
          <Icon icon="equals" color="secondary-2" />
          <TotalContainer>
            <TotalLabel>Total Billed Amount</TotalLabel>
            <TotalLineContainer>
              <Total>${initialTotal}</Total>
              {jobAction === 'validate' && totalBilledAmountFlags.length > 0 && (
                <ValidationFlagsPopover
                  fieldFlags={totalBilledAmountFlags}
                  updateValidationFlag={updateValidationFlag}
                  onRevertToInitialValueClick={() => {
                    totalBilledAmountFlags.forEach(({ flag_id }: any) => {
                      updateValidationFlag(flag_id, () => ({ is_resolved: false }));
                    });
                    for (let fieldName of [
                      'invoice.cost.subtotal_amount',
                      'invoice.cost.fees_and_surcharges',
                      'invoice.cost.credit_discount_amount',
                      'invoice.cost.total_tax_amount',
                    ]) {
                      setFieldValue(fieldName, get(initialFormValues, fieldName));
                    }
                  }}
                >
                  <ValidationFlagIcon
                    icon={activeTotalBilledAmountFlags.length > 0 ? 'flag' : 'check'}
                    color={activeTotalBilledAmountFlags.length > 0 ? 'warning' : 'success'}
                    size="medium"
                  />
                </ValidationFlagsPopover>
              )}
            </TotalLineContainer>
          </TotalContainer>
        </CostsRow>
        {error && (
          <CostsRow>
            <div />

            <Warning>
              <Icon icon="alertTriangle" size={'medium'} /> ${totalInputted} Amount does not add up
            </Warning>
          </CostsRow>
        )}
      </CostsGrid>
      <Divider />
      <Label>Balance Activity</Label>
      <Field name="invoice.cost.beginning_balance" />
      <Field name="invoice.cost.amount_paid" />
      <Field name="invoice.cost.ending_balance" />
    </Grid>
  );
};
