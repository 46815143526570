import styled from 'styled-components';

import { font } from 'shared/styles';

export const NoResult = styled.div`
  padding: 100px 30px;
  text-align: center;

  h1 {
    padding-bottom: 30px;
    ${font.size(22)}
  }
`;
