import React, { useState } from 'react';

import { truncate, uniq } from 'lodash';

import { Icon } from '@glean/glean-ui.atoms.icon';
import { Button } from '@glean/glean-ui.molecules.button';
import { LineItemElement as LineItemT } from '@gleanhq/schema';

import { IndicatorDot } from 'shared/components/indicatorDot';
import { Modal } from 'shared/components/modal';
import { Popover } from 'shared/components/popover';
import { replaceAtIndex } from 'shared/utils/javascript';
import { formatCurrency, formatCurrencyDelta } from 'shared/utils/numbers';

import { CanonicalLineItemSelect } from './canonical-line-item-select';
import { InvoiceCompare } from './invoice-compare';
import {
  Description,
  Edit,
  Heading,
  Label,
  Row,
  ShadingMessage,
  SmallCaption,
  SmallHeading,
  StyledLineItem,
  Top,
  Value,
} from './styles';

export const LineItem = ({
  index,
  data,
  vendorClis,
  addToVendorClis,
  cli,
  oli,
  canonicalVendor,
  mappedClis,
  setMappedClis,
  isShaded,
  isReprocessed,
}: {
  oli: LineItemT;
  isShaded?: boolean;
  index: number;
  isReprocessed: boolean;
} & Omit<any, 'oli' | 'isShaded' | 'index'>) => {
  const [isCollapsed, setIsCollapsed] = useState(!!isShaded);
  // const [confidenceScoreFF] = useFeatureFlag('stp_ExtractValidateConfidenceScoresOn');

  // to be implemented when line item confidence scores are available
  const showConfidenceScore = false;

  const canRemap = isReprocessed || !isShaded;

  return (
    <StyledLineItem id={`proof-line-item-${oli.line_item_id}`}>
      <Top>
        <Heading
          onClick={() => {
            if (!isShaded) return;
            setIsCollapsed((state) => !state);
          }}
          isShaded={isShaded}
        >
          Line item #{index + 1}{' '}
          {isShaded && <Icon icon={isCollapsed ? 'chevronRight' : 'chevronDown'} />}
        </Heading>

        <Modal
          maxWidth={1440}
          fullHeight
          renderHandle={() => (
            <Button color="background4" size="small">
              View PDFs
            </Button>
          )}
          renderContent={() => (
            <InvoiceCompare
              data={data}
              canonicalVendor={canonicalVendor}
              canonicalLineItem={cli}
              originLineItemName={oli.name}
            />
          )}
        />
      </Top>
      {!isCollapsed && (
        <>
          {isShaded && (
            <ShadingMessage>This line item has been mapped automatically</ShadingMessage>
          )}
          <Row>
            <div />
            <Label>Origin</Label>
            <Edit>
              <Label>Canonical</Label>
              {canRemap && (
                <Modal
                  maxWidth={1200}
                  fullHeight
                  renderHandle={() => (
                    <Button variant="outlined" size="small" style={{ marginLeft: 10 }}>
                      Switch
                    </Button>
                  )}
                  renderContent={(modal) => (
                    <CanonicalLineItemSelect
                      data={data}
                      vendorClis={vendorClis}
                      addToVendorClis={addToVendorClis}
                      setMappedCliAtCurrentIndex={(newCli: any) =>
                        setMappedClis(replaceAtIndex(mappedClis, newCli, index))
                      }
                      originLineItem={oli}
                      closeModal={modal.close}
                    />
                  )}
                />
              )}
            </Edit>
            <Label>Delta</Label>
          </Row>
          <Row>
            <Label>Name</Label>
            <div>
              <Value>{oli.name}</Value>
              <SmallHeading>Description</SmallHeading>
              <Description>{oli.description}</Description>
            </div>
            <div>
              <Value>{cli?.name}</Value>
              <SmallHeading>Previously mapped items</SmallHeading>
              <Description>
                {getCliNamesAsString(cli).length
                  ? truncate(getCliNamesAsString(cli), { length: 145 })
                  : 'n/a'}
              </Description>
            </div>
            <div />
            {showConfidenceScore && (
              <Popover interaction="hover" content="Confidence score is low for this mapping">
                <div style={{ cursor: 'pointer' }}>
                  <IndicatorDot />
                </div>
              </Popover>
            )}
          </Row>
          <Row>
            <Label>Unit price</Label>
            <Value>{formatCurrency(oli.unit_price)}</Value>
            <Value>
              {formatCurrency(cli?.average_unit_price)}
              <SmallCaption>average</SmallCaption>
            </Value>
            <Value>{formatCurrencyDelta(oli.unit_price, cli?.average_unit_price)}</Value>
          </Row>
          <Row>
            <Label>Total cost</Label>
            <Value>{formatCurrency(oli.total_amount)}</Value>
            <Value>
              {formatCurrency(cli?.average_total_amount)}
              <SmallCaption>average</SmallCaption>
            </Value>
            <Value>{formatCurrencyDelta(oli.total_amount, cli?.average_total_amount)}</Value>
          </Row>
          <Row>
            <Label>Attributes</Label>
            <div>
              <Description>
                Quantity: {oli.quantity} <br />
                Start Date: {oli.period_start_date} <br />
                End Date: {oli.period_end_date} <br />
              </Description>
            </div>
            <div></div>
            <div />
          </Row>
        </>
      )}
    </StyledLineItem>
  );
};

const getCliNamesAsString = (cli: any) => uniq(cli?.names || []).join(', ');
