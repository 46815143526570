import { useMutation, useQuery, useQueryClient } from 'react-query';

import { apiClient } from '..';

export const useGetCompanies = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['companies'],
    queryFn: () => apiClient.companyServiceGetCompanies(),
    onSuccess: (companies) => {
      for (const company of companies) {
        queryClient.setQueryData(['company', company.id], company);
      }
    },
  });
};

export const useGetCompanyById = ({ companyId }: { companyId?: string }) =>
  useQuery({
    queryKey: ['company', companyId],
    queryFn: async () => {
      if (!companyId) {
        return;
      } else {
        return await apiClient.companyServiceGetCompanyById({ companyId });
      }
    },
  });

export const useGetCompanyPriorities = () => {
  return useQuery({
    queryKey: ['company-priorities'],
    queryFn: () => apiClient.companyPriorityServiceGetCompanyPriorities(),
  });
};

export const useEditCompanyPriority = () =>
  useMutation(({ companyId, priority }: { companyId: string; priority: number }) =>
    apiClient.companyPriorityServiceEditCompanyPriority({
      input: {
        companyId,
        priority,
      },
    }),
  );
