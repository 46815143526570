// @ts-nocheck
import styled from 'styled-components';

import { color, font, mixin } from 'shared/styles';

export const Vendor = styled.div`
  display: flex;
  align-items: center;
`;

export const VendorInfo = styled.div`
  padding-left: 16px;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 30px 25px 6px;
  ${mixin.scrollableY}
  ${mixin.customScrollbar}

  /* hack to vertically center children within scrollable container */
  &:before, &:after {
    content: '';
  }
  &:before {
    margin-top: auto;
  }
  &:after {
    margin-bottom: auto;
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 25px;
  background: ${color.background3};
`;

export const SwitchVendorHeading = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ${font.bold}
`;
