import styled, { css } from 'styled-components';

import { Cell, Row } from 'shared/components/table';
import { border, color, font, mixin, padding } from 'shared/styles';

export const Results = styled.div`
  padding: 12px 0;
`;

export const StyledRow = styled(Row)`
  ${font.size(14)}
  ${mixin.clickable}
  &:hover {
    ${font.bold}
  }
  ${(props) =>
    props.isActive &&
    css`
      background: ${color.primary} !important;
      ${font.bold}
    `}
`;

export const StyledCell = styled(Cell)`
  white-space: unset;
  text-overflow: unset;
  padding: ${padding.sm};
`;

export const TableScrollContainer = styled.div`
  position: relative;
  height: 70%;
  overflow: scroll;
  th {
    background: ${color.background1};
    position: sticky;
    /* to account for the border along the top of the header row */
    top: -1px;
  }
`;

export const SearchAndCreateContainer = styled.div`
  display: flex;

  display: grid;
  gap: ${padding.med};
  grid-template-columns: 70% 30%;
  padding: ${padding.lrg};
  border-top: ${border.section};
`;
