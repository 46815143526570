import React from 'react';

import { FlaggAdmin } from 'flagg';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { useAuth } from 'shared/auth';
import { Modal } from 'shared/components/modal';

import * as styles from './styles';

export const FlaggAdminModal = () => {
  const { permissions } = useAuth();
  const showModal = permissions.includes('proof:flags');

  if (!showModal) return null;
  return (
    <Modal
      renderHandle={() => {
        return (
          <styles.RenderHandleContainer>
            <Icon icon="flag" color="warning" />
          </styles.RenderHandleContainer>
        );
      }}
      renderContent={({ close }) => <FlaggAdmin onDone={close} />}
    />
  );
};
