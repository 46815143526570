import styled from 'styled-components';

import { border, color, font, mixin, padding } from 'shared/styles';

export const StyledLineItem = styled.div`
  padding-top: 24px;
  &:not(:last-of-type) {
    padding-bottom: 24px;
    border-bottom: ${border.section};
  }
`;

export const ItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  ${mixin.clickable}
  ${font.medium}
`;

export const ReadOnlyFieldWrapper = styled.div`
  input {
    background: transparent;
    border: none;
    ${font.size(20)};
    ${font.medium};
  }
`;

export const InstallmentFieldsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${padding.sm};
  width: 50%;
  p {
    margin-bottom: ${padding.xs};
  }
`;

export const LineItemTopNav = styled.div`
  margin-left: calc(${padding.lrg} * -1);
  margin-right: calc(${padding.lrg} * -1);
  display: grid;
  align-items: center;
  grid-template-columns: calc(100% - 150px) 100px 50px;
  ${font.size(13)}
  position: sticky;
  top: 0;
  background: ${color.background3};
  z-index: 1;
`;

export const LineItemTopNavCell = styled.div`
  display: flex;
  align-items: center;
  border: ${border.section};
  border-radius: 0;
  padding: ${padding.med};
  cursor: pointer;
  &:hover {
    background: ${color.hoverBackground};
  }

  &:first-child {
    padding: 13px;
  }
`;
export const LineItemDropdownBar = styled.div`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const LineItemDropdownLabel = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const LineItemDropdownList = styled.ul`
  display: block;
  position: absolute;
  top: 16px;
  border-radius: ${border.borderRadius};
  background: ${color.background5};
  padding: ${padding.med};
  width: 100%;
  max-height: 60vh;
  overflow: scroll;
`;
export const LineItemDropdownItem = styled.li`
  padding: ${padding.sm};
  &:hover {
    background: ${color.hoverBackground};
  }
`;
