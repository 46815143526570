import styled from 'styled-components';

import { color } from 'shared/styles';

export const ExecutionModalButtonRow = styled.div`
  display: flex;
  padding: 16px 32px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${color.border2};
  gap: 16px;
`;
ExecutionModalButtonRow.displayName = 'ExecutionModalButtonRow';
