import React from 'react';

import styled from 'styled-components';

import { color } from 'shared/styles';
import { pipelineStepPrettyName } from 'shared/utils/pipeline-status-pretty-name';

type ExecutionStatusIndicatorProps = {
  executionStatus: 'SUCCEEDED' | 'ABORTED' | 'FAILED' | 'RUNNING';
  executionStep: string;
};
export const ExecutionStatusIndicator = ({
  executionStatus,
  executionStep,
}: ExecutionStatusIndicatorProps) => {
  const heading = getHeading(executionStatus);
  return (
    <IndicatorGrid>
      <Indicator executionStatus={executionStatus}></Indicator>
      <IndicatorTitle>{heading}</IndicatorTitle>
      <IndicatorSub>{pipelineStepPrettyName(executionStep)}</IndicatorSub>
    </IndicatorGrid>
  );
};
const getHeading = (executionStatus: any) => {
  switch (executionStatus) {
    case 'SUCCEEDED':
      return 'Succeeded';
    case 'ABORTED':
      return 'Aborted';
    case 'FAILED':
      return 'Failed';
    default:
      return 'In Progress';
  }
};
const IndicatorGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-row-gap: 2px;
`;
const IndicatorTitle = styled.p`
  color: $fff;
  font-size: 13px;
  line-height: 16px;
`;
const IndicatorSub = styled.p`
  color: ${color.text4};
  font-size: 13px;
  line-height: 16px;
  grid-column-start: 2;
  grid-column-end: 3;
`;
const Indicator = styled.div<{ executionStatus: ExecutionStatusIndicatorProps['executionStatus'] }>`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  position: relative;
  top: 1px; /*it just looks off*/

  ${(props) => {
    switch ((props as any).executionStatus) {
      case 'PIPELINE_EXECUTION_RESTART':
      case 'ABORTED':
        return 'background-color: #636681';
      case 'FAILED':
        return 'background-color: #E31940';
      case 'SUCCEEDED':
        return 'background-color: #00A090';
      default:
      case 'IN_PROGRESS':
        return 'background-color: #E5C100';
    }
  }}
`;
