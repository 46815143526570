import styled from 'styled-components';

import { font, padding } from 'shared/styles';

export const ExecutionModalContainer = styled.div``;
ExecutionModalContainer.displayName = 'ExecutionModalContainer';

export const ExecutionModalTitle = styled.h1`
  ${font.bold}
  ${font.size(16)}
  padding: ${padding.xlrg};
`;
ExecutionModalTitle.displayName = 'ExecutionModalTitle';

export const RestartModalFormFields = styled.div`
  // This wrapper component groups the fields together so we can use last-of-type in RestartModalStepSelectContainer
`;
RestartModalFormFields.displayName = 'RestartModalFormFields';

export const RestartModalLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 275px;
`;
RestartModalLoadingContainer.displayName = 'RestartModalLoadingContainer';

export const RestartModalStepSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${padding.sm} ${padding.xlrg};

  input,
  select,
  textarea {
    width: 350px;
  }

  :last-of-type {
    padding-bottom: ${padding.xlrg};
  }
`;
RestartModalStepSelectContainer.displayName = 'RestartModalStepSelectContainer';
