import styled from 'styled-components';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { color, padding } from 'shared/styles';

export const ValidationFlagIcon = styled(Icon)`
  cursor: pointer;
  margin-top: 3px;
  ${(props) => props.type === 'flag' && `color: ${color.warning};`}
`;

export const FlagContainer = styled.div`
  display: grid;
  gap: ${padding.sm};
  place-items: center;
  grid-template-columns: 1fr 1fr;
  margin-right: ${padding.med};
`;
