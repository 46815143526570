import styled from 'styled-components';

import { color, font, padding } from 'shared/styles';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Left = styled.div`
  width: 60%;
  padding: 20px 15px 20px 20px;
`;

export const Heading = styled.h1`
  margin-bottom: 20px;
  ${font.size(16)};
  ${font.bold};
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40%;
  padding-left: 15px;

  iframe,
  embed {
    flex: 1;
    border: none;
    width: 100%;
  }
`;

export const InvoiceLabel = styled.div`
  display: flex;
  align-items: center;
  height: 58px;
  color: ${color.text2};
  ${font.size(20)}
`;

export const OriginLineItemGrid = styled.div`
  width: 100%;
  display: grid;
  padding: ${padding.lrg};

  grid-template-columns: 60% 20% 20%;
  grid-template-areas: 'name price-label-1 price-label-2' 'description price-1 price-2';
`;

export const OriginLineItemName = styled.div`
  grid-area: name;
  ${font.size(14)};
  ${font.bold};
  color: ${color.text5};
`;

export const OriginLineItemDescription = styled.div`
  grid-area: description;
  ${font.size(13)};
  color: ${color.text5};
`;

export const OriginLineItemPriceLabel = styled.div`
  text-align: right;
  ${font.size(13)};
  ${font.bold};
  text-transform: uppercase;
  ${color.text4};
  &:first-of-type {
    grid-area: price-label-1;
  }
  &:last-of-type {
    grid-area: price-label-2;
  }
`;

export const OriginLineItemPrice = styled.div`
  text-align: right;
  ${font.size(14)}
  ${font.medium};
  text-transform: uppercase;
  ${color.text5};
  &:first-of-type {
    grid-area: price-1;
  }
  &:last-of-type {
    grid-area: price-2;
  }
`;
