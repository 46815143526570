import styled from 'styled-components';

import { color, padding } from 'shared/styles';

export const Outer = styled.div`
  display: flex;
  place-content: center;
  place-items: center;
`;

export const Inner = styled.div`
  background: ${color.background3};
  padding: ${padding.lrg};
  min-width: 600px;
  display: grid;
  gap: ${padding.med};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  background: ${color.background3};
  padding: ${padding.med};
  border-radius: 6px;
`;

export const FieldRow = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr 32px;
  align-items: center;
  border-top: 1px solid ${color.border2};
  padding: ${padding.med};
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${color.border2};
  padding: ${padding.med};
`;
