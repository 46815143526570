import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { CanonicalVendor, GleanSchema } from '@gleanhq/schema';

import * as api from 'shared/api';
import { useGetCompanyById } from 'shared/api';
import { CompanyDTO } from 'shared/api/generated';
import { ConfirmModal } from 'shared/components';
import { CommentsStream } from 'shared/components/comments-stream';
import { toast } from 'shared/components/toast';
import { useCommentsData } from 'shared/hooks/comments-meta-data';
import { useMergeState } from 'shared/hooks/merge-state';
import { JobT } from 'shared/types/job';

import { MapVendor } from './map-vendor';
import { MapCurateNavBar } from './navbar';
import { localStateToCanonicalPayload } from './utils';

export type HeaderCanonicalPayloadT = Pick<GleanSchema, 'canonical_map' | 'canonical_vendor'> & {
  canonical_vendor_map?: { canonical_vendor_id?: string };
};

export type HeaderMapCurateUiStateT = {
  canonicalVendor:
    | null
    | (CanonicalVendor & { canonical_vendor_id?: string; isNew?: boolean; is_staging?: boolean });
  isCanonicalVendorLoaded: boolean;
  sampleInvoices: { file_url: string; invoice_number: string }[];
  sampleLineItems: string[];
  sampleInvoiceIndex: number;
  isVendorStepValid: boolean;
};

const cleanMapCurateData = ({
  originalInvoiceData,
  mapPayload,
}: {
  originalInvoiceData: GleanSchema & { canonical_vendor_map?: { canonical_vendor_id: string } };
  mapPayload: HeaderCanonicalPayloadT;
}) => {
  const clonedOriginalInvoiceData = { ...originalInvoiceData };
  delete clonedOriginalInvoiceData.canonical_vendor;
  delete clonedOriginalInvoiceData.canonical_map;
  delete clonedOriginalInvoiceData.canonical_vendor_map;
  const invoice = { ...clonedOriginalInvoiceData, ...mapPayload };

  return invoice;
};

/**
 * checks the following conditions to enable the "Submit" button
 *
 *  1. if the mapPayload has a valid canonical_vendor or canonical_vendor_id
 *  1. if the invoice company has loaded and the company name is available
 *
 * If either of these checks fail, it is not safe to submit the job.
 */
const getCanSubmit = ({
  mapPayload,
  companyLoaded,
  companyName,
}: {
  mapPayload: HeaderCanonicalPayloadT;
  companyLoaded: boolean;
  companyName?: string;
}) => {
  if (!companyLoaded || typeof companyName !== 'string') return false;
  const isMapPayloadValid =
    !!mapPayload?.canonical_vendor_map?.canonical_vendor_id || !!mapPayload.canonical_vendor;
  return isMapPayloadValid;
};

const getIsCompanyTheSameAsVendor = ({
  canonicalVendor,
  company,
}: {
  canonicalVendor: HeaderMapCurateUiStateT['canonicalVendor'];
  company?: CompanyDTO;
}) => {
  if (canonicalVendor?.display_name.toUpperCase() === company?.display_name.toUpperCase())
    return true;
  if (canonicalVendor?.legal_name.toUpperCase() === company?.display_name.toUpperCase())
    return true;
  if (canonicalVendor?.domain?.toUpperCase() === company?.domain_name?.toUpperCase()) return true;

  return false;
};

export const Job = ({
  data,
  fetchJob,
  deescalating,
}: {
  data: { invoiceData: GleanSchema; job: JobT };
  fetchJob: () => Promise<void>;
  deescalating: boolean;
}) => {
  const { job, invoiceData } = data;
  const { executionArn, jobType, companyId } = job;
  const { data: companyData, isLoading: companyDataLoading } = useGetCompanyById({ companyId });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [mapPayload, setMapPayload] = useState<HeaderCanonicalPayloadT>({});
  const [isVendorNameConfirmModalOpen, setIsVendorNameConfirmModalOpen] = useState(false);

  const canSubmit = getCanSubmit({
    mapPayload,
    companyLoaded: companyDataLoading,
    companyName: companyData?.display_name,
  });

  const initialState: HeaderMapCurateUiStateT = {
    canonicalVendor: null,
    isCanonicalVendorLoaded: false,
    sampleInvoices: [],
    sampleLineItems: [],
    sampleInvoiceIndex: 0,
    isVendorStepValid: canSubmit,
  };

  const [jobState, setJobState] = useMergeState(initialState);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setMapPayload(
      localStateToCanonicalPayload(
        invoiceData,
        jobState,
        jobType.toUpperCase().indexOf('MAP') > 1 ? 'MAP' : 'CURATE',
      ),
    );
  }, [invoiceData, jobState, jobType]);

  const onClickSubmit = async () => {
    const isCompanyAndVendorTheSame = getIsCompanyTheSameAsVendor({
      canonicalVendor: jobState.canonicalVendor,
      company: companyData,
    });

    if (isCompanyAndVendorTheSame) {
      return setIsVendorNameConfirmModalOpen(true);
    }

    return handleFinalSubmit();
  };

  const handleFinalSubmit = async () => {
    setIsSubmitting(true);
    const invoice = cleanMapCurateData({ originalInvoiceData: invoiceData, mapPayload });
    await api.createJobCompletion({
      invoice,
      job,
    });
    setIsSubmitting(false);
    toast.success('The job was completed successfully.');
    await fetchJob();
  };

  const handleSave = async () => {
    setIsSaving(true);
    const invoice = cleanMapCurateData({ originalInvoiceData: invoiceData, mapPayload });
    try {
      await api.createInvoiceSnapshot({
        job,
        invoice,
      });

      toast.success('Saved.');
    } catch (e) {
      toast.danger(e.message);
      toast.danger('Unable to save.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleEscalate = async () => {
    setIsSaving(true);
    const invoice = cleanMapCurateData({ originalInvoiceData: invoiceData, mapPayload });
    await api.createInvoiceSnapshot({
      job,
      invoice,
    });
    await fetchJob();
    toast.success('This job has been escalated.');
  };

  const { data: executionEvents, isFetching, error, refetch: refetchComments } = api.useQuery({
    queryKey: `execution-comments-${executionArn}`,
    queryFn: () => api.getComments({ executionArn }),
    transformData: (data: any) => {
      const { comments, escalations, restartCategory, restartReason, startingStep } = data?.data;
      return {
        comments,
        escalations,
        restartCategory,
        restartReason,
        startingStep,
      };
    },
  });

  const { commentsData, setCommentsData } = useCommentsData({
    comments: executionEvents?.comments,
    restartCategory: executionEvents?.restartCategory,
  });
  const [commentAdded, setCommentAdded] = useState<boolean>(false);
  const handleSubmitComment = async (commentText: string) => {
    try {
      await api.addComment({ executionArn, jobType, commentText: commentText, deescalating });
      setCommentAdded(true);
      refetchComments();
    } catch (e) {
      toast.danger(e.message);
    }
  };
  return (
    <>
      <ConfirmModal
        confirmText="This Mapping is Correct"
        isOpen={isVendorNameConfirmModalOpen}
        message="The name of the selected vendor is the same as the customer's name. Please double check that this is correct before continuing."
        onClose={() => setIsVendorNameConfirmModalOpen(false)}
        onConfirm={() => handleFinalSubmit()}
        title="Is this mapping correct?"
      />
      <MapCurateNavBar
        commentAdded={commentAdded}
        commentsData={commentsData}
        fetchJob={fetchJob}
        isDeescalating={deescalating}
        isSaving={isSaving}
        isSubmitEnabled={jobState.isVendorStepValid}
        isSubmitting={isSubmitting}
        job={job}
        handleEscalate={handleEscalate}
        handleFinalSubmit={onClickSubmit}
        handleSave={handleSave}
        setCommentsData={setCommentsData}
      />

      <MapVendor data={data} vendorStepState={jobState} onVendorStepStateChange={setJobState} />

      <CommentsStream
        error={error}
        comments={executionEvents?.comments || []}
        executionEvents={executionEvents}
        handleSubmitComment={handleSubmitComment}
        isFetching={isFetching}
        open={commentsData.commentsOpen}
      />
    </>
  );
};

Job.propTypes = {
  data: PropTypes.shape({
    invoiceData: PropTypes.shape({
      line_items: PropTypes.shape({}),
    }),
    job: PropTypes.shape({
      executionArn: PropTypes.string,
      fileBucket: PropTypes.string,
      fileKey: PropTypes.string,
      hasComments: PropTypes.bool,
      jobType: PropTypes.string,
    }),
  }),
  fetchJob: PropTypes.func,
  deescalating: PropTypes.bool,
};
