/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetricsT,
    MetricsTFromJSON,
    MetricsTFromJSONTyped,
    MetricsTToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPipelineMetricsT
 */
export interface GetPipelineMetricsT {
    /**
     * 
     * @type {MetricsT}
     * @memberof GetPipelineMetricsT
     */
    header: MetricsT;
    /**
     * 
     * @type {MetricsT}
     * @memberof GetPipelineMetricsT
     */
    detail: MetricsT;
}

export function GetPipelineMetricsTFromJSON(json: any): GetPipelineMetricsT {
    return GetPipelineMetricsTFromJSONTyped(json, false);
}

export function GetPipelineMetricsTFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPipelineMetricsT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': MetricsTFromJSON(json['header']),
        'detail': MetricsTFromJSON(json['detail']),
    };
}

export function GetPipelineMetricsTToJSON(value?: GetPipelineMetricsT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': MetricsTToJSON(value.header),
        'detail': MetricsTToJSON(value.detail),
    };
}


