import React, { useEffect, useState } from 'react';

import { history } from 'browserHistory';
import { get } from 'lodash';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Button } from '@glean/glean-ui.molecules.button';

import * as api from 'shared/api';
import { InactivityModal } from 'shared/components/inactivity-modal';
import { PageLoader } from 'shared/components/page-loader';

import { Job as JobDetailMapCurate } from './detail-map-curate';
import { Job as JobHeaderExtractValidate } from './extract-validate';
import { Job as JobHeaderIdentify } from './header-identify';
import { Job as JobHeaderMapCurate } from './header-map-curate';
import { Job as JobMultiInvoiceCheck } from './multi-invoice-check';
import { NoResult } from './styles';
import { Job as JobVendorRequestMap } from './vendor-request-map';

type DataT = { job: { id: string } } & ReturnType<typeof transformData>;

export const JobLoader = () => {
  const { jobType: pathJobType, jobId: pathJobId } = useParams<{
    jobType: string;
    jobId?: string;
  }>();
  const [urlJobId, setUrlJobId] = useState(pathJobId);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DataT | null>(null);
  const [noJobsLeft, setNoJobsLeft] = useState(false);
  const [jobNotFound, setJobNotFound] = useState(false);
  const [jobLocked, setJobLocked] = useState(false);
  const deescalating = useLocation().pathname.indexOf('deescalate') > 0;
  const [deescalateJobState, setDeescalateJobState] = useState({ jobId: null, jobType: null });
  const jobType = deescalating ? deescalateJobState.jobType : pathJobType;

  let JobComponent;
  switch (jobType) {
    case 'header-map':
    case 'header-curate':
      JobComponent = JobHeaderMapCurate;
      break;
    case 'header-extract':
    case 'header-validate':
    case 'detail-extract':
    case 'detail-validate':
      JobComponent = JobHeaderExtractValidate;
      break;
    case 'detail-map':
    case 'detail-curate':
      JobComponent = JobDetailMapCurate;
      break;
    case 'multi-invoice':
      JobComponent = JobMultiInvoiceCheck;
      break;
    case 'vendor-request-map':
      JobComponent = JobVendorRequestMap;
      break;
    default:
    case 'header-identify':
      JobComponent = JobHeaderIdentify;
      break;
  }

  useEffect(() => {
    if (typeof urlJobId !== 'undefined') {
      if (deescalating) {
        history.replace(`/deescalate/${urlJobId}`);
      } else {
        history.replace(`/job/${jobType}/${urlJobId}`);
      }
    }
  }, [urlJobId, jobType, deescalating, deescalateJobState]);

  const fetchJobFromQueue = async () => {
    setIsLoading(true);
    setUrlJobId(undefined);
    try {
      if (deescalating) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
        const { data } = await api.getQueuedDeescalateJob();
        setDeescalateJobState({ jobType: data.job.jobType, jobId: data.job.id });
        setUrlJobId(data.job.id);

        setData(transformData(data, data.job.jobType));
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
        const { data } = await api.getQueuedJob({ jobType });
        setUrlJobId(data.job.id);

        setData(transformData(data, jobType));
      }
    } catch (err) {
      if (get(err, 'response.status') === 404) {
        setNoJobsLeft(true);
      }
    }
    setIsLoading(false);
  };

  const fetchJobByID = async () => {
    setIsLoading(true);
    setUrlJobId(undefined);
    try {
      if (deescalating) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
        const { data } = await api.getDeescalateJobById({ jobId: pathJobId });
        setDeescalateJobState({ jobId: data.job.id, jobType: data.job.jobType });
        setData(transformData(data, data.job.jobType));
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
        const { data } = await api.getJobById({ jobType, jobId: pathJobId });
        setData(transformData(data, jobType));
      }
    } catch (err) {
      if (get(err, 'response.status') === 404) {
        setJobNotFound(true);
      }
      if (get(err, 'response.status') === 403) {
        setJobLocked(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (typeof pathJobId === 'undefined') {
      fetchJobFromQueue();
    } else {
      fetchJobByID();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <PageLoader message="Loading job data..." />;

  if (noJobsLeft)
    return (
      <NoResult>
        <h1>
          {deescalating ? (
            <>No escalated jobs currently require attention</>
          ) : (
            <>There are no {jobType} jobs left.</>
          )}
        </h1>
        <Link to="/">
          <Button icon="arrowLeft">Go back and pick another job type</Button>
        </Link>
      </NoResult>
    );

  if (jobNotFound)
    return (
      <NoResult>
        <h1>
          {deescalating ? (
            <> No job found with id {pathJobId}.</>
          ) : (
            <>
              No {jobType} job found with id {pathJobId}.
            </>
          )}
        </h1>
        <Link to="/">
          <Button icon="arrowLeft">Go back and pick another job type</Button>
        </Link>
      </NoResult>
    );

  if (jobLocked)
    return (
      <NoResult>
        <h1>The requested job is being worked on by another user.</h1>
        <Link to="/">
          <Button icon="arrowLeft">Go back and pick another job type</Button>
        </Link>
      </NoResult>
    );

  if (!data)
    return (
      <NoResult>
        <h1>There was a problem fetching this job.</h1>
        <Link to="/">
          <Button icon="arrowLeft">Go back to home</Button>
        </Link>
      </NoResult>
    );

  return (
    <>
      <InactivityModal jobId={data?.job?.id} />
      {/* @ts-expect-error */}
      <JobComponent data={data} fetchJob={fetchJobFromQueue} deescalating={deescalating} />
    </>
  );
};

const transformData = (data: any, jobType: any) => {
  switch (jobType) {
    case 'map':
    case 'curate':
    case 'header-map':
    case 'header-curate':
    case 'detail-map':
    case 'detail-curate': {
      return {
        job: data.job,
        invoiceData: data.data || {},
      };
    }
    case 'extract':
    case 'validate': {
      const { validation_flags, ...invoice } = data.data || {};
      return {
        job: data.job,
        invoiceData: invoice,
        validationFlags: jobType === 'extract' ? [] : validation_flags,
      };
    }
    case 'header-extract':
    case 'header-validate':
    case 'detail-extract':
    case 'detail-validate': {
      const { validation_flags, ...invoice } = data.data || {};
      return {
        job: data.job,
        invoiceData: invoice,
        validationFlags: validation_flags || [],
      };
    }
    case 'vendor-request-map': {
      const { job, data: request } = data;
      return {
        job,
        request,
      };
    }
    case 'header-identify':
    case 'identify':
    default: {
      return {
        job: data.job,
        ocrData: data.data || {},
      };
    }
  }
};
