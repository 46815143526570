import styled, { css } from 'styled-components';

import { color, font } from 'shared/styles';

export const StatusBadge = styled.p<{ backgroundColor?: 'danger' }>`
  ${font.size(12)}
  ${font.bold}
  text-transform: uppercase;
  border-radius: 5px;
  width: fit-content;
  padding: 4px 8px;
  text-transform: uppercase;
  ${(props) => {
    switch (props.backgroundColor) {
      case 'danger':
        return css`
          background: ${color.danger};
        `;
      default:
        return css`
          background: ${color.background6};
        `;
    }
  }}
`;
StatusBadge.displayName = 'StatusBadge';
