export const constructLocation = (entity?: {
  country?: string;
  state?: string;
  zipCode?: string;
  city?: string;
  streetAddress1?: string;
}) =>
  [entity?.country, entity?.state, entity?.zipCode, entity?.city, entity?.streetAddress1]
    .filter((part) => !!part)
    .join(', ');
