import React, { createContext, useContext, useEffect, useState } from 'react';

import { noop, omit, uniq } from 'lodash';

import { Icon } from '@glean/glean-ui.atoms.icon';
import { Button } from '@glean/glean-ui.molecules.button';

import { PaginationControls } from 'shared/components/pagination-controls';
import { UseFiltersT } from 'shared/hooks/filters';
import { PaginationProps } from 'shared/hooks/pagination';

import { FiltersForm } from './filters-form';
import {
  AlignCenter,
  Controls,
  ControlsTop,
  EmptyState,
  FilterBadge,
  FilterBadgeKey,
  FilterBadgeValue,
  FiltersContainer,
  Header,
  ReadOnlyFilterBadge,
  SearchInput,
  SelectActions,
  SelectActionsBar,
  SelectedCount,
  SortIcon,
  StyledCell,
  StyledCheckbox,
  StyledRow,
  StyledSelectAction,
  StyledSortHandle,
  StyledTable,
  TableContainer,
} from './styles';

type TableProps = {
  className?: string;
  columns?: {
    name?: string;
    sortKey?: string;
    width?: string;
    align?: 'right' | 'center' | 'left' | 'justify' | 'char';
  }[];
  body?: React.ReactNode;
  isEmpty?: boolean;
  sort?: {
    sortBy: string;
    sortDirection: string;
    onChange: (...args: any[]) => any;
  };
  filters?: UseFiltersT<any>['filters'] | null;
  pagination?: PaginationProps;
  search?: {
    onChange: (...args: any[]) => any;
  };
  select?: {
    keys: any[];
    renderActions: (...args: any[]) => any;
  };
  hideControls?: boolean;
};

const TableContext = createContext<{
  selectedKeys: string[];
  onSelect?: (selectKey: any, isSelected: any) => void;
}>({
  selectedKeys: [],
});

export const Table = ({
  className,
  columns = [],
  body,
  isEmpty,
  sort,
  filters,
  pagination,
  search,
  select,
  hideControls,
  ...rest
}: TableProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([] as Array<string>);
  const [filterFormVisible, setFilterFormVisible] = useState(false);
  const handleSelectSingle = (selectKey: any, isSelected: any) => {
    const newSelectedRows = uniq(
      isSelected ? [...selectedKeys, selectKey] : selectedKeys.filter((key) => key !== selectKey),
    );
    setSelectedKeys(newSelectedRows);
  };

  const handleSelectAll = (isSelected: any) => {
    const newSelectedRows = uniq(
      isSelected
        ? [...selectedKeys, ...(select?.keys || [])]
        : selectedKeys.filter((key) => !select?.keys.includes(key)),
    );

    setSelectedKeys(newSelectedRows);
  };

  useEffect(() => {
    if (select && selectedKeys.some((key) => !select.keys.includes(key))) {
      setSelectedKeys(selectedKeys.filter((key) => select.keys.includes(key)));
    }
  }, [select, selectedKeys]);

  const columnsWithSelect = select ? [{ width: '46px' }, ...columns] : columns;
  const columnWidths = columnsWithSelect.map(({ width }) => width);
  return (
    <TableContainer>
      {!hideControls && (
        <Controls>
          <ControlsTop>
            <AlignCenter>
              {filters && (
                <FiltersContainer>
                  {filters.readOnly ? (
                    <ReadOnlyFilterBadge>
                      <Icon icon="filter" />
                      <span>Filters:</span>
                    </ReadOnlyFilterBadge>
                  ) : (
                    <Button
                      variant="outlined"
                      icon="filter"
                      disabled={filters.readOnly}
                      onClick={() => setFilterFormVisible((f) => !f)}
                    >
                      Filters
                    </Button>
                  )}

                  {filters.activeFilters.map(({ filterKey, filterValue }, idx) => {
                    return (
                      <FilterBadge
                        key={`filter-badge-${idx}-${filterKey}-${filterValue}`}
                        readOnly={filters.readOnly}
                        onClick={
                          filters.readOnly
                            ? noop
                            : () => {
                                const otherFilters = filters.activeFilters.filter(
                                  (filter) => filter.filterKey !== filterKey,
                                );
                                filters.onChange(otherFilters);
                              }
                        }
                      >
                        <FilterBadgeKey>
                          {filters.filterFields[filterKey].displayName}:
                        </FilterBadgeKey>

                        <FilterBadgeValue> {filterValue} </FilterBadgeValue>
                        <Icon icon="close" size="xxsmall" />
                      </FilterBadge>
                    );
                  })}
                  {filterFormVisible && (
                    <FiltersForm filters={filters} setFilterFormVisible={setFilterFormVisible} />
                  )}
                </FiltersContainer>
              )}
            </AlignCenter>

            <AlignCenter>
              {search && (
                <SearchInput placeholder="Search" iconStart="search" onChange={search.onChange} />
              )}
              {pagination && <PaginationControls {...pagination} />}
            </AlignCenter>
          </ControlsTop>

          {select && selectedKeys.length > 0 && (
            <SelectActionsBar>
              <SelectActions>{select.renderActions(selectedKeys)}</SelectActions>
              <SelectedCount onClick={() => setSelectedKeys([])}>
                {selectedKeys.length} Selected <Icon icon="close" />
              </SelectedCount>
            </SelectActionsBar>
          )}
        </Controls>
      )}
      <StyledTable {...rest} className={className}>
        {columnWidths.some((width) => !!width) && (
          <colgroup>
            {columnWidths.map((width, index) => (
              <col key={index} width={width} />
            ))}
          </colgroup>
        )}

        <thead>
          <Row>
            {select && (
              <Header>
                <StyledCheckbox
                  onChange={handleSelectAll}
                  value={
                    selectedKeys.length > 0 &&
                    select.keys.every((selectKey) => selectedKeys.includes(selectKey))
                  }
                />
              </Header>
            )}

            {columns.map((col, index) => (
              <Header key={index} {...omit(col, ['name', 'sortKey', 'width'])}>
                {col.sortKey && sort ? (
                  <SortHandle sortKey={col.sortKey} sort={sort}>
                    {col.name}
                  </SortHandle>
                ) : (
                  col.name
                )}
              </Header>
            ))}
          </Row>
        </thead>

        <TableContext.Provider
          value={select ? { selectedKeys, onSelect: handleSelectSingle } : { selectedKeys: [] }}
        >
          <tbody>{body}</tbody>
        </TableContext.Provider>
      </StyledTable>

      {isEmpty && <EmptyState>No items to show.</EmptyState>}
    </TableContainer>
  );
};

export const Row = ({ children, className, selectKey, ...rest }: any) => {
  const { selectedKeys, onSelect } = useContext(TableContext);

  return (
    <StyledRow className={className} {...rest}>
      {onSelect && (
        <Cell>
          <StyledCheckbox
            value={selectedKeys.includes(selectKey)}
            onChange={(checked) => onSelect(selectKey, checked)}
          />
        </Cell>
      )}
      {children}
    </StyledRow>
  );
};

export const Cell = ({ className, noTruncate, children, ...rest }: any) => (
  <StyledCell className={className} truncate={!noTruncate} {...rest}>
    {children}
  </StyledCell>
);

const SortHandle = ({ sortKey, sort, children }: any) => {
  const { sortBy, sortDirection, onChange } = sort;

  const handleSortClick = () => {
    if (sortBy !== sortKey) {
      onChange({ sortBy: sortKey, sortDirection: 'ASC' });
    } else if (sortBy === sortKey && sortDirection === 'ASC') {
      onChange({ sortBy: sortKey, sortDirection: 'DESC' });
    } else {
      onChange({ sortBy: '', sortDirection: '' });
    }
  };
  const sortIcon = getSortIcon({ sortKey, sortBy, sortDirection });
  return (
    <StyledSortHandle onClick={handleSortClick}>
      {children}
      <SortIcon type={sortIcon} top={1} />
    </StyledSortHandle>
  );
};

const getSortIcon = ({
  sortKey,
  sortBy,
  sortDirection,
}: {
  sortKey: any;
  sortBy: any;
  sortDirection: 'ASC' | 'DESC';
}): 'sort' | 'sortASC' | 'sortDESC' => {
  if (sortKey === sortBy) {
    if (sortDirection === 'ASC') return 'sortASC';
    return 'sortDESC';
  } else {
    return 'sort';
  }
};

export const SelectAction = (props: any) => <StyledSelectAction {...props} />;
