import styled from 'styled-components';

import { Button } from '@glean/glean-ui.molecules.button';

import { color, font } from 'shared/styles';

export const FlagsPopover = styled.div`
  background: ${color.background6};
  padding: 14px 16px 0;

  ol {
    padding-left: 11px;
  }
  li {
    ${font.size(12)};
  }
`;
export const FlagDescription = styled.p<{ isOverridenOrResolved?: boolean }>`
  margin-bottom: 12px;
  ${(props) => props.isOverridenOrResolved && `text-decoration: line-through;`}
`;
export const FlagToggleButton = styled(Button)`
  width: 100%;
  margin-bottom: 18px;
`;
