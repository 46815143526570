/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommentDTO
 */
export interface CommentDTO {
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    commentId: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    commentText: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    jobType: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    executionArn: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommentDTO
     */
    gleanResponse: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentDTO
     */
    author: string;
    /**
     * 
     * @type {Date}
     * @memberof CommentDTO
     */
    createdAt: Date;
}

export function CommentDTOFromJSON(json: any): CommentDTO {
    return CommentDTOFromJSONTyped(json, false);
}

export function CommentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commentId': json['commentId'],
        'commentText': json['commentText'],
        'jobType': json['jobType'],
        'executionArn': json['executionArn'],
        'gleanResponse': json['gleanResponse'],
        'author': json['author'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CommentDTOToJSON(value?: CommentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commentId': value.commentId,
        'commentText': value.commentText,
        'jobType': value.jobType,
        'executionArn': value.executionArn,
        'gleanResponse': value.gleanResponse,
        'author': value.author,
        'createdAt': (value.createdAt.toISOString()),
    };
}


