import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import { border, color, font, padding, shadow, sizes } from 'shared/styles';

const slideIn = keyframes`
   from {
    width: 0;
  }
  to {
    width: ${sizes.sidebarWidth};
  }
`;

const slideOut = keyframes`
   from {
    width: ${sizes.sidebarWidth};
  }
  to {
    width: 0;
  }
`;

const slideInAnimation = css`
  animation: ${slideIn} 1s forwards;
`;

const slideOutAnimation = css`
  animation: ${slideOut} 1s forwards;
`;

export const Slide = styled.div<{ open?: boolean | null }>`
  background: ${color.background3};
  border-radius: ${border.borderRadius};
  height: 100vh;
  padding-top: ${sizes.navbarHeight}px;
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  z-index: 5;
  ${({ open }) => open !== null && (open ? slideInAnimation : slideOutAnimation)};
`;

Slide.displayName = 'Slide';
Slide.propTypes = {
  open: PropTypes.bool,
};

export const Inner = styled.div`
  box-shadow: ${shadow.sidebar};
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  ${font.size(13)}
`;
Inner.displayName = 'Inner';

export const CommentSubmit = styled.div`
  border-top: ${border.section};
  display: flex;
  justify-content: space-between;
  ${padding.sidebarSection}
  width: ${sizes.sidebarWidth};
  justify-self: end;

  textarea {
    background-color: transparent;
    border: none;
    color: ${color.text2};
    resize: none;
    width: 320px;
    ${font.size(13)};
  }
`;
CommentSubmit.displayName = 'CommentSubmit';

export const SpinnerContainer = styled.div`
  justify-self: center;
  align-self: center;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const Header = styled.h3`
  ${font.size(16)}
  ${padding.sidebarSection}
`;
Header.displayName = 'Header';
