import styled from 'styled-components';

import { Button } from '@glean/glean-ui.molecules.button';

import { color } from 'shared/styles';

import { Cell, Row } from '../table';

export const StyledRow = styled(Row)`
  button {
    display: none;
  }
  &:hover {
    background: ${color.background4};
    button {
      display: inline-flex;
    }
  }
`;

export const StyledCell = styled(Cell)`
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: top;
  &:last-of-type {
    padding-right: 25px;
  }
`;

export const ActionButtonsCell = styled(StyledCell)`
  padding-right: 25px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin: 0 5px;
`;

export const StatusBadgeContainer = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 12px;
`;
StatusBadgeContainer.displayName = 'StatusBadgeContainer';
