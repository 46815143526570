/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceT
 */
export interface InvoiceT {
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    companyVendorId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    invoiceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    fileKey: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    recordType?: InvoiceTRecordTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceT
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceT
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceT
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    periodStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    periodEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    billingFrequency?: InvoiceTBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    paymentCurrency: InvoiceTPaymentCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    paymentTerms?: InvoiceTPaymentTermsEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    totalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    pretaxSubtotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    beginningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    endingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    amountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    originalTotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    originalPretaxSubtotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    originalAmountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    originalAmountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    originalBeginningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    originalEndingBalance?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceT
     */
    currencyConvertedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    approvalStatus: InvoiceTApprovalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    paymentStatus: InvoiceTPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    processingStatus: InvoiceTProcessingStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceT
     */
    isBacklog: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceT
     */
    isDuplicate: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    primaryDuplicateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceT
     */
    requiresLedgerSync: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    markedAsPaidVia?: InvoiceTMarkedAsPaidViaEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    markedAsPaidPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceT
     */
    markedAsPaidAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    markedAsPaidNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceT
     */
    isAmortizationActionBarDismissed: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    purchaseOrderEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    shipFromName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    shipToAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    shipper?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    taxIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceT
     */
    workOrderNumber?: string;
}

export function InvoiceTFromJSON(json: any): InvoiceT {
    return InvoiceTFromJSONTyped(json, false);
}

export function InvoiceTFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'companyVendorId': json['companyVendorId'],
        'invoiceNumber': json['invoiceNumber'],
        'fileKey': json['fileKey'],
        'recordType': !exists(json, 'recordType') ? undefined : json['recordType'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : json['invoiceDate'],
        'dueDate': !exists(json, 'dueDate') ? undefined : json['dueDate'],
        'periodStartDate': !exists(json, 'periodStartDate') ? undefined : json['periodStartDate'],
        'periodEndDate': !exists(json, 'periodEndDate') ? undefined : json['periodEndDate'],
        'billingFrequency': !exists(json, 'billingFrequency') ? undefined : json['billingFrequency'],
        'paymentCurrency': json['paymentCurrency'],
        'paymentTerms': !exists(json, 'paymentTerms') ? undefined : json['paymentTerms'],
        'totalAmount': json['totalAmount'],
        'pretaxSubtotalAmount': !exists(json, 'pretaxSubtotalAmount') ? undefined : json['pretaxSubtotalAmount'],
        'beginningBalance': !exists(json, 'beginningBalance') ? undefined : json['beginningBalance'],
        'endingBalance': !exists(json, 'endingBalance') ? undefined : json['endingBalance'],
        'amountDue': !exists(json, 'amountDue') ? undefined : json['amountDue'],
        'amountPaid': !exists(json, 'amountPaid') ? undefined : json['amountPaid'],
        'originalTotalAmount': !exists(json, 'originalTotalAmount') ? undefined : json['originalTotalAmount'],
        'originalPretaxSubtotalAmount': !exists(json, 'originalPretaxSubtotalAmount') ? undefined : json['originalPretaxSubtotalAmount'],
        'originalAmountPaid': !exists(json, 'originalAmountPaid') ? undefined : json['originalAmountPaid'],
        'originalAmountDue': !exists(json, 'originalAmountDue') ? undefined : json['originalAmountDue'],
        'originalBeginningBalance': !exists(json, 'originalBeginningBalance') ? undefined : json['originalBeginningBalance'],
        'originalEndingBalance': !exists(json, 'originalEndingBalance') ? undefined : json['originalEndingBalance'],
        'currencyConvertedAt': !exists(json, 'currencyConvertedAt') ? undefined : (new Date(json['currencyConvertedAt'])),
        'approvalStatus': json['approvalStatus'],
        'paymentStatus': json['paymentStatus'],
        'processingStatus': json['processingStatus'],
        'isBacklog': json['isBacklog'],
        'isDuplicate': json['isDuplicate'],
        'primaryDuplicateId': !exists(json, 'primaryDuplicateId') ? undefined : json['primaryDuplicateId'],
        'requiresLedgerSync': json['requiresLedgerSync'],
        'markedAsPaidVia': !exists(json, 'markedAsPaidVia') ? undefined : json['markedAsPaidVia'],
        'markedAsPaidPaymentDate': !exists(json, 'markedAsPaidPaymentDate') ? undefined : json['markedAsPaidPaymentDate'],
        'markedAsPaidAmount': !exists(json, 'markedAsPaidAmount') ? undefined : json['markedAsPaidAmount'],
        'markedAsPaidNotes': !exists(json, 'markedAsPaidNotes') ? undefined : json['markedAsPaidNotes'],
        'isAmortizationActionBarDismissed': json['isAmortizationActionBarDismissed'],
        'projectNumber': !exists(json, 'projectNumber') ? undefined : json['projectNumber'],
        'purchaseOrderEmail': !exists(json, 'purchaseOrderEmail') ? undefined : json['purchaseOrderEmail'],
        'shipFromName': !exists(json, 'shipFromName') ? undefined : json['shipFromName'],
        'shipToAddress': !exists(json, 'shipToAddress') ? undefined : json['shipToAddress'],
        'shipper': !exists(json, 'shipper') ? undefined : json['shipper'],
        'taxIdNumber': !exists(json, 'taxIdNumber') ? undefined : json['taxIdNumber'],
        'trackingNumber': !exists(json, 'trackingNumber') ? undefined : json['trackingNumber'],
        'workOrderNumber': !exists(json, 'workOrderNumber') ? undefined : json['workOrderNumber'],
    };
}

export function InvoiceTToJSON(value?: InvoiceT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceId': value.invoiceId,
        'companyVendorId': value.companyVendorId,
        'invoiceNumber': value.invoiceNumber,
        'fileKey': value.fileKey,
        'recordType': value.recordType,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'invoiceDate': value.invoiceDate,
        'dueDate': value.dueDate,
        'periodStartDate': value.periodStartDate,
        'periodEndDate': value.periodEndDate,
        'billingFrequency': value.billingFrequency,
        'paymentCurrency': value.paymentCurrency,
        'paymentTerms': value.paymentTerms,
        'totalAmount': value.totalAmount,
        'pretaxSubtotalAmount': value.pretaxSubtotalAmount,
        'beginningBalance': value.beginningBalance,
        'endingBalance': value.endingBalance,
        'amountDue': value.amountDue,
        'amountPaid': value.amountPaid,
        'originalTotalAmount': value.originalTotalAmount,
        'originalPretaxSubtotalAmount': value.originalPretaxSubtotalAmount,
        'originalAmountPaid': value.originalAmountPaid,
        'originalAmountDue': value.originalAmountDue,
        'originalBeginningBalance': value.originalBeginningBalance,
        'originalEndingBalance': value.originalEndingBalance,
        'currencyConvertedAt': value.currencyConvertedAt === undefined ? undefined : (value.currencyConvertedAt.toISOString()),
        'approvalStatus': value.approvalStatus,
        'paymentStatus': value.paymentStatus,
        'processingStatus': value.processingStatus,
        'isBacklog': value.isBacklog,
        'isDuplicate': value.isDuplicate,
        'primaryDuplicateId': value.primaryDuplicateId,
        'requiresLedgerSync': value.requiresLedgerSync,
        'markedAsPaidVia': value.markedAsPaidVia,
        'markedAsPaidPaymentDate': value.markedAsPaidPaymentDate,
        'markedAsPaidAmount': value.markedAsPaidAmount,
        'markedAsPaidNotes': value.markedAsPaidNotes,
        'isAmortizationActionBarDismissed': value.isAmortizationActionBarDismissed,
        'projectNumber': value.projectNumber,
        'purchaseOrderEmail': value.purchaseOrderEmail,
        'shipFromName': value.shipFromName,
        'shipToAddress': value.shipToAddress,
        'shipper': value.shipper,
        'taxIdNumber': value.taxIdNumber,
        'trackingNumber': value.trackingNumber,
        'workOrderNumber': value.workOrderNumber,
    };
}

/**
* @export
* @enum {string}
*/
export enum InvoiceTRecordTypeEnum {
    INVOICE = 'INVOICE',
    RECEIPT = 'RECEIPT',
    CREDITMEMO = 'CREDIT_MEMO',
    BILLINGSTATEMENT = 'BILLING_STATEMENT',
    OTHER = 'OTHER',
    CCSPENDITEM = 'CC_SPEND_ITEM'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTBillingFrequencyEnum {
    OTHER = 'OTHER',
    ANNUAL = 'ANNUAL',
    ANNUALLY = 'ANNUALLY',
    SEMIANNUALLY = 'SEMI_ANNUALLY',
    QUARTERLY = 'QUARTERLY',
    MONTHLY = 'MONTHLY'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTPaymentCurrencyEnum {
    AED = 'AED',
    AFN = 'AFN',
    ALL = 'ALL',
    AMD = 'AMD',
    ARS = 'ARS',
    AUD = 'AUD',
    AZN = 'AZN',
    BAM = 'BAM',
    BDT = 'BDT',
    BGN = 'BGN',
    BHD = 'BHD',
    BIF = 'BIF',
    BND = 'BND',
    BOB = 'BOB',
    BRL = 'BRL',
    BWP = 'BWP',
    BYN = 'BYN',
    BZD = 'BZD',
    CAD = 'CAD',
    CDF = 'CDF',
    CHF = 'CHF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    CVE = 'CVE',
    CZK = 'CZK',
    DJF = 'DJF',
    DKK = 'DKK',
    DOP = 'DOP',
    DZD = 'DZD',
    EEK = 'EEK',
    EGP = 'EGP',
    ERN = 'ERN',
    ETB = 'ETB',
    EUR = 'EUR',
    GBP = 'GBP',
    GEL = 'GEL',
    GHS = 'GHS',
    GNF = 'GNF',
    GTQ = 'GTQ',
    HKD = 'HKD',
    HNL = 'HNL',
    HRK = 'HRK',
    HUF = 'HUF',
    IDR = 'IDR',
    ILS = 'ILS',
    INR = 'INR',
    IQD = 'IQD',
    IRR = 'IRR',
    ISK = 'ISK',
    JMD = 'JMD',
    JOD = 'JOD',
    JPY = 'JPY',
    KES = 'KES',
    KHR = 'KHR',
    KMF = 'KMF',
    KRW = 'KRW',
    KWD = 'KWD',
    KZT = 'KZT',
    LBP = 'LBP',
    LKR = 'LKR',
    LTL = 'LTL',
    LVL = 'LVL',
    LYD = 'LYD',
    MAD = 'MAD',
    MDL = 'MDL',
    MGA = 'MGA',
    MKD = 'MKD',
    MMK = 'MMK',
    MOP = 'MOP',
    MUR = 'MUR',
    MXN = 'MXN',
    MYR = 'MYR',
    MZN = 'MZN',
    NAD = 'NAD',
    NGN = 'NGN',
    NIO = 'NIO',
    NOK = 'NOK',
    NPR = 'NPR',
    NZD = 'NZD',
    OMR = 'OMR',
    PAB = 'PAB',
    PEN = 'PEN',
    PHP = 'PHP',
    PKR = 'PKR',
    PLN = 'PLN',
    PYG = 'PYG',
    QAR = 'QAR',
    RON = 'RON',
    RSD = 'RSD',
    RUB = 'RUB',
    RWF = 'RWF',
    SAR = 'SAR',
    SDG = 'SDG',
    SEK = 'SEK',
    SGD = 'SGD',
    SOS = 'SOS',
    SYP = 'SYP',
    THB = 'THB',
    TND = 'TND',
    TOP = 'TOP',
    TRY = 'TRY',
    TTD = 'TTD',
    TWD = 'TWD',
    TZS = 'TZS',
    UAH = 'UAH',
    UGX = 'UGX',
    USD = 'USD',
    UYU = 'UYU',
    UZS = 'UZS',
    VEF = 'VEF',
    VND = 'VND',
    XAF = 'XAF',
    XOF = 'XOF',
    YER = 'YER',
    ZAR = 'ZAR',
    ZMK = 'ZMK',
    ZWL = 'ZWL'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTPaymentTermsEnum {
    OTHER = 'OTHER',
    NET7 = 'NET_7',
    NET10 = 'NET_10',
    NET14 = 'NET_14',
    NET15 = 'NET_15',
    NET20 = 'NET_20',
    NET21 = 'NET_21',
    NET30 = 'NET_30',
    NET45 = 'NET_45',
    NET60 = 'NET_60',
    NET90 = 'NET_90',
    UPONRECEIPT = 'UPON_RECEIPT'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTApprovalStatusEnum {
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
    PENDING = 'PENDING'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTPaymentStatusEnum {
    PENDING = 'PENDING',
    MARKEDASPAID = 'MARKED_AS_PAID',
    NOTPAID = 'NOT_PAID',
    SCHEDULED = 'SCHEDULED',
    INFLIGHT = 'IN_FLIGHT',
    PAID = 'PAID',
    FAILED = 'FAILED',
    READYTOPAY = 'READY_TO_PAY'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTProcessingStatusEnum {
    HEADERCOMPLETED = 'HEADER_COMPLETED',
    LINEITEMSINPROGRESS = 'LINE_ITEMS_IN_PROGRESS',
    LINEITEMSCOMPLETED = 'LINE_ITEMS_COMPLETED'
}
/**
* @export
* @enum {string}
*/
export enum InvoiceTMarkedAsPaidViaEnum {
    OTHER = 'OTHER',
    ACH = 'ACH',
    DOMESTICWIRE = 'DOMESTIC_WIRE',
    INTERNATIONALWIRE = 'INTERNATIONAL_WIRE',
    CHECK = 'CHECK',
    CREDITCARD = 'CREDIT_CARD'
}


