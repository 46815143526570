import { cloneDeep, isArray } from 'lodash';

import gleanSchema from '@gleanhq/schema';

export const cleanFormDataFields = ({ schema, formData: formDataInput }: any) => {
  const formData = cloneDeep(formDataInput);
  schema.properties &&
    Object.keys(schema.properties).forEach((key) => {
      const value = () => formData[key];
      const fieldSchema = getFieldSchemaWithRef(schema.properties[key]);
      if (isFormValueEmpty(value())) {
        delete formData[key];
        return;
      } else if (fieldSchema.type === 'number' && typeof value() === 'string' && value().trim()) {
        formData[key] = Number(value());
      } else if (fieldSchema.type === 'string') {
        formData[key] = value().trim();
      } else if (fieldSchema.type === 'array' && fieldSchema.items.type === 'string') {
        formData[key] = value()
          .map((val: any) => val.trim())
          .filter((val: any) => !!val);
      } else if (fieldSchema.type === 'array' && fieldSchema.items.type === 'object') {
        formData[key] = value().map((item: any) =>
          cleanFormDataFields({ schema: fieldSchema.items, formData: item }),
        );
      } else if (fieldSchema.type === 'object') {
        formData[key] = cleanFormDataFields({ schema: fieldSchema, formData: value() });
      }
    });
  return formData;
};

export const isFormValueEmpty = (value: any) =>
  value === null ||
  value === undefined ||
  (typeof value === 'string' && value.trim() === '') ||
  (isArray(value) && value.length === 0);

export const getFieldSchemaWithRef = (field: any) => {
  const ref = field.$ref ? getRef(field.$ref) || {} : {};
  return { ...ref, ...field };
};

const getRef = ($ref: string) => {
  const refKey = $ref.replace('#/definitions/', '');
  if (Object.keys(gleanSchema.definitions).includes(refKey)) {
    return gleanSchema.definitions[refKey as keyof typeof gleanSchema.definitions];
  }
};
