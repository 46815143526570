import React, { useContext } from 'react';

import { ExtractValidateContext } from 'views/job/extract-validate/state';

import { Divider, Field, Grid, Label } from '../shared';

export const Summary = () => {
  const [{ jobType }] = useContext(ExtractValidateContext);
  const isDetail = jobType.toLowerCase().includes('detail');
  const tip = isDetail
    ? `This field cannot be edited at this time. If it needs to be corrected, please escalate this job.`
    : undefined;
  return (
    <>
      {' '}
      <Grid>
        <Field name="invoice.summary.invoice_number" />
        <Field name="invoice.summary.customer_bill_to_name" />
        <Field name="invoice.summary.customer_account_number" />
        <Field name="invoice.summary.invoice_date" perRow={2} disabled={isDetail} tip={tip} />
        <Field name="invoice.summary.due_date" perRow={2} disabled={isDetail} tip={tip} />
        <Field name="invoice.summary.period_start_date" perRow={2} />
        <Field name="invoice.summary.period_end_date" perRow={2} />
        <Field name="invoice.summary.payment_terms" perRow={2} disabled={isDetail} tip={tip} />
        <Field name="invoice.summary.payment_currency" perRow={2} disabled={isDetail} tip={tip} />
        <Field name="invoice.cost.total_billed_amount" disabled={isDetail} tip={tip} />
        <Field name="invoice.cost.amount_due" disabled={isDetail} tip={tip} />
      </Grid>
      <Divider />
      <Label>ADDITIONAL INFO</Label>
      <Grid>
        <Field name="invoice.summary.order_number" perRow={2} />
        <Field name="invoice.summary.project_number" perRow={2} />
        <Field name="invoice.summary.purchase_order_email" perRow={2} />
        <Field name="invoice.summary.purchase_order_number" perRow={2} />
        <Field name="invoice.summary.tax_id_number" perRow={2} />
        <Field name="invoice.summary.work_order_number" perRow={2} />
      </Grid>
    </>
  );
};
