import { useState } from 'react';

import { omit, pick, uniqBy } from 'lodash';

import {
  CanonicalLineItemElement,
  GleanSchema,
  LineItemElement as LineItem,
} from '@gleanhq/schema';

import { useGetMergedCanonicalVendorId } from 'shared/api';
import { LineItemStpResponsePagination } from 'shared/api/generated';

export type LocalCLIState = {
  canonical_line_item_id: string;
  isNew?: boolean;
  is_staging?: boolean;
  files?: string[];
} & Partial<CanonicalLineItemElement>;

export type OriginLineItemT = LineItem & { isShaded?: boolean };

export type LineItemStepStateT = {
  mappedClis: (LocalCLIState | null)[];
  areMappedClisLoaded: boolean;
  areRecommendationsLoaded: boolean;
  vendorClis: any[];
  areVendorClisLoaded: boolean;
};

export const canonicalPayloadToLocalLineItemState = (
  invoiceData: GleanSchema,
  lineItemStpMap: ReturnType<typeof parseStpOutput>,
): Pick<LineItemStepStateT, 'mappedClis'> & { originLineItems: OriginLineItemT[] } => {
  const canonicalMap: Record<string, string> = {
    ...(invoiceData?.canonical_line_items_map?.canonical_line_items || {}),
  };

  const newCanonicalLineItemsFromPayload = [...(invoiceData?.canonical_line_items || [])];
  const originLineItems =
    invoiceData?.line_items.line_items?.map((lineItem) => {
      const stpEvent = lineItemStpMap.get(lineItem.line_item_id);
      const isShaded = stpEvent && stpEvent.probability > 0.9;
      const probability = stpEvent?.probability || -1;
      return {
        ...lineItem,
        isShaded,
        probability,
      };
    }) || [];

  const mappedCanonicalLineItems = Array<{
    canonical_line_item_id: string;
    isNew?: boolean;
  } | null>(originLineItems.length).fill(null);
  // if any line item data had been mapped in a previous map/curate session
  if (invoiceData?.canonical_line_items || invoiceData?.canonical_map) {
    for (let i = 0; i < originLineItems.length; i++) {
      let cliAtIndex = null;
      let originLineItemId = invoiceData.line_items?.line_items?.[i].line_item_id;
      // if a line item has been mapped add its canonical id to the
      // array of mapped invoices
      if (originLineItemId && canonicalMap[originLineItemId]) {
        const canonicalId =
          lineItemStpMap.get(originLineItemId)?.canonicalLineItemId ||
          canonicalMap[originLineItemId];
        cliAtIndex = {
          canonical_line_item_id: canonicalId,
        };
        let newCanonicalLineItemCandidate = newCanonicalLineItemsFromPayload.filter(
          (cli) => cli.canonical_line_item_id === canonicalId,
        )[0];
        if (newCanonicalLineItemCandidate) {
          cliAtIndex = { ...newCanonicalLineItemCandidate, isNew: true };
        }
      }
      mappedCanonicalLineItems[i] = cliAtIndex;
    }
  }

  return {
    mappedClis: mappedCanonicalLineItems,
    originLineItems: originLineItems,
  };
};

export const parseStpOutput = (lineItemStpData: LineItemStpResponsePagination | undefined) => {
  const map = new Map<
    string,
    { lineItemId: string; canonicalLineItemId: string; probability: number }
  >();

  lineItemStpData?.events?.forEach((event) => {
    if (event.canonical_line_item_id && event.probability && event.is_stp) {
      map.set(event.line_item_id, {
        lineItemId: event.line_item_id,
        canonicalLineItemId: event.canonical_line_item_id,
        probability: event.probability,
      });
    }
  });

  return map;
};

export const localStateToCanonicalPayload = ({
  invoiceData,
  lineItemsStepState,
  canonicalVendorId,
  step,
}: {
  step: 'MAP' | 'CURATE';
  canonicalVendorId: string;
  invoiceData: GleanSchema;
  lineItemsStepState: LineItemStepStateT;
}) => {
  // populate outgoing payload with original data.
  let payload: Pick<
    GleanSchema,
    'canonical_line_items_map' | 'canonical_line_items' | 'canonical_map'
  > = {
    canonical_map: {
      canonical_line_items: {
        ...invoiceData?.canonical_map?.canonical_line_items,
      },
      canonical_vendor_id: canonicalVendorId,
    },
  };
  if (typeof invoiceData.canonical_line_items !== 'undefined') {
    payload.canonical_line_items = [...invoiceData.canonical_line_items];
  }
  // Generate line item section of the payload
  // Create a mapping of {[originLineItemId]:canonicalLineItemId}
  const originLineItems = invoiceData.line_items.line_items || [];

  const canonicalLineItemMap = originLineItems.reduce((map, oli, index) => {
    return {
      ...map,
      [oli.line_item_id]: lineItemsStepState.mappedClis[index]?.canonical_line_item_id,
    };
  }, {});

  payload.canonical_map!.canonical_line_items = {
    ...(payload.canonical_map?.canonical_line_items || {}),
    ...canonicalLineItemMap,
  };
  payload.canonical_line_items_map = {
    canonical_line_items: {
      ...(payload?.canonical_line_items_map?.canonical_line_items || {}),
      ...canonicalLineItemMap,
    },
  };
  const newLineItemsFromLocalState = lineItemsStepState.mappedClis
    .filter((cli) => cli?.isNew || cli?.is_staging)
    .map((cli) => ({
      ...omit(cli, ['id', 'isNew', 'files', 'is_staging']),
      canonical_line_item_id: cli!.canonical_line_item_id,
    }));

  const newLineItemsFromInvoicePayload = invoiceData.canonical_line_items || [];
  const newCanonicalLineItemCandidates = uniqBy(
    [...newLineItemsFromInvoicePayload, ...newLineItemsFromLocalState],
    'canonical_line_item_id',
  );
  // When restarting a job at curate that has already gone through insert canon
  // we will have "new" line items in the payload that have already been written
  // to the db. We need to filter those out by comparing our new line items against
  // the existing line items.
  const newLineItemCandidatesThatHaveNotYetBeenCreated = newCanonicalLineItemCandidates
    .filter((newCli) => {
      const existingLineItemIds = lineItemsStepState.vendorClis
        .filter(
          (cli: { isNew: boolean; is_staging: boolean } & Record<string, unknown>) =>
            step === 'MAP' || !cli.is_staging,
        )
        .filter(
          (cli: { isNew: boolean; is_staging: boolean } & Record<string, unknown>) => !cli.isNew,
        )
        .map(
          (existingCli: { canonical_line_item_id: string }) => existingCli.canonical_line_item_id,
        );
      return !existingLineItemIds.includes(newCli.canonical_line_item_id);
    })
    .map((canonicalLineItem) =>
      pick(canonicalLineItem, [
        'canonical_line_item_id',
        'canonical_line_item_name',
        'canonical_line_item_type',
        'canonical_vendor_id',
        'has_constrained_capacity',
        'is_overage',
        'name',
      ]),
    );

  // Only include new canonical line items which have been mapped to the origin payload
  const newClisInUse = newLineItemCandidatesThatHaveNotYetBeenCreated
    .filter((cli) =>
      Object.values(payload.canonical_map!.canonical_line_items).includes(
        cli.canonical_line_item_id!,
      ),
    )
    .map((canonicalLineItem) => ({
      ...canonicalLineItem,
      canonical_line_item_name:
        //@ts-expect-error
        canonicalLineItem.name || canonicalLineItem.canonical_line_item_name,
      canonical_vendor_id: canonicalVendorId,
    }));
  (payload as any).canonical_line_items = [...newClisInUse];
  (payload as any).canonical_vendor_map = {
    canonical_vendor_id: canonicalVendorId,
  };

  return payload;
};

/**
 * In the course of data extraction, a canonical vendor may be created for a header pipeline execution
 * but then merged into a new canonical vendor by the time it reaches the line item pipeline.
 * this is hook makes sure we always have the most current canonical_vendor_id.
 * @param originalCanonicalVendorId the canonical vendor id that was originally extracted from the header
 * @returns the most current canonical vendor id
 */
export const useCanonicalVendorId = (originalCanonicalVendorId: string) => {
  const [canonicalVendorId, setCanonicalVendorId] = useState(originalCanonicalVendorId);
  useGetMergedCanonicalVendorId({
    originalCanonicalVendorId,
    onSuccess: (res) => {
      setCanonicalVendorId(res.newCanonicalVendorId);
    },
  });
  return canonicalVendorId;
};
