import styled from 'styled-components';

import { font } from 'shared/styles';

export const Inner = styled.div`
  padding: 40px;

  h3 {
    padding-bottom: 28px;
    ${font.medium}
    ${font.size(18)}
  }

  p {
    padding-bottom: 24px;
    ${font.size(14)}
  }

  ul {
    list-style: disc;
    padding: 0 0 20px 19px;
    ${font.size(14)}
  }

  li {
    line-height: 1.6;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 80px;
  }
`;
