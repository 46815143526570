import React, { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';

import { Input, InputProps } from 'shared/components/input';

type Props = {
  value?: string | number;
  onChange: (...args: any[]) => any;
} & Omit<InputProps, 'value' | 'onChange'>;

export const InputDebounced = ({ onChange, value: propsValue, ...inputProps }: Props) => {
  const [value, setValue] = useState(propsValue);
  const isControlled = propsValue !== undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((newValue) => onChange(newValue), 500),
    [],
  );

  const valueRef = useRef(value);
  valueRef.current = value;

  useEffect(() => {
    if (propsValue !== valueRef.current) {
      setValue(propsValue);
    }
  }, [propsValue]);

  return (
    <Input
      {...inputProps}
      value={isControlled ? value : undefined}
      onChange={(newValue) => {
        setValue(newValue);
        handleChange(newValue);
      }}
    />
  );
};
