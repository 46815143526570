/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PipelineRollupsT,
    PipelineRollupsTFromJSON,
    PipelineRollupsTFromJSONTyped,
    PipelineRollupsTToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetManagePipelineAggregationsT
 */
export interface GetManagePipelineAggregationsT {
    /**
     * 
     * @type {PipelineRollupsT}
     * @memberof GetManagePipelineAggregationsT
     */
    header: PipelineRollupsT;
    /**
     * 
     * @type {PipelineRollupsT}
     * @memberof GetManagePipelineAggregationsT
     */
    detail: PipelineRollupsT;
}

export function GetManagePipelineAggregationsTFromJSON(json: any): GetManagePipelineAggregationsT {
    return GetManagePipelineAggregationsTFromJSONTyped(json, false);
}

export function GetManagePipelineAggregationsTFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetManagePipelineAggregationsT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': PipelineRollupsTFromJSON(json['header']),
        'detail': PipelineRollupsTFromJSON(json['detail']),
    };
}

export function GetManagePipelineAggregationsTToJSON(value?: GetManagePipelineAggregationsT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': PipelineRollupsTToJSON(value.header),
        'detail': PipelineRollupsTToJSON(value.detail),
    };
}


