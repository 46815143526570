import React from 'react';

import { HeaderMapCurateUiStateT } from '..';
import { CanonicalSection } from './canonical-section';
import { OriginSection } from './origin-section';
import { Canonical, Container, Origin } from './styles';

export const MapVendor = ({
  data,
  vendorStepState,
  onVendorStepStateChange,
}: {
  data: {
    request: { requestedVendorName: string; requestedVendorUrl: string };
    job: {
      executionArn: string;
      jobType: string;
    };
  };
  vendorStepState: HeaderMapCurateUiStateT;
  onVendorStepStateChange: any;
}) => {
  const { request } = data;

  return (
    <Container>
      <Origin>
        <OriginSection
          requestedVendorName={request.requestedVendorName}
          requestedVendorUrl={request.requestedVendorUrl}
        />
      </Origin>

      <Canonical>
        <CanonicalSection
          requestedVendorName={request.requestedVendorName}
          vendorStepState={vendorStepState}
          onVendorStepStateChange={onVendorStepStateChange}
        />
      </Canonical>
    </Container>
  );
};
