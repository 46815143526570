/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCompanyPrioritiesReturnT
 */
export interface GetCompanyPrioritiesReturnT {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyPrioritiesReturnT
     */
    status: GetCompanyPrioritiesReturnTStatusEnum;
    /**
     * 
     * @type {Array<Company>}
     * @memberof GetCompanyPrioritiesReturnT
     */
    companies: Array<Company>;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyPrioritiesReturnT
     */
    error?: string;
}

export function GetCompanyPrioritiesReturnTFromJSON(json: any): GetCompanyPrioritiesReturnT {
    return GetCompanyPrioritiesReturnTFromJSONTyped(json, false);
}

export function GetCompanyPrioritiesReturnTFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyPrioritiesReturnT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'companies': ((json['companies'] as Array<any>).map(CompanyFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function GetCompanyPrioritiesReturnTToJSON(value?: GetCompanyPrioritiesReturnT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'companies': ((value.companies as Array<any>).map(CompanyToJSON)),
        'error': value.error,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetCompanyPrioritiesReturnTStatusEnum {
    Ok = 'ok',
    Error = 'error'
}


