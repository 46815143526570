import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { Form } from 'shared/components/form';
import { UseFiltersT } from 'shared/hooks/filters';

import {
  FilterButtonContainer,
  FiltersFormContainer,
  StyledDateInput,
  StyledNumberInput,
  StyledSelect,
} from './styles';

export const FiltersForm = ({
  filters,
  setFilterFormVisible,
}: {
  filters: UseFiltersT<any>['filters'];
  setFilterFormVisible?: (visible: boolean) => void;
}) => {
  return (
    <FiltersFormContainer>
      <Form
        initialValues={filters.activeFilters.reduce((acc: any, cur: any) => {
          return { ...acc, [cur.filterKey]: cur.filterValue };
        }, {})}
        validations={{}}
        onSubmit={(formData: any) => {
          const filterPayload = Object.entries(formData).map(([filterKey, filterValue]) => ({
            filterKey,
            filterValue,
          }));
          if (typeof setFilterFormVisible === 'function') {
            setFilterFormVisible(false);
          }
          filters.onChange(filterPayload);
        }}
      >
        {() => (
          <Form.Element>
            {Object.entries(filters.filterFields).map(
              ([filterKey, { displayName, type, options }], idx) => {
                switch (type) {
                  case 'select':
                    return (
                      <StyledSelect
                        name={filterKey}
                        label={displayName}
                        options={[{ label: '', value: '' }, ...(options || [])]}
                      />
                    );
                  case 'date':
                    return <StyledDateInput name={filterKey} label={displayName} />;
                  case 'number':
                    return <StyledNumberInput name={filterKey} label={displayName} />;
                  default:
                    return null;
                }
              },
            )}
            <FilterButtonContainer>
              <Button
                variant="outlined"
                onClick={() => {
                  if (typeof setFilterFormVisible === 'function') {
                    setFilterFormVisible(false);
                  }
                  filters.onChange([]);
                }}
              >
                Clear All
              </Button>
              <Button type="submit">Apply</Button>
            </FilterButtonContainer>
          </Form.Element>
        )}
      </Form>
    </FiltersFormContainer>
  );
};
