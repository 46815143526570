/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeCanonicalLineItemT
 */
export interface MergeCanonicalLineItemT {
    /**
     * 
     * @type {string}
     * @memberof MergeCanonicalLineItemT
     */
    canonicalLineItemId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MergeCanonicalLineItemT
     */
    toMergeIds: Array<string>;
}

export function MergeCanonicalLineItemTFromJSON(json: any): MergeCanonicalLineItemT {
    return MergeCanonicalLineItemTFromJSONTyped(json, false);
}

export function MergeCanonicalLineItemTFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeCanonicalLineItemT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonicalLineItemId': json['canonicalLineItemId'],
        'toMergeIds': json['toMergeIds'],
    };
}

export function MergeCanonicalLineItemTToJSON(value?: MergeCanonicalLineItemT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonicalLineItemId': value.canonicalLineItemId,
        'toMergeIds': value.toMergeIds,
    };
}


