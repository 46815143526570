import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Icon } from '@glean/glean-ui.atoms.icon';
import { Button, ButtonProps } from '@glean/glean-ui.molecules.button';

import { createJobCancellation, deescalateJob } from 'shared/api';
import { useAuth } from 'shared/auth';
import { ConfirmModal } from 'shared/components/confirm-modal';
import { EscalateJobModal } from 'shared/components/escalate-job-modal';

import { toast } from '../toast';
import {
  ExitLink,
  GridLeft,
  GridRight,
  Heading,
  IconBtnGroup,
  StyledNavBar,
  TextBtnGroup,
} from './styles';

type NavBarProps = {
  content?: React.ReactNode;
};

export const NavBar = ({ content }: NavBarProps) => <StyledNavBar>{content}</StyledNavBar>;

NavBar.GridLeft = GridLeft;
NavBar.GridRight = GridRight;
NavBar.Heading = Heading;
NavBar.IconBtnGroup = IconBtnGroup;
NavBar.TextBtnGroup = TextBtnGroup;

NavBar.EscalateJob = EscalateJobModal;

const Help = (props: Omit<ButtonProps, 'variant' | 'icon' | 'ref'>) => (
  <Button {...props} icon="help" variant="unstyled" />
);
NavBar.Help = Help;

const Comments = (props: Omit<ButtonProps, 'variant' | 'icon' | 'ref'>) => (
  <Button {...props} icon="messageCircle" variant="unstyled" />
);
NavBar.Comments = Comments;

type ExitJobProps = {
  job?: any;
};

const ExitJob = ({ job }: ExitJobProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  const handleExit = async () => {
    await createJobCancellation({ job, reason: 'USER_CANCELLED' });
    history.replace('/');
  };

  return (
    <ConfirmModal
      title="Are you sure you want to exit?"
      message="All progress you made on this job will be lost."
      onConfirm={handleExit}
      renderHandle={() => (
        <ExitLink>
          <Icon icon="chevronLeft" />
          <span>Exit</span>
        </ExitLink>
      )}
    />
  );
};
NavBar.ExitJob = ExitJob;

type DeescalateJobProps = {
  job?: any;
  onDeescalated: (...args: any[]) => any;
  commentAdded?: boolean;
};

const DeescalateJob = ({ job, onDeescalated, commentAdded }: DeescalateJobProps) => {
  const handleDeescalate = async () => {
    await deescalateJob({ jobId: job.id });
    await onDeescalated();
  };

  const onUnsuccessfulResolve = () =>
    toast.danger('You must add a comment before resolving a job.');
  return commentAdded ? (
    <ConfirmModal
      title="Are you sure you want to mark this job as resolved?"
      message="This job will be returned to the queue with your comments."
      onConfirm={handleDeescalate}
      renderHandle={() => <Button>Resolve</Button>}
    />
  ) : (
    <Button onClick={onUnsuccessfulResolve}>Resolve</Button>
  );
};
NavBar.DeescalateJob = DeescalateJob;

type BackLinkProps = {
  to: string;
};

const BackLink = ({ to }: BackLinkProps) => (
  <Link to={to}>
    <ExitLink>
      <Icon icon="chevronLeft" width={22} />
      <span>Back</span>
    </ExitLink>
  </Link>
);
NavBar.BackLink = BackLink;

type ViewExecutionProps = {
  arn?: string;
  navbar?: boolean;
};

const ViewExecution = ({ arn }: ViewExecutionProps) => {
  const { permissions } = useAuth();
  if (!permissions?.includes('proof:manage')) return null;
  return (
    <Link to={`/manage/view/${arn}`} target="_blank">
      <Button variant="outlined">View Execution</Button>
    </Link>
  );
};
NavBar.ViewExecution = ViewExecution;

type SaveJobProps = {
  onSave?: (...args: any[]) => any;
  isWorking?: boolean;
};

const SaveJob = ({ onSave, isWorking }: SaveJobProps) => {
  return (
    <Button icon="save" isWorking={isWorking} onClick={onSave} variant="unstyled">
      Save
    </Button>
  );
};
NavBar.SaveJob = SaveJob;
