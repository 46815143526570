import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { popoverAnchorDomId } from 'app';

import { useOnEscapeKeyDown } from 'shared/hooks/on-escape-keydown';
import { useOnOutsideClick } from 'shared/hooks/on-outside-click';
import { calcPopoverPosition } from 'shared/utils/popover';

import { AnimatedContent, StyledPopover } from './styles';

type PopoverPropT = {
  variant?: 'tooltip' | 'dropdown';
  placement?: 'top' | 'right' | 'bottom' | 'left';
  maxWidth?: number;
  maxHeight?: number;
  offsetTop?: number;
  offsetLeft?: number;
  content?: React.ReactNode;
  renderContent?: (...args: any[]) => any;
  children: any;
  interaction?: 'hover' | 'click';
};

export const Popover = ({
  variant = 'tooltip',
  placement = 'top',
  maxWidth = 300,
  maxHeight,
  offsetTop = 0,
  offsetLeft = 0,
  content,
  renderContent = () => null,
  children,
  interaction = 'click',
}: PopoverPropT) => {
  const [isOpen, setIsOpen] = useState(false);

  const linkRef = useRef();
  const popoverRef = useRef<HTMLDivElement>(null);

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  useOnOutsideClick([popoverRef, linkRef], isOpen, () => setIsOpen(false));
  useOnEscapeKeyDown(isOpen, () => setIsOpen(false));

  const setPosition = () => {
    if (!popoverRef.current || !linkRef.current) return;
    popoverRef.current.style.height = 'auto';
    popoverRef.current.style.maxHeight = 'none';

    const { top, left, height } = calcPopoverPosition(popoverRef, linkRef, placement, maxHeight);
    popoverRef.current.style.top = `${top + offsetTop}px`;
    popoverRef.current.style.left = `${left + offsetLeft}px`;
    popoverRef.current.style.height = `${height - offsetTop}px`;
    popoverRef.current.style.maxHeight = `${height - offsetTop}px`;
  };

  useLayoutEffect(setPosition);

  const getDelayedStateChange = () => {
    let timeout: number;

    return (newState: boolean, delay: number) => {
      if (timeout) window.clearTimeout(timeout);
      timeout = window.setTimeout(() => setIsOpen(newState), delay);
    };
  };

  const delayedSetIsOpen = getDelayedStateChange();
  const $root = document.getElementById(popoverAnchorDomId);

  return (
    <>
      {React.cloneElement(children, {
        ...children.props,
        ref: linkRef,
        onClick: () => interaction === 'click' && setIsOpen(!isOpen),
        onMouseEnter: () => interaction === 'hover' && delayedSetIsOpen(true, 0),
        onMouseLeave: () => interaction === 'hover' && delayedSetIsOpen(false, 250),
      })}

      {isOpen &&
        ReactDOM.createPortal(
          <StyledPopover
            variant={variant}
            maxWidth={maxWidth}
            ref={popoverRef}
            onMouseEnter={() => interaction === 'hover' && delayedSetIsOpen(true, 0)}
            onMouseLeave={() => interaction === 'hover' && delayedSetIsOpen(false, 250)}
          >
            <AnimatedContent variant={variant}>
              {content || renderContent({ close: () => setIsOpen(false), setPosition })}
            </AnimatedContent>
          </StyledPopover>,
          //@ts-expect-error it complains here because $root could be null. remove this by looking at how dash solves this
          $root,
        )}
    </>
  );
};
