import { useCallback, useState } from 'react';

import { isFunction } from 'lodash';

export const useMergeState = <T extends Record<string, unknown>>(initialState: T) => {
  const [state, setState] = useState<T>(initialState || {});

  const mergeState = useCallback((newState: Partial<T> | ((input: T) => Partial<T>)) => {
    if (isFunction(newState)) {
      setState((currentState) => ({
        ...currentState,
        ...newState(currentState),
      }));
    } else {
      setState((currentState) => ({
        ...currentState,
        ...newState,
      }));
    }
  }, []);

  return [state, mergeState] as const;
};
