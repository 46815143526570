import React, { useEffect, useState } from 'react';

import { uniqueId } from 'lodash';

import { Pubsub } from '@glean/glean-ui.utils.pubsub';

import { color } from 'shared/styles';

import { CloseIcon, Container, Message, StyledToast, Title } from './styles';

type ToastT = {
  id?: string;
  type?: keyof typeof color;
  title?: string;
  message?: string;
  duration?: number;
};

const pubsub = new Pubsub<ToastT>();

export const Toast = () => {
  const [toasts, setToasts] = useState<ToastT[]>([]);
  useEffect(() => {
    const addToast = ({ type = 'primary', title, message, duration = 6 }: ToastT) => {
      const id = uniqueId('toast-');

      setToasts((currentToasts) => [...currentToasts, { id, type, title, message }]);
      if (duration) {
        setTimeout(() => removeToast(id), duration * 1000);
      }
    };
    pubsub.on(addToast);
    return () => {
      pubsub.off(addToast);
    };
  }, []);
  const removeToast = (id?: string) => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
  };
  return (
    <Container>
      {toasts.map((toast) => (
        <StyledToast key={toast.id} type={toast.type} onClick={() => removeToast(toast.id)}>
          <CloseIcon icon="close" size={0.9} />
          {toast.title && <Title>{toast.title}</Title>}
          {toast.message && <Message>{toast.message}</Message>}
        </StyledToast>
      ))}
    </Container>
  );
};
const show = (toast: ToastT) => pubsub.emit(toast);
export const primary = (title: ToastT['title'], options: Omit<ToastT, 'title'> = {}) =>
  show({ title, ...options });
export const success = (title: ToastT['title'], options: Omit<ToastT, 'title'> = {}) =>
  show({ type: 'success', title, ...options });
export const danger = (title: ToastT['title'], options: Omit<ToastT, 'title'> = {}) =>
  show({ type: 'danger', title, duration: 0, ...options });
export const genericError = () =>
  toast.danger('Something strange happened, please contact our support.');
export const toast = { primary, success, danger, genericError };
