// @ts-nocheck
import React from 'react';

import { invoicePDFCDNHost } from 'shared/constants';

import { Invoice, InvoiceNumber, InvoicePDF, SmallCaption, Top, VendorName } from '../sharedStyles';

export const OriginSection = ({ originData, job }) => (
  <>
    <Top style={{ paddingRight: 0 }}>
      <div>
        <SmallCaption>Origin Vendor</SmallCaption>
        <VendorName>{originData.vendorDisplayName}</VendorName>
      </div>

      <Invoice>
        <InvoiceNumber>
          Invoice
          <span>{originData.invoiceNumber}</span>
        </InvoiceNumber>
      </Invoice>
    </Top>

    <InvoicePDF>
      <iframe
        src={job.fileBucket ? `${invoicePDFCDNHost}/${job.fileKey}` : undefined}
        title="Invoice PDF"
      />
    </InvoicePDF>
  </>
);
