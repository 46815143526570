// @ts-nocheck
import React from 'react';

import { cloneDeep, get, set } from 'lodash';

import { Button } from '@glean/glean-ui.molecules.button';

import { Form } from 'shared/components/form';

import { Buttons } from './styles';

type FormValuesT = {
  document_type: string;
  vendor_legal_name: string;
  vendor_dba_name: string;
  invoice_number: string;
};
export const JobForm = ({
  ocrData,
  onBack,
  onSubmit,
  readOnly,
}: {
  ocrData: {
    invoice: { summary: { record_type: string; number: string } };
    vendor: { id: { legal_name: string; dba_name: string } };
    document: { type: { document_type: string } };
    [key: string]: unknown;
  };
  onBack: () => void;
  onSubmit: (invoice: unknown) => Promise<void>;
  readOnly: boolean;
}) => (
  <Form
    initialValues={
      {
        document_type: get(ocrData, 'document.type.document_type', ''),
        vendor_legal_name: get(ocrData, 'vendor.id.legal_name', ''),
        vendor_dba_name: get(ocrData, 'vendor.id.dba_name', ''),
        invoice_number: get(ocrData, 'invoice.summary.invoice_number', ''),
      } as FormValuesT
    }
    validations={{
      document_type: Form.is.required(),
      vendor_legal_name: Form.is.required(),
      vendor_dba_name: Form.is.required(),
    }}
    onSubmit={(formData: FormValuesT) => {
      const payload = cloneDeep(ocrData);
      set(payload, 'document.type.document_type', formData.document_type.trim());
      set(payload, 'vendor.id.legal_name', formData.vendor_legal_name.trim());
      set(payload, 'vendor.id.dba_name', formData.vendor_dba_name.trim());
      set(payload, 'invoice.summary.invoice_number', formData.invoice_number.trim());
      onSubmit(payload);
    }}
  >
    {({ isSubmitting }: { isSubmitting: boolean }) => (
      <Form.Element>
        <Form.Field.Select
          name="document_type"
          label="Document Type *"
          tip={DocumentTypeTip}
          options={[
            { label: 'Select', value: '' },
            { label: 'Invoice', value: 'INVOICE' },
            { label: 'Billing Statement', value: 'BILLING_STATEMENT' },
            { label: 'Credit Memo', value: 'CREDIT_MEMO' },
            { label: 'Receipt', value: 'RECEIPT' },
            { label: 'Other', value: 'OTHER' },
          ]}
          disabled={readOnly}
        />

        <Form.Field.Input
          name="vendor_legal_name"
          label="Vendor Legal Name *"
          tip={LegalNameTip}
          disabled={readOnly}
        />

        <Form.Field.Input
          name="vendor_dba_name"
          label="Vendor DBA Name *"
          tip={DbaNameTip}
          disabled={readOnly}
        />

        <Form.Field.Input
          name="invoice_number"
          label="Invoice Number"
          tip={InvoiceNumberTip}
          disabled={readOnly}
        />

        <Buttons>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          {readOnly ? null : (
            <Button type="submit" isWorking={isSubmitting}>
              Submit
            </Button>
          )}
        </Buttons>
      </Form.Element>
    )}
  </Form>
);

const DocumentTypeTip = (
  <>
    <p>Represents what kind of record (file) this is:</p>
    <p>Invoices generally have an invoice number and generally has not yet been paid.</p>
    <p>Receipts generally have an order number and has already been paid.</p>
    <p>
      Credit statements represent credits/refunds for a previous charge, so they generally have a
      credit statement number, an invoice number / order number, and only contain credits.
    </p>
  </>
);
const LegalNameTip = (
  <p>
    Name of the legal entity - generally ends with LLC or LTD and usually shows up in the address
    field.
  </p>
);
const DbaNameTip = (
  <>
    <p>
      &apos;Doing business as&apos; name - think of this as the name that shows up to customers,
      generally is the text in the logo or a simplified version of the legal name.
    </p>
    <p>Occasionally, this may show up as a separate field.</p>
  </>
);
const InvoiceNumberTip = (
  <>
    <p>Unique identifier (ID) for the invoice.</p>
    <p>Be sure to copy the entire text (if it starts with #, that should be included as well).</p>
    <p>For receipts, fill in the order number instead.</p>
    <p>For credit statements, fill in the invoice number (not the credit statement number).</p>
  </>
);
