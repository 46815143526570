import { useEffect, useState } from 'react';

import { CommentDTO } from 'shared/api/generated/models/CommentDTO';

export const useCommentsData = ({
  comments,
  restartCategory,
}: {
  comments?: CommentDTO[];
  restartCategory?: any;
}) => {
  const [commentsData, setCommentsData] = useState<{
    hasComments: boolean;
    commentsOpen: boolean | null;
  }>({
    hasComments: false,
    commentsOpen: null,
  });

  useEffect(() => {
    const hasComments = !!comments && comments.length > 0;
    setCommentsData({
      hasComments,
      commentsOpen: hasComments || restartCategory ? true : null,
    });
  }, [comments, restartCategory]);

  return {
    commentsData,
    setCommentsData,
  };
};
