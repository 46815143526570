import React, { useContext } from 'react';

import { reverse } from 'lodash';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { ExtractValidateContext, ValidationFlagT } from '../../state';
import {
  getActiveValidationFlags,
  getValidationFlagStep,
  isFlagActive,
  sortValidationFlags,
} from '../../utils';
import { Container, Label, PrevNextIcon } from './styles';

export const PrevNextValidationFlags = () => {
  const [{ currentStep, validationFlags, focusedFlag, formValues }, dispatch] = useContext(
    ExtractValidateContext,
  );

  const focusedFlagStep = focusedFlag ? getValidationFlagStep(focusedFlag) : null;
  const activeFlags = getActiveValidationFlags(validationFlags);
  const sortedFlags = sortValidationFlags(validationFlags, formValues.line_items?.line_items);
  const sortedActiveFlags = sortValidationFlags(activeFlags, formValues.line_items?.line_items);

  const getFocusedIndex = (flags: ValidationFlagT[]) =>
    flags.findIndex(({ flag_id }: any) => flag_id === (focusedFlag || {}).flag_id);
  const focusedFlagIndex = getFocusedIndex(sortedFlags);
  const focusedActiveFlagIndex = getFocusedIndex(sortedActiveFlags);

  let prevFlag: ValidationFlagT | null = null;
  let nextFlag: ValidationFlagT | null = sortedActiveFlags[0];

  if (focusedFlag) {
    prevFlag = reverse(sortedFlags.slice(0, focusedFlagIndex)).find(isFlagActive) || null;
    nextFlag = sortedFlags.slice(focusedFlagIndex + 1).find(isFlagActive) || null;
  }

  const getLabel = () => {
    if (sortedActiveFlags.length === 0) {
      return 'All Done';
    }
    if (focusedActiveFlagIndex === -1) {
      const pluralizedFlag = sortedActiveFlags.length === 1 ? 'Flag' : 'Flags';
      return `${sortedActiveFlags.length} ${pluralizedFlag} Left`;
    }
    return `${focusedActiveFlagIndex + 1} / ${sortedActiveFlags.length}`;
  };

  const changeFlag = (flag: ValidationFlagT | null) => {
    if (focusedFlag && focusedFlagStep !== currentStep) {
      dispatch({ type: 'CHANGE_FOCUS_FLAG', payload: { flag: focusedFlag } });
    } else if (flag) {
      dispatch({ type: 'CHANGE_FOCUS_FLAG', payload: { flag } });
    }
  };

  return (
    <Container>
      <PrevNextIcon
        icon="arrowLeft"
        disabled={!prevFlag}
        onClick={() => {
          changeFlag(prevFlag);
        }}
      />

      <Label>
        <Icon icon="flag" size={0.9} />
        <span>{getLabel()}</span>
      </Label>

      <PrevNextIcon
        icon="arrowRight"
        disabled={!nextFlag}
        onClick={() => {
          changeFlag(nextFlag);
        }}
      />
    </Container>
  );
};
