import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '@glean/glean-ui.atoms.icon';

import { useGetExecutionInvoiceJsonsForStep } from 'shared/api';
import { CompanyDTO, EventT, SingleExecutionDTO } from 'shared/api/generated';
import { CommentDTO } from 'shared/api/generated/models/CommentDTO';
import { Comments } from 'shared/components/comments';
import { Tabs } from 'shared/components/tabs';
import { ExecutionMetadataT } from 'shared/types';
import { formatDateTime } from 'shared/utils/date-time';

import {
  ARN,
  Box,
  Caption,
  CommentsContainer,
  DropdownHandle,
  FormattedJSON,
  Heading,
  Item,
  JSONFileName,
  JSONRow,
  Left,
  LinkIconLayout,
  List,
  Right,
  TabLink,
  TabsLinks,
  Value,
} from './styles';

export const Content = ({
  company,
  execution,
  selectedEvent,
  executionEvents = {},
  comments,
}: {
  company?: CompanyDTO;
  execution: SingleExecutionDTO;
  comments?: CommentDTO[];
  selectedEvent?: EventT;
  executionEvents?: ExecutionMetadataT;
}) => {
  const [parentTab, setParentTab] = useState<'details' | 'comments' | 'json-table'>('details');
  const [childTab, setChildTab] = useState<'audit' | 'pipeline'>('audit');
  const step = selectedEvent?.status
    .replace(/proof_detail-/gi, 'PROOF_')
    .replace(/proof_header-/gi, 'PROOF_');

  const { data, isLoading } = useGetExecutionInvoiceJsonsForStep({
    executionArn: execution.executionArn,
    step,
  });

  const stepJson = data?.[0]?.data;

  const { escalations, restartCategory, restartReason, startingStep } = executionEvents;
  const [viewAllEscalations, setViewAllEscalations] = useState(false);

  function renderComplexOptions(options: { commentText?: string; reason?: string }[]) {
    const optionsToRender =
      options.length < 2 || viewAllEscalations ? options : options.slice(0, 2);

    return (
      <>
        <List>
          {optionsToRender.map(({ commentText, reason }, idx) => (
            <li key={`option-${idx}`}>
              <div>{reason}</div>
              <p className="subValue">{commentText}</p>
            </li>
          ))}
        </List>
        {options.length > 2 && (
          <DropdownHandle onClick={() => setViewAllEscalations(!viewAllEscalations)}>
            View {viewAllEscalations ? 'less' : 'all'}
            <Icon icon={viewAllEscalations ? 'chevronUp' : 'chevronDown'} size="xxsmall" />
          </DropdownHandle>
        )}
      </>
    );
  }
  const executionArn = execution.arn || execution.executionArn;

  return (
    <>
      <Box>
        <Heading>Summary</Heading>
        <ARN>
          <Caption className="arnCaption">ARN:</Caption>
          <a
            href={`https://us-east-2.console.aws.amazon.com/states/home?region=us-east-2#/executions/details/${executionArn}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {executionArn}
          </a>
        </ARN>

        <Left>
          <Item>
            <Caption>Company Name:</Caption>
            <Value>{company?.display_name || '-'}</Value>
          </Item>
          <Item>
            <Caption>Current Step:</Caption>
            <Value>
              <ExecutionStep execution={execution} />
            </Value>
          </Item>
          <Item>
            <Caption>Restart Reason:</Caption>
            <Value>
              <div>{restartCategory ? restartCategory : '-'}</div>
              {restartReason && <div className="subValue">{restartReason}</div>}
            </Value>
          </Item>
          <Item>
            <Caption>Restart Step:</Caption>
            <Value>{restartCategory ? startingStep : '-'}</Value>
          </Item>
        </Left>

        <Right>
          <Item>
            <Caption>Started:</Caption>
            <Value>{formatDateTime(execution.startDate) || '-'}</Value>
          </Item>
          <Item>
            <Caption>Finished:</Caption>
            <Value>{formatDateTime(execution.stopDate) || '-'}</Value>
          </Item>
          <Item>
            <Caption>Escalated Reasons:</Caption>
            <Value>
              {(escalations || []).length > 0 ? renderComplexOptions(escalations || []) : '-'}
            </Value>
          </Item>
        </Right>
      </Box>

      {selectedEvent && (
        <Box>
          <TabsLinks>
            <TabLink
              active={parentTab === 'details'}
              onClick={() => setParentTab('details')}
              className="leftAlign"
            >
              Step Details
            </TabLink>
            <TabLink active={parentTab === 'comments'} onClick={() => setParentTab('comments')}>
              Comments
            </TabLink>
          </TabsLinks>
          {parentTab === 'details' && (
            <>
              <Left>
                <Item>
                  <Caption>Step Status:</Caption>
                  <Value>{selectedEvent.status || '-'}</Value>
                </Item>
                <Item>
                  <Caption>Event Name:</Caption>
                  <Value>
                    {selectedEvent.data && 'eventName' in selectedEvent.data
                      ? selectedEvent.data['eventName']
                      : '-'}
                  </Value>
                </Item>
              </Left>

              <Right>
                <Item>
                  <Caption>Started:</Caption>
                  <Value>{formatDateTime(selectedEvent.timestamp)}</Value>
                </Item>
              </Right>

              <TabsLinks className="secondTabs">
                <Tabs.Link
                  active={childTab === 'audit'}
                  onClick={() => setChildTab('audit')}
                  className="leftAlign"
                >
                  Elastic Audit Log
                </Tabs.Link>
                <Tabs.Link active={childTab === 'pipeline'} onClick={() => setChildTab('pipeline')}>
                  Pipeline API Step JSON
                </Tabs.Link>
              </TabsLinks>

              {childTab === 'audit' && (
                <JSONRow>
                  <FormattedJSON>{JSON.stringify(selectedEvent, undefined, 2)}</FormattedJSON>
                </JSONRow>
              )}
              {childTab === 'pipeline' && (
                <JSONRow>
                  <JSONFileName>{step}</JSONFileName>
                  <FormattedJSON>
                    {stepJson
                      ? JSON.stringify(stepJson, null, 2)
                      : isLoading
                      ? 'Loading...'
                      : `No step data for ${step}`}
                  </FormattedJSON>
                </JSONRow>
              )}
            </>
          )}
          {parentTab === 'comments' && (
            <CommentsContainer>
              <Comments comments={comments ? comments : []} commentPadding="executionComment" />
            </CommentsContainer>
          )}
        </Box>
      )}
    </>
  );
};

const ExecutionStep = ({ execution }: { execution: SingleExecutionDTO }) => {
  const { executionStep } = execution;
  if (executionStep.toLowerCase().includes('proof')) {
    const event = execution.events.find((event) => event.status === executionStep);
    const proofData = event?.data;
    if (proofData && 'eventName' in proofData && 'jobId' in proofData) {
      const { eventName, jobId } = proofData as { eventName: string; jobId: string };
      const [jobType] = eventName.split('_');
      return (
        <Link to={`/job/${jobType}/${jobId}`} target="_blank">
          <LinkIconLayout>
            {executionStep} <Icon icon="externalLink" size="xxxsmall" />
          </LinkIconLayout>
        </Link>
      );
    }
  }

  return <>{executionStep}</>;
};
