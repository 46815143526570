import React from 'react';

import { CommentDTO } from 'shared/api/generated/models/CommentDTO';
import { Emoji } from 'shared/components/emoji';
import { GleanLogoIcon } from 'shared/components/glean-logo-icon';

import {
  CommentsContainer,
  Details,
  NoComments,
  NoCommentsContainer,
  StyledComment,
} from './styles';

function formatDate(createdAt: number | string | Date) {
  const date = new Date(createdAt);
  const UTCdate = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${UTCdate} ${month} ${year}`;
}

export type CommentPaddingT = 'sidebarReg' | 'sidebarSm' | 'executionComment';

const Comment = ({ comment, padding }: { comment: CommentDTO; padding: CommentPaddingT }) => {
  const { author, commentText, createdAt, jobType, gleanResponse } = comment;
  const jobString = jobType ? jobType.charAt(0).toUpperCase() + jobType.slice(1) : null;
  const date = formatDate(createdAt);

  return (
    <StyledComment padding={padding}>
      <p>{commentText}</p>
      <Details>
        {gleanResponse && <GleanLogoIcon width={12} className="gleanLogo" />}
        <span className="author">{author}</span>
        {createdAt ? <span>{date}</span> : null}
        {jobType ? <span>{jobString}</span> : null}
      </Details>
    </StyledComment>
  );
};

type CommentsProps = {
  error?: {
    error?: Error;
    errorMessage?: string;
  };
  comments: CommentDTO[];
  commentPadding?: CommentPaddingT;
};

export const Comments = ({
  comments = [],
  error,
  commentPadding = 'sidebarReg',
}: CommentsProps) => {
  const text = error ? 'Unable to display comments at this time ' : 'No comments so far ';
  const label = error ? 'alert' : 'shrug';
  const symbol = error ? '🚨' : '🤷';

  return (
    <>
      {comments.length !== 0 ? (
        <CommentsContainer>
          {comments.map((comment) => (
            <Comment comment={comment} key={comment.commentId} padding={commentPadding} />
          ))}
        </CommentsContainer>
      ) : (
        <NoCommentsContainer>
          <NoComments>
            {text}
            <Emoji label={label} symbol={symbol} />
          </NoComments>
        </NoCommentsContainer>
      )}
    </>
  );
};
