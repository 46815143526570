import React, { forwardRef } from 'react';

export const GleanLogoIcon = forwardRef<SVGSVGElement, { className?: string; width: number }>(
  ({ className, width, ...otherProps }, ref) => (
    <svg className={className} width={`${width}px`} viewBox="0 0 22 22" ref={ref} {...otherProps}>
      <path
        fill="currentColor"
        d="M16.5 13.754H8.25V8.25H22V22h-5.5v-8.245zM8.25 0h5.5v5.5h-5.5V0zm0 16.5h5.5V22h-5.5v-5.5zM16.5 0H22v5.5h-5.5V0zM0 0h5.5v22H0V0z"
      />
    </svg>
  ),
);
GleanLogoIcon.displayName = 'GleanLogoIcon';
