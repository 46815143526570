import React from 'react';

import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { useCanonicalVendor } from 'shared/api';
import { Avatar } from 'shared/components/avatar';
import { NavBar } from 'shared/components/nav-bar';
import { PageLoader } from 'shared/components/page-loader';
import { PageMessage } from 'shared/components/page-message';
import { Tabs } from 'shared/components/tabs';

import { Details } from './details';
import { LineItems } from './line-items';
import { Container, Header, VendorName } from './styles';

export const ManageVendorView = () => {
  const match = useRouteMatch();
  const { vendorId } = useParams<{ vendorId: string }>();

  const { data: vendor, isLoading } = useCanonicalVendor({ canonicalVendorId: vendorId });

  if (isLoading) return <PageLoader message="Fetching vendor data..." />;
  if (!vendor) return <PageMessage />;

  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.BackLink to="/manage/canonical-vendors" />
            <NavBar.Heading>Manage</NavBar.Heading>
            <NavBar.Help />
          </>
        }
      />

      <Container>
        <Header>
          <Avatar src={vendor.logo} alt={vendor.display_name} size={42} />
          <VendorName>{vendor.display_name}</VendorName>
        </Header>

        <Tabs.Links large style={{ padding: '30px 0 25px' }}>
          <Tabs.Link to={`${match.url}/vendor-details`}>Vendor Details</Tabs.Link>
          <Tabs.Link to={`${match.url}/line-items`}>Line Items</Tabs.Link>
        </Tabs.Links>

        <Switch>
          <Redirect exact from={match.path} to={`${match.url}/vendor-details`} />
          <Route path={`${match.path}/vendor-details`} render={() => <Details vendor={vendor} />} />
          <Route path={`${match.path}/line-items`} render={() => <LineItems vendor={vendor} />} />
        </Switch>
      </Container>
    </>
  );
};
