import React from 'react';

import { history } from 'browserHistory';

import { Button } from '@glean/glean-ui.molecules.button';

import { Form } from 'shared/components/form';

import { ButtonContainer, Inner, Outer } from './styles';

export const EditInvoices = () => {
  return (
    <Outer>
      <Inner>
        <h2>Edit Invoice</h2>
        <h3>Enter Invoice ID</h3>
        <Form
          initialValues={{
            invoiceId: '',
          }}
          validations={{
            invoiceId: [Form.is.required('An invoice ID is required to continue'), Form.is.uuid()],
          }}
          onSubmit={async ({ invoiceId }) => {
            history.push(`/manage/edit/${invoiceId}`);
          }}
        >
          {({ submitForm }: { submitForm: () => void }) => (
            <>
              <Form.Field.Input name="invoiceId" placeholder="Enter an invoice Id ..." />
              <ButtonContainer>
                <Button onClick={submitForm}>Submit</Button>
              </ButtonContainer>
            </>
          )}
        </Form>
      </Inner>
    </Outer>
  );
};
