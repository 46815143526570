import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { updateJobExecution, useMutation } from 'shared/api';
import { ConfirmModal } from 'shared/components/confirm-modal';
import { toast } from 'shared/components/toast';

type InactivityModalProps = {
  jobId: string;
};
export const InactivityModal = ({ jobId }: InactivityModalProps) => {
  const history = useHistory();
  const lastActiveTime = useRef(now());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: requestLockExtension } = useMutation(() => updateJobExecution({ jobId }), {
    onError: (error) => {
      if ((error as any).response.status === 409) {
        history.push('/');
        toast.danger(
          'You have been inactive for too long and the job was put back into the queue. Please take another job.',
        );
      }
    },
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });
  useEffect(() => {
    const updateLastActiveTime = () => {
      lastActiveTime.current = now();
    };
    document.addEventListener('keydown', updateLastActiveTime);
    document.addEventListener('mousemove', updateLastActiveTime);
    const interval = setInterval(() => {
      if (now() - lastActiveTime.current > minutes(10)) {
        setIsModalOpen(true);
      } else {
        requestLockExtension();
      }
    }, minutes(14));
    return () => {
      document.removeEventListener('keydown', updateLastActiveTime);
      document.removeEventListener('mousemove', updateLastActiveTime);
      clearInterval(interval);
    };
  }, [jobId, requestLockExtension]);

  return (
    <ConfirmModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title="Are you still here?"
      message="Please confirm or this job will be given to someone else."
      onConfirm={requestLockExtension}
    />
  );
};
const now = () => Date.now();
const minutes = (num: any) => 1000 * 60 * num;
