import styled from 'styled-components';

import { font } from 'shared/styles';

export const StyledPageLoader = styled.div<{ padding: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(props) => props.padding}px 50px;
  text-align: center;
`;
export const Message = styled.div`
  padding-top: 30px;
  line-height: 1.4;
  ${font.bold}
  ${font.size(20)}
`;
