import styled from 'styled-components';

import { font } from 'shared/styles';

export const Heading = styled.span`
  ${font.medium}
  font-style: normal;
  ${font.size(18)}
  line-height: 24px;
`;
