import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;

  button {
    min-width: 100px;
  }
`;
