import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Icon } from '@glean/glean-ui.atoms.icon';

import * as api from 'shared/api';
import { CommentsStream } from 'shared/components/comments-stream';
import { Modal } from 'shared/components/modal';
import { NavBar } from 'shared/components/nav-bar';
import { StatusBadge } from 'shared/components/status-badge/styles';
import { toast } from 'shared/components/toast';
import { invoicePDFCDNHost } from 'shared/constants';
import { useCommentsData } from 'shared/hooks/comments-meta-data';
import { JobT } from 'shared/types/job';

import { JobForm } from './job-form';
import {
  Buttons,
  Container,
  DropdownHandle,
  IdentifyInfo,
  Instructions,
  InvoicePDF,
  JobFormContainer,
  JobId,
  Right,
  StyledButton,
} from './styles';

export const Job = ({
  data,
  fetchJob,
  deescalating,
}: {
  data: {
    job: JobT;
    ocrData: {
      invoice: { summary: { record_type: string; number: string } };
      vendor: { id: { legal_name: string; dba_name: string } };
      document: { type: { document_type: string } };
      [key: string]: unknown;
    };
  };
  fetchJob: () => Promise<void>;
  deescalating: boolean;
}) => {
  const { job } = data;
  const { executionArn, jobType } = job;

  const [isCanceling, setIsCanceling] = useState(false);
  const [areInstructionsOpen, setAreInstructionsOpen] = useState(false);
  const [startedProcessing, setStartedProcessing] = useState(deescalating);

  const { data: executionEvents, error, isFetching, refetch: refetchComments } = api.useQuery({
    config: {},
    notifyOnNetworkStatusChange: true,
    queryKey: `execution-comments-${executionArn}`,
    queryFn: () => api.getComments({ executionArn }),
    transformData: (data: any) => {
      const { comments, escalations, restartCategory, restartReason, startingStep } = data?.data;
      return {
        comments,
        escalations,
        restartCategory,
        restartReason,
        startingStep,
      };
    },
  });

  const { commentsData, setCommentsData } = useCommentsData({
    comments: executionEvents?.comments,
    restartCategory: executionEvents?.restartCategory,
  });
  const [commentAdded, setCommentAdded] = useState(false);
  const handleSubmitComment = async (commentText: string) => {
    try {
      await api.addComment({ executionArn, jobType, commentText, deescalating });
      setCommentAdded(true);
      refetchComments();
    } catch (e) {
      toast.danger(e.message);
    }
  };

  const handleSubmit = async (invoice: unknown) => {
    await api.createJobCompletion({ invoice, job });
    toast.success('The job was completed successfully.');
    await fetchJob();
  };

  const handleNotInvoice = async () => {
    setIsCanceling(true);
    job.notInvoice = true;
    await api.handleNotInvoice({ job });
    await fetchJob();
  };

  const handleEscalate = async () => {
    toast.success('This job has been escalated.');
    await fetchJob();
  };

  return (
    <>
      <NavBar
        content={
          <>
            <NavBar.GridLeft>
              <NavBar.ExitJob job={job} />
              {deescalating && <StatusBadge backgroundColor="danger">Escalated</StatusBadge>}
            </NavBar.GridLeft>
            <NavBar.Heading>Identify</NavBar.Heading>
            <NavBar.GridRight>
              <NavBar.IconBtnGroup>
                <NavBar.Comments
                  className={commentsData.commentsOpen ? 'selected' : ''}
                  onClick={() =>
                    setCommentsData({ ...commentsData, commentsOpen: !commentsData.commentsOpen })
                  }
                />
                <Modal
                  renderHandle={() => <NavBar.Help />}
                  renderContent={(modal) => (
                    <IdentifyInfo>
                      <h2>Identify</h2>
                      <p>
                        Proof Identify is the first step that efficiently route invoices and
                        determine a more optimal field layout in later stages of the pipeline! It
                        involves two steps:
                      </p>
                      <p>
                        1. Determining whether or not we should process the file (see the
                        instructions there for more details).
                      </p>
                      <p>
                        2. Identifying the vendor, file type, and invoice number (if available).
                      </p>
                    </IdentifyInfo>
                  )}
                />
              </NavBar.IconBtnGroup>
              <NavBar.TextBtnGroup>
                {!deescalating && <NavBar.EscalateJob onEscalate={handleEscalate} job={job} />}
                <NavBar.ViewExecution arn={executionArn} navbar={true} />
                {deescalating && (
                  <NavBar.DeescalateJob
                    onDeescalated={fetchJob}
                    job={job}
                    commentAdded={commentAdded}
                  />
                )}
              </NavBar.TextBtnGroup>
            </NavBar.GridRight>
          </>
        }
      />

      <Container>
        <InvoicePDF>
          <iframe
            src={job.fileBucket ? `${invoicePDFCDNHost}/${job.fileKey}` : undefined}
            title="Invoice PDF"
          />
        </InvoicePDF>
        <Right>
          <CommentsStream
            error={error}
            comments={executionEvents?.comments || []}
            executionEvents={executionEvents}
            handleSubmitComment={handleSubmitComment}
            isFetching={isFetching}
            open={commentsData.commentsOpen}
          />

          <JobId>Job # {job.id}</JobId>
          {!startedProcessing ? (
            <>
              <DropdownHandle onClick={() => setAreInstructionsOpen(!areInstructionsOpen)}>
                Should we process this?
                <Icon icon={areInstructionsOpen ? 'chevronUp' : 'chevronDown'} />
              </DropdownHandle>

              {areInstructionsOpen && (
                <Instructions>
                  <h5>Process:</h5>
                  <ul>
                    <li>Invoices</li>
                    <li>receipts</li>
                    <li>credit statements</li>
                  </ul>
                  <h5>Do not process if:</h5>
                  <ul>
                    <li>
                      This doesn&apos;t contain any charges or credits (basically, if it&apos;s a
                      random file)
                    </li>
                    <li>
                      This is for future services or products (basically, it&apos;s more of a
                      confirmation of what will be performed and charged later, but hasn&apos;t
                      occurred yet)
                    </li>
                  </ul>
                </Instructions>
              )}

              <Buttons>
                <StyledButton onClick={() => setStartedProcessing(true)}>
                  Start Processing
                </StyledButton>
                <StyledButton variant="outlined" isWorking={isCanceling} onClick={handleNotInvoice}>
                  Do Not Process
                </StyledButton>
              </Buttons>
            </>
          ) : (
            <JobFormContainer>
              <JobForm
                ocrData={data.ocrData}
                onBack={() => setStartedProcessing(false)}
                onSubmit={handleSubmit}
                readOnly={deescalating}
              />
            </JobFormContainer>
          )}
        </Right>
      </Container>
    </>
  );
};

Job.propTypes = {
  data: PropTypes.shape({
    job: PropTypes.shape({
      executionArn: PropTypes.string,
      fileBucket: PropTypes.string,
      fileKey: PropTypes.string,
      hasComments: PropTypes.bool,
      id: PropTypes.string,
      jobType: PropTypes.string,
      notInvoice: PropTypes.bool,
    }),
    ocrData: PropTypes.shape({}),
  }),
  deescalating: PropTypes.bool,
  fetchJob: PropTypes.func,
};
