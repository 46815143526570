import { useState } from 'react';

export type PaginationProps = {
  currentPage: number;
  totalCount: number;
  pageCount: number;
  itemsPerPage: number;
  onChange: (newPage: number) => void;
};

export const usePagination = <T extends any>({
  items,
  itemsPerPage = 10,
  defaultPage = 1,
}: {
  items: T[];
  itemsPerPage?: number;
  defaultPage?: number;
}) => {
  const [currentPage, setCurrentPage] = useState(defaultPage);

  const offset = (currentPage - 1) * itemsPerPage;

  const paginatedItems = items.slice(offset, offset + itemsPerPage);

  const pagination: PaginationProps = {
    currentPage,
    totalCount: items.length,
    pageCount: Math.ceil(items.length / itemsPerPage),
    itemsPerPage,
    onChange: setCurrentPage,
  };

  return { pagination, paginatedItems };
};
