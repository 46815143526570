import React from 'react';

import { Button } from '@glean/glean-ui.molecules.button';

import { Modal } from 'shared/components/modal';

import { Bottom, Inner } from './styles';

export const ActiveFlagsModal = ({ onClose }: any) => (
  <Modal
    isOpen
    onClose={onClose}
    renderContent={(modal) => (
      <Inner>
        <h3>Active Validation Flags</h3>
        <p>
          In order to submit this job, please resolve or override all remaining validation flags.
        </p>

        <Bottom>
          <Button onClick={modal.close}>OK</Button>
        </Bottom>
      </Inner>
    )}
  />
);
