/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanonicalLineItemDetailT
 */
export interface CanonicalLineItemDetailT {
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDetailT
     */
    canonical_line_item_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDetailT
     */
    canonical_line_item_type?: CanonicalLineItemDetailTCanonicalLineItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDetailT
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDetailT
     */
    is_license?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDetailT
     */
    is_overage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDetailT
     */
    is_fail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDetailT
     */
    has_constrained_capacity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CanonicalLineItemDetailT
     */
    canonical_vendor_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CanonicalLineItemDetailT
     */
    line_item_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CanonicalLineItemDetailT
     */
    invoice_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CanonicalLineItemDetailT
     */
    company_count?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CanonicalLineItemDetailT
     */
    is_staging?: boolean;
}

export function CanonicalLineItemDetailTFromJSON(json: any): CanonicalLineItemDetailT {
    return CanonicalLineItemDetailTFromJSONTyped(json, false);
}

export function CanonicalLineItemDetailTFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanonicalLineItemDetailT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonical_line_item_id': !exists(json, 'canonical_line_item_id') ? undefined : json['canonical_line_item_id'],
        'canonical_line_item_type': !exists(json, 'canonical_line_item_type') ? undefined : json['canonical_line_item_type'],
        'name': json['name'],
        'is_license': !exists(json, 'is_license') ? undefined : json['is_license'],
        'is_overage': !exists(json, 'is_overage') ? undefined : json['is_overage'],
        'is_fail': !exists(json, 'is_fail') ? undefined : json['is_fail'],
        'has_constrained_capacity': !exists(json, 'has_constrained_capacity') ? undefined : json['has_constrained_capacity'],
        'canonical_vendor_id': !exists(json, 'canonical_vendor_id') ? undefined : json['canonical_vendor_id'],
        'line_item_count': !exists(json, 'line_item_count') ? undefined : json['line_item_count'],
        'invoice_count': !exists(json, 'invoice_count') ? undefined : json['invoice_count'],
        'company_count': !exists(json, 'company_count') ? undefined : json['company_count'],
        'is_staging': !exists(json, 'is_staging') ? undefined : json['is_staging'],
    };
}

export function CanonicalLineItemDetailTToJSON(value?: CanonicalLineItemDetailT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonical_line_item_id': value.canonical_line_item_id,
        'canonical_line_item_type': value.canonical_line_item_type,
        'name': value.name,
        'is_license': value.is_license,
        'is_overage': value.is_overage,
        'is_fail': value.is_fail,
        'has_constrained_capacity': value.has_constrained_capacity,
        'canonical_vendor_id': value.canonical_vendor_id,
        'line_item_count': value.line_item_count,
        'invoice_count': value.invoice_count,
        'company_count': value.company_count,
        'is_staging': value.is_staging,
    };
}

/**
* @export
* @enum {string}
*/
export enum CanonicalLineItemDetailTCanonicalLineItemTypeEnum {
    BYDAY = 'BY_DAY',
    BYVOLUME = 'BY_VOLUME',
    CONSOLIDATED = 'CONSOLIDATED'
}


