/* tslint:disable */
/* eslint-disable */
/**
 * proof-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceT,
    InvoiceTFromJSON,
    InvoiceTFromJSONTyped,
    InvoiceTToJSON,
    LineItemT,
    LineItemTFromJSON,
    LineItemTFromJSONTyped,
    LineItemTToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditGetInvoiceDTO
 */
export interface EditGetInvoiceDTO {
    /**
     * 
     * @type {InvoiceT}
     * @memberof EditGetInvoiceDTO
     */
    invoice: InvoiceT;
    /**
     * 
     * @type {Array<LineItemT>}
     * @memberof EditGetInvoiceDTO
     */
    lineItems: Array<LineItemT>;
}

export function EditGetInvoiceDTOFromJSON(json: any): EditGetInvoiceDTO {
    return EditGetInvoiceDTOFromJSONTyped(json, false);
}

export function EditGetInvoiceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditGetInvoiceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoice': InvoiceTFromJSON(json['invoice']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemTFromJSON)),
    };
}

export function EditGetInvoiceDTOToJSON(value?: EditGetInvoiceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoice': InvoiceTToJSON(value.invoice),
        'lineItems': ((value.lineItems as Array<any>).map(LineItemTToJSON)),
    };
}


