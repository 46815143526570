import { useQuery } from 'react-query';

import { apiClient } from '..';

export const useLineItemStp = ({
  executionArn,
  fileKey,
}: {
  executionArn: string;
  fileKey: string;
}) =>
  useQuery({
    refetchOnWindowFocus: false,
    queryFn: () =>
      apiClient.mapServiceLineItemStp({
        executionArn,
        fileKey,
      }),
    queryKey: ['line-item-stp', executionArn, fileKey],
  });
