import styled from 'styled-components';

import { font, padding, sizes } from 'shared/styles';

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: ${sizes.navbarHeight}px ${padding.lrg};
`;
Container.displayName = 'Container';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0 10px;
`;
Header.displayName = 'Header';

export const VendorName = styled.div`
  padding-left: 16px;
  ${font.size(26)}
`;
VendorName.displayName = 'VendorName';
