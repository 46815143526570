import { useMutation, useQuery, useQueryClient } from 'react-query';

import { apiClient } from '..';
import {
  CanonicalLineItemPostT,
  CanonicalVendorMergedDataDTO,
  CanonicalVendorT,
} from '../generated';

export const useCanonicalVendors = () =>
  useQuery({
    queryKey: ['canonical-vendors'],
    queryFn: () => apiClient.canonicalVendorsServiceGetCanonicalVendors(),
  });

export const useCanonicalVendor = ({ canonicalVendorId }: { canonicalVendorId: string }) =>
  useQuery({
    queryKey: ['canonical-vendors', canonicalVendorId],
    queryFn: () => apiClient.canonicalVendorsServiceGetCanonicalVendorById({ canonicalVendorId }),
  });

export const useCanonicalVendorLineItems = ({ canonicalVendorId }: { canonicalVendorId: string }) =>
  useQuery({
    queryKey: ['canonical-vendors', canonicalVendorId, 'canonical-line-items'],
    queryFn: () =>
      apiClient.canonicalVendorsServiceGetCanonicalLineItemsByCanonicalVendorId({
        canonicalVendorId,
      }),
  });

export const useCreateCanonicalVendor = () =>
  useMutation((input: CanonicalVendorT) =>
    apiClient.canonicalVendorsServiceCreateCanonicalVendor({
      input,
    }),
  );

export const useUpdateCanonicalVendor = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: { input: CanonicalVendorT; canonicalVendorId: string }) =>
      apiClient.canonicalVendorsServiceUpdateCanonicalVendor({
        canonicalVendorId: variables.canonicalVendorId,
        canonicalVendor: variables.input,
      }),
    {
      onSuccess: (_res, variables) =>
        queryClient.invalidateQueries(['canonical-vendors', variables.canonicalVendorId]),
    },
  );
};
export const useCreateCanonicalLineItem = () =>
  useMutation(
    ({
      canonicalLineItem,
      canonicalVendorId,
    }: {
      canonicalLineItem: CanonicalLineItemPostT;
      canonicalVendorId: string;
    }) =>
      apiClient.canonicalVendorsServiceCreateCanonicalLineItem({
        canonicalLineItem,
        canonicalVendorId,
      }),
  );

export const useUpdateCanonicalLineItem = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      canonicalLineItemId,
      canonicalVendorId,
      canonicalLineItem,
    }: {
      canonicalLineItemId: string;
      canonicalVendorId: string;
      canonicalLineItem: CanonicalLineItemPostT;
    }) =>
      apiClient.canonicalVendorsServiceUpdateCanonicalLineItem({
        canonicalLineItemId,
        canonicalVendorId,
        input: canonicalLineItem,
      }),
    {
      onSettled: (_res, _error, variables) => {
        queryClient.invalidateQueries([
          'canonical-vendors',
          variables.canonicalVendorId,
          'canonical-line-items',
        ]);
      },
    },
  );
};

export const useMergeCanonicalVendors = () =>
  useMutation(
    ({ canonicalVendorId, toMergeIds }: { canonicalVendorId: string; toMergeIds: string[] }) =>
      apiClient.canonicalVendorsServiceMergeVendors({
        input: {
          canonicalVendorId,
          toMergeIds,
        },
      }),
  );

export const useMergeCanonicalLineItems = () =>
  useMutation(
    ({ canonicalLineItemId, toMergeIds }: { canonicalLineItemId: string; toMergeIds: string[] }) =>
      apiClient.canonicalVendorsServiceMergeLineItems({
        input: {
          canonicalLineItemId,
          toMergeIds,
        },
      }),
  );

export const useGetMergedCanonicalVendorId = ({
  originalCanonicalVendorId,
  onSuccess,
}: {
  originalCanonicalVendorId: string;
  onSuccess: (data: CanonicalVendorMergedDataDTO) => void;
}) =>
  useQuery({
    queryFn: () =>
      apiClient.mapServiceGetCanonicalVendorMergedData({
        canonicalVendorId: originalCanonicalVendorId,
      }),
    onSuccess,
  });
