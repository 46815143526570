import React, { useContext } from 'react';

import { ExtractValidateContext } from 'views/job/extract-validate/state';

import { Divider, Field, Grid, Label } from '../shared';

export const Shipping = () => {
  return (
    <>
      <Label>SHIPPING INFO</Label>
      <Divider />
      <Grid>
        <Field name="invoice.shipping.ship_to_name" />
        <Field name="invoice.shipping.ship_to_address" textarea />
        <Field name="invoice.shipping.shipper" />
        <Field name="invoice.shipping.tracking_number" />
        <Field name="invoice.shipping.ship_from_name" />
      </Grid>
    </>
  );
};
